import React from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import styles from "styles/components/game-popup.module.scss";
import { setGameIsOpen } from "redux/reducers/PopupSlice";
import GameClose from "icons/GameClose";

const GamePopup: React.FC = () => {
  const dispatch = useAppDispatch();
  const { gameContent, gameIsOpen } = useAppSelector(selectPopup);

  const handleClose = () => dispatch(setGameIsOpen(false));

  return (
    <>
      {gameIsOpen && (
        <div className={styles.game_popup} onClick={handleClose}>
          <div className={styles.game_popup__content}>
            <div className={styles.game_popup__close}>
              <GameClose />
            </div>
            {gameContent}
          </div>
        </div>
      )}
    </>
  );
};

export default GamePopup;
