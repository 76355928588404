import React, { useState, memo } from "react";
import styles from "styles/pages/joinRace/info.module.scss";
import StreetRaceIcon from "icons/main-cards/StreetRace";
import LegalRaceIcon from "icons/main-cards/LegalRace";
import GameButton from "components/Buttons/GameButton";
import { StreetRaceApi } from "utils/api/StreetRace";
import { IAdvancedCarItem } from "interfaces/Car";
import Alert from "utils/alert";
import {
  FreeRaceTypes,
  IAdvancedRace,
  IConditions,
  IGetRaceResponse,
  IQualRaceEmulation,
  IQualRaceEmulationResponse,
  LegalRaceTypes,
  RaceStatus,
} from "interfaces/Race";
import { formatCars } from "utils/formatCars";
import { SetState } from "interfaces/General";
import { QualRaceApi } from "utils/api/QualRace";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "redux/hooks/redux";
import { setGameContent, setGameIsOpen } from "redux/reducers/PopupSlice";
import Confirm from "components/GamePopup/Confirm";
import { LegalRaceApi } from "utils/api/LegalRace";
import FreeRace from "icons/main-cards/FreeRace";
import { FreeRaceApi } from "utils/api/FreeRace";
import { renderChancePts } from "utils/renderChancePts";
import NewYear from "icons/NewYear";
import { SpecialRaceApi } from "utils/api/SpecialRace";
import { useTranslation } from "react-i18next";

export interface RaceInfoProps {
  name: string;
  number?: string;
  prize?: number | string;
  conditions?: IConditions;
  participants?:
    | {
        current: number;
        required: number;
      }
    | undefined;
  cost?: number | string;
  id?: string;
  timeToStart: string;
  setCars: SetState<IAdvancedCarItem[]>;
  setRace: SetState<IAdvancedRace | undefined>;
  sendDisabled: boolean;
  qualification?: boolean;
  carIds: number[];
  setCarIds: SetState<number[]>;
  isLoading?: boolean;
  setSended?: SetState<boolean>;
  setGameRace?: SetState<IQualRaceEmulation | undefined>;
  type?: LegalRaceTypes | FreeRaceTypes;
  status?: RaceStatus;
  participantsTab?: boolean;
}

const RaceInfo: React.FC<RaceInfoProps> = memo(
  ({
    name,
    number,
    prize,
    participants,
    cost,
    sendDisabled,
    id,
    setCars,
    setRace,
    timeToStart,
    qualification = false,
    participantsTab = false,
    carIds,
    setCarIds,
    setSended,
    setGameRace,
    conditions,
    type,
    status,
  }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const params = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [dataSending, setDataSending] = useState(false);

    const streetSuccess = (res: IGetRaceResponse | null) => {
      if (res) {
        let formattedCars = null;

        if (res.user) {
          formattedCars = formatCars(res.user.inventory.cars, []);
        }

        setCarIds([]);
        setRace(res.race);
        if (formattedCars) setCars(formattedCars);
      }
    };

    const qualSuccess = (res: IQualRaceEmulationResponse | null) => {
      if (res) {
        setSended!(true);
        setGameRace!(res.race);
      }
    };

    const renderParticipants = () => {
      if (
        pathname.includes("legal") ||
        pathname.includes("free") ||
        pathname.includes("special")
      ) {
        if (participants) {
          return (
            <>
              <div>
                {t("joined")}: {participants?.current}
              </div>
              <div>
                {t("min_to_start")}:{" "}
                <span
                  style={{
                    color:
                      participants.current < participants.required
                        ? "#ff4040"
                        : "#08b8d2",
                  }}
                >
                  {participants.required}
                </span>
              </div>
            </>
          );
        }

        return (
          <>
            <span>{t("joined")}: ...</span>
            <span>{t("min_to_start")}: ...</span>
          </>
        );
      }

      if (qualification) {
        return `${carIds.length}`;
      }

      if (participants) {
        return `${participants.current}/${participants.required}`;
      }

      return ".../...";
    };

    const sendToRaceCallback = () => {
      setDataSending(true);

      let promise;

      if (qualification) {
        promise = QualRaceApi.joinToRace(carIds, t("join_race_success"));
      } else if (pathname.includes("legal")) {
        promise = LegalRaceApi.joinToRace(
          { raceId: id!, carIds },
          t("join_race_success")
        );
      } else if (pathname.includes("free")) {
        promise = FreeRaceApi.joinToRace(
          { raceId: id!, carIds },
          t("join_race_success")
        );
      } else if (pathname.includes("special")) {
        promise = SpecialRaceApi.joinToRace(
          { raceId: id!, carIds },
          t("join_race_success")
        );
      } else {
        promise = StreetRaceApi.joinToRace(
          { raceId: id!, carIds },
          t("join_race_success")
        );
      }

      promise
        .then((res) => {
          if (qualification) {
            qualSuccess(res as IQualRaceEmulationResponse);
          } else {
            streetSuccess(res as IGetRaceResponse);
          }
        })
        .catch((e) => {
          console.log(e);
          Alert("error", t("default_error"));
        })
        .finally(() => setDataSending(false));
    };

    const handleView = () => {
      navigate(`/racing/${pathname.split("/")[2]}/${params.id}/view`);
    };

    const handleSendToRace = () => {
      if (
        !qualification &&
        !pathname.includes("free") &&
        !pathname.includes("special")
      ) {
        dispatch(
          setGameContent(
            <Confirm
              callback={sendToRaceCallback}
              amount={Number(cost) * carIds.length}
            />
          )
        );
        dispatch(setGameIsOpen(true));

        return;
      }

      sendToRaceCallback();
    };

    const renderSecondaryInfo = () => (
      <div className={styles.info__secondary}>
        <div className={styles.info__racers}>
          <span>{t("racers")}: </span>
          {renderParticipants()}
        </div>
        <div className={styles.info__starts}>
          <span>{t("starts_in")}:</span>
          {status !== "finished" ? timeToStart : t("race_finished")}
        </div>
      </div>
    );

    return (
      <div
        className={`${styles.info} ${
          qualification ? styles["info--qualification"] : ""
        }`}
      >
        <div className={styles.info__main}>
          <div className={styles.info__wrapper}>
            <div className={styles.info__icon}>
              {pathname.includes("legal") ? (
                <LegalRaceIcon />
              ) : pathname.includes("free") ? (
                <FreeRace />
              ) : pathname.includes("special") ? (
                <NewYear />
              ) : (
                <StreetRaceIcon />
              )}
            </div>

            <div>
              <div className={styles.info__name}>{name}</div>
              {!qualification && (
                <div className={styles.info__number}>
                  {number ? number : "..."}
                </div>
              )}
            </div>
          </div>

          {qualification ? (
            <div className={styles.info__prize}>
              {t("prize")}: {prize ? prize : "..."}
            </div>
          ) : (
            <>
              <div className={styles.info__prize}>
                {t("fund")}: {prize ? prize : "..."} <span>mgt</span>
              </div>

              <div className={styles.info__cost}>
                {t("cost")}:{" "}
                {type !== "newbies" ? (
                  <>
                    {cost ? cost : "..."}{" "}
                    {!pathname.includes("free") &&
                      !pathname.includes("special") && <span>mgt</span>}
                  </>
                ) : (
                  "-"
                )}
              </div>
            </>
          )}

          {qualification && renderSecondaryInfo()}
        </div>

        {!qualification &&
          !pathname.includes("special") &&
          renderSecondaryInfo()}

        {pathname.includes("special") &&
          timeToStart !== "00m:00s" &&
          renderSecondaryInfo()}

        {conditions && type !== "newbies" && (
          <div className={styles.info__secondary}>
            <div className={styles.info__racers}>
              <span>{t("chance_pts")}: </span>
              {renderChancePts(conditions)}
            </div>
          </div>
        )}

        {qualification && (
          <div className={styles.info__text}>{t("join_race_qual_text")}</div>
        )}

        {!participantsTab && (
          <div
            className={`${styles.info__navigation} ${styles["info__navigation--qual"]}`}
          >
            <div className={styles.info__buttons}>
              {/* {!qualification && (
              <GameButton
                disabled={timeToStart !== "00m:00s"}
                label={t("view")}
                onClick={handleView}
              />
            )} */}

              <GameButton
                disabled={sendDisabled || dataSending || carIds.length <= 0}
                label={t("send_to_race")}
                onClick={handleSendToRace}
              />
            </div>

            {(pathname.includes("free") || pathname.includes("special")) && (
              <p className={styles.info__free_text}>
                {pathname.includes("special")
                  ? t("special_race_warning")
                  : t("free_race_warning")}
              </p>
            )}

            {!qualification &&
              !pathname.includes("free") &&
              !pathname.includes("special") && (
                <div className={styles.info__total}>
                  <span>{t("total_price")}</span>
                  {cost !== undefined
                    ? Number(cost) * carIds.length
                    : "..."}{" "}
                  mgt
                </div>
              )}
          </div>
        )}
      </div>
    );
  }
);

export default RaceInfo;
