import React from "react";
import styles from "styles/pages/joinRace/distribution.module.scss";
import MGT from "icons/MGT";
import { useTranslation } from "react-i18next";

export interface IPool {
  mgt: number;
  usdt: number;
}

export interface IDistributionItem {
  prize: IPool;
  place: string;
}

const DistributionItem: React.FC<IDistributionItem> = ({ place, prize }) => {
  const { t } = useTranslation();

  const classNames = `${styles.distribution__item} ${
    place === "1" ? styles["distribution__item--first"] : ""
  } ${place === "2" ? styles["distribution__item--second"] : ""} ${
    place === "3" ? styles["distribution__item--third"] : ""
  }`;

  return (
    <div className={classNames}>
      <div className={styles.distribution__prize}>
        <span>
          {prize.mgt} <MGT />
        </span>
        ~<span>{prize.usdt}$</span>
      </div>

      <div className={styles.distribution__place}>
        {place} {place.includes("-") ? t("places") : t("place")}
      </div>
    </div>
  );
};

export default DistributionItem;
