import React from "react";

const ComingSoon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"11rem"}
      height={"12rem"}
      viewBox="0 0 110 120"
      fill="none"
    >
      <g clipPath="url(#clip0_588_8)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.0938 2.96073L82.5 12.4543L99.0625 21.9479C106.094 25.9943 110 32.9978 110 41.0907V59.9222V78.9093C110 87.0022 106.094 94.0057 99.0625 98.0521L82.5 107.39L66.0938 116.884C59.0625 120.93 51.0938 120.93 44.0625 116.884L27.5 107.39L11.0938 98.0521C4.0625 94.0057 0 87.0022 0 78.9093V59.9222V41.0907C0 32.9978 4.0625 25.9943 11.0938 21.9479L27.5 12.4543L44.0625 2.96073C51.0938 -0.930085 59.0625 -0.930085 66.0938 2.96073ZM44.0625 15.2557L21.7188 28.1732C14.6875 32.2196 10.625 39.0674 10.625 47.1603V72.8397C10.625 80.9326 14.6875 87.7804 21.7188 91.8268L44.0625 104.744C51.0938 108.791 59.0625 108.791 66.0938 104.744L88.4375 91.8268C95.4688 87.7804 99.375 80.9326 99.375 72.8397V47.1603C99.375 39.0674 95.4688 32.2196 88.4375 28.1732L66.0938 15.2557C59.0625 11.2092 51.0938 11.2092 44.0625 15.2557Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.5938 35.6435L29.0625 49.8061C28.125 50.4286 27.8125 50.7399 27.8125 51.8293V80.1544C27.8125 81.2438 28.125 81.7107 29.0625 82.3332L53.5938 96.4958C54.5313 96.9627 55.1562 96.9627 56.0938 96.4958L80.625 82.3332C81.5625 81.7107 81.875 81.2438 81.875 80.1544V51.8293C81.875 50.7399 81.5625 50.4286 80.625 49.8061L56.0938 35.6435C55.1562 35.1766 54.5313 35.1766 53.5938 35.6435ZM54.8438 47.6272L38.9063 56.8095V75.3298L54.8438 84.5121L70.7813 75.3298V56.8095L54.8438 47.6272Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.405 51.2068L25.78 43.7364C26.0925 43.2695 26.5613 43.1139 27.03 43.4252L35.6238 48.2498C35.9363 48.561 36.0925 49.0279 35.9363 49.4948L31.5613 56.9652C31.2488 57.4321 30.78 57.5877 30.3113 57.2764L21.7175 52.4518C21.405 52.1406 21.2488 51.6737 21.405 51.2068Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.8438 32.5309V25.3718C44.8438 24.9049 45.1563 24.5936 45.625 24.5936H64.0625C64.5312 24.5936 64.8438 24.9049 64.8438 25.3718V32.5309C64.8438 32.8421 64.5312 33.1534 64.0625 33.1534H45.625C45.1563 33.1534 44.8438 32.8421 44.8438 32.5309Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.4375 40.935H51.25C50.7812 40.935 50.4688 40.7794 50.4688 40.4681V29.7295C50.4688 29.4182 50.7812 29.2626 51.25 29.2626H58.4375C58.9062 29.2626 59.2187 29.4182 59.2187 29.7295V40.4681C59.2187 40.7794 58.9062 40.935 58.4375 40.935Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_588_8">
          <rect width={110} height={120} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ComingSoon;
