import { SetState } from "interfaces/General";
import { ITabItem } from "interfaces/Tabs";
import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "styles/components/tabs.module.scss";

interface TabsProps {
  tabs: ITabItem<string>[];
  setActiveTab: SetState<any>;
  activeTab: string;
  morePadding?: boolean;
}

const Tabs: React.FC<TabsProps> = memo(
  ({ tabs, setActiveTab, activeTab, morePadding = false }) => {
    const navigate = useNavigate();

    const handleChangeTab = (tabItem: ITabItem<string>) => {
      if (tabItem.href) {
        navigate(tabItem.href);
        return;
      }

      setActiveTab(tabItem.key);
      return;
    };

    return (
      <ul
        className={`${styles.tabs} ${
          morePadding ? styles["tabs--more-padding"] : ""
        }`}
      >
        {tabs.map((item) => (
          <li
            onClick={() => handleChangeTab(item)}
            key={item.key}
            className={`${styles.tabs__tab} ${
              item.key === activeTab ? styles["tabs__tab--active"] : ""
            }`}
          >
            {item.label}
          </li>
        ))}
      </ul>
    );
  }
);

export default Tabs;
