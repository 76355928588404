export const ROUTES_PATH = {
  MAIN: "/",
  SHOP: "/shop",
  HOW_TO_PLAY: "/how-to-play",
  RATINGS: "/ratings",
  GARAGE: "/garage",
  CARBOXES: "/garage/carboxes",
  PROFILE: "/profile",
  WALLET: "/profile/wallet",
  INVITE_FRIENDS: "/profile/invite-friends",
  INVENTORY_PARTS: "/inventory/parts",
  INVENTORY_LOOTBOXES: "/inventory/lootboxes",
  INVENTORY_CARBOXES: "/inventory/carboxes",
  INVENTORY_CARS: "/inventory/cars",
  LEGAL_RACING: "/racing/legal",
  STREET_RACING: "/racing/street",
  FREE_RACING: "/racing/free",
  SPECIAL_RACING: "/racing/special",
  QUAL_RACING: "/racing/qualification",
  LEGAL_RACING_JOIN: "/racing/legal/:id",
  STREET_RACING_JOIN: "/racing/street/:id",
  FREE_RACING_JOIN: "/racing/free/:id",
  SPECIAL_RACING_JOIN: "/racing/special/:id",
  RACING: "/racing",

  LOGIN: "/login",
  REGISTRATION: "/registration",
  RESET_PASSWORD: "/password/reset",
  CHANGE_PASSWORD: "/password/change",

  REF_CODE: "/r/:code",

  REMAKE: "/remake",
};
