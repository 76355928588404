import { IRating } from "interfaces/Ratings";
import React, { useState, useEffect } from "react";
import styles from "styles/pages/ratings.module.scss";
import ListItem from "./ListItem";
import scrollbarStyles from "styles/components/custom-scrollbar.module.scss";
import RatingsIcon from "icons/RatingsIcon";
import { RatingApi } from "utils/api/Rating";
import Alert from "utils/alert";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import SmoothImage from "components/SmoothImage";
import { ratingsBgHash } from "constants/blurhashes";
import ImageLazy from "components/ImageLazy";
import { useTranslation } from "react-i18next";

const Ratings: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState<IRating[]>([]);
  const [activeCar, setActiveCar] = useState<IRating>(cars[0]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate(ROUTES_PATH.LOGIN);
    } else {
      RatingApi.getRating()
        .then((res) => {
          setCars(res.rating);
          setActiveCar(res.rating[0]);
        })
        .catch((e) => Alert("error", t("default_error")))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.ratings}>
      <div className={styles.ratings__bg}>
        <SmoothImage
          src="/img/ratings/bg.webp"
          alt="Ratings background"
          hash={ratingsBgHash}
        />
      </div>

      <div className={styles.ratings__content}>
        <div className={styles.ratings__header}>
          <div className={styles.ratings__icon}>
            <RatingsIcon />
          </div>

          <div className={styles.ratings__title}>
            <span>{t("top_racers")}</span>
            {t("ratings")}
          </div>
        </div>

        <div className={styles.ratings__table}>
          <div className={styles.ratings__headings}>
            <span>{t("cars")}</span>
            <span>{t("position")}</span>
            <span>{t("earned")}</span>
          </div>

          <ul
            className={`${styles.ratings__list} ${scrollbarStyles.invisible_scrollbar}`}
          >
            {loading
              ? Array.from({ length: 10 }, (_, i) => (
                  //@ts-ignore
                  <ListItem
                    setActiveCar={setActiveCar}
                    id={i}
                    key={i}
                    loading
                  />
                ))
              : cars.map((item, key) => (
                  <ListItem
                    car={item}
                    setActiveCar={setActiveCar}
                    active={item?.position === activeCar?.position}
                    id={key}
                    key={key}
                  />
                ))}
          </ul>
        </div>

        {activeCar && (
          <div className={styles.ratings__car}>
            <ImageLazy src={activeCar.images.full} alt="Car" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Ratings;
