import React from "react";

const CounterBorder: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"13.7rem"}
      height={"13.7rem"}
      viewBox="0 0 137 137"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.5703 14.1154L123.022 66.5736H136.733L136.856 65.4082L110.545 39.0968L107.48 39.2751L83.6917 15.4797V12.237L71.4478 0L70.5703 0.130254V14.1154Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.655 61.7816L115.59 61.9599L91.8018 38.1645V34.9287L118.655 61.7816Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1154 66.4365L66.5736 13.9784V0.267381L65.4082 0.143982L39.0968 26.4553L39.2751 29.5197L15.4797 53.3151H12.237L0 65.5522L0.130254 66.4365H14.1154Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.7816 18.3453L61.9599 21.4097L38.1645 45.2051H34.9287L61.7816 18.3453Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.4297 122.885L13.9784 70.4332H0.267442L0.144043 71.5918L26.4554 97.9032L29.5198 97.7318L53.3152 121.52V124.763L65.5522 137L66.4297 136.877V122.885Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3452 75.2183L21.4096 75.0469L45.205 98.8355V102.078L18.3452 75.2183Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.884 70.5704L70.4331 123.022V136.733L71.5917 136.856L97.903 110.545L97.7316 107.48L121.52 83.6918H124.763L137 71.4479L136.876 70.5704H122.884Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.2183 118.655L75.0469 115.59L98.8354 91.8018H102.078L75.2183 118.655Z"
        fill="white"
      />
    </svg>
  );
};

export default CounterBorder;
