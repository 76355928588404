import React from "react";

const Fourth: React.FC = () => {
  return (
    <svg
      width={"44.8rem"}
      height={"38.5rem"}
      viewBox="0 0 448 385"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M447.632 3.05176e-05L447.632 149.362L433.12 163.874L433.12 314.828L362.948 385L321.951 385L307.288 370.337L52.7807 370.337L52.5154 370.791L38.1931 385L-6.10352e-05 385L-2.93781e-05 22.8856L22.8855 -6.61495e-06L447.632 3.05176e-05Z"
        fill="#16081B"
      />

      <clipPath id="fourth-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M1,0 l0,0.388 l-0.032,0.038 l0,0.392 l-0.157,0.182 l-0.092,0 l-0.033,-0.038 l-0.569,0 l-0.001,0.001 l-0.032,0.037 l-0.085,0 l0,-0.941 l0.051,-0.059 l0.949,0"></path>
      </clipPath>
    </svg>
  );
};

export default Fourth;
