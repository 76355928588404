import React from "react";

const Frame: React.FC = () => {
  return (
    <svg
      width={"145.6rem"}
      height={"28.4rem"}
      viewBox="0 0 1456 284"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.17157 33.8284L33.8284 1.17157C34.5786 0.421427 35.596 0 36.6569 0H647H1257.34C1258.4 0 1259.42 0.421424 1260.17 1.17157L1297.83 38.8284C1298.58 39.5786 1299.6 40 1300.66 40H1410.34C1411.4 40 1412.42 40.4214 1413.17 41.1716L1454.83 82.8284C1455.58 83.5786 1456 84.596 1456 85.6569V256.343C1456 257.404 1455.58 258.421 1454.83 259.172L1431.17 282.828C1430.42 283.579 1429.4 284 1428.34 284H132.657C131.596 284 130.579 283.579 129.828 282.828L114.172 267.172C113.421 266.421 112.404 266 111.343 266H36.6569C35.596 266 34.5786 265.579 33.8284 264.828L1.17157 232.172C0.421427 231.421 0 230.404 0 229.343V36.6569C0 35.596 0.421427 34.5786 1.17157 33.8284Z"
        fill="#180F1A"
        fillOpacity="0.95"
      />
    </svg>
  );
};

export default Frame;
