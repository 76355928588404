import { IGetRaceResponse, IJoinToRaceDTO, ILegalRaces } from "interfaces/Race";
import { API_ROUTES, newAuthApi } from ".";
import { errorCallback } from "./Auth";
import Alert from "utils/alert";
import { IRaceEmulationResponse } from "interfaces/Race";

export const LegalRaceApi = {
  async getRaces() {
    const response = await newAuthApi.get<ILegalRaces>(API_ROUTES.LEGAL_RACES);

    return response.data;
  },

  async getRaceById(id: string) {
    const response = await newAuthApi.get<IGetRaceResponse>(
      `${API_ROUTES.LEGAL_RACES}${id}`
    );

    return response.data;
  },

  async joinToRace({ raceId, carIds }: IJoinToRaceDTO, message: string) {
    const response = await newAuthApi
      .post<IGetRaceResponse>(`${API_ROUTES.LEGAL_RACES}${raceId}/join`, {
        carIds,
      })
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async getRaceEmulation(id: string) {
    const response = await newAuthApi
      .get<IRaceEmulationResponse>(`${API_ROUTES.LEGAL_RACES}${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },
};
