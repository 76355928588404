import { createAsyncThunk } from "@reduxjs/toolkit";
import { ShopApi } from "utils/api/Shop";

export const fetchShopData = createAsyncThunk(
  "user/fetchShopData",
  async () => {
    if (localStorage.getItem("access_token")) {
      const response = ShopApi.getShop();

      return response;
    }

    const response = ShopApi.getShopGuest();

    return response;
  }
);
