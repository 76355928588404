import React, { useRef, useState, useEffect } from "react";
import styles from "./style.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import "swiper/css";
import { isVisible } from "../../../utils/isVisible";

interface Item {
  id: number;
  title: string;
  text: string;
  image: string;
  small?: boolean;
}

const slidesList: Item[] = [
  {
    id: 1,
    image: "/img/landing/new/01.png",
    text: "We've taken into account all the feedback from beta players and made the design more user-friendly and accessible for newcomers.",
    title: "Updated design",
  },

  {
    id: 2,
    image: "/img/landing/new/02.png",
    text: "Now you can comfortably play MetaRacing on your phone!",
    title: "Mobile version",
  },

  {
    id: 3,
    image: "/img/landing/new/03.png",
    text: "Everything is now in one place, making it easier and more enjoyable than ever to upgrade your NFT cars.",
    title: "New garage",
  },

  {
    id: 4,
    image: "/img/landing/new/04.png",
    text: "Perhaps the most exciting feature of this release is the ability to buy and sell NFT cars. The price depends on the level of upgrades, the parts installed, and even the car number.",
    title: "Marketplace",
  },

  {
    id: 5,
    image: "/img/landing/new/05.jpg",
    text: "Upgrading your cars is now faster and more enjoyable.",
    title: "Game boost",
  },

  {
    id: 6,
    image: "/img/landing/new/06.jpg",
    text: "Complete daily tasks to earn pleasant bonuses.",
    title: "Daily tasks",
  },

  {
    id: 7,
    image: "/img/landing/new/07.jpg",
    text: "«Multi-range stacking system that offers not only percentage income but also gives valuable in-game bonuses».",
    title: "Hybrid staking",
  },
];

const New: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(slidesList[0].id);

  const sectionRef = useRef<HTMLDivElement>(null);
  const mainSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sectionRef.current) {
      var lastScrollTop = 0;

      const containerHeight = sectionRef.current?.clientHeight;
      const blockHeight = containerHeight / slidesList.length;

      let activeSlide = Math.floor(
        window.scrollY || document.documentElement.scrollTop / blockHeight
      );

      activeSlide =
        activeSlide > slidesList.length ? slidesList.length : activeSlide;
      activeSlide = activeSlide < 1 ? 1 : activeSlide;

      setActiveIndex(activeSlide);

      const handleScrollSection = (e: any) => {
        if (isVisible(sectionRef.current!)) {
          var st = window.scrollY || document.documentElement.scrollTop;

          let activeSlide = Math.ceil(
            (st - sectionRef.current!.getBoundingClientRect().top - 1900) /
              blockHeight
          );

          activeSlide =
            activeSlide > slidesList.length ? slidesList.length : activeSlide;
          activeSlide = activeSlide < 1 ? 1 : activeSlide;

          if (st > lastScrollTop) {
            setActiveIndex(activeSlide);
          } else if (st < lastScrollTop) {
            setActiveIndex(activeSlide);
          }

          lastScrollTop = st <= 0 ? 0 : st;
        }
      };

      window.addEventListener("scroll", handleScrollSection);
      return () => window.removeEventListener("scroll", handleScrollSection);
    }
  }, [sectionRef]);

  return (
    <section ref={mainSectionRef} id="new" className={styles.new}>
      <div className={styles.new__wrapper}>
        <div className="landing-container">
          <SectionTitle
            className={styles.new__title_row}
            parentRef="#new"
            label="What's new?"
          />

          <div className={styles.new__pluses}>
            <img src={"/img/landing/pluses.png"} alt="Pluses" />
          </div>

          <div ref={sectionRef} className={styles.new__container}>
            {slidesList.map((item, key) => (
              <div
                key={key}
                style={{ transform: `translateY(${key * -100}%` }}
                className={`${styles.new__slide} ${
                  item.id === activeIndex ? styles.active : ""
                } ${item.id > activeIndex ? styles.bottom : ""}`}
              >
                <div className={styles.new__content}>
                  <div className={styles.new__number}>0{item.id}</div>
                  <h5 className={styles.new__title}>{item.title}</h5>
                  <p className={styles.new__text}>{item.text}</p>
                </div>

                <div
                  className={`${styles.new__image} ${
                    item.small ? styles.small : ""
                  }`}
                >
                  <img src={item.image} alt="Update" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default New;
