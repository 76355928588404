import React, { useState } from "react";
import styles from "styles/components/gamePopup/buy-boost.module.scss";
import GameButton from "components/Buttons/GameButton";
import { useTranslation } from "react-i18next";
import { IBoost, boostTypes } from "components/JoinRace/Boosts";
import Boost from "components/Boost";

interface BuyBoostProps {
  boost: IBoost;
  type: boostTypes;
}

const BuyBoost: React.FC<BuyBoostProps> = ({ boost, type }) => {
  const { t } = useTranslation();
  const [dataSending, setDataSending] = useState(false);

  const handleBuy = () => {};

  return (
    <div className={styles.buy} onClick={(e) => e.stopPropagation()}>
      <div className={styles.buy__title}>Buy boost</div>

      <div className={styles.buy__text}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore vero,
        quidem tempora mollitia totam
      </div>

      <div className={styles.buy__boost}>
        <Boost item={boost} type={type} active />
      </div>

      <div className={styles.buy__price}>
        Total price:
        <span>100 MGT</span>
      </div>

      <GameButton label={t("buy")} disabled={dataSending} onClick={handleBuy} />
    </div>
  );
};

export default BuyBoost;
