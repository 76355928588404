import Auth from "components/Auth";
import Input from "components/FormElements/Input";
import GameButton from "components/Buttons/GameButton";
import { ROUTES_PATH } from "constants/routesPath";
import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styles from "styles/components/auth.module.scss";
import Alert from "utils/alert";
import { AuthApi } from "utils/api/Auth";
import { fetchData } from "utils/fetchData";
import { useAppDispatch } from "redux/hooks/redux";
import { useTranslation } from "react-i18next";

const Login: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dataSending, setDataSending] = useState(false);

  const handleLogin = () => {
    setDataSending(true);

    AuthApi.login({ email, password }, t("login_success"))
      .then((res) => {
        if (res?.user) {
          if (params.get("promocode"))
            navigate(
              `${ROUTES_PATH.SHOP}?promocode=${params.get("promocode")}`
            );
          else navigate(ROUTES_PATH.MAIN);
          fetchData(dispatch);
        }
      })
      .catch((e) => Alert("error", t("default_error")))
      .finally(() => setDataSending(false));
  };

  return (
    <Auth>
      <form className={styles.auth__form}>
        <Input placeholder={t("email")} value={email} setValue={setEmail} />
        <Input
          placeholder={t("password")}
          value={password}
          setValue={setPassword}
          type="password"
        />

        <GameButton
          label={t("sign_in")}
          onClick={handleLogin}
          disabled={dataSending}
        />
      </form>

      <div className={styles.auth__link}>
        <Link to={ROUTES_PATH.RESET_PASSWORD}>{t("forgot_password")}</Link>
      </div>

      <div className={styles.auth__link}>
        <span>{t("dont_have_account")}</span>
        <Link to={ROUTES_PATH.REGISTRATION}>{t("create_account")}</Link>
      </div>
    </Auth>
  );
};

export default Login;
