import React, { memo } from "react";
import { useAppDispatch } from "redux/hooks/redux";
import Card from "pages/Shop/Card";
import { setDisabled } from "redux/reducers/PopupSlice";
import { ShopApi } from "utils/api/Shop";
import Alert from "utils/alert";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { fetchInventoryData } from "redux/asyncActions/fetchInventoryData";
import { setBalance } from "redux/reducers/UserSlice";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { useTranslation } from "react-i18next";

const MintCarBox: React.FC = memo(() => {
  const { t } = useTranslation();

  const { shop } = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleMintCarbox = (amount: number) => {
    dispatch(setDisabled(true));

    ShopApi.buyCarBox(amount)
      .then((res) => {
        if (res && res.carboxes.length > 0) {
          navigate(ROUTES_PATH.INVENTORY_CARBOXES);
          Alert(
            "success",
            `${res.carboxes.length > 1 ? "Carboxes" : "Carbox"} ${t(
              "purchace_success"
            )}`
          );
          dispatch(setBalance(res.balance));
        }
        dispatch(fetchInventoryData());
      })
      .catch((e) => {
        Alert("error", t("default_error"));
      })
      .finally(() => dispatch(setDisabled(false)));
  };

  const renderCost = () => {
    const price = shop?.carbox.price;

    if (price) {
      const { discounted, base } = price;

      return discounted ? (
        <>
          <span>{base}</span> {discounted}
        </>
      ) : (
        base
      );
    }

    return "...";
  };

  return (
    <Card
      cost={<>{renderCost()} mgt</>}
      text={t("carbox_card_text")}
      title={t("buy_carbox")}
      price={shop?.carbox.price.base}
      discounted={shop?.carbox.price.discounted || null}
      callback={handleMintCarbox}
    />
  );
});

export default MintCarBox;
