import SelectArrow from "icons/SelectArrow";
import { ISelectItem } from "interfaces/Form";
import { SetState } from "interfaces/General";
import React, { useState, memo } from "react";
import styles from "styles/components/form-elements/select.module.scss";

interface SelectProps {
  list: ISelectItem<any>[];
  current: ISelectItem<any>;
  setCurrent: SetState<ISelectItem<any>>;
}

const Select: React.FC<SelectProps> = memo(({ list, setCurrent, current }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleChange = (key: ISelectItem<any>) => {
    setCurrent(key);
  };

  return (
    <div
      onClick={toggleOpen}
      className={`${styles.select} ${isOpen ? styles["select--active"] : ""}`}
    >
      <div className={styles.select__wrapper}>
        <div className={styles.select__header}>
          <span>
            {current.label} <SelectArrow />
          </span>
        </div>

        <ul className={styles.select__list}>
          {list.map((item) => (
            <li
              onClick={() => handleChange(item)}
              key={item.key}
              className={styles.select__item}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export default Select;
