import React from "react";

const Small: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="275px"
      height="310px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 1.61 1.81"
      imageRendering="optimizeQuality"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "\n   \n    .fil0 {fill:#8FD12F}\n   \n  ",
          }}
        />
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M0.87 0.02l0.34 0.19 0.33 0.19c0.04,0.03 0.07,0.07 0.07,0.12l0 0.39 0 0.38c0,0.05 -0.03,0.1 -0.07,0.12l-0.33 0.19 -0.34 0.19c-0.04,0.03 -0.09,0.03 -0.14,0l-0.33 -0.19 -0.33 -0.19c-0.04,-0.02 -0.07,-0.07 -0.07,-0.12l0 -0.38 0 -0.39c0,-0.05 0.03,-0.09 0.07,-0.12l0.33 -0.19 0.33 -0.19c0.05,-0.03 0.1,-0.03 0.14,0zm0.37 0.79c0,0.1 0.07,0.15 0.15,0.15 0.08,-0.01 0.17,-0.07 0.14,-0.17 -0.01,-0.07 -0.07,-0.14 -0.11,-0.19 -0.03,-0.04 -0.06,-0.04 -0.09,0 -0.05,0.08 -0.1,0.15 -0.09,0.21zm-1.04 0.06c0.03,0.04 0.06,0.02 0.09,-0.01l0.04 0.05 0.27 -0.21 -0.04 -0.05c0.02,-0.01 0.04,-0.02 0.05,-0.04 0.01,-0.05 -0.04,-0.07 -0.07,-0.05l-0.33 0.26c-0.01,0.01 -0.02,0.03 -0.01,0.05zm-0.01 0.42l-0.03 -0.04 0.09 -0.07 0.08 0.11c-0.02,0.02 -0.05,0.04 -0.08,0.03 -0.02,0 -0.04,-0.01 -0.06,-0.03zm-0.06 0.06l-0.06 -0.09c-0.01,-0.02 -0.01,-0.04 0.01,-0.06l0.72 -0.55c0.04,-0.03 0.08,0.03 0.1,0.07 0.01,-0.02 0.02,-0.04 0.03,-0.06 0.01,-0.04 0.05,-0.17 0.06,-0.18l0.14 -0.11c0.01,-0.01 0.04,0 0.05,0.01 0.02,0.02 0.01,0.03 0.01,0.06l-0.01 0.54c0,0.07 -0.01,0.14 -0.08,0.19l-0.38 0.29c-0.04,0.04 -0.11,0.05 -0.15,0.04 -0.02,0 -0.04,-0.01 -0.06,-0.02 -0.06,-0.02 -0.09,-0.07 -0.13,-0.12 -0.04,0.03 -0.08,0.05 -0.14,0.05 -0.05,-0.01 -0.08,-0.03 -0.11,-0.06z"
        />
      </g>
    </svg>
  );
};

export default Small;
