import React from "react";

const Separator: React.FC = () => {
  return (
    <svg
      width={430}
      height={4}
      viewBox="0 0 430 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2633 1.14285L430 1.14285V0H0V3.99998H15.5369L17.2633 1.14285Z"
        fill="#F35850"
      />
    </svg>
  );
};

export default Separator;
