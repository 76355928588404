import { createSlice } from "@reduxjs/toolkit";
import { fetchStreetRacesData } from "../asyncActions/fetchStreetRacesData";
import { fetchLegalRacesData } from "../asyncActions/fetchLegalRacesData";
import { fetchFreeRacesData } from "../asyncActions/fetchFreeRacesData";
import { IRacesState } from "redux/models/RacesState";
import { fetchSpecialRacesData } from "redux/asyncActions/fetchSpecialRacesData";

const initialState: IRacesState = {
  street: [],
  legal: null,
  free: null,
  special: null,
};

const racesSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchStreetRacesData.fulfilled, (state, action) => {
      if (action.payload) {
        state.street = action.payload.races;
      }
    });

    builder.addCase(fetchLegalRacesData.fulfilled, (state, action) => {
      if (action.payload) {
        state.legal = action.payload;
      }
    });

    builder.addCase(fetchFreeRacesData.fulfilled, (state, action) => {
      if (action.payload) {
        state.free = action.payload;
      }
    });

    builder.addCase(fetchSpecialRacesData.fulfilled, (state, action) => {
      if (action.payload) {
        state.special = action.payload;
      }
    });
  },
});

export default racesSlice.reducer;
