import React from "react";
import styles from "./style.module.scss";
import Logo from "../../icons/Logo";
import { Link } from "react-router-dom";
import SocialList from "landing/components/SocialList";

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className="landing-container">
        <div className={styles.footer__row}>
          <div className={styles.footer__logo}>
            <Logo />
          </div>

          <Link
            to={"https://docs.metaraces.io/"}
            target="_blank"
            className={styles.footer__link}
          >
            Whitepaper
          </Link>
        </div>

        <div className={styles.footer__social}>
          <SocialList />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
