import Check from "icons/Check";
import React, { memo } from "react";
import styles from "styles/components/form-elements/checkbox.module.scss";
import { rarityTypes } from "interfaces/Rarity";
import { SetState } from "interfaces/General";
import { useTranslation } from "react-i18next";

interface CheckboxProps {
  checked: boolean;
  setChecked: SetState<boolean | any>;
  id: string;
  unchangable?: boolean;
  rarity?: rarityTypes;
  listed?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = memo(
  ({ setChecked, checked, rarity, id, unchangable = false, listed }) => {
    const { t } = useTranslation();

    const handleChange = () => {
      if (!unchangable && !listed) setChecked(!checked);

      if (listed) {
        setChecked((prev: any) => {
          const newArr = [...prev];

          newArr.forEach((item) => {
            if (String(item.id) === String(id)) item.active = !item.active;
          });

          return newArr;
        });
      }
    };

    return (
      <div
        className={`${styles.checkbox} ${
          checked ? styles["checkbox--checked"] : ""
        }`}
      >
        <input
          id={`checkbox-${id}`}
          className={styles.checkbox__input}
          type="checkbox"
          checked={checked}
          onChange={handleChange}
        />
        <label htmlFor={`checkbox-${id}`} className={styles.checkbox__label}>
          <div className={styles.checkbox__check}>
            <Check />
          </div>

          {rarity && (
            <span className={styles[`checkbox__${rarity}`]}>
              {t(`${rarity}`)}
            </span>
          )}
        </label>
      </div>
    );
  }
);

export default Checkbox;
