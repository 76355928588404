import React from "react";

const Error: React.FC = () => {
  return (
    <svg
      width={"5.6rem"}
      height={"6.1rem"}
      viewBox="0 0 56 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6126 1.53034L42 6.33324L50.3874 11.1361C53.9505 13.1766 56 16.7238 56 20.8047V30.4419V40.0791C56 44.1914 53.9505 47.7072 50.3874 49.7477L42 54.5506L33.6126 59.3535C30.0495 61.3939 25.9505 61.3939 22.3874 59.3535L14 54.5506L5.61261 49.7477C2.04955 47.7072 0 44.16 0 40.0791V30.4419V20.8047C0 16.6924 2.04955 13.1766 5.61261 11.1361L14 6.33324L22.3874 1.53034C25.9505 -0.510112 30.0495 -0.510112 33.6126 1.53034ZM22.3874 7.74585L11.036 14.2753C7.47297 16.3157 5.42342 19.863 5.42342 23.9439V37.0027C5.42342 41.115 7.47297 44.6309 11.036 46.6713L22.3874 53.2008C25.9505 55.2412 30.0495 55.2412 33.6126 53.2008L44.964 46.6713C48.527 44.6309 50.5766 41.0836 50.5766 37.0027V23.9439C50.5766 19.8316 48.527 16.3157 44.964 14.2753L33.6126 7.74585C30.0495 5.70541 25.9505 5.70541 22.3874 7.74585Z"
        fill="white"
      />
      <path
        d="M39.8327 23.1613L35.3491 18.6976L16.1545 37.807L20.6381 42.2707L39.8327 23.1613Z"
        fill="white"
      />
      <path
        d="M16.1528 23.1613L20.6364 18.6976L39.831 37.807L35.3474 42.2707L16.1528 23.1613Z"
        fill="white"
      />
    </svg>
  );
};

export default Error;
