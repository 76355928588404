import SmoothImage from "components/SmoothImage";
import { inventoryBgHash } from "constants/blurhashes";
import React from "react";
import styles from "styles/pages/profile.module.scss";

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  return (
    <div className={styles.profile}>
      <div className={styles.profile__bg}>
        <SmoothImage
          src="/img/inventory-bg.webp"
          alt="Cyberpunk city"
          hash={inventoryBgHash}
        />
      </div>

      <div className={styles.profile__content}>{children}</div>
    </div>
  );
};

export default Wrapper;
