import MGT from "icons/MGT";
import React, { useCallback, useEffect, useState } from "react";
import styles from "styles/pages/joinRace/distribution.module.scss";
import DistributionItem, { IDistributionItem, IPool } from "./DistributionItem";
import { useTranslation } from "react-i18next";

interface DistributionTabProps {
  prizes: IDistributionItem[];
  pool: IPool;
  legal?: boolean;
  special?: boolean;
}

const DistributionTab: React.FC<DistributionTabProps> = ({
  prizes,
  legal,
  pool,
  special,
}) => {
  const { t } = useTranslation();
  const [winners, setWinners] = useState<IDistributionItem[][]>([]);

  const renderGrid = useCallback(() => {
    const formattedPrizes: IDistributionItem[][] = [];
    let arrayToFill: IDistributionItem[] = [];
    let currentItem = 0;
    let maxLength = 1;

    prizes.forEach((item, key) => {
      if (currentItem < maxLength) {
        arrayToFill.push(item);
        currentItem++;
      }

      if (currentItem === maxLength) {
        formattedPrizes.push(arrayToFill);
        if (maxLength !== 5) maxLength++;
        currentItem = 0;
        arrayToFill = [];
      }

      if (
        key + 1 === prizes.length &&
        prizes.length !== maxLength &&
        arrayToFill.length !== 0
      )
        formattedPrizes.push(arrayToFill);
    });

    if (legal && formattedPrizes.length > 2) {
      formattedPrizes[2].push(formattedPrizes[3][0]);
      formattedPrizes.pop();

      setWinners([...formattedPrizes]);
      return;
    }

    setWinners(formattedPrizes);
  }, [prizes]);

  useEffect(() => {
    renderGrid && renderGrid();
  }, [renderGrid]);

  return (
    <div className={styles.distribution}>
      <div className={styles.distribution__title}>
        {legal ? t("minimum") : t("total")} {t("prize_pool")}
      </div>
      <div className={styles.distribution__pool}>
        <span>
          {pool.mgt} <MGT />
        </span>
        ~<span>{pool.usdt}$</span>
      </div>

      <div className={styles.distribution__subtitle}>
        {t("prize_breakdown")}
      </div>

      <ul className={styles.distribution__list}>
        {winners.map((item, key) => (
          <li
            className={`${styles.distribution__row} ${
              key === winners.length - 1 &&
              winners[winners.length - 1].length === 2
                ? styles["distribution__row--double"]
                : ""
            } ${
              (special || (legal && winners.length > 2)) &&
              key === winners.length - 1
                ? styles["distribution__row--fourth"]
                : ""
            }`}
          >
            {item.map((winner) => (
              <DistributionItem {...winner} />
            ))}
          </li>
        ))}
      </ul>

      {legal && (
        <p className={styles.distribution__text}>
          {t("distribution_text_1")} {winners.length > 2 ? "70%" : "30%"}{" "}
          {t("distribution_text_2")}.
        </p>
      )}
    </div>
  );
};

export default DistributionTab;
