import MGT from "icons/MGT";
import { SetState } from "interfaces/General";
import React from "react";
import styles from "styles/components/form-elements/select-tabs.module.scss";

export interface ISelectTabItem {
  label: string;
  key: string;
}

interface TabsProps {
  tabs: ISelectTabItem[];
  activeTab: ISelectTabItem;
  setActiveTab: SetState<ISelectTabItem>;
  mgtIcon?: boolean;
}

const SelectTabs: React.FC<TabsProps> = ({
  activeTab,
  setActiveTab,
  tabs,
  mgtIcon,
}) => {
  const handleChange = (item: ISelectTabItem) => {
    setActiveTab(item);
  };

  return (
    <ul className={styles.select_tabs}>
      {tabs.map((item) => (
        <li
          key={item.key}
          className={`${styles.select_tabs__item} ${
            activeTab.key === item.key
              ? styles["select_tabs__item--active"]
              : ""
          }`}
          onClick={() => handleChange(item)}
        >
          {mgtIcon && <MGT />}
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default SelectTabs;
