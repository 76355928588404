import React from "react";

const NewYear: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"10.3rem"}
      height={"11.3rem"}
      viewBox="0 0 103 113"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.8783 2.84415L77.2826 11.7362L92.6869 20.6282C99.2142 24.4204 103 30.9587 103 38.5431V56.458V74.2421C103 81.8264 99.2142 88.3647 92.6869 92.1569L77.2826 101.049L61.8783 110.072C55.2205 113.864 47.7795 113.864 41.1217 110.072L25.7174 101.049L10.3131 92.1569C3.7858 88.3647 0 81.8264 0 74.2421V56.458V38.5431C0 30.9587 3.7858 24.4204 10.3131 20.6282L25.7174 11.7362L41.1217 2.84415C47.7795 -0.948049 55.2205 -0.948049 61.8783 2.84415ZM41.1217 14.3515L20.2345 26.3819C13.7072 30.1741 9.92142 36.7124 9.92142 44.2968V68.4884C9.92142 76.0728 13.7072 82.611 20.2345 86.4032L41.1217 98.5644C47.7795 102.357 55.2205 102.357 61.8783 98.5644L82.7655 86.4032C89.2928 82.611 93.0786 76.0728 93.0786 68.4884V44.2968C93.0786 36.7124 89.2928 30.1741 82.7655 26.3819L61.8783 14.3515C55.2205 10.5593 47.7795 10.5593 41.1217 14.3515Z"
        fill="white"
      />
      <path
        d="M48.1699 27.8204L52.2168 25.5973C52.3473 25.5973 52.4779 25.5973 52.6084 25.5973L56.5248 27.8204C56.7858 27.8204 56.9164 27.8204 57.0469 27.6896C57.1775 27.6896 57.1775 27.4281 57.1775 27.2973L56.3942 22.8513C56.3942 22.7205 56.3942 22.5897 56.5248 22.459L59.7884 19.3206C59.9189 19.1898 59.9189 19.0591 59.9189 18.9283C59.7884 18.7975 59.6578 18.6668 59.5273 18.6668L55.0888 18.0129C54.9582 18.0129 54.8277 17.8822 54.6971 17.7514L52.7389 13.6977C52.7389 13.5669 52.6084 13.4362 52.3473 13.4362C52.2168 13.4362 52.0862 13.5669 51.9557 13.6977L49.9975 17.7514C49.9975 17.8822 49.867 18.0129 49.7364 18.0129L45.1673 18.6668C45.0368 18.6668 44.9062 18.7975 44.9062 18.9283C44.9062 19.0591 44.9062 19.1898 45.0368 19.3206L48.3004 22.459C48.3004 22.5897 48.431 22.7205 48.3004 22.8513L47.6477 27.2973C47.5172 27.4281 47.6477 27.6896 47.7782 27.6896C47.9088 27.8204 48.0393 27.8204 48.1699 27.8204Z"
        fill="white"
      />
      <path
        d="M73.3661 50.0505L52.0873 29.1281L40.4688 40.8969L73.3661 50.0505Z"
        fill="white"
      />
      <path
        d="M70.1063 64.5654L67.1038 65.0885C67.8871 65.6116 68.2787 66.5269 68.2787 67.4423C68.2787 68.8807 67.1038 70.1883 65.6678 70.1883C64.1013 70.1883 62.9264 68.8807 62.9264 67.4423C62.9264 66.7884 63.1874 66.2654 63.4485 65.7423L58.2267 66.7884C58.8795 67.4423 59.4016 68.3576 59.4016 69.4037C59.4016 71.496 57.7046 73.0652 55.7464 73.0652C53.6577 73.0652 52.0911 71.496 52.0911 69.4037C52.0911 68.8807 52.2217 68.2269 52.4828 67.7038L40.8643 69.796C41.3864 70.3191 41.6475 70.9729 41.6475 71.6268C41.6475 73.1959 40.4726 74.3728 38.9061 74.3728C37.4701 74.3728 36.2952 73.1959 36.2952 71.6268C36.2952 71.2345 36.2952 70.9729 36.4257 70.5806L26.5043 72.4114L21.8047 77.1189L84.9885 79.3419L70.1063 64.5654Z"
        fill="white"
      />
      <path
        d="M36.5507 62.3424L30.5456 68.3576L75.4531 59.3348L67.8815 51.8812L63.7041 50.9658C64.0957 51.4889 64.3568 52.012 64.3568 52.6658C64.3568 54.235 63.0513 55.5426 61.4848 55.5426C59.9183 55.5426 58.6128 54.235 58.6128 52.6658C58.6128 51.6197 59.2655 50.5735 60.1794 50.1812L51.5634 48.089C51.955 48.7428 52.0856 49.3966 52.0856 50.0505C52.0856 52.2735 50.3885 53.9734 48.1692 53.9734C45.9499 53.9734 44.2529 52.2735 44.2529 50.0505C44.2529 48.7428 44.9056 47.5659 45.9499 46.7813L36.6813 44.6891L32.1122 49.2659H39.0311L25.9766 62.3424H36.5507Z"
        fill="white"
      />
      <path
        d="M86.0318 93.4646L77.0242 84.4418L64.3614 83.5264C65.5363 84.1802 66.3195 85.3571 66.3195 86.7955C66.3195 89.0186 64.6225 90.7185 62.4032 90.7185C60.1839 90.7185 58.4868 89.0186 58.4868 86.7955C58.4868 85.2264 59.5312 83.7879 60.9672 83.2649L44.388 81.9572C44.7796 82.4803 45.0407 83.1341 45.0407 83.7879C45.0407 85.3571 43.8658 86.6648 42.2993 86.6648C40.7327 86.6648 39.4273 85.3571 39.4273 83.7879C39.4273 83.0034 39.8189 82.2188 40.3411 81.6957L30.6808 81.0419L18.1484 93.4646L38.2524 102.226L45.6934 106.672H58.6174L72.1941 100.134L86.0318 93.4646Z"
        fill="white"
      />
    </svg>
  );
};

export default NewYear;
