import EnterPromocode from "pages/Profile/EnterPromocode";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/components/gamePopup/withdraw.module.scss";

interface PromocodeProps {
  initialValue?: string | null;
}

const Promocode: React.FC<PromocodeProps> = ({ initialValue }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.withdraw} onClick={(e) => e.stopPropagation()}>
      <div className={styles.withdraw__title}>{t("promocode")}</div>
      {/* <div className={styles.withdraw__text}>
        Invite 5 friends and get a super plushie you can't refuse - it's super
        duper if you didn't know it
      </div> */}

      <EnterPromocode initialValue={initialValue} />
    </div>
  );
};

export default Promocode;
