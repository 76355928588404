import React from "react";

const Ship: React.FC = () => {
  return (
    <svg
      width={"3.8rem"}
      height={"3rem"}
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient gradientTransform="rotate(-270)" id="linear-gradient-2">
        <stop offset="0%" stopColor="#504153" />
        <stop offset="100%" stopColor="#504153" />
      </linearGradient>
      <path
        fill="url(#linear-gradient-2)"
        d="M34.9679 3.52579L38 0.633158V0H27.4962L20.0102 18.5811L11.4934 0H0.47975V0.633158L4.02167 4.88526C4.36683 5.19947 4.54733 5.65895 4.50142 6.12158V22.8316C4.61067 23.4332 4.41433 24.0521 3.99 24.4895L0 29.3116V29.9368H11.3129V29.3037L7.32292 24.4895C6.89067 24.0505 6.68642 23.4426 6.7735 22.8316V8.3779L16.7042 29.9447H17.8584L26.3973 8.3779V25.5584C26.3973 26.0116 26.3973 26.1047 26.0997 26.4016L23.028 29.3653V30H37.9303V29.3668L34.9695 26.4758C34.7098 26.28 34.5752 25.9516 34.6307 25.6326V4.36895C34.5752 4.04842 34.7082 3.72 34.9679 3.52579Z"
      />
    </svg>
  );
};

export default Ship;
