import React from "react";

const Racers: React.FC = () => {
  return (
    <svg
      width={"3.9rem"}
      height={"3.8rem"}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8502 23.4342C25.7062 22.329 12.7607 17.5395 11.0962 15.8816C8.69204 13.3027 9.06192 9.43425 13.5004 9.25004C17.569 8.88162 22.7473 9.06583 26.8159 9.25004C23.3021 -4.38154 0.184937 -2.72365 0 12.75C0 15.6974 1.4795 20.3027 3.14393 21.5921C5.36317 23.4342 18.3088 26.5658 21.6376 27.4869C26.446 28.5921 28.1104 27.8553 28.8502 23.4342ZM5.36317 10.1711C6.10292 10.1711 6.84267 10.9079 6.84267 11.829C6.84267 12.5658 6.10292 13.3027 5.36317 13.3027C4.43849 13.3027 3.69874 12.5658 3.69874 11.829C3.69874 10.9079 4.43849 10.1711 5.36317 10.1711Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8164 20.3029C27.0013 18.4608 26.0766 13.1187 25.7068 11.4608C24.2273 11.2766 13.8708 10.9081 12.9461 12.0134C10.1721 14.9608 18.6792 17.1713 20.3436 17.7239C22.3779 18.645 24.7821 19.566 26.8164 20.3029Z"
        fill="white"
      />
    </svg>
  );
};

export default Racers;
