import Auth from "components/Auth";
import React, { useState } from "react";
import EnterEmail from "./EnterEmail";
import ConfirmCode from "./ConfirmCode";
import CreateAccount from "./CreateAccount";
import SecretPhraseScreen from "./SecretPhraseScreen";
import InviteCode from "./InviteCode";

export type authStatuses =
  | "code"
  | "email"
  | "password"
  | "phrase"
  | "invite_code";

const Registration: React.FC = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<authStatuses>("email");
  const [code, setCode] = useState<string[]>(new Array(4).fill(""));
  const [phrase, setPhrase] = useState("");

  const renderContent = () => {
    switch (status) {
      case "code":
        return (
          <ConfirmCode
            setStatus={setStatus}
            code={code}
            setCode={setCode}
            email={email}
          />
        );
      case "email":
        return (
          <EnterEmail email={email} setEmail={setEmail} setStatus={setStatus} />
        );
      case "password":
        return (
          <CreateAccount
            setPhrase={setPhrase}
            code={code.join("")}
            email={email}
            setStatus={setStatus}
          />
        );
      // case "invite_code":
      //   return (
      //     <InviteCode
      //       password={password}
      //       setPhrase={setPhrase}
      //       code={code.join("")}
      //       email={email}
      //       setStatus={setStatus}
      //     />
      //   );
      case "phrase":
        return <SecretPhraseScreen phrase={phrase} />;
    }
  };

  return <Auth phrase={phrase}>{renderContent()}</Auth>;
};

export default Registration;
