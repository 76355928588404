import {
  ChangePasswordDTO,
  ChangePasswordResponse,
  IUser,
  MnemonicDTO,
  MnemonicResponse,
  PromocodeCheckResponse,
} from "interfaces/User";
import { IInitResponse } from "interfaces/Blockchain";
import { API_ROUTES, newApi, newAuthApi } from ".";
import { errorCallback } from "./Auth";
import { IInventoryResponse } from "interfaces/Inventory";
import Alert from "utils/alert";

export const UserApi = {
  async getUserData() {
    const response = await newAuthApi
      .get<IUser>(API_ROUTES.GET_USER)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => console.log(e));

    return response;
  },

  async getInventoryData() {
    const response = await newAuthApi
      .get<IInventoryResponse>(`${API_ROUTES.GET_INVENTORY}`)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => console.log(e));

    return response;
  },

  async getMnemonic(dto: MnemonicDTO) {
    const response = await newAuthApi
      .post<MnemonicResponse>(API_ROUTES.GET_MNEMONIC, dto)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async changePassword(dto: ChangePasswordDTO) {
    const response = await newAuthApi
      .post<ChangePasswordResponse>(API_ROUTES.CHANGE_PASSWORD, dto)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async activatePromocode(promocode: string, message: string) {
    const response = await newAuthApi
      .post<ChangePasswordResponse>(API_ROUTES.PROMOCODE_ACTIVATE, {
        promocode,
      })
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async getData() {
    const response = await newAuthApi
      .post<IInitResponse>(API_ROUTES.GET_DATA, { platform: "web" })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        console.log(e);
      });

    return response;
  },
};
