import { IHistoryResponse } from "interfaces/Race";
import { API_ROUTES, newAuthApi } from ".";

export const HistoryApi = {
  async getHistory() {
    const response = await newAuthApi.get<IHistoryResponse>(
      API_ROUTES.RACE_HISTORY
    );

    return response.data;
  },
};
