import React, { useState } from "react";
import styles from "styles/components/auth.module.scss";
import GameButton from "components/Buttons/GameButton";
import PinInput from "components/FormElements/PinInput";
import Timer from "components/Timer";
import Steps from "components/Auth/Steps";
import { AuthApi } from "utils/api/Auth";
import Alert from "utils/alert";
import { authStatuses } from ".";
import { SetState } from "interfaces/General";
import { useTranslation } from "react-i18next";

interface ConfirmCodeProps {
  email: string;
  code: string[];
  setCode: SetState<string[]>;
  setStatus: SetState<authStatuses>;
}

const ConfirmCode: React.FC<ConfirmCodeProps> = ({
  email,
  code,
  setCode,
  setStatus,
}) => {
  const { t } = useTranslation();

  const [resendDisabled, setResendDisabled] = useState(true);

  const handleResend = () => {
    AuthApi.resendCode({ email }, t("code_resend_success"))
      .then(() => setResendDisabled(true))
      .catch((e) => {
        Alert("error", t("default_error"));
      });
  };

  const handleConfirm = () => {
    AuthApi.codeCheck({ email, code: code.join("") }, t("code_check_success"))
      .then((res) => {
        if (res?.checked) {
          setStatus("password");
        }
      })
      .catch((e) => {
        Alert("error", t("default_error"));
      });
  };

  return (
    <>
      <Steps active={2} />

      <div className={styles.auth__text}>
        {t("code_sent")}: <span>{email}</span>
      </div>

      <PinInput pin={code} setPin={setCode} />

      {resendDisabled ? (
        <div className={styles.auth__text}>
          {t("resend_code_in")}:{" "}
          <span>{<Timer setDisabled={setResendDisabled} seconds={60} />}</span>
        </div>
      ) : (
        <button onClick={handleResend} className={styles.auth__button}>
          {t("resend_code")}
        </button>
      )}

      <GameButton label={t("next")} onClick={handleConfirm} />
    </>
  );
};

export default ConfirmCode;
