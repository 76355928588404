import CarAvatar from "components/CarAvatar";
import { SetState } from "interfaces/General";
import { IRating } from "interfaces/Ratings";
import React from "react";
import styles from "styles/pages/ratings.module.scss";

interface ListItemProps {
  active?: boolean;
  loading?: boolean;
  id: number;
  setActiveCar: SetState<IRating>;
  car: IRating;
}

const ListItem: React.FC<ListItemProps> = ({
  car,
  active = false,
  loading = false,
  id,
  setActiveCar,
}) => {
  const toggleActive = () => {
    setActiveCar(car);
  };

  return (
    <li
      className={`${styles.ratings__item} ${
        active ? styles["ratings__item--active"] : ""
      }`}
      onClick={toggleActive}
    >
      <CarAvatar
        extraClass={styles.ratings__item_frame}
        active={active}
        height="6.6rem"
        width="7.3rem"
        id={String(id)}
        img={loading ? "" : car.images.avatar}
        loading={loading}
      />

      <div className={styles.ratings__pos}>
        {loading ? "..." : car.position}
      </div>

      <div className={styles.ratings__earned}>
        <span>{loading ? "..." : car.title}</span>
        {loading ? "..." : car.result} MGT
      </div>
    </li>
  );
};

export default ListItem;
