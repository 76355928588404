import HowToPlayContent from "components/GamePopup/HowToPlayContent";
import ArrowLeft from "icons/ArrowLeft";
import React from "react";
import { useAppDispatch } from "redux/hooks/redux";
import { setGameContent, setGameIsOpen } from "redux/reducers/PopupSlice";
import styles from "styles/pages/how-to-play.module.scss";

export interface ItemProps {
  number: number;
  title: string;
  content: React.ReactNode;
  top: number;
  left: number;
}

const Item: React.FC<ItemProps> = ({ content, number, title, left, top }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(
      setGameContent(<HowToPlayContent content={content} title={title} />)
    );
    dispatch(setGameIsOpen(true));
  };

  return (
    <div
      onClick={handleClick}
      style={{ top: `${top}rem`, left: `${left}rem` }}
      className={styles.how_to_play__item}
    >
      <div className={styles.how_to_play__content}>
        <div className={styles.how_to_play__number}>{number}</div>
        <div className={styles.how_to_play__title}>{title}</div>
      </div>

      <div className={styles.how_to_play__arrow}>
        <ArrowLeft />
      </div>
    </div>
  );
};

export default Item;
