import Lock from "icons/Lock";
import React from "react";
import styles from "styles/components/secret-phrase.module.scss";

interface SecretPhraseProps {
  phrase: string;
  label?: string;
  blur?: boolean;
}

const SecretPhrase: React.FC<SecretPhraseProps> = ({ phrase, blur, label }) => {
  return (
    <>
      <div className={styles.secret_phrase__label}>{label}</div>
      <div
        className={`${styles.secret_phrase} ${
          blur ? styles["secret_phrase--blur"] : ""
        }`}
      >
        <div className={`${styles.secret_phrase__content}`}>{phrase}</div>

        <div className={styles.secret_phrase__lock}>
          <Lock />
        </div>
      </div>
    </>
  );
};

export default SecretPhrase;
