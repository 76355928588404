import {
  CodeCheckDTO,
  CodeCheckResponse,
  CodeResendResponse,
  PresignupDTO,
  PresignupResponse,
  SignupResponse,
  SignupDTO,
  LoginDTO,
  ResetPasswordDTO,
  ResetPasswordResponse,
  ResetPasswordChangeResponse,
  ResetPasswordChangeDTO,
} from "interfaces/Auth";
import { API_ROUTES, newApi } from ".";
import Alert from "utils/alert";
import { IUser } from "interfaces/User";
import i18n from "i18n";

export const errorCallback = (e: any) => {
  const error = e.response.data.error;

  if (error) {
    const { detail, code } = error;

    if (code) Alert("error", i18n.t(code));
    else if (detail) Alert("error", detail);
  }

  return null;
};

const setTokens = (data: IUser) => {
  localStorage.setItem("access_token", data.tokens.access.web);
  localStorage.setItem("refresh_token", data.tokens.refresh);
  localStorage.setItem("access_pixi_token", data.tokens.access.pixi);
};

export const AuthApi = {
  async presignup(dto: PresignupDTO, message: string) {
    const response = await newApi
      .post<PresignupResponse>(API_ROUTES.PRESIGNUP, dto)
      .then(({ data }) => {
        if (data.presignup) {
          Alert("success", message);
          return data;
        }

        return;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async resendCode(dto: PresignupDTO, message: string) {
    const response = await newApi
      .post<CodeResendResponse>(API_ROUTES.CODE_RESEND, dto)
      .then(({ data }) => {
        if (data.resended) {
          Alert("success", message);
          return data;
        }

        return;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async codeCheck(dto: CodeCheckDTO, message: string) {
    const response = await newApi
      .post<CodeCheckResponse>(API_ROUTES.CODE_CHECK, dto)
      .then(({ data }) => {
        if (data.checked) {
          Alert("success", message);
          return data;
        }

        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async signup(dto: SignupDTO, message: string) {
    const response = await newApi
      .post<SignupResponse>(API_ROUTES.SIGNUP, dto)
      .then(({ data }) => {
        if (data.user) {
          setTokens(data);
          Alert("success", message);
          return data;
        }

        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async login(dto: LoginDTO, message: string) {
    const response = await newApi
      .post<IUser>(API_ROUTES.LOGIN, dto)
      .then(({ data }) => {
        if (data.user) {
          setTokens(data);
          Alert("success", message);
          return data;
        }

        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async refreshToken() {
    const response = await newApi
      .get<IUser>(API_ROUTES.REFRESH, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
        },
      })
      .then(({ data }) => {
        setTokens(data);
        return data;
      })
      .catch((e) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        console.log(e);
      });

    return response;
  },

  async resetPassword(dto: ResetPasswordDTO) {
    const response = await newApi
      .post<ResetPasswordResponse>(API_ROUTES.PASSWORD_RESET, dto)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async changePassword(dto: ResetPasswordChangeDTO) {
    const response = await newApi
      .post<ResetPasswordChangeResponse>(API_ROUTES.PASSWORD_RESET_CHANGE, dto)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },
};
