import { API_ROUTES, newAuthApi } from ".";
import { errorCallback } from "./Auth";
import Alert from "utils/alert";
import {
  ITopupDTO,
  ITransferResponse,
  IWithdrawDTO,
  IWithdrawResponse,
} from "interfaces/Tokens";

export const TokensApi = {
  async transferIngame(amount: string, message: string) {
    const response = await newAuthApi
      .post<ITransferResponse>(API_ROUTES.TRANSFER_INGAME, {
        amount,
      })
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async transferBlockchain(amount: string, message: string) {
    const response = await newAuthApi
      .post<ITransferResponse>(API_ROUTES.TRANSFER_BLOCKCHAIN, {
        amount,
      })
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async withdrawMgt(dto: IWithdrawDTO, message: string) {
    const response = await newAuthApi
      .post<IWithdrawResponse>(API_ROUTES.WITHDRAW_MGT, dto)
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async withdrawBnb(dto: IWithdrawDTO, message: string) {
    const response = await newAuthApi
      .post<IWithdrawResponse>(API_ROUTES.WITHDRAW_BNB, dto)
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async topupGameBalanceFromSwapBNB(dto: ITopupDTO, message: string) {
    const response = await newAuthApi
      .post<IWithdrawResponse>(API_ROUTES.SWAP_BNB, dto)
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async topupGameBalanceFromMainWallet(dto: ITopupDTO, message: string) {
    const response = await newAuthApi
      .post<IWithdrawResponse>(API_ROUTES.SWAP_MAIN_WALLET, dto)
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async claimGameBalanceFromSwapMGTtoBNB(dto: IWithdrawDTO, message: string) {
    const response = await newAuthApi
      .post<IWithdrawResponse>(API_ROUTES.CLAIM_FROM_SWAP, dto)
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async claimGameBalanceFromTransfer(dto: IWithdrawDTO, message: string) {
    const response = await newAuthApi
      .post<IWithdrawResponse>(API_ROUTES.CLAIM_FROM_TRANSFER, dto)
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },
};
