import { SetState } from "interfaces/General";
import React, { useState, useRef, useEffect } from "react";

interface TimerProps {
  seconds: number;
  setDisabled?: SetState<boolean>;
}

const formatTime = (time: number) => {
  let minutes: string | number = Math.floor(time / 60);
  let seconds: string | number = Math.floor(time - minutes * 60);

  if (minutes <= 10) minutes = "0" + minutes;
  if (seconds <= 10) seconds = "0" + seconds;

  return `${minutes}:${seconds}`;
};

const Timer: React.FC<TimerProps> = ({ seconds, setDisabled }) => {
  const [countdown, setCountdown] = useState(seconds);
  const timerRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      if (setDisabled) setDisabled(false);
      clearInterval(timerRef.current);
    }
  }, [countdown, setDisabled]);

  return <>{formatTime(countdown)}</>;
};

export default Timer;
