let _token = "";
const getConfig = () =>
  _token
    ? {
        headers: {
          Authorization: `Bearer ${_token}`,
          Accept: "application/json",
        },
      }
    : undefined;

const setToken = (value = "") => {
  _token = value.replace("Bearer ", "");
};
export { setToken, getConfig };
