import React, { useEffect } from "react";
import styles from "styles/pages/main.module.scss";
import Card from "./Card";
import First from "./Frames/First";
import FreeRaceIcon from "icons/main-cards/FreeRace";
import { ROUTES_PATH } from "constants/routesPath";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectRaces } from "redux/selectors/racesSelector";
import { useNavigate } from "react-router-dom";
import { FreeRaceTypes } from "interfaces/Race";
import GoBack from "components/GoBack";
import { fetchFreeRacesData } from "redux/asyncActions/fetchFreeRacesData";
import { useTranslation } from "react-i18next";

interface FreeCardProps {
  index: FreeRaceTypes;
}

const FreeCard: React.FC<FreeCardProps> = ({ index }) => {
  const { t } = useTranslation();
  const { free } = useAppSelector(selectRaces);

  return (
    <>
      {free && free?.races[index] ? (
        <Card
          key={index}
          race={{
            reward: free.races[index].fund,
            racers: free.races[index].participants.current,
            timeToStart: free.races[index].startIn,
            status: "started",
            raceName: t("free_racing"),
            avatars: free.races[index].participants.avatars,
          }}
          clipPath="first-clip-path"
          icon={<FreeRaceIcon />}
          img={
            index === "daily"
              ? `/img/free-race/01.webp`
              : `/img/free-race/02.webp`
          }
          title={`${index} race`}
          text={
            index === "daily" ? t("free_daily_text_1") : t("free_weekly_text_1")
          }
          frame={<First />}
          href={`${ROUTES_PATH.FREE_RACING}/${free.races[index].id}`}
          legal
          free
        />
      ) : (
        <Card
          key={index}
          race={{
            status: "started",
            raceName: t("free_racing"),
          }}
          clipPath="first-clip-path"
          icon={<FreeRaceIcon />}
          img={
            index === "daily"
              ? `/img/free-race/01.webp`
              : `/img/free-race/02.webp`
          }
          title={`${index} race`}
          text={
            index === "daily" ? t("free_daily_text_2") : t("free_weekly_text_2")
          }
          frame={<First />}
          legal
          free
        />
      )}
    </>
  );
};

const FreeRace: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cardsToRender: FreeRaceTypes[] = ["daily", "weekly"];

  useEffect(() => {
    dispatch(fetchFreeRacesData());

    if (!localStorage.getItem("access_token")) {
      navigate(ROUTES_PATH.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GoBack />
      <div className={`${styles.main__grid} ${styles["main__grid--free"]}`}>
        {cardsToRender.map((item) => (
          <FreeCard key={item} index={item} />
        ))}
      </div>
    </>
  );
};

export default FreeRace;
