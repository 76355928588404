import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

interface ImageLazyProps {
  src: string;
  fallback?: string;
  width?: string;
  height?: string;
  clipPath?: string;
  alt: string;
}

const ImageLazy: React.FC<ImageLazyProps> = ({
  fallback: fallbackImage = "",
  width,
  height,
  alt,
  src,
  clipPath,
}) => {
  const [error, setError] = useState<null | boolean>(null);

  return (
    <LazyLoadImage
      style={{
        clipPath: `url(#frame-clip-${clipPath})`,
        WebkitClipPath: `url(#frame-clip-${clipPath})`,
      }}
      alt={alt}
      height={height}
      src={error ? fallbackImage : src}
      width={width}
      effect="opacity"
      wrapperProps={{
        style: { transitionDelay: "0.3s" },
      }}
      onError={() => {
        setError(true);
      }}
      visibleByDefault={src === fallbackImage}
    />
  );
};

export default ImageLazy;
