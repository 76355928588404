import React from "react";

interface CarFrameProps {
  orange?: boolean;
  active?: boolean;
  blue?: boolean;
  id?: string;
}

const CarFrame: React.FC<CarFrameProps> = ({ active, orange, id, blue }) => {
  const fillColor = orange
    ? "url(#car-frame-gradient)"
    : blue
    ? "url(#blue-car-frame-gradient)"
    : active
    ? "#ffffff"
    : "#241729";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="11.2rem"
      height="10rem"
      version="1.1"
      imageRendering={"optimizeQuality"}
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 26.973 24.005"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "\n   \n    .fil0 {fill:white}\n   \n  ",
          }}
        />
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          fill={fillColor}
          d="M0.34 10.768l2.752 -4.767 2.752 -4.766c0.453,-0.786 1.232,-1.235 2.139,-1.235l5.503 0 5.504 0c0.907,0 1.686,0.449 2.139,1.235l2.752 4.766 2.752 4.767c0.453,0.785 0.453,1.684 0,2.469l-2.752 4.767 -2.752 4.766c-0.453,0.786 -1.232,1.235 -2.139,1.235l-5.504 0 -5.503 0c-0.907,0 -1.686,-0.449 -2.139,-1.235l-2.752 -4.766 -2.752 -4.767c-0.453,-0.785 -0.453,-1.684 0,-2.469z"
        />
      </g>

      <clipPath id={`frame-clip-${id}`} clipPathUnits="objectBoundingBox">
        <path d="M0.013,0.449 l0.102,-0.199 l0.102,-0.199 c0.017,-0.033,0.046,-0.051,0.079,-0.051 l0.204,0 l0.204,0 c0.034,0,0.063,0.019,0.079,0.051 l0.102,0.199 l0.102,0.199 c0.017,0.033,0.017,0.07,0,0.103 l-0.102,0.199 l-0.102,0.199 c-0.017,0.033,-0.046,0.051,-0.079,0.051 l-0.204,0 l-0.204,0 c-0.034,0,-0.063,-0.019,-0.079,-0.051 l-0.102,-0.199 l-0.102,-0.199 c-0.017,-0.033,-0.017,-0.07,0,-0.103"></path>
      </clipPath>

      {orange && (
        <linearGradient transform="rotate(270deg)" id="car-frame-gradient">
          <stop stopColor="#FF7854" />
          <stop offset="1" stopColor="#E73B4B" />
        </linearGradient>
      )}

      {blue && (
        <linearGradient
          id="blue-car-frame-gradient"
          x1="41.9331"
          y1="0"
          x2="41.9331"
          y2="79"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#60EEF7" />
          <stop offset="1" stopColor="#1CA1B9" />
        </linearGradient>
      )}
    </svg>
  );
};

export default CarFrame;
