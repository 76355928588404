import { ROUTES_PATH } from "constants/routesPath";
import ProfileIcon from "icons/ProfileIcon";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/hooks/redux";
import styles from "styles/pages/profile.module.scss";
import ChangePassword from "./ChangePassword";
import Alert from "utils/alert";
import { action } from "common/bridge";
import { setUser } from "redux/reducers/UserSlice";
import { setToken } from "common/bridge/token";
import Wrapper from "./Wrapper";
import Button from "components/Buttons/Button";
import RevealMnemonic from "./Wallet/RevealMnemonic";
import { setHintIsOpen, setHintContent } from "redux/reducers/PopupSlice";
import Notifications from "./Notifications";
import { useTranslation } from "react-i18next";

const Profile: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleLogout = async () => {
    setToken("");
    navigate(ROUTES_PATH.MAIN);
    action.post.disconnect();
    Alert("success", t("logout_success"));
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_pixi_token");
    localStorage.removeItem("refresh_token");
    dispatch(setUser(null));
  };

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate(ROUTES_PATH.LOGIN);
    } else {
      if (!localStorage.getItem("profile_hint")) {
        const isDesktop = window.innerWidth > 1023.98;
        dispatch(
          setHintContent({
            name: "judy",
            onNext() {
              localStorage.setItem("profile_hint", "completed");
              navigate(ROUTES_PATH.WALLET);
              dispatch(setHintIsOpen(false));
            },
            text: t("profile_hint_text"),
            hole: isDesktop
              ? {
                  left: "21.5%",
                  height: "69rem",
                  width: "48rem",
                  top: "14rem",
                }
              : undefined,
            img: isDesktop
              ? {
                  right: "-23rem",
                }
              : undefined,
            content: isDesktop
              ? {
                  left: "37rem",
                }
              : undefined,
          })
        );
        dispatch(setHintIsOpen(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <div className={styles.profile__col}>
        <div className={styles.profile__wrapper}>
          <div className={styles.profile__header}>
            <div className={styles.profile__icon}>
              <ProfileIcon />
            </div>

            <div className={styles.profile__title}>
              {t("profile")}{" "}
              <Button callback={handleLogout} label={t("logout")} />
            </div>
          </div>

          <RevealMnemonic />
        </div>

        <ChangePassword />
      </div>

      {/* <div className={styles.profile__col}>
        <Notifications />
      </div> */}
    </Wrapper>
  );
};

export default Profile;
