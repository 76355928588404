import React, { memo } from "react";
import Item from "./Item";
import RaceInfo, { RaceInfoProps } from "./RaceInfo";
import { IParticipantItem } from "interfaces/Car";
import styles from "styles/pages/joinRace/join-race.module.scss";
import scrollStyles from "styles/components/custom-scrollbar.module.scss";
import { useTranslation } from "react-i18next";

export interface ParticipantsTabProps {
  cars: IParticipantItem[];
  info: RaceInfoProps;
  qualification?: boolean;
  isLoading: boolean;
}

const ParticipantsTab: React.FC<ParticipantsTabProps> = memo(
  ({ cars, info, qualification, isLoading }) => {
    const { t } = useTranslation();

    return (
      <>
        <RaceInfo
          isLoading={isLoading}
          qualification={qualification}
          participantsTab
          {...info}
        />

        <div
          className={`${styles.join_race__wrapper} ${scrollStyles.custom_scrollbar}`}
        >
          <ul className={styles.join_race__grid}>
            {cars.length > 0 ? (
              cars.map((item, key) => {
                return (
                  <Item
                    disabled={true}
                    key={key}
                    disableSelect={true}
                    participantsTab={true}
                    isOwner={item.isOwner}
                    active={false}
                    {...item.car}
                  />
                );
              })
            ) : (
              <div className={styles.join_race__no_cars}>
                {cars.length === 0 && <div>{t("no_participants")}</div>}

                {isLoading && <div>{t("loading")}...</div>}
              </div>
            )}
          </ul>
        </div>
      </>
    );
  }
);

export default ParticipantsTab;
