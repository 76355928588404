import React from "react";
import styles from "styles/components/rounded-check.module.scss";
import Check from "icons/Check";

interface RoundedCheckProps {
  big?: boolean;
  margin?: boolean;
}

const RoundedCheck: React.FC<RoundedCheckProps> = ({
  big = false,
  margin = false,
}) => {
  return (
    <div
      className={`${styles.rounded_check} ${
        big ? styles["rounded_check--big"] : ""
      } ${margin ? styles["rounded_check--margin"] : ""}`}
    >
      <Check />
    </div>
  );
};

export default RoundedCheck;
