import Auth from "components/Auth";
import Input from "components/FormElements/Input";
import GameButton from "components/Buttons/GameButton";
import React, { useState } from "react";
import styles from "styles/components/auth.module.scss";
import { useTranslation } from "react-i18next";
import { AuthApi } from "utils/api/Auth";
import Alert from "utils/alert";
import Button from "components/Buttons/Button";
import Warning from "icons/Warning";

const RecoverPwd: React.FC = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [dataSending, setDataSending] = useState(false);

  const handleSubmit = () => {
    setDataSending(true);

    AuthApi.resetPassword({ email })
      .then((res) => {
        if (res?.sent) {
          setSent(true);
          Alert("success", t("send_success"));
        }
      })
      .finally(() => setDataSending(false));
  };

  const handleRedirect = () => {
    setSent(false);
  };

  return (
    <Auth>
      <div className={styles.auth__title}>{t("recover_pwd")}</div>
      {sent ? (
        <>
          <div className={styles.auth__text}>
            {t("code_sent")}: <span>{email}</span>
          </div>

          <div className={styles.auth__warning}>
            <Warning />
            {t("reset_password_warning")}
          </div>

          <Button label={t("other_mail")} big callback={handleRedirect} />
        </>
      ) : (
        <>
          <div className={styles.auth__text}>{t("reset_pwd_enter_email")}</div>

          <form className={styles.auth__form}>
            <Input placeholder={t("email")} value={email} setValue={setEmail} />

            <GameButton
              label={t("recover")}
              onClick={handleSubmit}
              disabled={dataSending}
            />
          </form>
        </>
      )}
    </Auth>
  );
};

export default RecoverPwd;
