import SmoothImage from "components/SmoothImage";
import React, { useEffect, useState } from "react";
import styles from "styles/pages/invite-friends/invite-friends.module.scss";
import { inventoryBgHash } from "constants/blurhashes";
import ReferralProgram from "icons/ReferralProgram";
import Input from "components/FormElements/Input";
import { IRefferal } from "interfaces/Referral";
import { ReferralSystemApi } from "utils/api/ReferralSystem";
import Alert from "utils/alert";
import StatsItem from "./StatsItem";
import { useAppDispatch } from "redux/hooks/redux";
import { setBalance } from "redux/reducers/UserSlice";
import { useTranslation } from "react-i18next";

const InviteFriends: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [referral, setReferral] = useState<IRefferal>();

  const [dataSending, setDataSending] = useState(false);
  const [code, setCode] = useState("...");
  const [reflink, setReflink] = useState("...");

  const handleClaimMGT = () => {
    setDataSending(true);

    ReferralSystemApi.claim()
      .then((res) => {
        if (res) {
          //@ts-ignore
          setReferral((prev) => {
            return {
              invaiting: { ...prev?.invaiting },
              stats: { ...res.stats },
            };
          });
          Alert("success", t("claim_success"));
          dispatch(setBalance(res.balance));
        }
      })
      .finally(() => setDataSending(false));
  };

  useEffect(() => {
    ReferralSystemApi.get().then((res) => {
      if (res) {
        setReferral(res);
        setCode(res.invaiting.code);
        setReflink(res.invaiting.link);
      }
    });
  }, []);

  return (
    <div className={styles.invite_friends}>
      <div className={styles.invite_friends__bg}>
        <SmoothImage
          src="/img/inventory-bg.webp"
          alt="Cyberpunk city"
          hash={inventoryBgHash}
        />
      </div>

      <div className={styles.invite_friends__content}>
        <div className={styles.invite_friends__col}>
          <div className={styles.invite_friends__top}>
            <div className={styles.invite_friends__icon}>
              <ReferralProgram />
            </div>

            <div className={styles.invite_friends__title}>
              {t("invite_friends")}
            </div>
          </div>

          <div className={styles.invite_friends__text}>
            {t("invite_friends_text")}
          </div>

          <div className={styles.invite_friends__code}>
            <Input
              value={code}
              setValue={setCode}
              disabled
              label={t("invite_code")}
              copy={code}
            />

            <Input
              value={reflink}
              setValue={setReflink}
              disabled
              label={t("ref_link")}
              copy={reflink}
            />
          </div>

          <div className={styles.invite_friends__stats}>
            <div className={styles.invite_friends__subtitle}>
              {t("statistics")}
            </div>

            <StatsItem
              main={referral?.stats.referrals.count || "..."}
              secondary={t("referrals_count")}
              icon={<ReferralProgram />}
            />

            <StatsItem
              main={referral?.stats.rewards.tokens.unclaimed || "..."}
              secondary={`MGT ${t("unclaimed")}`}
              callback={handleClaimMGT}
              disabled={!referral?.stats.rewards.tokens.button}
              dataSending={dataSending}
            />

            <StatsItem
              main={referral?.stats.rewards.tokens.claimed || "..."}
              secondary={`MGT ${t("claimed")}`}
            />

            <StatsItem
              main={referral?.stats.rewards.tokens.total || "..."}
              secondary={t("total")}
            />
          </div>
        </div>

        {/* <div className={styles.invite_friends__col}>
          <div className={styles.invite_friends__subtitle}>
            Your rewards for racers
          </div>

          <Invited />

          <Table />
        </div> */}
      </div>
    </div>
  );
};

export default InviteFriends;
