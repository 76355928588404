import { Callback, CallbacksDict } from "./types";

const CALLBACKS: CallbacksDict = {
  onRacePlaySound: [],
  onRaceMuteSound: [],
  onRaceMuteMusic: [],
  onRaceReadyGetInit: [],
  onRaceLoaded: [],
  onRaceExit: [],
};
function call(name: keyof CallbacksDict, ...arg: any[]) {
  CALLBACKS[name]?.forEach((f) => f(...arg));
}
function add(name: keyof CallbacksDict, cb?: Callback) {
  if (!cb) {
    return;
  }
  remove(name, cb);
  CALLBACKS[name].push(cb);
}
function remove(name: keyof CallbacksDict, cb: Callback) {
  const index = CALLBACKS[name].indexOf(cb);
  if (index !== -1) {
    CALLBACKS[name].splice(index, 1);
  }
}
function clearAll() {
  for (const key in CALLBACKS) {
    CALLBACKS[key as keyof CallbacksDict] = [];
  }
}

const callbacks = { call, add, remove, clearAll };

export default callbacks;
