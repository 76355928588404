import { combineReducers, configureStore } from "@reduxjs/toolkit";
import popupReducer from "./reducers/PopupSlice";
import userReducer from "./reducers/UserSlice";
import racesReducer from "./reducers/RacesSlice";

const rootReducer = combineReducers({
  popupReducer,
  userReducer,
  racesReducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
  });
};

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore["dispatch"];
