import { IDistributionItem } from "components/JoinRace/DistributionItem";

export const freeWeeklyPrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 690,
      usdt: 69,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 435,
      usdt: 43,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 276,
      usdt: 27.6,
    },
  },

  {
    place: "4",
    prize: {
      mgt: 240,
      usdt: 24,
    },
  },

  {
    place: "5",
    prize: {
      mgt: 219,
      usdt: 22,
    },
  },

  {
    place: "6",
    prize: {
      mgt: 195,
      usdt: 19.5,
    },
  },

  {
    place: "7",
    prize: {
      mgt: 174,
      usdt: 17.4,
    },
  },

  {
    place: "8",
    prize: {
      mgt: 153,
      usdt: 15,
    },
  },

  {
    place: "9",
    prize: {
      mgt: 129,
      usdt: 13,
    },
  },

  {
    place: "10",
    prize: {
      mgt: 108,
      usdt: 11,
    },
  },

  {
    place: "11",
    prize: {
      mgt: 69,
      usdt: 7,
    },
  },

  {
    place: "12",
    prize: {
      mgt: 63,
      usdt: 6.3,
    },
  },

  {
    place: "13",
    prize: {
      mgt: 57,
      usdt: 5.7,
    },
  },

  {
    place: "14",
    prize: {
      mgt: 51,
      usdt: 5,
    },
  },

  {
    place: "15",
    prize: {
      mgt: 42,
      usdt: 4,
    },
  },

  {
    place: "16",
    prize: {
      mgt: 33,
      usdt: 3.3,
    },
  },

  {
    place: "17",
    prize: {
      mgt: 27,
      usdt: 2.7,
    },
  },

  {
    place: "18",
    prize: {
      mgt: 21,
      usdt: 2,
    },
  },

  {
    place: "19",
    prize: {
      mgt: 12,
      usdt: 1,
    },
  },

  {
    place: "20",
    prize: {
      mgt: 6,
      usdt: 0.6,
    },
  },
];

export const freeDailyPrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 150,
      usdt: 15,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 75,
      usdt: 7.5,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 36,
      usdt: 3.6,
    },
  },

  {
    place: "4",
    prize: {
      mgt: 24,
      usdt: 2.4,
    },
  },

  {
    place: "5",
    prize: {
      mgt: 15,
      usdt: 1.5,
    },
  },
];

export const streetRacePrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 45,
      usdt: 4.5,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 27,
      usdt: 2.7,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 18,
      usdt: 1.8,
    },
  },
];

export const streetRacePrizesSecondBet: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 450,
      usdt: 45,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 270,
      usdt: 27,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 180,
      usdt: 18,
    },
  },
];

export const streetRacePrizesThirdBet: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 1125,
      usdt: 112,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 675,
      usdt: 67.5,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 450,
      usdt: 45,
    },
  },
];

export const legalDailyPrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 636,
      usdt: 63,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 457,
      usdt: 45,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 361,
      usdt: 36,
    },
  },

  {
    place: "4",
    prize: {
      mgt: 324,
      usdt: 32,
    },
  },

  {
    place: "5",
    prize: {
      mgt: 294,
      usdt: 29,
    },
  },

  {
    place: "6",
    prize: {
      mgt: 257,
      usdt: 25,
    },
  },

  {
    place: "7",
    prize: {
      mgt: 224,
      usdt: 22,
    },
  },

  {
    place: "8",
    prize: {
      mgt: 194,
      usdt: 19,
    },
  },

  {
    place: "9",
    prize: {
      mgt: 169,
      usdt: 17,
    },
  },

  {
    place: "10",
    prize: {
      mgt: 139,
      usdt: 14,
    },
  },
];

export const legalWeeklyPrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 894,
      usdt: 89,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 645,
      usdt: 64,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 510,
      usdt: 51,
    },
  },

  {
    place: "4",
    prize: {
      mgt: 458,
      usdt: 45,
    },
  },

  {
    place: "5",
    prize: {
      mgt: 417,
      usdt: 41,
    },
  },

  {
    place: "6",
    prize: {
      mgt: 366,
      usdt: 36,
    },
  },

  {
    place: "7",
    prize: {
      mgt: 319,
      usdt: 32,
    },
  },

  {
    place: "8",
    prize: {
      mgt: 278,
      usdt: 27,
    },
  },

  {
    place: "9",
    prize: {
      mgt: 242,
      usdt: 24,
    },
  },

  {
    place: "10",
    prize: {
      mgt: 201,
      usdt: 20,
    },
  },
];

export const legalMonthlyPrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 1668,
      usdt: 168,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 1207,
      usdt: 120,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 958,
      usdt: 95,
    },
  },

  {
    place: "4",
    prize: {
      mgt: 863,
      usdt: 86,
    },
  },

  {
    place: "5",
    prize: {
      mgt: 786,
      usdt: 78,
    },
  },

  {
    place: "6",
    prize: {
      mgt: 691,
      usdt: 69,
    },
  },

  {
    place: "7",
    prize: {
      mgt: 605,
      usdt: 60,
    },
  },

  {
    place: "8",
    prize: {
      mgt: 523,
      usdt: 52,
    },
  },

  {
    place: "9",
    prize: {
      mgt: 462,
      usdt: 46,
    },
  },

  {
    place: "10",
    prize: {
      mgt: 386,
      usdt: 38,
    },
  },
];

export const newYearPrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 2000,
      usdt: 200,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 1200,
      usdt: 120,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 800,
      usdt: 80,
    },
  },

  {
    place: "4",
    prize: {
      mgt: 600,
      usdt: 60,
    },
  },

  {
    place: "5",
    prize: {
      mgt: 500,
      usdt: 50,
    },
  },

  {
    place: "6",
    prize: {
      mgt: 400,
      usdt: 40,
    },
  },

  {
    place: "7",
    prize: {
      mgt: 350,
      usdt: 35,
    },
  },

  {
    place: "8",
    prize: {
      mgt: 300,
      usdt: 30,
    },
  },

  {
    place: "9",
    prize: {
      mgt: 250,
      usdt: 25,
    },
  },

  {
    place: "10",
    prize: {
      mgt: 200,
      usdt: 20,
    },
  },

  {
    place: "11-20",
    prize: {
      mgt: 150,
      usdt: 40,
    },
  },

  {
    place: "21-30",
    prize: {
      mgt: 100,
      usdt: 10,
    },
  },

  {
    place: "31-40",
    prize: {
      mgt: 50,
      usdt: 5,
    },
  },

  {
    place: "41-50",
    prize: {
      mgt: 40,
      usdt: 4,
    },
  },
];

export const newbiesAndBeginnersPrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 163,
      usdt: 16,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 142,
      usdt: 14,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 130,
      usdt: 13,
    },
  },

  {
    place: "4",
    prize: {
      mgt: 124,
      usdt: 12,
    },
  },

  {
    place: "5",
    prize: {
      mgt: 118,
      usdt: 11,
    },
  },

  {
    place: "6",
    prize: {
      mgt: 112,
      usdt: 11,
    },
  },

  {
    place: "7",
    prize: {
      mgt: 106,
      usdt: 10,
    },
  },
];

export const unlimitedPrizes: IDistributionItem[] = [
  {
    place: "1",
    prize: {
      mgt: 402,
      usdt: 40,
    },
  },

  {
    place: "2",
    prize: {
      mgt: 291,
      usdt: 29,
    },
  },

  {
    place: "3",
    prize: {
      mgt: 206,
      usdt: 20,
    },
  },
];
