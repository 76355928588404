import React from "react";

const IOSIcon = () => {
  return (
    <svg
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="15rem"
      height="12rem"
      viewBox="0 29.445 595.279 583"
      enableBackground="new 0 29.445 595.279 583"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <polygon
            fill="none"
            points="595.28,644.175 0,644.175 0,48.895 595.28,48.895 		"
          />
          <path
            fill="#eb4a41"
            d="M310.041,115.188v292.119c0,7.532-6.328,13.638-13.642,13.638c-7.534,0-13.642-6.142-13.642-13.638V115.17l-53.626,52.939
			c-5.334,5.266-14.14,5.111-19.319-0.002c-5.336-5.268-5.183-13.959-0.003-19.073l67.269-66.407l1.783-1.76
			c9.68-9.556,25.391-9.562,35.077,0l1.783,1.76l67.269,66.407c5.334,5.266,5.177,13.959-0.003,19.073
			c-5.335,5.267-14.14,5.116-19.319,0.096L310.041,115.188z M372.05,222.519c-6.85,0-12.401-5.753-12.401-12.402
			c0-6.849,5.752-12.402,12.401-12.402h74.558c27.315,0,49.459,22.159,49.459,49.405v273.239c0,27.286-22.187,49.406-49.459,49.406
			H148.673c-27.316,0-49.46-22.16-49.46-49.406V247.12c0-27.286,22.188-49.405,49.46-49.405h74.557
			c6.85,0,12.402,5.753,12.402,12.402c0,6.849-5.753,12.402-12.402,12.402h-74.24c-13.812,0-24.973,11.088-24.973,24.766v272.911
			c0,13.667,11.181,24.767,24.973,24.767H446.29c13.812,0,24.973-11.088,24.973-24.767V247.285
			c0-13.667-11.181-24.766-24.973-24.766H372.05z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IOSIcon;
