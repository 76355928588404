import React from "react";
import styles from "styles/components/gamePopup/oulined.module.scss";
import scrollStyles from "styles/components/custom-scrollbar.module.scss";

interface HowToPlayContentProps {
  title: string;
  content: React.ReactNode;
}

const HowToPlayContent: React.FC<HowToPlayContentProps> = ({
  content,
  title,
}) => {
  return (
    <div className={styles.outlined} onClick={(e) => e.stopPropagation()}>
      <div
        className={`${styles.outlined__content} ${scrollStyles.custom_scrollbar}`}
      >
        <div className={styles.outlined__title}>{title}</div>
        <div className={styles.outlined__text}>{content}</div>
      </div>
    </div>
  );
};

export default HowToPlayContent;
