import React, { useState } from "react";
import Alert from "utils/alert";
import { UserApi } from "utils/api/User";
import SecretPhrase from "components/SecretPhrase";
import styles from "styles/pages/profile.module.scss";
import GameButton from "components/Buttons/GameButton";
import Input from "components/FormElements/Input";
import Copy from "components/Copy";
import { useTranslation } from "react-i18next";

const RevealMnemonic: React.FC = () => {
  const { t } = useTranslation();

  const [revealPassword, setRevealPassword] = useState("");

  const [phrase, setPhrase] = useState(
    "use begin sister prosper dumb topic memory pilot cost aisle snack kenn"
  );
  const [enterPass, setEnterPass] = useState(false);
  const [blur, setBlur] = useState(true);

  const handleReveal = () => {
    setEnterPass(true);
  };

  const onReveal = () => {
    UserApi.getMnemonic({ password: revealPassword })
      .then((res) => {
        if (res) {
          setPhrase(res.mnemonic);
          setBlur(false);
        }
      })
      .catch((e) => Alert("error", t("default_error")));
  };

  return (
    <div className={styles.profile__secret_phrase}>
      <SecretPhrase label={t("secret_phrase")} blur={blur} phrase={phrase} />

      {blur ? (
        <div className={styles.profile__button}>
          {enterPass && (
            <Input
              value={revealPassword}
              setValue={setRevealPassword}
              label={t("enter_your_pwd")}
              type="password"
            />
          )}
          <GameButton
            disabled={
              (revealPassword.trim() === "" || revealPassword.length < 8) &&
              enterPass
            }
            small
            label={t("reveal")}
            onClick={enterPass ? onReveal : handleReveal}
          />
        </div>
      ) : (
        <Copy noMargin text={phrase} withText />
      )}
    </div>
  );
};

export default RevealMnemonic;
