import GameButton from "components/Buttons/GameButton";
import Input from "components/FormElements/Input";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/pages/profile.module.scss";
import Alert from "utils/alert";
import { UserApi } from "utils/api/User";

interface EnterPropmocedeProps {
  initialValue?: string | null;
}

const EnterPromocode: React.FC<EnterPropmocedeProps> = ({ initialValue }) => {
  const { t } = useTranslation();

  const [promocode, setPromocode] = useState(initialValue || "");
  const [disabled, setDisabled] = useState(false);

  const handleSave = () => {
    setDisabled(true);
    UserApi.activatePromocode(promocode, t("activate_promocode_success"))
      .then(() => {
        setPromocode("");
      })
      .catch(() => Alert("error", t("default_error")))
      .finally(() => setDisabled(false));
  };

  return (
    <form className={styles.profile__promocode}>
      <Input setValue={setPromocode} value={promocode} label={t("promocode")} />
      <GameButton
        small
        label={t("apply")}
        onClick={handleSave}
        disabled={promocode.trim() === "" || disabled}
      />
    </form>
  );
};

export default EnterPromocode;
