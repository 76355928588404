import React from "react";

const FreeRace: React.FC = () => {
  return (
    <svg
      width={"10.7rem"}
      height={"11.8rem"}
      viewBox="0 0 107 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.2333 3.03604L80.2084 12.2517L96.3499 21.6349C103.173 25.6562 107 32.3585 107 40.4012V59.0001V77.5989C107 85.4741 103.173 92.3439 96.3499 96.3653L95.8507 96.5328L89.1944 92.0088L86.3655 89.9981L76.8802 83.7985L68.2271 78.1016L53.5832 68.2157L47.2597 72.2371L38.7729 78.1016L30.1197 83.7985L20.8009 89.9981L17.14 92.5115L11.1493 96.5328L10.6501 96.3653C3.82737 92.3439 0 85.4741 0 77.5989V59.0001V40.4012C0 32.3585 3.82737 25.6562 10.6501 21.6349L26.7916 12.2517L42.7667 3.03604C49.5894 -0.985322 57.4106 -0.985322 64.2333 3.03604ZM19.1369 101.224L28.7885 94.6897L42.7667 102.9C49.5894 106.921 57.4106 106.921 64.2333 102.9L77.5459 95.1924L87.1975 101.56L80.2084 105.581L64.2333 114.964C57.4106 118.985 49.5894 118.985 42.7667 114.964L26.7916 105.581L19.1369 101.224ZM93.3546 83.1283C95.5179 79.7771 96.6827 75.9233 96.6827 71.5668V46.4333C96.6827 38.3906 92.6889 31.6883 85.8662 27.6669L64.2333 15.1001C57.4106 11.0788 49.5894 11.0788 42.7667 15.1001L21.1337 27.6669C14.311 31.6883 10.3173 38.3906 10.3173 46.4333V71.5668C10.3173 75.9233 11.4821 79.7771 13.6454 83.1283L21.9658 77.5989C20.9673 75.9233 20.4681 73.9126 20.4681 71.5668V46.4333C20.4681 42.0768 22.465 38.5581 26.126 36.3799L47.7589 23.8131C51.5863 21.6349 55.4137 21.6349 59.2411 23.8131L80.874 36.3799C84.535 38.5581 86.5319 42.0768 86.5319 46.4333V71.5668C86.5319 73.9126 86.0327 75.9233 85.0342 77.5989L93.3546 83.1283ZM29.7869 72.4046L38.9393 66.205V50.4547L53.5832 42.0768L68.0607 50.4547V66.205L77.2131 72.4046C77.2131 72.2371 77.3795 71.902 77.3795 71.5668V46.4333C77.3795 45.428 77.0466 44.9253 76.2146 44.4226L54.5816 31.8558C53.7496 31.3532 53.2504 31.3532 52.4184 31.8558L30.7854 44.4226C29.9533 44.9253 29.6205 45.428 29.6205 46.4333V71.5668C29.6205 71.902 29.7869 72.2371 29.7869 72.4046Z"
        fill="white"
      />
    </svg>
  );
};

export default FreeRace;
