import React from "react";

const Control: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"3rem"}
      height={"3rem"}
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M15.0008 0C19.1428 0 22.8918 1.67879 25.6065 4.39349C28.3212 7.1082 30 10.8588 30 15.0008C30 19.1428 28.3212 22.8918 25.6065 25.6065C22.8918 28.3212 19.1428 30 15.0008 30C10.8588 30 7.1082 28.3212 4.39349 25.6065C1.67879 22.8918 0 19.1428 0 15.0008C0 10.8588 1.67879 7.1082 4.39349 4.39349C7.1082 1.67879 10.8588 0 15.0008 0ZM22.7486 7.25309C20.765 5.26953 18.0253 4.04375 15.0008 4.04375C11.9747 4.04375 9.235 5.26953 7.25309 7.25309C5.26953 9.235 4.04375 11.9747 4.04375 15.0008C4.04375 18.0253 5.26953 20.765 7.25309 22.7486C9.235 24.7305 11.9747 25.9579 15.0008 25.9579C18.0253 25.9579 20.765 24.7305 22.7486 22.7486C24.7305 20.765 25.9579 18.0253 25.9579 15.0008C25.9579 11.9747 24.7305 9.235 22.7486 7.25309Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0354 12.078H14.9654H4.09825C3.8018 12.9424 3.22055 15.1125 3.88174 17.4608C8.88646 17.634 12.3923 20.4769 12.3923 25.7032C12.2773 25.6998 12.1458 25.6798 12.0009 25.6449C12.9602 26.1145 13.9595 26.416 14.9654 26.4593V26.4626L15.0004 26.4609L15.0354 26.4626V26.4593C16.0396 26.416 17.0389 26.1145 17.9982 25.6449C17.8533 25.6798 17.7217 25.6998 17.6068 25.7032C17.6068 20.4769 21.1126 17.634 26.119 17.4608C26.7785 15.1125 26.1973 12.9424 25.9008 12.078H15.0354ZM15.0004 19.919C13.2949 19.8807 11.9259 18.4867 11.9259 16.7729C11.9259 15.0592 13.2949 13.6669 15.0004 13.6269C16.7041 13.6669 18.0732 15.0592 18.0732 16.7729C18.0732 18.4867 16.7041 19.8807 15.0004 19.919Z"
        fill="white"
      />
    </svg>
  );
};

export default Control;
