import React from "react";

const Check: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"2.9rem"}
      height={"2.3rem"}
      viewBox="0 0 29 23"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0205078 13.8238L3.20833 10.636L9.16688 16.6244L25.7912 0L28.9791 3.18782L9.16688 23L0.0205078 13.8238Z"
        fill="white"
      />
    </svg>
  );
};

export default Check;
