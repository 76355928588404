import React from "react";
import styles from "styles/components/download-app.module.scss";
import InstallPWA from "./InstallPWA";
import Logo2 from "icons/Logo2";
import { useTranslation } from "react-i18next";

const DownloadApp: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.download_app}>
      <div className={styles.download_app__img}>
        <Logo2 />
      </div>

      <span className={styles.download_app__label}>{t("download_text")}</span>

      <InstallPWA />
    </div>
  );
};

export default DownloadApp;
