import Laurel from "icons/Laurel";
import React from "react";
import styles from "styles/components/total-points.module.scss";

interface TotalPointsProps {
  points: number | string;
  small?: boolean;
}

const TotalPoints: React.FC<TotalPointsProps> = ({ points, small }) => {
  return (
    <div
      className={`${styles.total_points} ${
        small ? styles["total_points--small"] : ""
      }`}
    >
      <div className={styles.total_points__icon}>
        <Laurel />
      </div>
      <div className={styles.total_points__number}>{points}</div>
    </div>
  );
};

export default TotalPoints;
