import React from "react";

const ReferralProgram: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"6rem"}
      height={"6.6rem"}
      viewBox="0 0 60 66"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.042 1.66219L45.0788 6.83343L54.0105 12.1102C57.7933 14.3265 60 18.1258 60 22.5583V33.0063V43.3488C60 47.7813 57.7933 51.6861 54.0105 53.9023L45.0788 59.0736L36.042 64.2448C32.2592 66.4611 27.8459 66.4611 23.958 64.2448L15.0263 59.0736L5.98949 53.9023C2.20665 51.6861 0 47.7813 0 43.3488V33.0063V22.5583C0 18.1258 2.20665 14.3265 5.98949 12.1102L15.0263 6.83343L23.958 1.66219C27.8459 -0.554062 32.2592 -0.554062 36.042 1.66219ZM23.958 8.41647L11.8739 15.3818C7.98599 17.5981 5.77933 21.5029 5.77933 25.9354V39.9716C5.77933 44.5097 7.98599 48.309 11.8739 50.5252L23.958 57.5961C27.8459 59.8123 32.2592 59.8123 36.042 57.5961L48.2312 50.5252C52.014 48.309 54.2207 44.5097 54.2207 39.9716V25.9354C54.2207 21.5029 52.014 17.5981 48.2312 15.3818L36.042 8.41647C32.2592 6.20022 27.8459 6.20022 23.958 8.41647Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.12 16.1205C27.1095 16.1205 31.9431 20.9752 31.9431 26.9907C31.9431 33.0063 27.1095 37.8609 21.12 37.8609C15.1305 37.8609 10.2969 33.0063 10.2969 26.9907C10.2969 20.9752 15.1305 16.1205 21.12 16.1205ZM21.12 20.7641C24.5876 20.7641 27.3196 23.508 27.3196 26.9907C27.3196 30.4734 24.5876 33.3229 21.12 33.3229C17.6524 33.3229 14.8153 30.4734 14.8153 26.9907C14.8153 23.508 17.6524 20.7641 21.12 20.7641Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.084 24.0358C47.3923 24.0358 50.8599 27.5184 50.8599 31.8454C50.8599 36.1724 47.3923 39.655 43.084 39.655C38.7758 39.655 35.2031 36.1724 35.2031 31.8454C35.2031 27.5184 38.7758 24.0358 43.084 24.0358ZM43.084 27.3074C45.5008 27.3074 47.6024 29.3126 47.6024 31.8454C47.6024 34.3783 45.5008 36.3834 43.084 36.3834C40.5621 36.3834 38.5656 34.3783 38.5656 31.8454C38.5656 29.3126 40.5621 27.3074 43.084 27.3074Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.063 40.7104C30.049 40.7104 36.774 45.2484 40.1366 51.7916L41.1874 59.2847L22.1681 61.9231L4.30469 51.475C7.56213 44.6152 14.077 40.7104 22.063 40.7104Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3458 42.0823C50.02 42.0823 54.9587 45.3539 57.2705 50.2086L44.3458 57.4905L31.2109 50.5252C33.5227 45.565 38.5665 42.0823 44.3458 42.0823Z"
        fill="white"
      />
    </svg>
  );
};

export default ReferralProgram;
