import React from "react";

const Large: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="275px"
      height="310px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 1.56 1.76"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      imageRendering="optimizeQuality"
      // xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "\n   \n    .fil0 {fill:#1DD7FF}\n   \n  ",
          }}
        />
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M0.85 0.02l0.32 0.18 0.32 0.19c0.04,0.03 0.07,0.07 0.07,0.12l0 0.37 0 0.37c0,0.05 -0.03,0.09 -0.07,0.12l-0.32 0.18 -0.32 0.19c-0.05,0.02 -0.1,0.02 -0.14,0l-0.12 -0.07c0.01,-0.01 0.02,-0.02 0.03,-0.03l0.01 -0.02c0.02,-0.03 0.01,-0.09 -0.02,-0.11l0 0c0.03,0.01 0.09,-0.01 0.11,-0.04 0.02,-0.04 0.03,-0.07 0.01,-0.1 0,0 0,-0.01 0,-0.01l-0.02 -0.02 0.22 0.13c0.01,0 0.02,0 0.02,-0.01l0.04 -0.06c0,-0.01 0,-0.01 -0.01,-0.02l-0.07 -0.04c-0.01,0 -0.01,-0.01 -0.01,-0.02l0.09 -0.14c0,-0.01 0,-0.02 -0.01,-0.02l-0.57 -0.33c-0.01,-0.01 -0.02,-0.01 -0.02,0l-0.09 0.15c0,0 -0.01,0.01 -0.01,0l-0.08 -0.04c0,0 -0.01,0 -0.02,0l-0.03 0.07c0,0 0,0.01 0,0.01l0.18 0.11c0.01,0 0.01,0.01 0.01,0.01 0,0.01 -0.01,0.01 -0.01,0.01 -0.02,0.01 -0.05,0.02 -0.07,0.06 -0.02,0.03 -0.01,0.09 0.02,0.11l0 0.01c-0.03,-0.02 -0.09,0 -0.11,0.03l-0.01 0.02c-0.01,0.01 -0.01,0.03 -0.01,0.04l-0.09 -0.05c-0.05,-0.03 -0.07,-0.07 -0.07,-0.12l0 -0.37 0 -0.37c0,-0.05 0.02,-0.09 0.07,-0.12l0.32 -0.19 0.32 -0.18c0.04,-0.03 0.09,-0.03 0.14,0zm0.11 0.38c-0.07,-0.04 -0.09,-0.05 -0.1,-0.05 -0.01,0.01 -0.01,0.02 -0.03,0.05 0,0 0,0 0,0l-0.27 0.41c0,0.01 0,0.01 0,0.01 0,0.01 0,0.01 0.01,0.01l0.33 0.19c0,0.01 0,0.01 0.01,0 0,0 0,0 0.01,0l0.23 -0.46c0,0 0,0 0,0 0.02,-0.04 0.03,-0.03 -0.08,-0.1 -0.01,0 -0.01,-0.01 0,-0.01l0.04 -0.08c0,-0.01 0,-0.02 0,-0.02l-0.07 -0.04c0,0 -0.01,0 -0.01,0.01l-0.05 0.08c0,0 -0.01,0.01 -0.02,0z"
        />
      </g>
    </svg>
  );
};

export default Large;
