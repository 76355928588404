import { fetchUserData } from "redux/asyncActions/fetchUserData";
import { fetchInventoryData } from "redux/asyncActions/fetchInventoryData";
import { fetchStreetRacesData } from "redux/asyncActions/fetchStreetRacesData";
import { fetchLegalRacesData } from "redux/asyncActions/fetchLegalRacesData";
import { fetchShopData } from "redux/asyncActions/fetchShopData";
import { Dispatch } from "react";
import { ROUTES_PATH } from "constants/routesPath";
import { fetchFreeRacesData } from "redux/asyncActions/fetchFreeRacesData";
import { fetchSpecialRacesData } from "redux/asyncActions/fetchSpecialRacesData";

export const fetchData = (dispatch: Dispatch<any>, pathname: string = "") => {
  if (localStorage.getItem("access_token")) {
    dispatch(fetchUserData());
    if (!pathname.includes("inventory")) dispatch(fetchInventoryData());
    if (pathname !== ROUTES_PATH.STREET_RACING)
      dispatch(fetchStreetRacesData());
    if (pathname !== ROUTES_PATH.LEGAL_RACING) dispatch(fetchLegalRacesData());
    if (pathname !== ROUTES_PATH.FREE_RACING) dispatch(fetchFreeRacesData());
    if (pathname !== ROUTES_PATH.SPECIAL_RACING)
      dispatch(fetchSpecialRacesData());
  }
  if (pathname !== ROUTES_PATH.SHOP) dispatch(fetchShopData());
};
