import React, { useMemo } from "react";
import styles from "styles/pages/how-to-play.module.scss";
import Item, { ItemProps } from "./Item";
import SmoothImage from "components/SmoothImage";
import { howToPlayBgHash } from "constants/blurhashes";
import { useTranslation } from "react-i18next";

const HowToPlay: React.FC = () => {
  const { t } = useTranslation();

  const howToPlayItems: ItemProps[] = useMemo(
    () => [
      {
        left: 25,
        top: 10,
        content: (
          <>
            <p>{t("how_to_play_item_text_1")}</p>
            <img src="/img/how-to-play/01.gif" alt="Game instruction" />
          </>
        ),
        title: t("how_to_play_item_title_1"),
        number: 1,
      },

      {
        left: 44,
        top: 25,
        content: (
          <>
            <p>{t("how_to_play_item_text_2_1")}</p>
            <img src="/img/how-to-play/03.gif" alt="Game instruction" />
            <p>
              {t("how_to_play_item_text_2_2")}{" "}
              <b>{t("how_to_play_item_text_2_3")}</b>
            </p>

            <img src="/img/how-to-play/02.gif" alt="Game instruction" />
          </>
        ),
        title: t("how_to_play_item_title_2"),
        number: 2,
      },

      {
        left: 32,
        top: 41,
        content: (
          <>
            <p>{t("how_to_play_item_text_3_1")}</p>
            <img src="/img/how-to-play/04.gif" alt="Game instruction" />
            <p>{t("how_to_play_item_text_3_2")}</p>
            <img src="/img/how-to-play/05.gif" alt="Game instruction" />
            <p>
              <b>{t("how_to_play_item_text_3_3")}</b>
            </p>
          </>
        ),
        title: t("how_to_play_item_title_3"),
        number: 3,
      },

      {
        left: 40,
        top: 65,
        content: (
          <>
            <p>{t("how_to_play_item_text_4_1")}</p>
            <p>
              {t("how_to_play_item_text_4_2")}{" "}
              <a
                rel="noreferrer"
                href="https://docs.metaraces.io/"
                target="_blank"
              >
                https://docs.metaraces.io/
              </a>
            </p>
            <img src="/img/how-to-play/06.gif" alt="Game instruction" />
          </>
        ),
        title: t("how_to_play_item_title_4"),
        number: 4,
      },

      {
        left: 40,
        top: 80,
        content: (
          <>
            <p>{t("how_to_play_item_text_5_1")}</p>

            <img src="/img/how-to-play/07.gif" alt="Game instruction" />

            <p>{t("how_to_play_item_text_5_2")}</p>

            <p>{t("how_to_play_item_text_5_3")}</p>

            <p>{t("how_to_play_item_text_5_4")}</p>

            <p>{t("how_to_play_item_text_5_5")}</p>
          </>
        ),
        title: t("how_to_play_item_title_5"),
        number: 5,
      },

      {
        left: 100,
        top: 70,
        content: (
          <>
            <p>{t("how_to_play_item_text_6_1")}</p>

            <p>{t("how_to_play_item_text_6_2")}</p>

            <img src="/img/how-to-play/08.gif" alt="Game instruction" />
          </>
        ),
        title: t("how_to_play_item_title_6"),
        number: 6,
      },

      {
        left: 105,
        top: 25,
        content: (
          <>
            <p>{t("how_to_play_item_text_7_1")}</p>
            <img src="/img/how-to-play/09.gif" alt="Game instruction" />
            <p>
              <b>{t("how_to_play_item_text_7_2")}</b>
            </p>
          </>
        ),
        title: t("how_to_play_item_title_7"),
        number: 7,
      },
    ],
    []
  );

  return (
    <div className={styles.how_to_play}>
      <div className={styles.how_to_play__bg}>
        <SmoothImage
          src="/img/how-to-play/bg.webp"
          alt="How to play background"
          hash={howToPlayBgHash}
        />
      </div>

      <div className={styles.how_to_play__container}>
        {howToPlayItems.map((item) => (
          <Item {...item} key={item.number} />
        ))}
      </div>
    </div>
  );
};

export default HowToPlay;
