"use client";

import React, { useRef } from "react";
import styles from "../style.module.scss";
import { useIsomorphicLayoutEffect } from "../../../../utils/useIsomorphicLayoutEffect";
import { gsap } from "gsap";

interface Props {
  id: number;
  label: string;
}

const Update: React.FC<Props> = ({ id, label }) => {
  const itemRef = useRef<HTMLLIElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (itemRef.current) {
      const ctx = gsap.context(() => {
        gsap.set(itemRef.current, { perspective: 400 });

        gsap.from(itemRef.current, {
          duration: 0.4,
          opacity: 0,
          scale: 1,
          delay: 0.5 + id / 2,
          x: -100 * id,
          transformOrigin: "0% 50% -50",
          ease: "inOut",
          stagger: 0.025,
        });
      });

      return () => ctx.revert();
    }
  }, []);

  return (
    <li ref={itemRef} className={styles.hero__update}>
      {label}
    </li>
  );
};

export default Update;
