import ShopCarbox from "components/Animations/ShopCarbox";
import ShopLootbox from "components/Animations/ShopLootbox";
import Counter from "components/Counter";
import GameButton from "components/Buttons/GameButton";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import styles from "styles/components/shop-card.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { setGameContent, setGameIsOpen } from "redux/reducers/PopupSlice";
import Confirm from "components/GamePopup/Confirm";
import { selectPopup } from "redux/selectors/popupSelector";
import useSound from "use-sound";
import sound from "sounds/buy_a_car.mp3";
import FreeIcon from "icons/FreeIcon";
import { ShopApi } from "utils/api/Shop";
import { selectUser } from "redux/selectors/userSelector";
import ReceiveBnb from "components/GamePopup/ReceiveBnb";
import TopupGameBalance from "components/GamePopup/TopupGameBalance";
import { IFeeError } from "interfaces/General";
import useDebounce from "utils/useDebounce";
import Loader from "components/Loader";
import FreeLootboxAnim from "components/Animations/ShopLootbox/FreeLootboxAnim";
import { useTranslation } from "react-i18next";
import Frame from "./Frame";
import MGT from "icons/MGT";

interface CardProprs {
  title: string;
  text: string;
  cost: React.ReactNode;
  price: number | undefined;
  discounted?: number | null;
  lootbox?: boolean;
  callback: (arg: number) => void;
  free?: number;
}

const Card: React.FC<CardProprs> = ({
  cost,
  text,
  title,
  price = 0,
  lootbox = false,
  callback,
  free = 0,
  discounted,
}) => {
  const { t } = useTranslation();

  const { shop, user } = useAppSelector(selectUser);
  const { muteSound } = useAppSelector(selectPopup);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [play] = useSound(sound);

  const [count, setCount] = useState(0);
  const debouncedCount = useDebounce(count, 500);
  const [fee, setFee] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState<IFeeError>();

  const selectedPrice = discounted ? discounted : price;

  const onClick = () => {
    if (localStorage.getItem("access_token")) {
      if (free > 0) {
        callback(count);
        return;
      }

      dispatch(setGameIsOpen(true));

      if (error?.type === "bnb") {
        dispatch(setGameContent(<ReceiveBnb type="bnb" />));
        return;
      }

      if (error?.type === "mgt") {
        dispatch(setGameContent(<TopupGameBalance />));
        return;
      }

      dispatch(
        setGameContent(
          <Confirm
            fee={fee}
            amount={count * selectedPrice}
            callback={() => callback(count)}
          />
        )
      );
      !muteSound && play();
      return;
    }

    navigate(ROUTES_PATH.LOGIN);
  };

  const countFee = () => {
    if (lootbox) {
      ShopApi.getLootboxesFee(count).then((res) => {
        res && setFee(res.fee);
      });
      return;
    }

    ShopApi.getCarboxesFee(count).then((res) => {
      if (res) {
        const { fee, total } = res;

        setFee(fee);
        setTotal(total.discounted ? total.discounted : total.base);
        setLoading(false);
      }
    });
  };

  const renderButtonLabel = () => {
    if (!localStorage.getItem("access_token")) return t("sign_in");
    if (error?.type === "bnb") return `${t("deposit")} BNB`;
    if (error?.type === "mgt") return `${t("topup")} MGT`;
    if (lootbox) return t("buy_lootbox");
    return t("buy_carbox");
  };

  const setDefaultFee = () => {
    if (shop) {
      if (lootbox) {
        setFee(shop.lootbox.fee);
        return;
      }

      setFee(shop.carbox.fee);
    }
  };

  useEffect(() => {
    if (free > 0) setCount(free);
  }, [free]);

  useEffect(() => {
    if (debouncedCount > 1) {
      countFee();
    } else {
      setDefaultFee();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCount, shop, lootbox]);

  useEffect(() => {
    if (shop && user) {
      const mgtCondition =
        selectedPrice * (count || 1) > Number(user?.user.balance.ingame.mgt);
      const bnbCondition =
        Number(fee) > Number(user?.user.balance.blockchain?.bnb);

      if (bnbCondition) {
        setError({
          type: "bnb",
          message: t("not_enough_bnb_error"),
        });
      } else if (mgtCondition) {
        setError({
          type: "mgt",
          message: t("not_enough_mgt_error"),
        });
      }
    }
  }, [shop, count, user, fee, lootbox, selectedPrice]);

  useEffect(() => {
    if (discounted && !free) setCount(1);
  }, [discounted]);

  return (
    <div className={styles.shop_card}>
      <div className={styles.shop_card__frame}>
        <Frame />
      </div>

      <div
        className={`${styles.shop_card__img} ${
          lootbox ? styles["shop_card__img--lootbox"] : ""
        }`}
      >
        {lootbox ? (
          free > 0 ? (
            <FreeLootboxAnim />
          ) : (
            <ShopLootbox />
          )
        ) : (
          <ShopCarbox />
        )}
      </div>

      <div className={styles.shop_card__content}>
        <div className={styles.shop_card__container}>
          <div className={styles.shop_card__title}>
            {title} {free > 0 && <FreeIcon />}
          </div>

          <div className={styles.shop_card__wrapper}>
            <div className={styles.shop_card__text}>{text}</div>

            <div className={styles.shop_card__cost}>
              <MGT /> {cost}{" "}
              <span className={styles.shop_card__tx}>+ tx fee</span>
            </div>
          </div>
        </div>

        <Counter
          disabled={
            (free > 0 && count >= free) ||
            error !== undefined ||
            (discounted !== null && discounted !== undefined && count === 1)
          }
          changable={!discounted && price !== 0}
          count={count}
          setCount={setCount}
        />

        <div className={styles.shop_card__bottom_row}>
          <div className={styles.shop_card__total_price}>
            <span>{t("total")}</span>

            {!lootbox && loading ? (
              <div style={{ height: "5.4rem" }}>
                <Loader />
              </div>
            ) : (
              <>
                {free > 0 ? (
                  <b>{t("free")}</b>
                ) : (
                  <b>
                    {discounted && count !== 0 && <span>{count * price}</span>}
                    {count * selectedPrice} mgt
                  </b>
                )}
                {fee !== "" && (
                  <div className={styles.shop_card__fee}>
                    TX FEE: <span>{fee} BNB</span>
                  </div>
                )}
              </>
            )}
          </div>

          <div className={styles.shop_card__buttons}>
            <GameButton
              disabled={
                Boolean(localStorage.getItem("access_token")) &&
                count === 0 &&
                !error
              }
              label={renderButtonLabel()}
              onClick={onClick}
              noSound
            />

            {error && (
              <div className={styles.shop_card__error}>{error.message}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
