import React, { useEffect } from "react";
import Card from "./Card";
import StreetRace from "icons/main-cards/StreetRace";
import QualRace from "icons/main-cards/LegalRace";
import Third from "./Frames/Third";
import Fourth from "./Frames/Fourth";
import Garage from "icons/main-cards/Garage";
import Shop from "icons/main-cards/Shop";
import styles from "styles/pages/main.module.scss";
import ClaimReward from "./ClaimReward";
import RaceHistory from "./Inventory";
import { useAppDispatch } from "redux/hooks/redux";
import {
  setGameContent,
  setGameIsOpen,
  setHintContent,
  setHintIsOpen,
} from "redux/reducers/PopupSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import GrandPrix from "icons/main-cards/GrandPrix";
import FreeRace from "icons/main-cards/FreeRace";
import Ratings from "./Ratings";
import Airdrop, { airdropInfo } from "./Airdrop";
import AirdropContent from "components/GamePopup/AirdropContent";
import smallCardStyles from "styles/components/small-main-card.module.scss";
import { useTranslation } from "react-i18next";

const MainContent: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      !localStorage.getItem("main_hint")
    ) {
      dispatch(
        setHintContent({
          name: "judy",
          onNext() {
            dispatch(setHintIsOpen(false));
            navigate(ROUTES_PATH.PROFILE);
            localStorage.setItem("main_hint", "completed");
          },
          text: t("main_hint_text"),
          hole:
            window.innerWidth > 1023.98
              ? {
                  left: "0rem",
                  height: "6rem",
                  width: "17rem",
                  top: "27rem",
                }
              : undefined,
        })
      );
      dispatch(setHintIsOpen(true));
    }

    // if (
    //   localStorage.getItem("access_token") &&
    //   localStorage.getItem("main_hint") &&
    //   !localStorage.getItem("airdrop_1") &&
    //   Date.now() < airdropInfo.expires
    // ) {
    //   dispatch(setGameContent(<AirdropContent />));
    //   dispatch(setGameIsOpen(true));
    //   localStorage.setItem("airdrop_1", "completed");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main__grid}>
      <div className={`${styles.main__double_card}`}>
        {localStorage.getItem("access_token") ? (
          <ClaimReward />
        ) : (
          Date.now() < airdropInfo.expires && (
            <div className={smallCardStyles.small_main_card}></div>
          )
        )}

        <div className={styles.main__double_card}>
          <Card
            clipPath="third-clip-path"
            icon={<StreetRace />}
            img="/img/main/cards/01.webp"
            title={t("street_racing")}
            text={t("main_card_text_1")}
            frame={<Third />}
            href={ROUTES_PATH.STREET_RACING}
            small
          />

          <Card
            clipPath="fourth-clip-path"
            icon={<FreeRace />}
            img="/img/main/cards/08.webp"
            title={t("free_racing")}
            text={t("main_card_text_2")}
            frame={<Fourth />}
            href={ROUTES_PATH.FREE_RACING}
            small
          />
        </div>
      </div>

      <div className={`${styles.main__double_card}`}>
        {localStorage.getItem("access_token") ? (
          <RaceHistory />
        ) : (
          Date.now() < airdropInfo.expires && (
            <div className={smallCardStyles.small_main_card}></div>
          )
        )}

        <div className={styles.main__double_card}>
          <Card
            clipPath="fourth-clip-path"
            icon={<QualRace />}
            img="/img/main/cards/02.webp"
            title={t("legal_racing")}
            text={t("main_card_text_3")}
            frame={<Fourth />}
            href={ROUTES_PATH.LEGAL_RACING}
            small
          />

          <Card
            clipPath="third-clip-path"
            icon={<GrandPrix />}
            img="/img/main/cards/09.webp"
            title={t("special_events")}
            text={t("main_card_text_4")}
            frame={<Third />}
            small
            href={ROUTES_PATH.SPECIAL_RACING}
          />
        </div>
      </div>

      <div className={`${styles.main__double_card}`}>
        {Date.now() <= airdropInfo.expires ? (
          <Airdrop />
        ) : (
          localStorage.getItem("access_token") && <Ratings />
        )}

        <div className={styles.main__double_card}>
          <Card
            clipPath="third-clip-path"
            small
            icon={<Garage />}
            img="/img/main/cards/03.webp"
            title={t("garage")}
            text={t("main_card_text_5")}
            frame={<Third />}
            href={ROUTES_PATH.GARAGE}
          />

          <Card
            clipPath="fourth-clip-path"
            small
            icon={<Shop />}
            img="/img/main/cards/04.webp"
            title={t("shop")}
            text={t("main_card_text_6")}
            frame={<Fourth />}
            href={ROUTES_PATH.SHOP}
          />
        </div>
      </div>
    </div>
  );
};

export default MainContent;
