import React from "react";

interface FrameProps {
  blue?: boolean;
}

const Frame: React.FC<FrameProps> = ({ blue }) => {
  return (
    <svg
      width={"82.3rem"}
      height={"44.1rem"}
      viewBox="0 0 823 441"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M469.463 8.27555L453.789 0H381.845H331.603H136.048L110.489 13.4955H39.1588L11.2537 28.2295V108.415L0.46875 114.122V308.117L15.9886 316.311V401.428L90.9356 441H134.711L150.385 432.724H354.006L369.68 441H441.645H491.866H687.42L713.002 427.504H784.31L812.215 412.77V332.585L823 326.89V132.895L807.502 124.7V39.5722L732.533 0H688.779L673.106 8.27555H469.463Z"
        fill={blue ? "#0E8ED7" : "#EB4A41"}
      />
    </svg>
  );
};

export default Frame;
