import JoinRace from "components/JoinRace";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { IAdvancedCarItem } from "interfaces/Car";
import { useNavigate, useParams } from "react-router-dom";
import { IAdvancedRace } from "interfaces/Race";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { LegalRaceApi } from "utils/api/LegalRace";
import { newbiesAndBeginnersPrizes, unlimitedPrizes } from "constants/prizes";
import { IDistributionItem } from "components/JoinRace/DistributionItem";
import { updateRaceCallback } from "utils/updateRaceCallback";
import { useTranslation } from "react-i18next";
import { ROUTES_PATH } from "constants/routesPath";

const JoinLegalRacePage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { id } = useParams();
  const { user } = useAppSelector(selectUser);

  const dataUpdated = useRef(false);
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const startsIn = useRef<number | null>(null);

  const [carIds, setCarIds] = useState<number[]>([]);
  const [timeToStart, setTimeToStart] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [race, setRace] = useState<IAdvancedRace>();
  const [cars, setCars] = useState<IAdvancedCarItem[]>([]);
  const [prizes, setPrizes] = useState<IDistributionItem[]>([]);

  const getPrizes = useCallback(() => {
    if (race?.type === "newbies" || race?.type === "beginners") {
      setPrizes(newbiesAndBeginnersPrizes);
      return;
    }

    setPrizes(unlimitedPrizes);
  }, [race]);

  const getRaceName = useCallback(() => {
    return `${race?.type ? `${race.type} racing` : "..."}`;
  }, [race]);

  const updateRaceData = () => {
    LegalRaceApi.getRaceById(id!)
      .then((data) => {
        updateRaceCallback({
          data,
          timeToStart,
          startsIn,
          timerRef,
          setTimeToStart,
          setCars,
          carIds,
          setRace,
        });
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Fetching race info
  useEffect(() => {
    if (id) {
      updateRaceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Activating `send to race` button
  useEffect(() => {
    if (cars && race && user) {
      let isActiveCars = false;
      const isEnoughBalance =
        Number(user.user.balance.ingame.mgt) >= Number(race.cost);
      const statusCondition = race.status === "opened";

      cars.forEach((item) => {
        if (!item.inRace && item.qualified) isActiveCars = true;
      });

      if (isActiveCars && isEnoughBalance && statusCondition) {
        setIsSendDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars, race, user]);

  useEffect(() => {
    if (race && getPrizes) {
      getPrizes();
    }
  }, [race, getPrizes]);

  useEffect(() => {
    if (timeToStart === "00m:00s") {
      setTimeout(() => updateRaceData(), 1000);
    }
  }, [timeToStart]);

  useEffect(() => {
    if (race?.status === "finished" && timeToStart === "00m:00s")
      navigate(ROUTES_PATH.LEGAL_RACING + `/${id}/view`);
  }, [race]);

  return (
    <JoinRace
      isLoading={isLoading}
      cars={cars}
      participants={race?.participants || []}
      info={{
        conditions: race?.conditions,
        name: getRaceName(),
        number: race ? race.title : isLoading ? "..." : "",
        prize: race?.prizes?.tokens
          ? race?.prizes?.tokens
          : race?.prizes?.lootboxes,
        participants: race
          ? {
              required: race?.requirements.participants,
              current: race?.participants.length,
            }
          : undefined,
        cost: race?.cost,
        timeToStart:
          timeToStart !== "" ? timeToStart : isLoading ? "..." : t("soon"),
        sendDisabled: isSendDisabled,
        setCars,
        setRace,
        id: race?.id,
        carIds,
        setCarIds,
        type: race?.type,
        status: race?.status,
      }}
      prizes={prizes}
      pool={{
        mgt: 900,
        usdt: 90,
      }}
    />
  );
};

export default JoinLegalRacePage;
