import Button from "components/Buttons/Button";
import { IAdvancedCarItem } from "interfaces/Car";
import React, { useState } from "react";
import styles from "styles/components/boosts.module.scss";
import scrollbarStyles from "styles/components/custom-scrollbar.module.scss";
import { useAppDispatch } from "redux/hooks/redux";
import { setGameContent, setGameIsOpen } from "redux/reducers/PopupSlice";
import BuyBoost from "components/GamePopup/BuyBoost";
import Boost from "components/Boost";

import AS from "icons/boosts/Acceleration/Small";
import AM from "icons/boosts/Acceleration/Medium";
import AL from "icons/boosts/Acceleration/Large";

import DS from "icons/boosts/Durability/Small";
import DM from "icons/boosts/Durability/Medium";
import DL from "icons/boosts/Durability/Large";

import HS from "icons/boosts/Handling/Small";
import HM from "icons/boosts/Handling/Medium";
import HL from "icons/boosts/Handling/Large";

export type boostTypes = "durability" | "acceleration" | "handling";

export interface IBoost {
  id: number;
  boost: number;
  count: number;
  description: string;
  icon: React.ReactNode;
}

export interface ISelectedBoost {
  durability: null | IBoost;
  acceleration: null | IBoost;
  handling: null | IBoost;
}

interface BoostsProps {
  selectedCar: IAdvancedCarItem | null;
}

const boosts = {
  durability: [
    {
      id: 1,
      boost: 5,
      count: 0,
      description: "Hello world! This is the boost description",
      icon: <DS />,
    },

    {
      id: 2,
      boost: 10,
      count: 10,
      description: "Hello world! This is the boost description",
      icon: <DM />,
    },

    {
      id: 3,
      boost: 15,
      count: 10,
      description: "Hello world! This is the boost description",
      icon: <DL />,
    },
  ],
  acceleration: [
    {
      id: 4,
      boost: 5,
      count: 10,
      description: "Hello world! This is the boost description",
      icon: <AS />,
    },

    {
      id: 5,
      boost: 10,
      count: 10,
      description: "Hello world! This is the boost description",
      icon: <AM />,
    },

    {
      id: 6,
      boost: 15,
      count: 10,
      description: "Hello world! This is the boost description",
      icon: <AL />,
    },
  ],
  handling: [
    {
      id: 7,
      boost: 5,
      count: 10,
      description: "Hello world! This is the boost description",
      icon: <HS />,
    },

    {
      id: 8,
      boost: 10,
      count: 10,
      description: "Hello world! This is the boost description",
      icon: <HM />,
    },

    {
      id: 9,
      boost: 15,
      count: 10,
      description: "Hello world! This is the boost description",
      icon: <HL />,
    },
  ],
};

const Boosts: React.FC<BoostsProps> = ({ selectedCar }) => {
  const dispatch = useAppDispatch();

  const [selectedBoosts, setSelectedBoosts] = useState<ISelectedBoost>({
    durability: null,
    acceleration: null,
    handling: null,
  });

  const handleSelectBoost = (item: IBoost, type: boostTypes) => {
    if (item.count > 0) {
      setSelectedBoosts((prev) => {
        return {
          ...prev,
          [type]: item.id !== prev[type]?.id ? item : null,
        };
      });

      return;
    }

    handleBuy(item, type);
  };

  const handleBuy = (item: IBoost, type: boostTypes) => {
    dispatch(setGameContent(<BuyBoost boost={item} type={type} />));
    dispatch(setGameIsOpen(true));
  };

  const handleApply = () => {};

  return (
    <div className={`${styles.boosts} ${scrollbarStyles.invisible_scrollbar}`}>
      <div className={styles.boosts__title}>Boost your car</div>

      <div className={styles.boosts__container}>
        <div className={styles.boosts__content}>
          <div className={styles.boosts__wrapper}>
            <h4 className={`${styles.boosts__subtitle} ${styles.blue}`}>
              Durability
            </h4>
            <div className={styles.boosts__row}>
              {boosts.durability.map((item) => (
                <Boost
                  item={item}
                  selectedBoosts={selectedBoosts}
                  callback={handleSelectBoost}
                  type="durability"
                />
              ))}
            </div>
          </div>

          <div className={styles.boosts__wrapper}>
            <h4 className={`${styles.boosts__subtitle} ${styles.blue}`}>
              Acceleration
            </h4>

            <div className={styles.boosts__row}>
              {boosts.acceleration.map((item) => (
                <Boost
                  item={item}
                  selectedBoosts={selectedBoosts}
                  callback={handleSelectBoost}
                  type="acceleration"
                />
              ))}
            </div>
          </div>

          <div className={styles.boosts__wrapper}>
            <h4 className={`${styles.boosts__subtitle} ${styles.blue}`}>
              Handling
            </h4>

            <div className={styles.boosts__row}>
              {boosts.handling.map((item) => (
                <Boost
                  item={item}
                  selectedBoosts={selectedBoosts}
                  callback={handleSelectBoost}
                  type="handling"
                />
              ))}
            </div>
          </div>
        </div>

        <div className={styles.boosts__info}>
          {selectedCar ? (
            <>
              {" "}
              <div className={styles.boosts__car}>
                Selected car: <span>{selectedCar?.title}</span>
              </div>
              <Button
                disabled={
                  !selectedBoosts.acceleration &&
                  !selectedBoosts.durability &&
                  !selectedBoosts.handling
                }
                label="Apply"
                big
              />
            </>
          ) : (
            <div className={styles.boosts__message}>
              Select car to apply the boosts
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Boosts;
