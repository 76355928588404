import EnglishFlag from "icons/flags/EnglishFlag";
import RussianFlag from "icons/flags/RussianFlag";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/components/select-language.module.scss";

type languageType = "en-EN" | "ru-RU";

interface ILanguage {
  label: string;
  icon: React.ReactNode;
  key: languageType;
}

const languages: ILanguage[] = [
  {
    label: "en",
    icon: <EnglishFlag />,
    key: "en-EN",
  },
  {
    label: "ru",
    icon: <RussianFlag />,
    key: "ru-RU",
  },
];

const SelectLanguage: React.FC = () => {
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState<ILanguage>();

  const toggleOpen = () => setMenuOpen(!menuOpen);

  const handleChange = (item: ILanguage) => {
    i18n.changeLanguage(item.key);
    setSelectedLang(item);
    toggleOpen();
  };

  useEffect(() => {
    if (i18n) {
      languages.forEach((item) => {
        if (
          item.key === i18n.language ||
          item.key.split("-")[0] === i18n.language
        ) {
          setSelectedLang(item);
        }
      });

      setLoading(false);
    }
  }, [i18n]);

  useEffect(() => {
    if (!loading && !selectedLang) setSelectedLang(languages[0]);
  }, [loading, selectedLang]);

  return (
    <div className={styles.select_language}>
      <div className={styles.select_language__header} onClick={toggleOpen}>
        {loading
          ? "..."
          : selectedLang && (
              <>
                {selectedLang.icon}
                <span>{selectedLang.label}</span>
              </>
            )}
      </div>
      <ul
        className={`${styles.select_language__list} ${
          menuOpen ? styles["select_language__list--open"] : ""
        }`}
      >
        {languages.map((item) => (
          <li
            key={item.key}
            onClick={() => handleChange(item)}
            className={styles.select_language__item}
          >
            {item.icon}
            <span>{item.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectLanguage;
