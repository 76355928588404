import React from "react";
import styles from "styles/components/main-card.module.scss";

const StreetRace: React.FC = () => {
  return (
    <svg
      width={"10.5rem"}
      height={"11.5rem"}
      viewBox="0 0 105 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.0806 2.88387L78.5988 11.953L94.3186 21.0221C101.171 25.0528 105 31.5019 105 39.3618V57.5V75.6382C105 83.2965 101.171 89.9472 94.3186 93.7764L78.5988 102.845L63.0806 111.915C56.2284 115.744 48.5701 115.744 41.9194 111.915L26.1996 102.845L10.4798 93.7764C3.82917 89.9472 0 83.2965 0 75.6382V57.5V39.3618C0 31.5019 3.82917 25.0528 10.4798 21.0221L26.1996 11.953L41.9194 2.88387C48.5701 -0.9453 56.2284 -0.9453 63.0806 2.88387ZM52.3992 26.8666C36.6795 26.8666 23.3781 39.9664 23.3781 55.8877V72.8167C27.6104 72.8167 31.6411 74.6305 34.4626 77.2505V71.2044C31.6411 69.5921 29.8273 66.5691 29.8273 63.143C29.8273 58.1046 33.858 53.8724 38.8964 53.8724C44.1363 53.8724 48.167 58.1046 48.167 63.143C48.167 68.1814 44.1363 72.2121 39.2994 72.4136V87.1257C39.2994 87.5288 39.2994 87.7303 39.2994 88.1334H44.5393V84.3042C44.5393 83.2965 45.3455 82.4904 46.5547 82.4904C47.5624 82.4904 48.3685 83.2965 48.3685 84.3042V88.1334H56.6315V84.3042C56.6315 83.2965 57.4376 82.4904 58.4453 82.4904C59.453 82.4904 60.4607 83.2965 60.4607 84.3042V88.1334H65.7006C65.7006 87.9319 65.7006 87.7303 65.7006 87.5288V72.4136C60.6622 72.2121 56.833 68.1814 56.833 63.143C56.833 58.1046 60.8637 53.8724 65.9021 53.8724C71.142 53.8724 75.1727 58.1046 75.1727 63.143C75.1727 66.5691 73.3589 69.5921 70.5374 71.2044V77.2505C73.3589 74.6305 77.1881 72.8167 81.4203 72.8167V55.8877C81.4203 39.9664 68.3205 26.8666 52.3992 26.8666ZM41.9194 14.7745L20.7582 26.8666C13.9059 30.8973 10.0768 37.548 10.0768 45.2063V69.7937C10.0768 77.452 13.9059 84.1027 20.7582 87.9319L41.9194 100.226C48.5701 104.055 56.2284 104.055 63.0806 100.226L84.2418 87.9319C90.8925 84.1027 94.7217 77.452 94.7217 69.7937V45.2063C94.7217 37.548 90.8925 30.8973 84.2418 26.8666L63.0806 14.7745C56.2284 10.7438 48.5701 10.7438 41.9194 14.7745Z"
        fill="white"
        className={styles["border-1"]}
      />
    </svg>
  );
};

export default StreetRace;
