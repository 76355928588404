import { API_ROUTES, newAuthApi } from ".";
import { errorCallback } from "./Auth";
import {
  IBuyCarboxResponse,
  IBuyLootboxResponse,
  ICalculateResponse,
  IFeeResponse,
  ILootboxesDTO,
  IShop,
} from "interfaces/Shop";

export const ShopApi = {
  async getShop() {
    const response = await newAuthApi
      .get<IShop>(API_ROUTES.GET_SHOP)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        errorCallback(e);
      });

    return response;
  },

  async getShopGuest() {
    const response = await newAuthApi
      .get<IShop>(API_ROUTES.GET_SHOP_GUEST)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        errorCallback(e);
      });

    return response;
  },

  async buyCarBox(dto: number) {
    const response = await newAuthApi
      .post<IBuyCarboxResponse>(API_ROUTES.BUY_CARBOX, {
        quantity: String(dto),
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        errorCallback(e);
      });

    return response;
  },

  async buyLootbox(dto: ILootboxesDTO) {
    const response = await newAuthApi
      .post<IBuyLootboxResponse>(API_ROUTES.BUY_LOOTBOX, dto)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        errorCallback(e);
      });

    return response;
  },

  async getCarboxesFee(dto: number) {
    const response = await newAuthApi
      .post<ICalculateResponse>(API_ROUTES.CARBOXES_FEE, {
        quantity: String(dto),
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        errorCallback(e);
      });

    return response;
  },

  async getLootboxesFee(dto: number) {
    const response = await newAuthApi
      .post<IFeeResponse>(API_ROUTES.LOOTBOXES_FEE, {
        quantity: String(dto),
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => {
        errorCallback(e);
      });

    return response;
  },
};
