import React from "react";
import Circle from "icons/Circle";
import styles from "styles/components/sidebar.module.scss";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { useTranslation } from "react-i18next";

const UserInfo: React.FC = () => {
  const { t } = useTranslation();

  const { user } = useAppSelector(selectUser);

  return (
    <div className={styles.sidebar__info_item}>
      <div
        className={`${styles.sidebar__info_left} ${styles["sidebar__info_left--no-margin"]}`}
      >
        <Circle />
        <div>
          <span className={styles.sidebar__info_title}>{t("account")}</span>
          <span className={styles.sidebar__info_data}>
            {user ? user?.user.email.split("@")[0] : "..."}
          </span>
        </div>
      </div>

      {/* {user && (
        <div className={styles.sidebar__balance}>
          Balance: <Balance /> USDT
        </div>
      )} */}
    </div>
  );
};

export default UserInfo;
