import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "styles/components/go-back.module.scss";
import useSound from "use-sound";
import sound from "sounds/main_menu.mp3";
import { useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";

const GoBack: React.FC = () => {
  const { muteSound } = useAppSelector(selectPopup);
  const [play] = useSound(sound);
  const navigate = useNavigate();

  const handleClick = () => {
    !muteSound && play();
    navigate(-1);
  };

  return (
    <svg
      className={styles.go_back}
      onClick={handleClick}
      width={"3.8rem"}
      height={"3.8rem"}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6467 10.6464L13.6466 10.6464L10.6466 13.6464L7.64665 16.6464L6.85719 17.4359C6.71709 17.576 6.54989 17.7462 6.4083 17.9485C6.32683 18.0648 6.22977 18.2182 6.15148 18.3878C6.07611 18.5512 6.0002 18.7675 6.0002 19C6.0002 19.2325 6.07611 19.4488 6.15148 19.6122C6.22977 19.7818 6.32683 19.9352 6.4083 20.0515C6.54989 20.2538 6.71709 20.424 6.85719 20.5641L8.64664 22.3536L11.6466 25.3536L13.6466 27.3536L16.6456 30.3525L16.6458 30.3527L16.646 30.3529L16.6486 30.3555C16.6761 30.3827 16.9956 30.696 17.4811 30.8539C17.7313 30.9352 18.0381 30.9793 18.3734 30.9103C18.712 30.8406 19.0452 30.6625 19.3541 30.3535C19.6611 30.0465 19.839 29.7155 19.9096 29.3788C19.9795 29.0453 19.9373 28.7397 19.8573 28.4896C19.7022 28.0045 19.3905 27.6833 19.3576 27.6499C19.3551 27.6473 19.3524 27.6446 19.3526 27.6448L18.3538 26.6464L18.3537 26.6464L16.3538 24.6464L13.3538 21.6465L13.3538 21.6464L12.7075 21H14.0002H20.0002H27.0002H30.5002C31.6048 21 32.5 20.1044 32.5 19C32.5 17.8956 31.6048 17 30.5002 17H27.0002H20.0002H14.0002H12.7072L14.3537 15.3536L14.3538 15.3536L19.3531 10.3542L19.3532 10.3541L19.3549 10.3524C19.3792 10.328 19.6935 10.0094 19.8527 9.52386C19.9348 9.27355 19.9798 8.96622 19.9112 8.63009C19.8419 8.29044 19.6637 7.95627 19.3537 7.64639C18.7897 7.08256 18.1129 7.07149 17.6228 7.18693C16.9472 7.34608 16.452 7.84141 16.0904 8.2031L16.0742 8.21932L13.6467 10.6464ZM12 20.5002L11.7503 20.7501L12 20.5002ZM10 0.5H28C33.2467 0.5 37.5 4.7533 37.5 10V28C37.5 33.2467 33.2467 37.5 28 37.5H10C4.7533 37.5 0.5 33.2467 0.5 28V10C0.5 4.7533 4.7533 0.5 10 0.5Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};

export default GoBack;
