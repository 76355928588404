import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import Card from "pages/Shop/Card";
import { setDisabled } from "redux/reducers/PopupSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { ShopApi } from "utils/api/Shop";
import Alert from "utils/alert";
import { fetchInventoryData } from "redux/asyncActions/fetchInventoryData";
import { decrementFreeLootboxes, setBalance } from "redux/reducers/UserSlice";
import { selectUser } from "redux/selectors/userSelector";
import { useTranslation } from "react-i18next";

interface MintLootboxProps {
  free?: number;
}

const MintLootbox: React.FC<MintLootboxProps> = memo(({ free }) => {
  const { t } = useTranslation();

  const { shop } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleMintLootbox = (quantity: number) => {
    dispatch(setDisabled(true));

    ShopApi.buyLootbox({
      quantity: String(quantity),
      type: Number(free) > 0 ? "free" : "paid",
    })
      .then((res) => {
        if (res && res.lootboxes.length > 0) {
          navigate(ROUTES_PATH.INVENTORY_LOOTBOXES);
          Alert(
            "success",
            `${res.lootboxes.length > 1 ? "Lootboxes" : "Lootbox"} ${t(
              "purchace_success"
            )}`
          );
          dispatch(setBalance(res.balance));
          dispatch(decrementFreeLootboxes(quantity));
        }
        dispatch(fetchInventoryData());
      })
      .catch((e) => {
        Alert("error", t("default_error"));
      })
      .finally(() => dispatch(setDisabled(false)));
  };

  const renderCost = () => {
    const price = shop?.lootbox.price;

    if (price) {
      const { discounted, base } = price;

      return discounted ? (
        <>
          <span>{base}</span> {discounted}
        </>
      ) : (
        base
      );
    }

    return "...";
  };

  return (
    <Card
      cost={<>{renderCost()} mgt</>}
      text={t("lootbox_card_text")}
      title={free ? t("get_lootbox") : t("buy_lootbox")}
      lootbox
      price={shop?.lootbox.price.base}
      discounted={shop?.lootbox.price.discounted}
      callback={handleMintLootbox}
      free={free}
    />
  );
});

export default MintLootbox;
