import React from "react";
import Loader from "./Loader";
import { useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import styles from "styles/components/game-overlay.module.scss";

const GameOverlay: React.FC = () => {
  const { screenDisabled } = useAppSelector(selectPopup);

  return (
    <div
      className={`${styles.game_overlay} ${
        screenDisabled ? styles["game_overlay--visible"] : ""
      }`}
    >
      <div className={styles.game_overlay__loader}>
        <Loader />
      </div>
    </div>
  );
};

export default GameOverlay;
