import Discord from "icons/social/Discord";
import Ship from "icons/social/Ship";
import Telegram from "icons/social/Telegram";
import Twitter from "icons/social/Twitter";
import React, { useEffect, useState } from "react";
import styles from "styles/components/sidebar.module.scss";
import scrollbarStyles from "styles/components/custom-scrollbar.module.scss";
import Navigation from "./Navigation";
import UserInfo from "./UserInfo";
import action from "common/bridge/action";
import Connect from "./Connect";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { selectPopup } from "redux/selectors/popupSelector";
import BalanceInfo from "./BalanceInfo";
import { setToken } from "common/bridge/token";
import InGameBalance from "./InGameBalance";
import { useMedia } from "use-media";
import Sounds from "./Sounds";
import WhitePaper from "icons/social/WhitePaper";
import Logo2 from "icons/Logo2";
import { useTranslation } from "react-i18next";
import SelectLanguage from "components/SelectLanguage";

const socialList = [
  {
    icon: <WhitePaper />,
    href: "https://docs.metaraces.io/",
  },
  {
    icon: <Discord />,
    href: "https://discord.com/invite/metaracing",
  },

  {
    icon: <Ship />,
    href: "https://metaracing.medium.com",
  },

  {
    icon: <Telegram />,
    href: "https://t.me/metaraces",
  },

  {
    icon: <Twitter />,
    href: "https://twitter.com/MetaRaces_io",
  },
];

interface SidebarProps {
  online: number;
  menuIsOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ online, menuIsOpen }) => {
  const { user } = useAppSelector(selectUser);
  const { screenDisabled } = useAppSelector(selectPopup);
  const isMobile = useMedia("(max-width: 1023.98px)");
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (user) {
      setToken(`Bearer ${localStorage.getItem("access_pixi_token")}`);
      action.post.connect();
    }
  }, [user]);

  return (
    <aside
      className={`${styles.sidebar} ${scrollbarStyles.custom_scrollbar} ${
        disabled || screenDisabled ? styles["sidebar--disabled"] : ""
      } ${menuIsOpen ? styles["sidebar--active"] : ""}`}
    >
      <div className={styles.sidebar__header}>
        {!isMobile && user && (
          <div className={styles.sidebar__online}>
            {online ? online : "..."}
          </div>
        )}

        {!isMobile && <Sounds />}
        <SelectLanguage />

        <img src="/img/sidebar_header.gif" alt="Animated skull" />
      </div>

      <div className={styles.sidebar__content}>
        <div className={styles.sidebar__logo}>
          <div className={styles.sidebar__top}>
            <Logo2 />
          </div>
        </div>

        <Navigation />

        {!isMobile && (
          <div className={styles.sidebar__info}>
            {localStorage.getItem("access_token") ? (
              <>
                <UserInfo />

                <BalanceInfo />

                <InGameBalance />
              </>
            ) : (
              <Connect />
            )}
          </div>
        )}

        <div className={styles.sidebar__wrapper}>
          <div className={styles.sidebar__social}>
            <h4 className={styles.sidebar__social_title}>
              {t("social_media")}
            </h4>

            <div className={styles.sidebar__social_row}>
              {socialList.map((item, key) => (
                <a rel="noreferrer" target="_blank" href={item.href} key={key}>
                  {item.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
