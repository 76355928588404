import Steps from "components/Auth/Steps";
import Copy from "components/Copy";
import GameButton from "components/Buttons/GameButton";
import SecretPhrase from "components/SecretPhrase";
import { ROUTES_PATH } from "constants/routesPath";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "styles/components/auth.module.scss";
import { useTranslation } from "react-i18next";

interface SecretPhraseScreenProps {
  phrase: string;
}

const SecretPhraseScreen: React.FC<SecretPhraseScreenProps> = ({ phrase }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <>
      <Steps active={4} />

      <div className={styles.auth__title}>{t("secret_recovery_phrase")}</div>
      <div className={styles.auth__text}>{t("recovery_phrase_text_1")}</div>

      <div className={styles.auth__text}>
        <span>{t("warning")}:</span> {t("recovery_phrase_text_2")}
      </div>

      <SecretPhrase phrase={phrase} />

      <Copy text={phrase} withText />

      <GameButton
        label={t("next")}
        onClick={() => navigate(ROUTES_PATH.MAIN)}
      />
    </>
  );
};

export default SecretPhraseScreen;
