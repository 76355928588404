import GameButton from "components/Buttons/GameButton";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "redux/hooks/redux";
import { setGameIsOpen } from "redux/reducers/PopupSlice";
import styles from "styles/components/gamePopup/confirm.module.scss";

interface ConfirmProps {
  amount: number;
  fee?: string;
  callback: () => void;
}

const Confirm: React.FC<ConfirmProps> = ({ amount, callback, fee }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    dispatch(setGameIsOpen(false));
    callback();
  };

  return (
    <div className={styles.confirm} onClick={(e) => e.stopPropagation()}>
      <div className={styles.confirm__title}>{t("confirmation")}</div>
      <div className={styles.confirm__subtitle}>
        {t("confirmation_warning")}
      </div>

      <div
        className={`${styles.confirm__amount_row} ${
          fee ? styles["confirm__amount_row--less-margin"] : ""
        }`}
      >
        {t("amount")} <span>{amount} MGT</span>
      </div>

      {fee && (
        <div
          className={`${styles.confirm__amount_row} ${styles["confirm__amount_row--fee"]}`}
        >
          {t("blockchain_fee")} <span>{fee} BNB</span>
        </div>
      )}

      {fee && (
        <div className={styles.confirm__warning}>
          {t("confirmation_attention")}
        </div>
      )}

      <GameButton label={t("submit")} onClick={handleSubmit} />
    </div>
  );
};

export default Confirm;
