import { TARGET_GAME, TARGET_WRAPPER } from "./constants";

export type GotoType = "main" | "garage" | "race" | "shop" | "rating" | "rules";

export enum EventType {
  EVENT_ERROR = "event_error", // на клиенте что-то пошло не так
  EVENT_GET_INVENTORY = "event_get_inventory", // запрос текущих данных по машинкам, деталям
  EVENT_GET_RATING = "event_get_rating", // запрос top 100 машинок
  EVENT_OPEN_LOOTBOX = "event_open_lootbox", // открытие лутбокса
  EVENT_OPEN_CARBOX = "event_open_carbox", // открытие карбокса
  EVENT_OPEN_CARBOX_FEE = "event_open_carbox_fee", // узнать стоимость открытия карбокса
  EVENT_JOIN_RACE_GARAGE = "event_join_race_garage", // записаться на гонку (garage)
  EVENT_JOIN_RACE_PRIMARY = "event_join_race_primary", // записаться на гонку (primary)
  EVENT_PART_VIEWED = "event_part_viewed", // просмотр детали
  EVENT_SELECT_PART = "event_select_part", // оповещение бэк о замене/выборе детали (применение деталей)
  EVENT_BUY_CARBOX_ACCEPT = "event_buy_carbox_accept", // покупка карбоксов
  EVENT_BUY_CARBOX_BUY = "event_buy_carbox_buy",
  EVENT_BUY_LOOTBOX_ACCEPT = "event_buy_lootbox_accept", // покупка лутбоксов
  EVENT_BUY_LOOTBOX_BUY = "event_buy_lootbox_buy",
  EVENT_SET_PARTS = "event_set_parts", // одеть детали на машину
  EVENT_GOTO_MAIN = "event_goto_main",
  EVENT_GOTO_GARAGE = "event_goto_garage",
  EVENT_GOTO_CARBOXES = "event_goto_carboxes",
  EVENT_GOTO_RACE = "event_goto_race",
  EVENT_GOTO_SHOP = "event_goto_shop",
  EVENT_GOTO_RULES = "event_goto_rules",
  EVENT_GOTO_RATING = "event_goto_rating",
  EVENT_GOTO_QUALIFY = "event_goto_qualify",
  EVENT_GOTO_SIGN_IN = "event_goto_sign_in",
  EVENT_GOTO_WALLET = "event_goto_wallet",
  EVENT_CHANGE_USDT_TO_MGT_ACCEPT = "event_change_usdt_to_mgt_accept", // игра сообщает об обмене USDT в MGT
  EVENT_CHANGE_MGT_TO_USDT_ACCEPT = "event_change_mgt_to_usdt_accept", // игра сообщает об обмене MGT в USDT
  EVENT_CHANGE_USDT_TO_MGT_SWAP = "event_change_usdt_to_mgt_swap",
  EVENT_CHANGE_MGT_TO_USDT_SWAP = "event_change_mgt_to_usdt_swap",
  EVENT_INTERFACE_ENABLE = "event_interface_enable", // игра сообщает, что можно разблокировать интерфейс
  EVENT_INTERFACE_DISABLE = "event_interface_disable", // игра сообщает, что надо заблокировать интерфейс
  EVENT_WITHDRAW_REWARD = "event_withdraw_reward", // получить выигрыш
  EVENT_PLAY_SOUND = "event_play_sound", // проиграть звук
  EVENT_LOADED = "event_loaded", // игра загрузилась
}

const targets = [TARGET_GAME, TARGET_WRAPPER] as const;
export type Message = {
  key: EventType | ActionType;
  target: (typeof targets)[number];
  value?: any;
};

export enum ActionType {
  UNDEFINED = "undefined",
  ACTION_ERROR = "action_error",
  ACTION_CONNECT = "action_connect",
  ACTION_DISCONNECT = "action_disconnect",
  ACTION_PURCHASE_APPROVED = "action_purchase_approved",
  ACTION_UPDATE_BALANCE = "action_update_balance",
  ACTION_UPDATE_UNCLAIMED = "action_update_unclaimed",
  ACTION_UPDATE_OFFSET_TIME = "action_update_offset_time",
  ACTION_UPDATE_COST = "action_update_cost",
  ACTION_UPDATE_RARITY = "action_update_rarity",
  ACTION_UPDATE_RACES = "action_update_races",
  ACTION_GOTO_MAIN = "action_goto_main",
  ACTION_GOTO_GARAGE = "action_goto_garage",
  ACTION_GOTO_CARBOX = "action_goto_carbox",
  ACTION_GOTO_RACE = "action_goto_race",
  ACTION_GOTO_SHOP = "action_goto_shop",
  ACTION_GOTO_RULES = "action_goto_rules",
  ACTION_GOTO_RATING = "action_goto_rating",
  ACTION_SHOW_OPEN_LOOTBOX = "action_show_open_lootbox",
  ACTION_MUTE_SOUNDS = "action_mute_sounds",
  ACTION_MUTE_MUSIC = "action_mute_music",
  ACTION_SHOW_BUY_CARBOX = "action_show_buy_carbox",
  ACTION_SHOW_BUY_LOOTBOX = "action_show_buy_lootbox",
  ACTION_SHOW_CHANGER_USDT_TO_MGT = "action_show_changer_usdt_to_mgt",
  ACTION_SHOW_CHANGER_MGT_TO_USDT = "action_show_changer_mgt_to_usdt",
}

export type WaitUntilResult<T> = {
  type: ActionType;
  done: boolean;
  timeout: number;
  data?: T;
};
export type ListenResult<T> = {
  type: ActionType;
  data?: T;
};

export type ListenEventResult<T> = {
  type: EventType;
  data?: T;
};

export type Callback = (...args: any) => void;
export type CallbacksDict = {
  onEnable: Array<Callback>;
  onGoto: Array<Callback>;
  onAcceptBuyLootbox: Array<Callback>;
  onBuyLootbox: Array<Callback>;
  onAcceptBuyCarbox: Array<Callback>;
  onBuyCarbox: Array<Callback>;
  onOpenLootbox: Array<Callback>;
  onOpenCarbox: Array<Callback>;
  onAcceptChangeUSDTToMGT: Array<Callback>;
  onAcceptChangeMGTToUSDT: Array<Callback>;
  onSwapChangeUSDTToMGT: Array<Callback>;
  onSwapChangeMGTToUSDT: Array<Callback>;
  onJoinRacePrimary: Array<Callback>;
  onJoinRaceGarage: Array<Callback>;
  onWithdrawReward: Array<Callback>;
  onError: Array<Callback>;
  onPlaySound: Array<Callback>;
  onLoaded: Array<Callback>;
  onCarsLoaded: Array<Callback>;
};

export type CostValue = {
  lootbox: number;
  carbox: number;
};
export type RarityRecord = {
  common: number;
  uncommon: number;
  rare: number;
  epic: number;
  legendary: number;
};
export type RarityValue = {
  details: number;
  stickers: number;
  lootbox: RarityRecord;
  carbox: RarityRecord;
};

export type UpdateRace = {
  id: number;
  type: "main" | "garage";
  //closed - это значит она еще не создана, не существует
  status: "closed" | "open" | "started" | "finished";
  fund: number;
  prizes: number[];
  startDateTime: number;
  finishDateTime: number;
  carIds: number[];
  resultCarIds?: number[];
};

export type SetParts = {
  car: {
    id: number;
    equipment: {
      wing: number | null;
      roof: number | null;
      mirrors: number | null;
      headlights: number | null;
      grill: number | null;
      bumper: number | null;
      hood: number | null;
      rims: number | null;
      sideskirts: number | null;
      fenders: number | null;
      sticker: number | null;
    };
  };
};
