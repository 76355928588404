import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/style.scss";
import { BrowserRouter } from "react-router-dom";
import "./fonts/Grotesk-01Extrafin.otf";
import "./fonts/Grotesk-02Mince.otf";
import "./fonts/Grotesk-03Regular.otf";
import "./fonts/Grotesk-04Gras.otf";
import "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
