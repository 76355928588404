"use client";

import React, { useEffect, useRef } from "react";
import styles from "./style.module.scss";
import Logo from "../../icons/Logo";
import { Link } from "react-router-dom";
import { gsap } from "gsap";

const Header: React.FC = () => {
  const headerRef = useRef<any>();

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(headerRef.current, {
        opacity: 0,
        duration: 0.5,
        delay: 0.4,
        y: -100,
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <header ref={headerRef} className={styles.header}>
      <div className={styles.header__logo}>
        <Logo />
      </div>

      <nav className={styles.header__nav}>
        <ul className={styles.header__list}>
          <li>
            <Link target="_blank" to={"https://docs.metaraces.io/"}>
              Whitepaper
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
