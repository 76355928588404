import Button from "components/Buttons/Button";
import React from "react";
import styles from "styles/components/sidebar.module.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { useTranslation } from "react-i18next";

const Connect: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={styles.sidebar__info_item}
      style={{ display: "block", marginLeft: 0 }}
    >
      <div className={styles.sidebar__info_left}>
        <div>
          <span
            className={`${styles.sidebar__info_title} ${styles["sidebar__info_title--additional"]}`}
          >
            {t("authorization")}
          </span>
        </div>
      </div>

      <div
        className={styles.sidebar__info_buttons}
        style={{ justifyContent: "flex-start", marginLeft: 0 }}
      >
        <Button
          big
          label={t("sign_in")}
          callback={() => navigate(ROUTES_PATH.LOGIN)}
        />
        <Button
          big
          label={t("create_account")}
          callback={() => navigate(ROUTES_PATH.REGISTRATION)}
        />
      </div>
    </div>
  );
};

export default Connect;
