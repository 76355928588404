import React from "react";
import styles from "styles/components/small-main-card.module.scss";
import Fifth from "./Frames/Fifth";
import MGT from "icons/MGT";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import useSound from "use-sound";
import sound from "sounds/main_menu.mp3";
import { selectPopup } from "redux/selectors/popupSelector";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { useTranslation } from "react-i18next";

const ClaimReward: React.FC = () => {
  const { t } = useTranslation();

  const { user } = useAppSelector(selectUser);
  const { muteSound } = useAppSelector(selectPopup);
  const [play] = useSound(sound);
  const navigate = useNavigate();

  const handleOpenTrasfer = () => {
    !muteSound && play();
    navigate(ROUTES_PATH.WALLET);
  };

  return (
    <div
      onClick={handleOpenTrasfer}
      style={{
        clipPath: "url(#fifth-clip-path)",
        WebkitClipPath: "url(#fifth-clip-path)",
      }}
      className={styles.small_main_card}
    >
      <div className={styles.small_main_card__frame}>
        <Fifth />
      </div>

      <div className={styles.small_main_card__content}>
        <div className={styles.small_main_card__img}>
          <img src="/img/main/cards/05.webp" alt="Get reward" />
        </div>

        <div className={styles.small_main_card__icon}>
          <MGT />
        </div>

        <div className={styles.small_main_card__wrapper}>
          <div className={styles.small_main_card__title}>
            {t("game_balance")}
          </div>

          <div className={styles.small_main_card__reward}>
            <div>
              {user ? Number(user.user.balance.ingame.mgt).toFixed(2) : 0}{" "}
              <span>MGT</span>
            </div>{" "}
          </div>

          {/* <div className={styles.small_main_card__races_count}>10 races</div> */}
        </div>
      </div>
    </div>
  );
};

export default ClaimReward;
