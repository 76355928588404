import React, { useEffect, useState } from "react";
import styles from "styles/pages/main.module.scss";
import Card from "./Card";
import ComingSoon from "icons/main-cards/ComingSoon";
import StreetRaceIcon from "icons/main-cards/StreetRace";
import { ROUTES_PATH } from "constants/routesPath";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectRaces } from "redux/selectors/racesSelector";
import { useNavigate } from "react-router-dom";
import GoBack from "components/GoBack";
import Third from "./Frames/Third";
import { setHintContent, setHintIsOpen } from "redux/reducers/PopupSlice";
import { fetchStreetRacesData } from "redux/asyncActions/fetchStreetRacesData";
import scrollbarStyles from "styles/components/custom-scrollbar.module.scss";
import SelectTabs, { ISelectTabItem } from "components/FormElements/SelectTabs";
import { IRace } from "interfaces/Race";
import First from "./Frames/First";
import smallCardStyles from "styles/components/small-main-card.module.scss";
import Fifth from "./Frames/Fifth";
import MGT from "icons/MGT";
import { useTranslation } from "react-i18next";

const tabs: ISelectTabItem[] = [
  {
    key: "10",
    label: "10",
  },

  {
    key: "100",
    label: "100",
  },

  {
    key: "250",
    label: "250",
  },
];

const StreetRace: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { street } = useAppSelector(selectRaces);

  const [activeTab, setActiveTab] = useState<ISelectTabItem>(tabs[0]);
  const [filteredRaces, setFilteredRaces] = useState<IRace[]>([]);

  const renderCards = () => {
    const renderComingSoonCondition =
      filteredRaces.length === 0 ||
      (filteredRaces.length < 6 && filteredRaces.length > 3);

    if (filteredRaces.length <= 3 && filteredRaces.length !== 0) {
      return (
        <>
          {filteredRaces.map((item) => (
            <Card
              key={item.id}
              race={{
                reward: item.fund,
                racers: item.participants.current,
                status: "started",
                raceName: t("street_racing"),
                avatars: item.participants.avatars,
                conditions: item.conditions,
              }}
              clipPath="first-clip-path"
              icon={<StreetRaceIcon />}
              img={`/img/street-race/001.webp`}
              title={`${item.title}`}
              frame={<First />}
              href={`${ROUTES_PATH.STREET_RACING}/${item.id}`}
              legal
              longStreet
            />
          ))}

          {filteredRaces.length < 3 &&
            Array.from({ length: 3 - filteredRaces.length }, (_, i) => (
              <Card
                key={i}
                clipPath="first-clip-path"
                icon={<ComingSoon />}
                img={`/img/street-race/0${filteredRaces.length + i + 1}.webp`}
                frame={<First />}
                comingSoon
                longStreet
              />
            ))}
        </>
      );
    }

    return (
      <>
        {filteredRaces.map((item, key) => (
          <Card
            key={item.id}
            race={{
              raceName: t("street_racing"),
            }}
            clipPath="third-clip-path"
            icon={<StreetRaceIcon />}
            img={`/img/street-race/0${(key % 6) + 1}.webp`}
            title={item.title}
            frame={<Third />}
            href={`${ROUTES_PATH.STREET_RACING}/${item.id}`}
            small
            street={{
              racers: item.participants,
              conditions: item.conditions,
            }}
          />
        ))}

        {renderComingSoonCondition &&
          Array.from({ length: 6 - filteredRaces.length }, (_, i) => (
            <Card
              key={i}
              clipPath="third-clip-path"
              icon={<ComingSoon />}
              img={`/img/street-race/0${filteredRaces.length + i + 1}.webp`}
              frame={<Third />}
              comingSoon
              small
            />
          ))}
      </>
    );
  };

  useEffect(() => {
    dispatch(fetchStreetRacesData());

    if (!localStorage.getItem("access_token")) {
      navigate(ROUTES_PATH.LOGIN);
    }

    if (
      !localStorage.getItem("select_street_hint") &&
      localStorage.getItem("access_token")
    ) {
      dispatch(
        setHintContent({
          name: "jason",
          onNext() {
            dispatch(
              setHintContent({
                name: "jason",
                onNext() {
                  dispatch(setHintIsOpen(false));
                  localStorage.setItem("select_street_hint", "completed");
                },
                text: t("select_street_hint_text_2"),
              })
            );
          },
          text: t("select_street_hint_text_1"),
        })
      );
      dispatch(setHintIsOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newArray: IRace[] = [];

    street.forEach((item) => {
      if (item.cost === Number(activeTab.key)) newArray.push(item);
    });

    setFilteredRaces([...newArray]);
  }, [activeTab, street]);

  return (
    <>
      <GoBack />

      <div className={styles.main__filters_wrapper}>
        <div
          style={{
            clipPath: "url(#fifth-clip-path)",
            WebkitClipPath: "url(#fifth-clip-path)",
          }}
          className={`${smallCardStyles.small_main_card}`}
        >
          <div className={smallCardStyles.small_main_card__frame}>
            <Fifth />
          </div>

          <div className={smallCardStyles.small_main_card__content}>
            <div className={smallCardStyles.small_main_card__img}>
              <img src="/img/main/cards/05.webp" alt="Get reward" />
            </div>

            <div className={smallCardStyles.small_main_card__icon}>
              <MGT />
            </div>

            <div className={smallCardStyles.small_main_card__wrapper}>
              <div
                className={`${smallCardStyles.small_main_card__title} ${smallCardStyles["small_main_card__title--less-margin"]}`}
              >
                {t("bets")}
              </div>

              <SelectTabs
                mgtIcon
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${styles.main__grid} ${scrollbarStyles.custom_scrollbar} ${
          filteredRaces.length > 2 ? styles["main__grid--scrollable"] : ""
        } ${filteredRaces.length > 2 ? styles["main__grid--small"] : ""}`}
      >
        {renderCards()}
      </div>
    </>
  );
};

export default StreetRace;
