import React from "react";

const Detail: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"3.2rem"}
      height={"3.2rem"}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4586 0.0376936L19.1502 2.43124C20.6052 2.76483 21.9716 3.32646 23.2136 4.07657L25.4432 2.81571L29.026 6.45126L27.8235 8.62807C28.5887 9.85877 29.1673 11.2176 29.5198 12.6651L32 13.3548L31.9623 18.4586L29.5348 19.1597C29.1937 20.5883 28.634 21.932 27.8895 23.1514L29.1843 25.4432L25.5487 29.026L23.2777 27.7727C22.0809 28.5039 20.7654 29.0618 19.367 29.4048L18.6451 32L13.5414 31.9623L12.8139 29.4425L12.7178 29.401C11.3665 29.0674 10.0944 28.5378 8.93339 27.8405L6.55681 29.1843L2.97403 25.5487L4.24242 23.2513L4.18776 23.1194C3.49043 21.9697 2.95518 20.7089 2.61594 19.3726L0 18.6451L0.0376936 13.5414L2.54243 12.8177C2.8666 11.4099 3.4075 10.0812 4.12557 8.87308L2.81571 6.55681L6.45126 2.97403L8.67896 4.20284C9.89081 3.43954 11.2308 2.85906 12.6594 2.50097L13.3548 0L18.4586 0.0376936ZM23.208 8.7845C21.3761 6.95259 18.8449 5.8199 16.0481 5.8199C13.2531 5.8199 10.722 6.95259 8.89004 8.7845C7.05813 10.6164 5.92355 13.1475 5.92355 15.9444C5.92355 18.7394 7.05813 21.2705 8.89004 23.1024C10.722 24.9343 13.2531 26.0689 16.0481 26.0689C18.8449 26.0689 21.3761 24.9343 23.208 23.1024C25.0399 21.2705 26.1726 18.7394 26.1726 15.9444C26.1726 13.1475 25.0399 10.6164 23.208 8.7845Z"
        fill="white"
      />
    </svg>
  );
};

export default Detail;
