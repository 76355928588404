import SmallButton from "components/Buttons/SmallButton";
import { ROUTES_PATH } from "constants/routesPath";
import { IHistory } from "interfaces/Race";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "styles/pages/joinRace/history-item.module.scss";

const HistoryItem: React.FC<IHistory> = memo(
  ({ date, number, fund, racers, outcome, id, type }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleReplay = () => {
      let redirectPath = ROUTES_PATH.FREE_RACING;

      if (type === "street") {
        redirectPath = ROUTES_PATH.STREET_RACING;
      }
      if (type === "legal") {
        redirectPath = ROUTES_PATH.LEGAL_RACING;
      }

      navigate(`${redirectPath}/${id}/view?history=true`);
    };

    return (
      <div className={styles.history_item}>
        <div className={styles.history_item__number}>{number}</div>
        <div className={styles.history_item__prize}>{fund} mgt</div>
        <div className={styles.history_item__racers}>{racers}</div>
        <div className={styles.history_item__date}>
          {new Date(date).toISOString().split("T")[0]}
        </div>
        <div className={styles.history_item__button}>
          <SmallButton label={t("replay")} onClick={handleReplay} />
        </div>
      </div>
    );
  }
);

export default HistoryItem;
