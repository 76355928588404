import { IFeeResponse } from "interfaces/Shop";
import { API_ROUTES, newAuthApi } from ".";
import { errorCallback } from "./Auth";
import { IOpenLootboxesResponse } from "interfaces/Lootboxes";

export const LootboxesApi = {
  async open(ids: number[]) {
    const response = await newAuthApi
      .post<IOpenLootboxesResponse>(API_ROUTES.OPEN_LOOTBOXES, {
        ids,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async openFee(ids: number[]) {
    const response = await newAuthApi
      .post<IFeeResponse>(API_ROUTES.OPEN_LOOTBOXES_FEE, {
        ids,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },
};
