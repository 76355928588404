import React, { useEffect } from "react";
import styles from "styles/pages/main.module.scss";
import Card from "./Card";
import First from "./Frames/First";
import { ROUTES_PATH } from "constants/routesPath";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { useNavigate } from "react-router-dom";
import GoBack from "components/GoBack";
import NewYear from "icons/NewYear";
import { selectRaces } from "redux/selectors/racesSelector";
import { fetchSpecialRacesData } from "redux/asyncActions/fetchSpecialRacesData";
import { useTranslation } from "react-i18next";

const SpecialEvents: React.FC = () => {
  const { t } = useTranslation();

  const { special } = useAppSelector(selectRaces);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSpecialRacesData());

    if (!localStorage.getItem("access_token")) {
      navigate(ROUTES_PATH.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GoBack />
      <div className={`${styles.main__grid} ${styles["main__grid--special"]}`}>
        <Card
          race={{
            reward: special?.race.fund,
            racers: special?.race.participants.current,
            timeToStart: special?.race.startIn,
            status: special?.race.status,
            raceName: t("special_event"),
            avatars: special?.race.participants.avatars,
          }}
          clipPath="first-clip-path"
          icon={<NewYear />}
          img={`/img/special-events/01.webp`}
          title={"New year race"}
          text={t("special_race_text")}
          frame={<First />}
          href={`${ROUTES_PATH.SPECIAL_RACING}/${special?.race.id}`}
          legal
          special
        />
      </div>
    </>
  );
};

export default SpecialEvents;
