import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserApi } from "utils/api/User";

export const fetchInventoryData = createAsyncThunk(
  "user/fetchInventoryData",
  async () => {
    const response = UserApi.getInventoryData();

    return response;
  }
);
