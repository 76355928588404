import axios from "axios";
import { AuthApi } from "./Auth";

export let baseURL = process.env.REACT_APP_API;
export let newBaseURL = process.env.REACT_APP_NEW_API;

export const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": "3600",
  },
});

export const authApi = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": "3600",
  },
});

export const newApi = axios.create({
  baseURL: newBaseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": "3600",
  },
});

export const newAuthApi = axios.create({
  baseURL: newBaseURL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Max-Age": "3600",
  },
});

authApi.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem("auth_key")}`;

  return config;
});

newAuthApi.interceptors.request.use((config) => {
  // @ts-ignore
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "access_token"
  )}`;

  return config;
});

newAuthApi.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.config &&
      !error.config._isRetry
    ) {
      originalRequest._isRetry = true;
      try {
        AuthApi.refreshToken();

        return newAuthApi.request(originalRequest);
      } catch (e) {
        console.log("Unauhtorized");
      }
    }

    throw error;
  }
);

export const API_ROUTES = {
  GET_DATA: "init/",
  GET_BALANCE_DATA: "user/balance/",

  GET_USER: "user",
  GET_INVENTORY: "user/inventory",
  GET_MNEMONIC: "user/wallet/mnemonic",
  CHANGE_PASSWORD: "user/password/change",
  PRESIGNUP: "auth/presignup",
  SIGNUP: "auth/signup",
  CODE_CHECK: "auth/code/check",
  CODE_RESEND: "auth/code/resend",
  LOGIN: "auth/login/",
  REFRESH: "auth/tokens/refresh/",
  PASSWORD_RESET: "auth/password/reset/",
  PASSWORD_RESET_CHANGE: "auth/password/change/",

  GET_SHOP: "shop/",
  GET_SHOP_GUEST: "shop/guest/",
  BUY_CARBOX: "shop/carboxes/buy/",
  BUY_LOOTBOX: "shop/lootboxes/buy/",
  CARBOXES_FEE: "shop/carboxes/buy/calculate/",
  LOOTBOXES_FEE: "shop/lootboxes/buy/calculate/",

  STREET_RACES: "racing/street/",

  QUAL_RACE: "racing/qualification/",
  QUAL_RACE_JOIN: "racing/qualification/join",

  LEGAL_RACES: "racing/legal/",

  FREE_RACES: "racing/free/",

  SPECIAL_RACES: "racing/special/",

  TRANSFER_INGAME: "user/balance/transfer/ingame/",
  TRANSFER_BLOCKCHAIN: "user/balance/transfer/blockchain/",
  WITHDRAW_MGT: "user/wallet/withdraw/mgt/",
  WITHDRAW_BNB: "user/wallet/withdraw/bnb/",

  OPEN_LOOTBOXES: "user/inventory/lootboxes/open/",
  OPEN_LOOTBOXES_FEE: "user/inventory/lootboxes/open/fee/",

  RATING: "rating/",

  RACE_HISTORY: "racing/history/",

  SWAP_BNB: "user/balance/topup/fromswap/",
  SWAP_MAIN_WALLET: "user/balance/topup/frommainwallet/",

  CLAIM_FROM_SWAP: "user/balance/claim/fromswap/",
  CLAIM_FROM_TRANSFER: "user/balance/claim/fromtransfer/",

  BACKOFFICE_TOPUP_MARKETING: "/backoffice/marketing/balance/topup",

  PROMOCODE_ACTIVATE: "/promotion/promocode/activate",

  REFERRAL_CHECK: "/referral/checkcode",
  REFERRAL_CLAIM: "/referral/claim",
  REFERRAL_GET: "/referral",

  NOTIFICATIONS_SUBSCRIBE: "/notification/web-push/subscribe",
};
