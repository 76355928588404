import React from "react";

const Discord: React.FC = () => {
  return (
    <svg
      width={"3.5rem"}
      height={"2.7rem"}
      viewBox="0 0 35 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient gradientTransform="rotate(-270)" id="linear-gradient-1">
        <stop offset="0%" stopColor="#504153" />
        <stop offset="100%" stopColor="#504153" />
      </linearGradient>

      <path
        fill="url(#linear-gradient-1)"
        d="M29.6241 2.2638C27.3592 1.21472 24.9471 0.441717 22.406 0C22.093 0.552146 21.7247 1.30675 21.4854 1.91411C18.797 1.5092 16.1271 1.5092 13.494 1.91411C13.2362 1.30675 12.8679 0.552146 12.5549 0C10.0139 0.441717 7.6017 1.21472 5.33686 2.2638C0.77034 9.14722 -0.463356 15.8651 0.144285 22.4724C3.18249 24.7362 6.11022 26.0982 9.00112 27C9.71924 26.0245 10.3453 24.9755 10.8977 23.8896C9.84813 23.5031 8.85381 23.0061 7.91473 22.4356C8.17252 22.2515 8.41189 22.0491 8.65127 21.865C14.4146 24.5522 20.6568 24.5522 26.3465 21.865C26.5859 22.0675 26.8253 22.2515 27.083 22.4356C26.144 23.0061 25.1312 23.4847 24.1001 23.8896C24.6525 24.9755 25.2785 26.0245 25.9967 27C28.8876 26.0982 31.8337 24.7362 34.8535 22.4724C35.5716 14.7976 33.6198 8.15336 29.6609 2.2638H29.6241ZM11.6711 18.4233C9.9402 18.4233 8.52237 16.8221 8.52237 14.8528C8.52237 12.8835 9.90337 11.2822 11.6711 11.2822C13.4387 11.2822 14.8381 12.8835 14.8197 14.8528C14.8197 16.8037 13.4387 18.4233 11.6711 18.4233ZM23.2899 18.4233C21.559 18.4233 20.1412 16.8221 20.1412 14.8528C20.1412 12.8835 21.5222 11.2822 23.2899 11.2822C25.0576 11.2822 26.457 12.8835 26.4386 14.8528C26.4386 16.8037 25.0576 18.4233 23.2899 18.4233Z"
      />
    </svg>
  );
};

export default Discord;
