import styles from "styles/pages/shop.module.scss";
import MintLootbox from "components/MintLootbox";
import MintCarBox from "components/MintCarBox";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import {
  setGameContent,
  setGameIsOpen,
  setHintContent,
  setHintIsOpen,
} from "redux/reducers/PopupSlice";
import { fetchShopData } from "redux/asyncActions/fetchShopData";
import SmoothImage from "components/SmoothImage";
import { shopBgHash } from "constants/blurhashes";
import Promocode from "components/GamePopup/Promocode";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { selectUser } from "redux/selectors/userSelector";
import { useTranslation } from "react-i18next";

const Shop = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const handleOpenPromocode = () => {
    dispatch(
      setGameContent(<Promocode initialValue={params.get("promocode")} />)
    );
    dispatch(setGameIsOpen(true));
  };

  useEffect(() => {
    if (!localStorage.getItem("access_token") && params.get("promocode")) {
      navigate(ROUTES_PATH.LOGIN + `?promocode=${params.get("promocode")}`);
    }

    if (localStorage.getItem("access_token") && params.get("promocode")) {
      handleOpenPromocode();
    }
  }, [params]);

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      !localStorage.getItem("shop_hint") &&
      dispatch
    ) {
      dispatch(
        setHintContent({
          name: "judy",
          onNext() {
            dispatch(setHintIsOpen(false));
            localStorage.setItem("shop_hint", "completed");
          },
          text: t("shop_hint_text"),
        })
      );
      dispatch(setHintIsOpen(true));
    }

    dispatch(fetchShopData());
  }, [dispatch]);

  return (
    <div className={styles.shop}>
      <div className={styles.shop__bg}>
        <SmoothImage
          src="/img/shop/bg.webp"
          alt="Shop background"
          hash={shopBgHash}
        />
      </div>

      <div className={styles.shop__wrapper}>
        {/* <div className={styles.shop__promocode}>
          <p>{t("is_promocode")}</p>
          <Button label={t("click_here")} callback={handleOpenPromocode} />
        </div> */}

        <div
          className={`${styles.shop__grid} ${
            Number(user?.user.balance.ingame.lootbox) > 0
              ? styles["shop__grid--triple"]
              : ""
          }`}
        >
          <MintCarBox />
          <MintLootbox />
          {Number(user?.user.balance.ingame.lootbox) > 0 && (
            <MintLootbox free={user?.user.balance.ingame.lootbox} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Shop;
