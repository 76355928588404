import React from "react";
import { IBoost, ISelectedBoost, boostTypes } from "./JoinRace/Boosts";
import styles from "styles/components/boosts.module.scss";
import Info from "icons/Info";

interface IBoostProps {
  item: IBoost;
  selectedBoosts?: ISelectedBoost;
  callback?: (item: IBoost, type: boostTypes) => void;
  active?: boolean;
  type: boostTypes;
}

const Boost: React.FC<IBoostProps> = ({
  callback,
  item,
  selectedBoosts,
  active,
  type,
}) => {
  let color: "blue" | "red" | "green" =
    type === "acceleration" ? "blue" : type === "durability" ? "green" : "red";

  return (
    <div
      onClick={() => {
        callback && callback(item, type!);
      }}
      className={`${styles.boosts__item} ${
        (selectedBoosts && selectedBoosts[type]?.id === item.id) || active
          ? styles.active
          : ""
      }`}
    >
      <div className={`${styles.boosts__icon} ${styles[`${color}`]}`}>
        {item.icon}

        <div className={styles.boosts__count}>x{item.count}</div>
      </div>

      <span className={styles.boosts__label}>
        +{item.boost}% <Info />
      </span>

      {/* {item.count === 0 && <Button label="Buy" />} */}

      <div className={styles.boosts__tooltip}>{item.description}</div>
    </div>
  );
};

export default Boost;
