import React from "react";
import { Link } from "react-router-dom";
import styles from "styles/components/game-button.module.scss";
import useSound from "use-sound";
import sound from "sounds/click_button.mp3";
import { useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";

interface GameButtonProps {
  label: string;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
  small?: boolean;
  noSound?: boolean;
}

const GameButton: React.FC<GameButtonProps> = ({
  label,
  onClick,
  small,
  href,
  disabled = false,
  noSound = false,
}) => {
  const { muteSound } = useAppSelector(selectPopup);
  const [play] = useSound(sound);

  const classNames = `${styles.game_button} ${
    disabled ? styles["game_button--disabled"] : ""
  } ${small ? styles["game_button--small"] : ""}`;

  const renderContent = () => {
    return (
      <>
        <span>{label}</span>

        <div
          className={`${styles.game_button__bottom} ${styles.game_button__bar}`}
        ></div>
        <div
          className={`${styles.game_button__top} ${styles.game_button__bar}`}
        ></div>
      </>
    );
  };

  return (
    <>
      {onClick ? (
        <button
          disabled={disabled}
          className={classNames}
          onClick={(e) => {
            e.preventDefault();
            !noSound && !muteSound && play();
            onClick();
          }}
        >
          {renderContent()}
        </button>
      ) : (
        <Link
          onClick={() => {
            !noSound && !muteSound && play();
          }}
          className={classNames}
          to={href!}
        >
          {renderContent()}
        </Link>
      )}
    </>
  );
};

export default GameButton;
