import { IConditions } from "interfaces/Race";

export function renderChancePts(conditions: IConditions) {
  if (conditions.points.min || conditions.points.max) {
    return (
      Number(conditions.points.min) +
      `${conditions.points.max ? "-" + conditions.points.max : "+"}`
    );
  }

  return "Unlimited";
}
