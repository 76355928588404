import { createAsyncThunk } from "@reduxjs/toolkit";
import { SpecialRaceApi } from "utils/api/SpecialRace";

export const fetchSpecialRacesData = createAsyncThunk(
  "user/fetchSpecialRacesData",
  async () => {
    const response = SpecialRaceApi.getRaces();

    return response;
  }
);
