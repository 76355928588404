import { ICarItem, IAdvancedCarItem } from "interfaces/Car";

export const formatCars = (cars: ICarItem[], carIds: number[]) => {
  const newCars: IAdvancedCarItem[] = [];

  cars.forEach((item) => {
    const formattedItem: IAdvancedCarItem = {
      ...item,
      active: carIds.includes(item.id) ? true : false,
    };

    newCars.push(formattedItem);
  });

  return newCars;
};
