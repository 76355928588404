import Button from "components/Buttons/Button";
import MGT from "icons/MGT";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/pages/invite-friends/item.module.scss";

interface StatsItemProps {
  main: string | number;
  secondary: string;
  callback?: () => void;
  disabled?: boolean;
  dataSending?: boolean;
  icon?: React.ReactNode;
}

const StatsItem: React.FC<StatsItemProps> = ({
  callback,
  main,
  secondary,
  disabled,
  dataSending,
  icon,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.item}>
      {icon ? icon : <MGT />}

      <div>
        <div className={styles.item__main}>{main}</div>
        <div className={styles.item__secondary}>{secondary}</div>
      </div>

      {callback && (
        <Button
          label={t("claim")}
          callback={callback}
          disabled={disabled || dataSending}
        />
      )}

      {disabled && <span>{t("min_claim")} 100 MGT</span>}
    </div>
  );
};

export default StatsItem;
