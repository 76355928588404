import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { setNotifications } from "redux/reducers/PopupSlice";
import { selectPopup } from "redux/selectors/popupSelector";
import styles from "styles/components/notification.module.scss";

const CLOSE_DELAY = 5000;

const Notification: React.FC = () => {
  const dispatch = useAppDispatch();
  const { notification } = useAppSelector(selectPopup);

  const handleClose = () => {
    dispatch(setNotifications({ message: notification.message, open: false }));
  };

  useEffect(() => {
    if (notification.open) {
      setTimeout(() => handleClose, CLOSE_DELAY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  return (
    <div
      className={`${styles.notification} ${
        notification.open ? styles["notification--active"] : ""
      }`}
      onClick={handleClose}
    >
      <div className={styles.notification__content}>
        <div className={styles.notification__image}>
          <img src="" alt="Notification view" />
        </div>

        <div
          className={styles.notification__message}
          dangerouslySetInnerHTML={{ __html: notification.message }}
        ></div>
      </div>
    </div>
  );
};

export default Notification;
