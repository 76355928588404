import React, { useState, memo, useEffect } from "react";
import styles from "styles/pages/joinRace/history.module.scss";
import Select from "components/FormElements/Select";
import { ISelectItem } from "interfaces/Form";
import ArrowDown from "icons/ArrowDown";
import HistoryItem from "./HistoryItem";
import scrollStyles from "styles/components/custom-scrollbar.module.scss";
import { SetState } from "interfaces/General";
import { IHistory } from "interfaces/Race";
import { useLocation } from "react-router-dom";
import { HistoryApi } from "utils/api/History";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { useTranslation } from "react-i18next";

type selectValues = "win" | "lose" | "not involved" | "all";

const HistoryTab: React.FC = memo(() => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const [history, setHistory] = useState<IHistory[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectList] = useState<ISelectItem<selectValues>[]>([
    {
      key: "all",
      label: t("all"),
    },
    {
      key: "w",
      label: t("win"),
    },

    {
      key: "l",
      label: t("lose"),
    },

    {
      key: "ni",
      label: t("not_involved"),
    },
  ]);

  const [selected, setSelected] = useState<ISelectItem<selectValues>>(
    selectList[0]
  );

  const [prizeUp, setPrizeUp] = useState(false);
  const [dateUp, setDateUp] = useState(false);
  const [filteredHistory, setFilteredHistory] = useState<IHistory[]>([]);

  const toggleUp = (state: boolean, setState: SetState<boolean>) => {
    setDateUp(false);
    setPrizeUp(false);

    setState(!state);
  };

  useEffect(() => {
    let newHistory = [...history].filter((item) =>
      pathname.includes(item.type)
    );

    switch (selected.key) {
      case "all":
        break;
      case "w":
        newHistory = [...newHistory].filter((item) => item.outcome.win);
        break;
      case "l":
        newHistory = [...newHistory].filter((item) => item.outcome.defeat);
        break;
      case "ni":
        newHistory = [...newHistory].filter(
          (item) => !item.outcome.defeat && !item.outcome.win
        );
        break;
    }

    if (prizeUp) {
      newHistory = [
        ...newHistory.sort((a, b) => {
          return b.fund - a.fund;
        }),
      ];

      setFilteredHistory(newHistory);
    }

    if (dateUp) {
      newHistory = [
        ...newHistory.sort((a, b) => {
          //@ts-ignore
          return new Date(a.date) - new Date(b.date);
        }),
      ];

      setFilteredHistory(newHistory);
    }

    if (!prizeUp && !dateUp) {
      setFilteredHistory([...newHistory]);
    }
  }, [prizeUp, dateUp, selected, history, pathname]);

  useEffect(() => {
    HistoryApi.getHistory()
      .then((res) => {
        if (res.history) {
          setHistory(res.history);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes("special")) {
      navigate(
        ROUTES_PATH.SPECIAL_RACING +
          "/e7e110fa-2040-4e93-a9dd-496588b7ef0b/view"
      );
    }
  }, [pathname]);

  return (
    <div className={styles.history}>
      <div className={styles.history__filters}>
        <div className={`${styles.history__filter}`}>{t("race_number")}</div>

        <div
          onClick={() => toggleUp(prizeUp, setPrizeUp)}
          className={`${styles.history__filter} ${
            prizeUp ? styles["history__filter--up"] : ""
          }`}
        >
          {t("prize_fund")}
          <ArrowDown />
        </div>

        <div className={`${styles.history__filter} `}>{t("racers")}</div>

        <div
          onClick={() => toggleUp(dateUp, setDateUp)}
          className={`${styles.history__filter} ${
            dateUp ? styles["history__filter--up"] : ""
          }`}
        >
          {t("date")}
          <ArrowDown />
        </div>

        <div
          className={`${styles.history__filter} ${styles["history__filter--select"]}`}
        >
          <Select
            current={selected}
            list={selectList}
            setCurrent={setSelected}
          />
        </div>
      </div>

      <div
        className={`${styles.history__wrapper} ${scrollStyles.custom_scrollbar}`}
      >
        {loading ? (
          <div className={styles.history__no_races}>{t("loading")}...</div>
        ) : filteredHistory.length > 0 ? (
          <ul className={styles.history__list}>
            {filteredHistory.map((item, key) => (
              <HistoryItem key={key} {...item} />
            ))}
          </ul>
        ) : (
          <div className={styles.history__no_races}>{t("no_races")}</div>
        )}
      </div>
    </div>
  );
});

export default HistoryTab;
