import { useEffect, memo } from "react";
import styles from "styles/components/sidebar.module.scss";
import Button from "components/Buttons/Button";
import MGT from "icons/MGT";
import { action } from "common/bridge";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { setGameContent, setGameIsOpen } from "redux/reducers/PopupSlice";
import Bnb from "icons/Bnb";
import ReceiveBnb from "components/GamePopup/ReceiveBnb";
import Withdraw from "components/GamePopup/Withdraw";
import Metamask from "icons/Metamask";
import { tokenTypes } from "interfaces/Tokens";
import { Link } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import Info from "icons/Info";
import { useTranslation } from "react-i18next";

interface BalanceInfoProps {
  buttons?: boolean;
}

const BalanceInfo: React.FC<BalanceInfoProps> = memo(({ buttons = true }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const handleReceive = (type: tokenTypes) => {
    dispatch(setGameContent(<ReceiveBnb type={type} />));
    dispatch(setGameIsOpen(true));
  };

  const openWithdraw = (type: tokenTypes) => {
    dispatch(setGameContent(<Withdraw type={type} />));
    dispatch(setGameIsOpen(true));
  };

  useEffect(() => {
    if (user && user.user.balance.blockchain) {
      action.post.updateBalance(Number(user.user.balance.blockchain.mgt));
    }
  }, [user]);

  return (
    <div
      id="your-wallet-block"
      className={`${styles.sidebar__info_item} ${
        styles["sidebar__info_item--wallet"]
      } ${!buttons ? styles["sidebar__info_item--no-buttons"] : ""}`}
    >
      <div className={styles.sidebar__info_wrapper}>
        <div className={styles.sidebar__info_left}>
          <Bnb />
          <div>
            {buttons && (
              <span className={styles.sidebar__info_title}>
                <Link to={ROUTES_PATH.WALLET}>{t("your_wallet")}</Link>
              </span>
            )}
            <span className={styles.sidebar__info_data}>
              {user && user.user.balance?.blockchain
                ? `${
                    !buttons
                      ? user.user.balance.blockchain.bnb
                      : Number(user.user.balance.blockchain.bnb).toFixed(4)
                  } BNB`
                : "..."}
            </span>

            <span
              className={`${styles.sidebar__info_title} ${styles["sidebar__info_title--additional"]}`}
            >
              ~
              {user && user.user.balance?.blockchain
                ? Number(user.user.balance.blockchain.bnbInUSDT).toFixed(2)
                : "..."}{" "}
              USDT
            </span>
          </div>

          {!buttons && (
            <>
              <Button
                label={t("deposit")}
                callback={() => handleReceive("bnb")}
              />
              <Button
                label={t("withdraw")}
                callback={() => openWithdraw("bnb")}
              />
            </>
          )}
        </div>

        {buttons && (
          <div className={styles.sidebar__info_icon}>
            <Link to={ROUTES_PATH.WALLET}></Link>
            <Info />
          </div>
        )}

        {user && !buttons && Number(user.user.balance?.blockchain?.mgt) > 0 && (
          <div className={styles.sidebar__info_left}>
            <MGT />
            <div>
              <span
                className={`${styles.sidebar__info_title} ${styles["sidebar__info_title--hidden"]}`}
              >
                {t("your_wallet")}
              </span>
              <span className={styles.sidebar__info_data}>
                {user && user.user.balance?.blockchain
                  ? `${
                      !buttons
                        ? user.user.balance.blockchain.mgt
                        : Number(user.user.balance.blockchain.mgt).toFixed(2)
                    } MGT`
                  : "..."}

                {!buttons && <Metamask />}
              </span>

              <span
                className={`${styles.sidebar__info_title} ${styles["sidebar__info_title--additional"]}`}
              >
                ~
                {user && user.user.balance?.blockchain
                  ? Number(user.user.balance.blockchain.mgtInUSDT).toFixed(2)
                  : "..."}{" "}
                USDT
              </span>
            </div>
            {!buttons && (
              <>
                <Button
                  label={t("deposit")}
                  callback={() => handleReceive("mgt")}
                />
                <Button
                  label={t("withdraw")}
                  callback={() => openWithdraw("mgt")}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export default BalanceInfo;
