import Button from "components/Buttons/Button";
import GameButton from "components/Buttons/GameButton";
import Input from "components/FormElements/Input";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import styles from "styles/components/gamePopup/withdraw.module.scss";
import { TokensApi } from "utils/api/Tokens";
import SelectTabs, { ISelectTabItem } from "components/FormElements/SelectTabs";
import MGT from "icons/MGT";
import { IWithdrawDTO } from "interfaces/Tokens";
import { setDisabled } from "redux/reducers/PopupSlice";
import Decimal from "decimal.js";
import { useTranslation } from "react-i18next";

const Claim: React.FC = () => {
  const { t } = useTranslation();

  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const tabs: ISelectTabItem[] = [
    {
      key: "bnb",
      label: `${t("receive")} bnb`,
    },

    {
      key: "mgt",
      label: `${t("receive")} mgt`,
    },
  ];

  const [dataSendind, setDataSending] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleMax = useCallback(() => {
    if (user) {
      const decimalAmount = String(new Decimal(user.user.balance.ingame.mgt));
      setAmount(
        Number(user.user.balance.ingame.mgt) > 0 && !decimalAmount.includes("e")
          ? decimalAmount
          : "0"
      );
    }
  }, [user]);

  const handleSetMainAddress = () => {
    setAddress(user!.user.wallet.address);
  };

  const handleClaim = () => {
    setDataSending(true);
    dispatch(setDisabled(true));

    const data: IWithdrawDTO = {
      amount,
      address,
    };

    const promise =
      activeTab.key === "mgt"
        ? TokensApi.claimGameBalanceFromTransfer(data, t("claim_success"))
        : TokensApi.claimGameBalanceFromSwapMGTtoBNB(data, t("claim_success"));

    promise
      .then(() => {
        setAmount("");
      })
      .finally(() => {
        setDataSending(false);
        dispatch(setDisabled(false));
      });
  };

  useEffect(() => {
    handleMax && handleMax();
  }, [activeTab, handleMax]);

  useEffect(() => {
    if (
      Number(amount) > Number(user?.user.balance.ingame.mgt) ||
      Number(amount) <= 0
    )
      setBlocked(true);
    else setBlocked(false);
  }, [amount, user]);

  return (
    <div className={styles.withdraw} onClick={(e) => e.stopPropagation()}>
      <div className={styles.withdraw__title}>{t("claim")}</div>

      <div className={styles.withdraw__tabs}>
        <SelectTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
      </div>

      <div className={styles.withdraw__text}>
        {activeTab.key === "mgt"
          ? t("claim_modal_transfer")
          : t("claim_modal_convert")}
      </div>

      <div className={styles.withdraw__balance}>
        <span>{t("game_balance")}</span>
        <div>
          <MGT />
          {user?.user.balance.ingame.mgt
            ? String(new Decimal(user?.user.balance.ingame.mgt)).includes("e")
              ? 0
              : String(new Decimal(user?.user.balance.ingame.mgt))
            : "..."}{" "}
          MGT
        </div>
      </div>

      <div
        className={`${styles.withdraw__row} ${styles["withdraw__row--address"]} ${styles["withdraw__row--margin"]}`}
      >
        <Input
          placeholder="0xAB1cc8..."
          setValue={setAddress}
          value={address}
          label={t("address")}
        />

        <Button label={t("main_address")} callback={handleSetMainAddress} />
      </div>

      <div
        className={`${styles.withdraw__row} ${styles["withdraw__row--margin"]}`}
      >
        <Input
          type="number"
          label={
            activeTab.key === "mgt"
              ? `${t("transfer_amount")} (mgt)`
              : `${t("swap_amount")} (mgt)`
          }
          setValue={setAmount}
          value={amount}
          placeholder="0.00"
        />

        <Button label="Max" callback={handleMax} />
      </div>

      <div className={styles.withdraw__message}>
        {t("you_receive")}{" "}
        <span>
          ~
          {activeTab.key === "mgt"
            ? amount === ""
              ? 0
              : amount
            : 1 /
                ((user!.user.wallet?.rates?.bnbToUsdt || 0) /
                  (Number(amount) *
                    (user!.user.wallet?.rates?.mgtToUsdt || 0))) || "0"}{" "}
          {activeTab.key}
        </span>
      </div>

      <div className={styles.withdraw__price}>
        <div>
          {t("blockchain_fee")}:{" "}
          <span>~{activeTab.key === "mgt" ? "0.00006" : "0.001"} bnb</span>
        </div>
      </div>

      <GameButton
        label={t("claim")}
        disabled={dataSendind || amount === "" || blocked}
        onClick={handleClaim}
      />
    </div>
  );
};

export default Claim;
