import AuthFrame from "components/AuthFrame";
import SmoothImage from "components/SmoothImage";
import { authBgHash } from "constants/blurhashes";
import { ROUTES_PATH } from "constants/routesPath";
import GameClose from "icons/GameClose";
import Logo2 from "icons/Logo2";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "styles/components/auth.module.scss";

interface AuthProps {
  children: React.ReactNode;
  phrase?: string;
}

const Auth: React.FC<AuthProps> = ({ children, phrase }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const goBack = () => navigate(ROUTES_PATH.MAIN);

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      if (pathname !== ROUTES_PATH.REGISTRATION || !phrase) {
        navigate && navigate(ROUTES_PATH.MAIN);
      }
    }
  }, [pathname, navigate, phrase]);

  return (
    <div className={styles.auth}>
      <div className={styles.auth__bg}>
        <SmoothImage
          src={"/img/auth-bg.webp"}
          alt="Cyberpunk city"
          hash={authBgHash}
        />
      </div>

      <div className={styles.auth__content}>
        <div onClick={goBack} className={styles.auth__go_back}>
          <GameClose />
        </div>

        <div className={styles.auth__frame}>
          <AuthFrame />
        </div>

        <div className={styles.auth__wrapper}>
          <div className={styles.auth__logo}>
            <Logo2 />
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default Auth;
