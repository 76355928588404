import { IUserState } from "redux/models/UserState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IUser,
  IUserBalance,
  IUserBlockchainBalance,
  IUserInGameBalance,
} from "interfaces/User";
import { fetchUserData } from "../asyncActions/fetchUserData";
import { fetchInventoryData } from "../asyncActions/fetchInventoryData";
import { ICarboxItem, ILootboxItem, IPartItem } from "interfaces/Inventory";
import { fetchShopData } from "redux/asyncActions/fetchShopData";

const initialState: IUserState = {
  user: null,
  inventory: null,
  shop: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IUser | null>) {
      state.user = action.payload;
    },

    setBalance(state, action: PayloadAction<IUserBalance>) {
      if (state.user) state.user.user.balance = action.payload;
    },

    setIngameBalance(state, action: PayloadAction<IUserInGameBalance>) {
      if (state.user) state.user.user.balance.ingame = action.payload;
    },

    setBlockchainBalance(state, action: PayloadAction<IUserBlockchainBalance>) {
      if (state.user) state.user.user.balance.blockchain = action.payload;
    },

    addParts(state, action: PayloadAction<IPartItem[]>) {
      if (state.inventory) {
        state.inventory.parts = [...state.inventory.parts, ...action.payload];
      }
    },

    removeLootboxes(state, action: PayloadAction<number[]>) {
      if (state.inventory) {
        const filteredLootboxes: ILootboxItem[] = [];

        state.inventory.lootboxes.forEach((item) => {
          if (!action.payload.includes(item.id)) {
            filteredLootboxes.push(item);
          }
        });

        state.inventory.lootboxes = filteredLootboxes;
      }
    },

    removeCarboxes(state, action: PayloadAction<number[]>) {
      if (state.inventory) {
        const filteredCarboxes: ICarboxItem[] = [];

        state.inventory.carboxes.forEach((item) => {
          if (!action.payload.includes(item.id)) {
            filteredCarboxes.push(item);
          }
        });

        state.inventory.carboxes = filteredCarboxes;
      }
    },

    decrementFreeLootboxes(state, action: PayloadAction<number>) {
      if (state.shop && state.shop.lootbox.free) {
        state.shop.lootbox.free = state.shop.lootbox.free - action.payload;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload;
      }
    });

    builder.addCase(fetchInventoryData.fulfilled, (state, action) => {
      if (action.payload) {
        state.inventory = action.payload.inventory;
      }
    });

    builder.addCase(fetchShopData.fulfilled, (state, action) => {
      if (action.payload) {
        state.shop = action.payload;
      }
    });
  },
});

export const {
  setUser,
  setBalance,
  addParts,
  removeLootboxes,
  removeCarboxes,
  decrementFreeLootboxes,
  setBlockchainBalance,
  setIngameBalance,
} = userSlice.actions;

export default userSlice.reducer;
