import React from "react";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

interface Props {
  label: string;
  onClick?: () => void;
  href?: string;
  outlined?: boolean;
  className?: string;
  disabled?: boolean;
  small?: boolean;
  target?: string;
}

const Button: React.FC<Props> = ({
  label,
  className = "",
  disabled,
  href,
  onClick,
  outlined,
  small,
  target = "",
}) => {
  const classNames = `${styles.button} ${outlined ? styles.outlined : ""} ${
    disabled ? styles.disabled : ""
  } ${className} ${small ? styles.small : ""}`;

  return (
    <>
      {onClick ? (
        <button onClick={onClick} className={classNames}>
          <span>{label}</span>
        </button>
      ) : (
        href && (
          <Link target={target} to={href} className={classNames}>
            <span>{label}</span>
          </Link>
        )
      )}
    </>
  );
};

export default Button;
