import React from "react";

const ProfileIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"6rem"}
      height={"6.6rem"}
      viewBox="0 0 60 66"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0709 1.66174L45.0222 6.80207L54.062 12.031C57.873 14.2467 60 18.0576 60 22.4889V32.8582V43.2275C60 47.6588 57.873 51.3811 54.062 53.5967L45.0222 58.8257L36.0709 64.0546C32.1713 66.1817 27.8287 66.1817 24.0177 64.0546L14.9778 58.8257L6.02659 53.5967C2.21566 51.3811 0 47.6588 0 43.2275V32.8582V22.4889C0 18.0576 2.21566 14.2467 6.02659 12.031L14.9778 6.80207L24.0177 1.66174C27.8287 -0.553914 32.1713 -0.553914 36.0709 1.66174ZM30.0443 15.5761C36.4254 15.5761 41.5657 20.7164 41.5657 27.0975C41.5657 33.4786 36.4254 38.6189 30.0443 38.6189C23.6632 38.6189 18.5229 33.4786 18.5229 27.0975C18.5229 20.7164 23.6632 15.5761 30.0443 15.5761ZM30.0443 20.4505C33.678 20.4505 36.6913 23.4638 36.6913 27.0975C36.6913 30.8198 33.678 33.7445 30.0443 33.7445C26.322 33.7445 23.3973 30.8198 23.3973 27.0975C23.3973 23.4638 26.322 20.4505 30.0443 20.4505ZM30.0443 41.8981C37.1344 41.8981 43.5155 45.4431 47.4151 50.7607L48.2127 50.3176C52.0236 48.1019 54.2393 44.291 54.2393 39.8597V25.7681C54.2393 21.3368 52.0236 17.6145 48.2127 15.3988L36.0709 8.30871C32.1713 6.09306 27.8287 6.09306 24.0177 8.30871L11.7873 15.3988C7.97637 17.6145 5.84934 21.3368 5.84934 25.7681V39.8597C5.84934 44.291 7.97637 48.1019 11.7873 50.3176L12.6736 50.7607C16.5731 45.4431 22.8656 41.8981 30.0443 41.8981Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileIcon;
