import React from "react";

const ShowPassword: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"2.9rem"}
      height={"1.5rem"}
      viewBox="0 0 29 15"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2844 7.36006C20.2844 7.17571 20.2723 6.97576 20.2509 6.80585L20.2156 6.52846C20.1849 6.26667 20.1172 5.9991 20.0397 5.74772L19.7637 4.982C19.7007 4.83463 19.6197 4.70634 19.5636 4.59885L18.8658 3.57365C18.8028 3.49506 18.7808 3.48639 18.7143 3.42224L18.1334 2.86053C17.9379 2.70391 18.0316 2.73628 17.7232 2.53228C17.066 2.0977 16.4308 1.75442 15.6781 1.56487C15.0064 1.39612 14.3671 1.32678 13.6926 1.35914C12.9879 1.39266 12.4198 1.51691 11.7655 1.74922C11.2795 1.92144 10.6732 2.25142 10.2601 2.55019C9.55486 3.05875 9.06195 3.617 8.60548 4.33879C7.57858 5.96327 7.47328 8.20553 8.28266 9.87335C8.58581 10.4975 8.97285 11.0887 9.45883 11.5886C9.82677 11.9677 10.3006 12.3104 10.7565 12.5906C11.0683 12.7825 11.5983 13.0038 11.9506 13.124C13.4918 13.6488 15.1511 13.5661 16.6344 12.912C17.585 12.4924 18.3122 11.9087 18.9035 11.1921L19.3188 10.6316C19.3507 10.5842 19.3605 10.5686 19.3906 10.5183C19.9836 9.53123 20.2844 8.5332 20.2844 7.36006ZM10.4493 7.42132C10.4493 4.37867 14.3099 2.90445 16.4539 5.06175C16.797 5.40675 16.948 5.63676 17.1655 6.07885C17.3634 6.48281 17.4785 6.99945 17.4664 7.51263C17.4201 9.45148 15.788 10.8957 13.9355 10.8673C13.18 10.8558 12.5748 10.6633 11.9546 10.2629L11.8609 10.1999C11.8557 10.1958 11.8482 10.1906 11.843 10.1866C11.6422 10.0317 11.493 9.92478 11.3287 9.71674C11.2963 9.67571 11.3136 9.69478 11.2737 9.65548C10.7542 9.14115 10.4493 8.15583 10.4493 7.42132ZM28.3047 7.41901V7.4427C20.7403 15.5807 10.0368 19.2769 0.000892639 7.44617V7.41901C9.37725 -2.41571 18.8201 -2.53013 28.3047 7.41901Z"
        fill="#74566C"
      />
    </svg>
  );
};

export default ShowPassword;
