import JoinRace from "components/JoinRace";
import React, { useState, useEffect, useRef } from "react";
import { IAdvancedCarItem } from "interfaces/Car";
import { useNavigate, useParams } from "react-router-dom";
import { IAdvancedRace } from "interfaces/Race";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { newYearPrizes } from "constants/prizes";
import { SpecialRaceApi } from "utils/api/SpecialRace";
import { updateRaceCallback } from "utils/updateRaceCallback";
import { useTranslation } from "react-i18next";
import { ROUTES_PATH } from "constants/routesPath";

const JoinSpecialRacePage: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { id } = useParams();
  const { user } = useAppSelector(selectUser);

  const timerRef = useRef<NodeJS.Timer | null>(null);
  const startsIn = useRef<number | null>(null);

  const [carIds, setCarIds] = useState<number[]>([]);
  const [timeToStart, setTimeToStart] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [race, setRace] = useState<IAdvancedRace>();
  const [cars, setCars] = useState<IAdvancedCarItem[]>([]);

  const updateRaceData = () => {
    SpecialRaceApi.getRaceById(id!)
      .then((data) => {
        updateRaceCallback({
          data,
          timeToStart,
          startsIn,
          timerRef,
          setTimeToStart,
          setCars,
          carIds,
          setRace,
        });
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Fetching race info
  useEffect(() => {
    if (id) {
      updateRaceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Activating `send to race` button
  useEffect(() => {
    if (cars && race && user) {
      let isActiveCars = false;

      cars.forEach((item) => {
        if (!item.inRace && item.qualified) isActiveCars = true;
      });
      let isInRaceCars = false;

      cars.forEach((item) => {
        if (item.inRace) isInRaceCars = true;
      });

      if (isActiveCars && !isInRaceCars) {
        setIsSendDisabled(false);
      } else {
        setIsSendDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cars, race, user]);

  useEffect(() => {
    if (timeToStart === "00m:00s") {
      setTimeout(() => updateRaceData(), 1000);
    }
  }, [timeToStart]);

  useEffect(() => {
    if (race?.status === "finished" && timeToStart === "00m:00s")
      navigate(ROUTES_PATH.SPECIAL_RACING + `/${id}/view`);
  }, [race]);

  return (
    <JoinRace
      participants={race?.participants || []}
      isLoading={isLoading}
      cars={cars}
      info={{
        conditions: race?.conditions,
        name: "New year race",
        number: race ? race.title : isLoading ? "..." : "",
        prize: race?.prizes?.tokens
          ? race?.prizes?.tokens
          : race?.prizes?.lootboxes,
        participants: race
          ? {
              required: race?.requirements.participants,
              current: race?.participants.length,
            }
          : undefined,
        cost: t("free"),
        timeToStart:
          timeToStart !== "" ? timeToStart : isLoading ? "..." : t("soon"),
        sendDisabled: isSendDisabled,
        setCars,
        setRace,
        id: race?.id,
        carIds,
        setCarIds,
        type: race?.type,
        status: race?.status,
      }}
      prizes={newYearPrizes}
      pool={{
        mgt: 10000,
        usdt: 1000,
      }}
    />
  );
};

export default JoinSpecialRacePage;
