import React from "react";
import styles from "./style.module.scss";

import WhitePaper from "./icons/WhitePaper";
import Discord from "./icons/Discord";
import Ship from "./icons/Ship";
import Telegram from "./icons/Telegram";
import Twitter from "./icons/Twitter";

const socialList = [
  {
    icon: <WhitePaper />,
    href: "https://docs.metaraces.io/",
  },
  {
    icon: <Discord />,
    href: "https://discord.com/invite/metaracing",
  },

  {
    icon: <Ship />,
    href: "https://metaracing.medium.com",
  },

  {
    icon: <Telegram />,
    href: "https://t.me/metaraces",
  },

  {
    icon: <Twitter />,
    href: "https://twitter.com/MetaRaces_io",
  },
];

const SocialList: React.FC = () => {
  return (
    <div className={styles.list}>
      {socialList.map((item, key) => (
        <a rel="noreferrer" target="_blank" href={item.href} key={key}>
          {item.icon}
        </a>
      ))}
    </div>
  );
};

export default SocialList;
