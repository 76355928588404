import React from "react";

const Medium: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="275px"
      height="310px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 1.58 1.79"
      imageRendering="optimizeQuality"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "\n   \n    .fil0 {fill:#8FD12F}\n   \n  ",
          }}
        />
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M0.86 0.02l0.33 0.19 0.32 0.19c0.05,0.02 0.07,0.06 0.07,0.11l0 0.38 0 0.38c0,0.05 -0.02,0.09 -0.07,0.12l-0.32 0.19 -0.33 0.19c-0.04,0.02 -0.09,0.02 -0.14,0l-0.32 -0.19 -0.33 -0.19c-0.05,-0.03 -0.07,-0.07 -0.07,-0.12l0 -0.38 0 -0.38c0,-0.05 0.02,-0.09 0.07,-0.11l0.33 -0.19 0.32 -0.19c0.05,-0.03 0.1,-0.03 0.14,0zm-0.14 0.89c0,-0.03 -0.01,-0.05 0.02,-0.07 0.02,-0.02 0.07,-0.02 0.09,0 0.04,0.03 0.03,0.12 -0.05,0.12 -0.03,0 -0.06,-0.02 -0.06,-0.05zm0.16 0.22l0.01 0c0,0 -0.01,0 -0.01,0zm-0.25 -0.03c0.01,0.01 0,0 0,0.01l-0.01 0.02c-0.02,0.07 -0.05,0.18 -0.04,0.25 0.05,0.15 0.28,0.14 0.39,0.1 0.09,-0.04 0.03,-0.12 0,-0.17 -0.02,-0.03 -0.04,-0.08 -0.06,-0.11l-0.02 -0.06c0.02,0.01 0.14,0.18 0.25,0.18 0.08,0 0.13,-0.05 0.17,-0.11 0.02,-0.02 0.04,-0.05 0.05,-0.08 0.02,-0.07 0.05,-0.14 -0.01,-0.18 -0.03,-0.01 -0.18,-0.01 -0.26,-0.01 -0.01,-0.01 -0.03,-0.01 -0.05,-0.01l0 0c0.06,0 0.24,-0.05 0.28,-0.11 0.07,-0.09 0.03,-0.2 -0.03,-0.28 -0.02,-0.04 -0.09,-0.11 -0.13,-0.11 -0.07,0 -0.09,0.1 -0.16,0.19 -0.01,0.01 -0.04,0.06 -0.05,0.07 0.02,-0.1 0.11,-0.28 -0.02,-0.37 -0.07,-0.05 -0.21,-0.05 -0.3,-0.02 -0.09,0.03 -0.08,0.09 -0.04,0.15l0.06 0.09c0,0.02 0.01,0.02 0.01,0.04l0.03 0.06c0,0.01 0,0.01 0,0.01 0,0.01 0,0 -0.02,-0.03 -0.02,-0.02 -0.03,-0.04 -0.06,-0.05 -0.01,-0.01 -0.01,-0.02 -0.02,-0.03l-0.07 -0.05c-0.1,-0.06 -0.19,0 -0.25,0.08 -0.02,0.03 -0.03,0.05 -0.05,0.08 -0.02,0.06 -0.05,0.15 0.02,0.19 0.02,0.01 0.15,0 0.2,0.01l0.09 0.01c-0.03,0 -0.07,0.01 -0.1,0.02 -0.09,0.03 -0.21,0.07 -0.21,0.19 0,0.06 0.04,0.14 0.07,0.17 0.04,0.07 0.14,0.18 0.2,0.07 0.04,-0.06 0.04,-0.08 0.09,-0.14 0.01,-0.03 0.03,-0.05 0.05,-0.07zm-0.03 -0.19l0 -0.03c0,-0.09 0.08,-0.17 0.17,-0.17l0.03 0c0.08,0 0.17,0.08 0.17,0.17 0,0.06 -0.01,0.1 -0.05,0.14 -0.12,0.12 -0.32,0.03 -0.32,-0.11zm0.06 -0.03l0 0.03c0,0.09 0.13,0.15 0.21,0.07 0.09,-0.08 0.01,-0.21 -0.07,-0.21l-0.03 0c-0.05,0 -0.11,0.06 -0.11,0.11z"
        />
      </g>
    </svg>
  );
};

export default Medium;
