import React from "react";

const CounterPlus: React.FC = () => {
  return (
    <svg
      width={"3.2rem"}
      height={"3.3rem"}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 28.786C22.6274 28.786 28 23.4134 28 16.786C28 10.1585 22.6274 4.78595 16 4.78595C9.37258 4.78595 4 10.1585 4 16.786C4 23.4134 9.37258 28.786 16 28.786Z"
        stroke="url(#paint0_linear_1754_6425)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8097 9.92883V23.2622M9.14307 16.5955H22.4764"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1754_6425"
          x1={16}
          y1="4.78595"
          x2={16}
          y2="28.7859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E73B4B" />
          <stop offset={1} stopColor="#FF7854" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CounterPlus;
