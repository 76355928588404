import React from "react";
import styles from "./styles.module.scss";

export interface Props {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  className?: string;
  type?: "number" | "text" | "password";
}

const Input: React.FC<Props> = ({
  setValue,
  value,
  className = "",
  placeholder,
  type = "text",
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (type === "number") value = value.replace(/\D/g, "");

    setValue(value);
  };

  return (
    <div
      className={`${styles.input__wrapper} ${
        value.trim() !== "" ? styles.filled : ""
      } ${className}`}
    >
      <div className={styles.input__container}>
        <input
          className={`${styles.input}`}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Input;
