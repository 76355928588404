import React from "react";
import styles from "styles/components/small-button.module.scss";

interface SmallButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const SmallButton: React.FC<SmallButtonProps> = ({
  label,
  onClick,
  disabled,
}) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={`${styles.small_button} ${
        disabled ? styles["small_button--disabled"] : ""
      }`}
      disabled={disabled}
    >
      <div className={styles.small_button__wrapper}>
        <span>{label}</span>
      </div>
    </button>
  );
};

export default SmallButton;
