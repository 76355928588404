import IOSIcon from "icons/IOSIcon";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/components/install-pwa.module.scss";
import { isIOS } from "utils/isIOS";

const InstallPWA: React.FC = () => {
  const { t } = useTranslation();

  const [supportsPWA, setSupportsPWA] = useState<boolean>();
  const [promptInstall, setPromptInstall] = useState<Event | null>(null);

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    window.addEventListener("beforeinstallprompt", handler);

    if (supportsPWA === undefined) setSupportsPWA(false);

    return () => window.removeEventListener("transitionend", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    //@ts-ignore
    promptInstall.prompt();
  };

  if (isIOS()) {
    return (
      <p
        style={{
          fontSize: "7.2rem",
          lineHeight: "9rem",
          maxWidth: "120rem",
          textAlign: "center",
          marginTop: "10rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        {t("install_pwa_ios_1")} <IOSIcon /> {t("install_pwa_ios_2")}
      </p>
    );
  }

  if (supportsPWA === undefined) return null;

  if (supportsPWA === false) {
    return (
      <p
        style={{
          fontSize: "7.2rem",
          lineHeight: "9rem",
          maxWidth: "120rem",
          textAlign: "center",
          marginTop: "10rem",
        }}
      >
        {t("install_pwa_text")}
      </p>
    );
  }

  return (
    <button className={styles.install_pwa} onClick={onClick}>
      {t("install_pwa_btn")}
    </button>
  );
};

export default InstallPWA;
