import React from "react";

const AirdropIcon: React.FC = () => {
  return (
    <svg
      width={"10.7rem"}
      height={"11.7rem"}
      viewBox="0 0 107 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.2383 2.87167L80.3459 12.2525L96.2616 21.4418C103.165 25.4621 107 32.1627 107 40.0119V58.5821V76.9607C107 84.81 103.165 91.5105 96.2616 95.5309L80.3459 104.72L64.2383 114.101C57.5269 117.93 49.6649 117.93 42.7616 114.101L26.8459 104.72L10.7384 95.5309C3.83513 91.5105 0 84.81 0 76.9607V58.5821V40.0119C0 32.1627 3.83513 25.4621 10.7384 21.4418L26.8459 12.2525L42.7616 2.87167C49.6649 -0.957223 57.5269 -0.957223 64.2383 2.87167ZM42.7616 14.9327L21.0932 27.3766C14.19 31.3969 10.3548 38.0975 10.3548 45.9467V71.0259C10.3548 78.8752 14.19 85.5757 21.0932 89.5961L42.7616 102.04C49.6649 106.06 57.5269 106.06 64.2383 102.04L85.9068 89.5961C92.81 85.5757 96.6452 78.8752 96.6452 71.0259V45.9467C96.6452 38.0975 92.81 31.3969 85.9068 27.3766L64.2383 14.9327C57.5269 10.9123 49.6649 10.9123 42.7616 14.9327Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7812 75.6205L23.973 45.1808C23.973 44.7979 24.1648 44.415 24.5483 44.2236L55.421 26.4192C55.8046 26.2278 56.1881 26.2278 56.5716 26.4192C56.9551 26.6107 57.1468 26.9936 57.1468 27.3764L56.9551 56.476C56.9551 56.8589 57.1468 57.2418 57.5304 57.4332L75.3637 67.7713C75.7472 67.9627 76.1307 67.9627 76.5142 67.7713C76.8977 67.5798 77.0895 67.3884 77.0895 67.0055V46.138C77.0895 45.7551 76.8977 45.3722 76.5142 45.1808L72.8709 43.0749C72.4873 42.8835 72.1038 42.8835 71.9121 43.0749C71.5286 43.2663 71.3368 43.4578 71.3368 43.8407V57.0504C71.3368 57.4332 71.1451 57.6247 70.7615 57.8161C70.378 58.0076 69.9945 58.0076 69.8028 57.8161L64.4336 54.753C64.0501 54.5616 63.8583 54.3701 63.8583 53.9872V31.2053C63.8583 30.8224 64.0501 30.4396 64.4336 30.2481C64.8171 30.0567 65.2006 30.0567 65.3924 30.2481L83.9927 40.969C84.3762 41.1605 84.568 41.3519 84.568 41.9262L84.3762 72.3659C84.3762 72.7488 84.1845 73.1317 83.801 73.3231L77.473 76.9606C77.2812 77.152 76.8977 77.152 76.5142 76.9606L50.0519 61.645C49.6683 61.4536 49.4766 61.2621 49.4766 60.8792L49.6683 40.2032C49.6683 39.8203 49.4766 39.4375 49.0931 39.246C48.7096 39.0546 48.3261 39.0546 48.1343 39.246L44.8744 41.1605C44.4909 41.3519 44.2992 41.7348 44.2992 42.1177V64.1338C44.2992 64.5167 44.4909 64.7081 44.6827 64.8996L69.9945 79.6408C70.378 79.8323 70.5698 80.0237 70.5698 80.4066C70.5698 80.7895 70.378 81.1724 69.9945 81.3638L64.8171 84.4269C64.4336 84.6184 64.0501 84.6184 63.6666 84.4269L37.3959 69.3028C37.0124 69.1114 36.8207 68.7285 36.8207 68.3456V47.4781C36.8207 47.0952 36.6289 46.7123 36.4372 46.5209C36.0537 46.3295 35.6701 46.3295 35.2866 46.5209L31.835 48.6268C31.4515 48.8182 31.2597 49.2011 31.2597 49.584V71.4087C31.2597 71.7916 31.4515 72.1745 31.835 72.3659L57.1468 86.9157C57.5304 87.1072 57.7221 87.4901 57.7221 87.8729C57.7221 88.2558 57.5304 88.6387 57.1468 88.8302L51.7777 91.8933C51.5859 92.0847 51.2024 92.0847 50.8189 91.8933L24.3565 76.5777C23.973 76.3863 23.7812 76.0034 23.7812 75.6205Z"
        fill="white"
      />
    </svg>
  );
};

export default AirdropIcon;
