import React, { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";

interface SmoothImageProps {
  src: string;
  alt: string;
  hash: string;
}

const SmoothImage: React.FC<SmoothImageProps> = ({ src, alt, hash }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };

    img.src = src;
  }, [src]);

  return (
    <>
      <div
        style={{
          opacity: imageLoaded ? "0" : "1",
          visibility: imageLoaded ? "hidden" : "visible",
          display: "inline",
          position: "absolute",
          zIndex: "2",
          height: "100%",
          width: "100%",
          transition: ".1s ease all",
        }}
      >
        <Blurhash
          hash={hash}
          width={"100%"}
          height={"100%"}
          punch={1}
          resolutionX={100}
          resolutionY={100}
        />
      </div>
      <img style={{ position: "relative", zIndex: 1 }} src={src} alt={alt} />
    </>
  );
};

export default SmoothImage;
