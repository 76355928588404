import { ROUTES_PATH } from "constants/routesPath";
import React, { useEffect, useState, useCallback, useRef, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Error from "./Error";
import { action, callbacks, useBridge } from "common/bridge";
import { AxiosError } from "axios";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { setHintContent, setHintIsOpen } from "redux/reducers/PopupSlice";
import soundHover from "sounds/navedenie.mp3";
import soundChoose from "sounds/choose_a_car.mp3";
import soundClickButton from "sounds/click_button.mp3";
import soundHighMenu from "sounds/click_button.mp3";
import soundScrewdriver from "sounds/shurupovert.mp3";
import soundBuyCar from "sounds/buy_a_car.mp3";
import { selectPopup } from "redux/selectors/popupSelector";
import { selectUser } from "redux/selectors/userSelector";
import { removeCarboxes } from "redux/reducers/UserSlice";
import { errorCallback } from "utils/api/Auth";
import { useTranslation } from "react-i18next";

type pixiSoundTypes =
  | "navedenie"
  | "choose_a_car"
  | "click_button"
  | "garage_high_menu"
  | "shurupovert"
  | "buy_a_car";

const sounds = {
  navedenie: soundHover,
  choose_a_car: soundChoose,
  click_button: soundClickButton,
  garage_high_menu: soundHighMenu,
  shurupovert: soundScrewdriver,
  buy_a_car: soundBuyCar,
};

const Client: React.FC = memo(() => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { inventory } = useAppSelector(selectUser);

  const { muteSound } = useAppSelector(selectPopup);

  const [carsLength, setCarsLength] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [active, setActive] = useState(false);
  const [carboxOpened, setCarboxOpened] = useState(false);
  const [pixiError, setPixiError] = useState(false);

  const onError = useCallback((e: AxiosError) => {
    if (e.response?.status !== 401) {
      setPixiError(true);
      return;
    }

    errorCallback(e);
    return;
  }, []);

  const onGoto = useCallback((msg: string) => {
    switch (msg) {
      case "shop": {
        navigate(ROUTES_PATH.SHOP);
        break;
      }
      case "sign_in": {
        navigate(ROUTES_PATH.LOGIN);
        break;
      }
      case "qualify": {
        navigate(ROUTES_PATH.QUAL_RACING);
        break;
      }
      case "garage": {
        navigate(ROUTES_PATH.GARAGE);
        break;
      }
      case "carboxes": {
        navigate(ROUTES_PATH.CARBOXES);
        break;
      }
      case "wallet": {
        navigate(ROUTES_PATH.WALLET);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCarsLoaded = useCallback((data: number) => setCarsLength(data), []);

  const onOpenCarbox = useCallback((ids: number[]) => {
    dispatch(removeCarboxes(ids));
    setCarboxOpened(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPlaySound = useCallback((sound: pixiSoundTypes) => {
    const audio = new Audio(sounds[sound]);

    audio.play();
  }, []);

  useEffect(() => {
    if (pathname === ROUTES_PATH.CARBOXES) {
      action.post.gotoCarbox();
    }

    if (pathname.includes(ROUTES_PATH.GARAGE)) {
      setActive(true);

      if (loaded && localStorage.getItem("access_token")) {
        action.post.connect();
      }

      const isDesktop = window.innerWidth > 1023.98;

      if (
        !localStorage.getItem("garage_hint") &&
        pathname === ROUTES_PATH.GARAGE &&
        loaded &&
        localStorage.getItem("access_token") &&
        carsLength > 0 &&
        dispatch
      ) {
        dispatch(
          setHintContent({
            name: "judy",
            onNext() {
              dispatch(
                setHintContent({
                  name: "judy",
                  onNext() {
                    localStorage.setItem("garage_hint", "completed");
                    dispatch(setHintIsOpen(false));
                  },
                  text: t("garage_hint_text_2"),
                  hole: {
                    height: "34rem",
                    width: "32rem",
                    top: "29rem",
                    right: "0",
                  },
                  img: {
                    right: "unset",
                    left: "25rem",
                  },
                })
              );
            },
            text: t("garage_hint_text_1"),
            hole: isDesktop
              ? {
                  left: "21%",
                  height: "21rem",
                  width: "21rem",
                  top: "34rem",
                }
              : {
                  left: "2%",
                  height: "21rem",
                  width: "21rem",
                  top: "41%",
                },
            img: {
              right: "-20rem",
            },
            content: {
              left: "35rem",
            },
          })
        );
        dispatch(setHintIsOpen(true));
      }

      if (
        !localStorage.getItem("carboxes_hint_1") &&
        pathname === ROUTES_PATH.CARBOXES &&
        loaded &&
        localStorage.getItem("access_token") &&
        dispatch
      ) {
        dispatch(
          setHintContent({
            name: "judy",
            onNext() {
              localStorage.setItem("carboxes_hint_1", "completed");
              dispatch(setHintIsOpen(false));
            },
            text: t("carboxes_hint_text_1"),
            hole: isDesktop
              ? {
                  left: "29%",
                  height: "14rem",
                  width: "15rem",
                  bottom: "10rem",
                }
              : undefined,
            img: isDesktop
              ? {
                  right: "-20rem",
                }
              : undefined,
            content: isDesktop
              ? {
                  left: "35rem",
                }
              : undefined,
          })
        );
        dispatch(setHintIsOpen(true));
      }
    } else {
      setActive(false);
    }
  }, [pathname, inventory, loaded, carsLength, dispatch]);

  useEffect(() => {
    if (carboxOpened && !localStorage.getItem("carboxes_hint_2") && dispatch) {
      dispatch(
        setHintContent({
          name: "judy",
          text: t("carboxes_hint_text_2"),
          onNext() {
            dispatch(setHintIsOpen(false));
            localStorage.setItem("carboxes_hint_2", "completed");
          },
        })
      );
      dispatch(setHintIsOpen(true));
    }
  }, [carboxOpened, dispatch]);

  useEffect(() => {
    if (onError && onGoto && onOpenCarbox) {
      callbacks.clearAll();
      callbacks.add("onError", onError);
      callbacks.add("onGoto", onGoto);
      callbacks.add("onOpenCarbox", onOpenCarbox);
      callbacks.add("onPlaySound", (arg) => {
        if (!muteSound) onPlaySound(arg);
      });
      callbacks.add("onLoaded", () => {
        setLoaded(true);
      });
      callbacks.add("onCarsLoaded", onCarsLoaded);
    }
  }, [onError, onGoto, onOpenCarbox, muteSound, onCarsLoaded, onPlaySound]);

  useEffect(() => {
    if (iframeRef.current) {
      useBridge.setToken(`Bearer ${localStorage.getItem("access_pixi_token")}`);
      useBridge.setIframe(iframeRef.current);

      if (localStorage.getItem("access_token")) action.post.connect();
    }
  }, []);

  return (
    <div
      className={`game-client ${active ? "game-client--active" : ""}`}
      style={{ overflow: "hidden" }}
    >
      {pixiError && <Error />}
      <iframe
        ref={iframeRef}
        src={`${process.env.REACT_APP_PIXI_GARAGE}?locale=${
          i18n.language.split("-")[0]
        }&silent=1`}
        title={"canvas"}
        sandbox="allow-same-origin allow-scripts"
        style={{
          width: "100%",
          height: "100%",
          display: pixiError ? "none" : "block",
        }}
      />

      {/* <Preloader loaded={garageLoaded} /> */}
    </div>
  );
});

export default Client;
