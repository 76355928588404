import React from "react";
import styles from "styles/components/auth.module.scss";
import AuthFrame from "components/AuthFrame";

interface PopupLayoutProps {
  children: React.ReactNode;
}

const PopupLayout: React.FC<PopupLayoutProps> = ({ children }) => {
  return (
    <div className={styles.auth__content} onClick={(e) => e.stopPropagation()}>
      <div className={styles.auth__frame}>
        <AuthFrame dark />
      </div>

      <div className={styles.auth__wrapper}>{children}</div>
    </div>
  );
};

export default PopupLayout;
