import React from "react";
import styles from "styles/components/burger.module.scss";

interface BurgerProps {
  isActive: boolean;
  callback: () => void;
}

const Burger: React.FC<BurgerProps> = ({ isActive, callback }) => {
  return (
    <div
      className={`${styles["burger-wrapper"]} ${
        isActive ? styles["burger-wrapper--active"] : ""
      }`}
      onClick={callback}
    >
      <div
        className={`${styles.burger} ${
          isActive ? styles["burger--active"] : ""
        }`}
      >
        <span></span>
      </div>
    </div>
  );
};

export default Burger;
