import React from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/components/flip-device.module.scss";

const FlipDevice: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.filp_device}>
      <div className={styles.filp_device__img}>
        <img src="/img/flip.png" alt="Flip your phone" />
      </div>

      <span className={styles.filp_device__label}>{t("flip_device_text")}</span>
    </div>
  );
};

export default FlipDevice;
