import React from "react";

const ArrowLeft: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="21"
      viewBox="0 0 13 21"
      fill="none"
    >
      <rect
        x="9.89844"
        y="8.00002"
        width="3"
        height="14"
        transform="rotate(45 9.89844 8.00002)"
        fill="#EB4A41"
      />
      <rect
        x="12.0234"
        y="9.89949"
        width="3"
        height="14"
        transform="rotate(135 12.0234 9.89949)"
        fill="#EB4A41"
      />
    </svg>
  );
};

export default ArrowLeft;
