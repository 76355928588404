import Button from "components/Buttons/Button";
import GameButton from "components/Buttons/GameButton";
import Input from "components/FormElements/Input";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import styles from "styles/components/gamePopup/withdraw.module.scss";
import { TokensApi } from "utils/api/Tokens";
import SelectTabs, { ISelectTabItem } from "components/FormElements/SelectTabs";
import Bnb from "icons/Bnb";
import MGT from "icons/MGT";
import { ITopupDTO } from "interfaces/Tokens";
import { setDisabled } from "redux/reducers/PopupSlice";
import Decimal from "decimal.js";
import { useTranslation } from "react-i18next";

const transactionFeeFromSwap = 0.005;

const TopupGameBalance: React.FC = () => {
  const { t } = useTranslation();

  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const [tabs, setTabs] = useState<ISelectTabItem[]>();

  const tabsInitial = [
    {
      key: "bnb",
      label: t("from_swap"),
    },

    {
      key: "mgt",
      label: t("from_main"),
    },
  ];

  const [blocked, setBlocked] = useState(false);
  const [dataSendind, setDataSending] = useState(false);
  const [amount, setAmount] = useState("");
  const [activeTab, setActiveTab] = useState(tabsInitial[0]);

  const handleMax = useCallback(() => {
    if (user && user?.user.balance.blockchain) {
      if (activeTab.key === "mgt") {
        setAmount(String(user?.user.balance.blockchain?.mgt));
        return;
      }

      const newAmount =
        Number(user?.user.balance.blockchain?.bnb) - transactionFeeFromSwap;
      const decimalAmount = String(new Decimal(newAmount));

      setAmount(
        newAmount > 0 && !decimalAmount.includes("e") ? decimalAmount : "0"
      );
    }
  }, [user, activeTab]);

  const handleTopup = () => {
    setDataSending(true);
    dispatch(setDisabled(true));

    const data: ITopupDTO = {
      amount,
    };

    const promise =
      activeTab.key === "mgt"
        ? TokensApi.topupGameBalanceFromMainWallet(data, t("topup_success"))
        : TokensApi.topupGameBalanceFromSwapBNB(data, t("topup_success"));

    promise
      .then(() => {
        setAmount("");
      })
      .finally(() => {
        setDataSending(false);
        dispatch(setDisabled(false));
      });
  };

  useEffect(() => {
    handleMax && handleMax();
  }, [activeTab, handleMax]);

  useEffect(() => {
    if (Number(user?.user.balance.blockchain?.mgt) > 0) {
      setTabs(tabsInitial);
    } else {
      setTabs([
        {
          ...tabsInitial[0],
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (activeTab.key === "bnb") {
      if (
        Number(amount) > Number(user?.user.balance.blockchain?.bnb) ||
        Number(amount) <= 0
      )
        setBlocked(true);
      else setBlocked(false);
      return;
    }

    if (activeTab.key === "mgt") {
      if (
        Number(amount) > Number(user?.user.balance.blockchain?.mgt) ||
        Number(amount) <= 0
      )
        setBlocked(true);
      else setBlocked(false);
    }
  }, [amount, user, activeTab]);

  return (
    <div className={styles.withdraw} onClick={(e) => e.stopPropagation()}>
      <div className={styles.withdraw__title}>{t("topup_modal_title")}</div>

      <div className={styles.withdraw__tabs}>
        <SelectTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs || []}
        />
      </div>

      <div className={styles.withdraw__text}>
        {activeTab.key === "mgt"
          ? t("topup_modal_transfer")
          : t("topup_modal_convert")}
      </div>

      <div className={styles.withdraw__balance}>
        <span>{t("wallet_balance")}</span>
        {activeTab.key === "mgt" ? (
          <div>
            <MGT />
            {user?.user.balance.blockchain?.mgt
              ? String(new Decimal(user?.user.balance.blockchain?.mgt))
              : "..."}{" "}
            MGT
          </div>
        ) : (
          <div>
            <Bnb />
            {user?.user.balance.blockchain?.bnb || "..."} BNB
          </div>
        )}
      </div>

      <div
        className={`${styles.withdraw__row} ${styles["withdraw__row--margin"]}`}
      >
        <Input
          type="number"
          label={
            activeTab.key === "mgt"
              ? `${t("transfer_amount")} (mgt)`
              : `${t("swap_amount")} (bnb)`
          }
          setValue={setAmount}
          value={amount}
          placeholder="0.00"
        />

        <Button label="Max" callback={handleMax} />
      </div>

      <div className={styles.withdraw__message}>
        {t("you_receive")}{" "}
        <span>
          ~
          {activeTab.key === "mgt"
            ? amount === ""
              ? 0
              : amount
            : (Number(amount) * (user!.user.wallet?.rates?.bnbToUsdt || 0)) /
                user!.user.wallet?.rates?.mgtToUsdt || "0"}{" "}
          mgt
        </span>
      </div>

      <div className={styles.withdraw__price}>
        <div>
          {t("blockchain_fee")}:{" "}
          <span>
            ~{activeTab.key === "mgt" ? "0.00006" : transactionFeeFromSwap} bnb
          </span>
        </div>
      </div>

      <GameButton
        label={t("topup")}
        disabled={dataSendind || amount.trim() === "" || blocked}
        onClick={handleTopup}
      />
    </div>
  );
};

export default TopupGameBalance;
