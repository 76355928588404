import React from "react";

const Third: React.FC = () => {
  return (
    <svg
      width={"44.8rem"}
      height={"38.5rem"}
      viewBox="0 0 448 385"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.183838 385V235.638L14.6957 221.126V70.1722L84.8679 0H125.865L140.528 14.6634H395.035L395.3 14.2087L409.623 0H447.816V362.114L424.93 385H0.183838Z"
        fill="#16081B"
      />

      <clipPath id="third-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M0,1 V0.612 L0.033,0.574 V0.182 L0.19,0 H0.281 L0.314,0.038 H0.882 L0.883,0.037 L0.915,0 H1 V0.941 L0.949,1 H0"></path>
      </clipPath>
    </svg>
  );
};

export default Third;
