import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import Finish from "./icons/Finish";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";

import "swiper/css";
import { isVisible } from "../../../utils/isVisible";

interface RoadmapItem {
  date: string;
  active: boolean;
  list: string[];
  car?: boolean;
}

const roadmapList: RoadmapItem[] = [
  {
    active: true,
    date: "Q4 2023",
    list: ["Added and improved new races", "Airdrops for Beta users"],
  },

  {
    active: true,
    date: "Q1 2024",
    list: [
      "Referral system launched",
      "RU localization added",
      "3D races launched",
    ],
    car: true,
  },

  {
    active: true,
    car: true,
    date: "Q2 2024",
    list: [
      "Marketplace launch",
      "Design overhaul",
      "Development of new tokenomics",
      "Launch of farm application",
      "Addition of game boosts",
      "Addition of daily tasks",
    ],
  },

  {
    active: false,
    date: "Q3 2024",
    list: [
      "SEED phase funding",
      "TGE, IDO conduct",
      "Addition of hybrid staking",
      "Introduction of WEB 3.0 wallets",
      "Update of referral system",
      "AirDrop Phase 1",
    ],
  },

  {
    active: false,
    date: "Q4 2024",
    list: [
      "Adding a lottery",
      "Adding drag racing",
      "Improved 3D racing graphics",
      "Upgrade parts",
      "Adding skins",
      "New Year's race",
      "Airdrop phase 2",
    ],
  },

  {
    active: false,
    date: "Q1-Q3 2025",
    list: [
      "Adding a betting system for race participants",
      "Adding racing teams and guilds",
      "Adding new tracks",
      "Full mobile app",
      "Airdrop phase 3",
    ],
  },

  {
    active: false,
    date: "Q3-Q4 2025",
    list: [
      "Launching voting",
      "Adding online races with direct user participation and influence on the outcome",
      "Launch mini-games in the garage",
      "Adding tournaments",
    ],
  },

  {
    active: false,
    date: "Q1-Q3 2026",
    list: [
      "Improving the racing process",
      "Addition of multichain",
      "Adding new car classes",
      "Influence of car class on the outcome of races depending on the track",
      "Collaborations with industry projects",
    ],
  },

  {
    active: false,
    date: "Q3-Q4 2026",
    list: [
      "Big tournaments between guilds and teams",
      "Battle Pass - introducing themed seasons",
      "Car Auctions",
      "Introduction of F1 races with teams",
      "GambleFi ecosystem development",
    ],
  },

  {
    active: false,
    date: "2027",
    list: [
      "Creating a gallery of new GambleFi gaming projects based on the MGT token.",
      "Collaborations with famous world-class racers",
      "Organizing offline events with top players",
      "Active development of the global GambleFi industry with direct influence of WEB 3.0 users on game outcomes",
    ],
  },
];

const Roadmap: React.FC = () => {
  const firstElementRef = useRef<HTMLDivElement>(null);
  const currentElementRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const swiperRef = useRef<any>(null);

  const [offsetLeft, setOffsetLeft] = useState(0);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (triggerRef.current) {
      var lastScrollTop = 0;

      const handleScrollSection = () => {
        if (isVisible(triggerRef.current!)) {
          var st = window.scrollY || document.documentElement.scrollTop;

          if (st - lastScrollTop > 200) {
            if (st > lastScrollTop) {
              swiperRef.current.slideNext();
            }

            lastScrollTop = st <= 0 ? 0 : st;
          }
        }
      };

      window.addEventListener("scroll", handleScrollSection);
      return () => window.removeEventListener("scroll", handleScrollSection);
    }
  }, [triggerRef]);

  return (
    <section ref={triggerRef} id="roadmap" className={`${styles.roadmap}`}>
      <div className={styles.roadmap__separator}>
        <img src={"/img/landing/roadmap/seperator.png"} alt="separator" />
      </div>

      <div className="landing-container">
        <SectionTitle parentRef="#roadmap" label="Roadmap" />

        <div className={styles.roadmap__bg}>
          <img src={"/img/landing/roadmap/grid.png"} alt="grid" />
        </div>

        <div className={styles.roadmap__content}>
          <div className={styles.roadmap__road}></div>
          <div className={styles.roadmap__line}>
            <Swiper
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              modules={[FreeMode]}
              freeMode={{ enabled: true }}
              onSetTranslate={(e: any) => {
                if (e.translate * -1 > offsetLeft && !finished) {
                  setOffsetLeft(e.translate * -1);
                }

                if (e.progress === 1 && !finished) {
                  setOffsetLeft((prev) => (prev += 900));
                  setFinished(true);
                }
              }}
              breakpoints={{
                0: {
                  slidesPerView: 0.5,
                },

                480: {
                  slidesPerView: 1.5,
                },
                1025: {
                  slidesPerView: 3,
                },
              }}
            >
              {roadmapList.map((item, key) => (
                <SwiperSlide key={key}>
                  <div
                    ref={
                      item.car
                        ? currentElementRef
                        : key === 0
                        ? firstElementRef
                        : undefined
                    }
                    className={`${styles.roadmap__item} ${
                      item.active ? styles.active : ""
                    }`}
                  >
                    {key === 0 && (
                      <div
                        className={`${styles.roadmap__text} ${styles.start}`}
                      >
                        Start
                      </div>
                    )}

                    {key === roadmapList.length - 1 && (
                      <>
                        <div
                          id="finish"
                          className={`${styles.roadmap__text} ${styles.finish}`}
                        >
                          Finish
                        </div>

                        <div className={styles.roadmap__finish}>
                          <Finish />
                        </div>
                      </>
                    )}
                    {key === 0 && (
                      <div
                        style={{ left: offsetLeft }}
                        className={styles.roadmap__car}
                      >
                        <img src={"/img/landing/roadmap/car.png"} alt="car" />
                      </div>
                    )}
                    <div className={styles.roadmap__date}>{item.date}</div>
                    <div className={styles.roadmap__round}></div>
                    <ul className={styles.roadmap__list}>
                      {item.list.map((item, key) => (
                        <li key={key}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
