import React from "react";
import styles from "styles/components/error.module.scss";
import AuthFrame from "./AuthFrame";
import Logo2 from "icons/Logo2";
import { useTranslation } from "react-i18next";

const Error: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.error}>
      <div className={styles.error__bg}>
        <img src="/img/main/bg.webp" alt="City background" />
      </div>

      <div className={styles.error__content}>
        <div className={styles.error__block}>
          <div className={styles.error__frame}>
            <AuthFrame />
          </div>

          <div className={styles.error__logo}>
            <Logo2 />
          </div>

          <div className={styles.error__text}>
            <p>{t("tech_problems")}</p>
            <p>{t("write_to_support")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
