import Auth from "components/Auth";
import Input from "components/FormElements/Input";
import GameButton from "components/Buttons/GameButton";
import React, { useEffect, useState } from "react";
import styles from "styles/components/auth.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Alert from "utils/alert";
import { ROUTES_PATH } from "constants/routesPath";
import { decodeJwt } from "utils/decodeJwt";
import { AuthApi } from "utils/api/Auth";

const ChangePwd: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [resetted, setResetted] = useState(false);
  const [dataSending, setDataSending] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const handleChangePassword = () => {
    setDataSending(true);

    if (password === repeatPassword) {
      if (password.length < 8) {
        Alert("error", t("password_short_error"));
        setDataSending(false);
        return;
      }

      AuthApi.changePassword({ password, sign: params.get("sign")! })
        .then((res) => {
          if (res?.changed) {
            Alert("success", t("password_reset_success"));
            setResetted(true);
          }
        })
        .finally(() => setDataSending(false));
    } else {
      Alert("error", t("password_dont_match"));
      setDataSending(false);
    }
  };

  const handleRedirect = () => {
    Alert("error", t("change_pwd_expired"));
    navigate(ROUTES_PATH.RESET_PASSWORD);
  };

  useEffect(() => {
    if (params.get("sign")) {
      const decodedToken = decodeJwt(params.get("sign")!);

      if (Date.now() >= decodedToken.exp * 1000) {
        handleRedirect();
      }
    } else {
      handleRedirect();
    }
  }, [params]);

  return (
    <Auth>
      {resetted ? (
        <>
          <div className={styles.auth__title}>{t("change_pwd_title")}</div>
          <div className={styles.auth__text}>{t("password_resetted_text")}</div>

          <GameButton
            label={t("sign_in")}
            onClick={() => navigate(ROUTES_PATH.LOGIN)}
          />
        </>
      ) : (
        <>
          <div className={styles.auth__title}>{t("change_pwd_title")}</div>
          <div className={styles.auth__text}>{t("change_pwd_text")}</div>

          <form className={styles.auth__form}>
            <Input
              placeholder={t("enter_new_pwd")}
              type="password"
              value={password}
              setValue={setPassword}
            />

            <div className={styles.auth__message}>
              {t("password_condition")}
            </div>
            <Input
              placeholder={t("repeat_new_pwd")}
              type="password"
              value={repeatPassword}
              setValue={setRepeatPassword}
            />

            <GameButton
              disabled={dataSending}
              label={t("save")}
              onClick={handleChangePassword}
            />
          </form>
        </>
      )}
    </Auth>
  );
};

export default ChangePwd;
