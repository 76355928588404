import React, { useRef } from "react";
import styles from "./style.module.scss";
import { useIsomorphicLayoutEffect } from "../../utils/useIsomorphicLayoutEffect";

import { gsap } from "gsap/dist/gsap";

interface Props {
  label: string;
  main?: boolean;
  className?: string;
  parentRef: string;
}

const SectionTitle: React.FC<Props> = ({
  label,
  className = "",
  main,
  parentRef,
}) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const subtitleRef = useRef<HTMLHeadingElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (titleRef.current && subtitleRef.current) {
      const ctx = gsap.context(() => {
        gsap.set(titleRef.current, { perspective: 400 });
        gsap.set(subtitleRef.current, { perspective: 400 });
        gsap.from(titleRef.current, {
          scrollTrigger: {
            trigger: parentRef,
            start: "-500 0",
          },
          duration: 0.5,
          opacity: 0,
          scale: 1,
          delay: 0.5,
          x: -600,
          transformOrigin: "0% 50% -50",
          ease: "inOut",
          stagger: 0.025,
        });

        gsap.from(subtitleRef.current, {
          scrollTrigger: {
            trigger: parentRef,
            start: "-500 0",
          },
          duration: 0.5,
          opacity: 0,
          scale: 1,
          delay: 1,
          x: 600,
          transformOrigin: "0% 50% -50",
          ease: "inOut",
          stagger: 0.025,
        });
      });

      return () => ctx.revert();
    }
  }, [titleRef, subtitleRef]);

  return (
    <div className={`${styles.title} ${className}`}>
      <h2 ref={titleRef} className={styles.title__main}>
        {label}
      </h2>
      {!main && (
        <h4 ref={subtitleRef} className={styles.title__secondary}>
          {label}
        </h4>
      )}
    </div>
  );
};

export default SectionTitle;
