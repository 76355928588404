import Button from "components/Buttons/Button";
import GameButton from "components/Buttons/GameButton";
import Input from "components/FormElements/Input";
import { IWithdrawDTO, tokenTypes } from "interfaces/Tokens";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { setDisabled } from "redux/reducers/PopupSlice";
import { selectUser } from "redux/selectors/userSelector";
import styles from "styles/components/gamePopup/withdraw.module.scss";
import { TokensApi } from "utils/api/Tokens";

interface WithdrawProps {
  type: tokenTypes;
}

const Withdraw: React.FC<WithdrawProps> = ({ type }) => {
  const { t } = useTranslation();

  const { user } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [dataSendind, setDataSending] = useState(false);
  const [wallet, setWallet] = useState("");
  const [amount, setAmount] = useState("");
  const [blocked, setBlocked] = useState(false);

  const handleMax = () => {
    if (user && user?.user.balance.blockchain) {
      if (type === "mgt") {
        setAmount(String(user?.user.balance.blockchain?.mgt));
        return;
      }

      setAmount(String(user?.user.balance.blockchain?.bnb));
    }
  };

  const handleWithdraw = () => {
    setDataSending(true);
    dispatch(setDisabled(true));

    const data: IWithdrawDTO = {
      amount,
      address: wallet,
    };

    const promise =
      type === "mgt"
        ? TokensApi.withdrawMgt(data, t("withdraw_success"))
        : TokensApi.withdrawBnb(data, t("withdraw_success"));

    promise
      .then(() => {
        setAmount("");
        setWallet("");
      })
      .finally(() => {
        setDataSending(false);
        dispatch(setDisabled(false));
      });
  };

  useEffect(() => {
    if (type === "bnb") {
      if (
        Number(amount) > Number(user?.user.balance.blockchain?.bnb) ||
        Number(amount) <= 0
      )
        setBlocked(true);
      else setBlocked(false);
      return;
    }

    if (type === "mgt") {
      if (
        Number(amount) > Number(user?.user.balance.blockchain?.mgt) ||
        Number(amount) <= 0
      )
        setBlocked(true);
      else setBlocked(false);
    }
  }, [amount, user, type]);

  return (
    <div className={styles.withdraw} onClick={(e) => e.stopPropagation()}>
      <div className={styles.withdraw__title}>
        {t("withdraw")} {type}
      </div>

      <div className={styles.withdraw__row}>
        <div className={styles.withdraw__input}>
          <Input
            placeholder="0xAB1cc8..."
            setValue={setWallet}
            value={wallet}
            label={t("address")}
          />
        </div>
      </div>

      <div
        className={`${styles.withdraw__row} ${styles["withdraw__row--margin"]}`}
      >
        <Input
          type="number"
          label={t("amount")}
          setValue={setAmount}
          value={amount}
          placeholder="0.00"
        />

        <Button label="Max" callback={handleMax} />
      </div>

      <div className={styles.withdraw__text}>
        {t("withdraw_text")}
        (BEP-20)
      </div>

      <div className={styles.withdraw__price}>
        <div>
          {t("blockchain_fee")}: <span>~0.00006 bnb</span>
        </div>
        <div>
          {t("total")}:{" "}
          <span>
            {amount !== "" ? amount : 0} {type}
          </span>
        </div>
      </div>

      <GameButton
        label={t("withdraw")}
        disabled={
          dataSendind || wallet.trim() === "" || amount.trim() === "" || blocked
        }
        onClick={handleWithdraw}
      />
    </div>
  );
};

export default Withdraw;
