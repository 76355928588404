import JoinRace from "components/JoinRace";
import React, { useState, useEffect } from "react";
import { IAdvancedCarItem } from "interfaces/Car";
import { IAdvancedRace, IQualRaceEmulation } from "interfaces/Race";
import { QualRaceApi } from "utils/api/QualRace";
import { formatCars } from "utils/formatCars";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import {
  setHintContent,
  setHintIsOpen,
  setSidebarHidden,
} from "redux/reducers/PopupSlice";
import Racing from "./Racing";
import { useTranslation } from "react-i18next";

const JoinQualification: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const [sended, setSended] = useState(false);
  const [carIds, setCarIds] = useState<number[]>([]);
  const [isSendDisabled, setIsSendDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [race, setRace] = useState<IAdvancedRace | undefined>({
    startIn: null,
    participants: [],
    requirements: {
      participants: 0,
    },
  });
  const [gameRace, setGameRace] = useState<IQualRaceEmulation>();
  const [cars, setCars] = useState<IAdvancedCarItem[]>([]);

  // Fetching race info
  const updateRaceData = () => {
    QualRaceApi.getRace()
      .then((data) => {
        const formattedCars = formatCars(data.cars, carIds);
        setCars(formattedCars);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updateRaceData();

    if (
      !localStorage.getItem("join_qual_hint") &&
      localStorage.getItem("access_token") &&
      dispatch
    ) {
      dispatch(
        setHintContent({
          name: "judy",
          onNext() {
            localStorage.setItem("join_qual_hint", "completed");
            dispatch(setHintIsOpen(false));
          },
          text: t("join_qual_hint_text"),
        })
      );
      dispatch(setHintIsOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Activating `send to race` button
  useEffect(() => {
    if (cars && race && user) {
      let isActiveCars = false;
      const timeCondition = race.startIn === null;

      cars.forEach((item) => {
        if (!item.inRace) isActiveCars = true;
      });

      if (isActiveCars && timeCondition) {
        setIsSendDisabled(false);
      }
    }
  }, [cars, race, user]);

  useEffect(() => {
    if (sended) dispatch(setSidebarHidden(true));
  }, [sended]);

  return (
    <>
      {sended ? (
        <Racing
          type="qual"
          initialRace={gameRace}
          selectedCars={gameRace?.results.length}
        />
      ) : (
        <JoinRace
          cars={cars}
          info={{
            name: t("qual_race"),
            number: race ? race.title : isLoading ? "..." : "",
            prize: `${Math.ceil(carIds.length * 0.3)} ${t("lootboxes")}`,
            sendDisabled: isSendDisabled,
            setCars,
            setRace,
            timeToStart: t("instantly"),
            id: race?.id,
            carIds,
            setCarIds,
            setSended,
            setGameRace,
          }}
          qualification
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default JoinQualification;
