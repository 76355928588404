import React, { memo, useEffect, useState } from "react";
import Item from "./Item";
import GameButton from "components/Buttons/GameButton";
import RaceInfo, { RaceInfoProps } from "./RaceInfo";
import { IAdvancedCarItem } from "interfaces/Car";
import styles from "styles/pages/joinRace/join-race.module.scss";
import scrollStyles from "styles/components/custom-scrollbar.module.scss";
import { ROUTES_PATH } from "constants/routesPath";
import RoundedCheck from "components/RoundedCheck";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Boosts from "./Boosts";

export interface CurrentRaceTabProps {
  cars: IAdvancedCarItem[];
  info: RaceInfoProps;
  qualification?: boolean;
  isLoading: boolean;
}

const CurrentRaceTab: React.FC<CurrentRaceTabProps> = memo(
  ({ cars, info, qualification, isLoading }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const params = useParams();

    const [selectedCar, setSelectedCar] = useState<IAdvancedCarItem | null>(
      null
    );

    const renderMessages = () => {
      if (isLoading) return <div>{t("loading")}...</div>;

      if (info.status === "started") {
        return (
          <>
            <div>{t("race_started_1") + info.timeToStart}</div>
            <GameButton
              label={t("view")}
              disabled={info.timeToStart !== "00m:00s"}
              // href={`/racing/${pathname.split("/")[2]}/${params.id}/view`}
            />
          </>
        );
      }

      if (info.status === "finished") {
        return (
          <>
            <div>{t("race_finished")}</div>
            <GameButton
              label={t("show_results")}
              href={`/racing/${pathname.split("/")[2]}/${
                params.id
              }/view?history=true`}
            />
          </>
        );
      }

      if (qualification) {
        return (
          <div>
            <RoundedCheck big margin /> {t("no_qualify_cars")}
          </div>
        );
      }

      if (info.type === "newbies") {
        return (
          <>
            <div>{t("newbie_race_message")}</div>
            <GameButton label={t("buy_carbox")} href={ROUTES_PATH.SHOP} />
          </>
        );
      }

      return (
        <>
          <div>{t("no_cars")}</div>
          <GameButton label={t("buy_carbox")} href={ROUTES_PATH.SHOP} />
        </>
      );
    };

    useEffect(() => {
      if (info.carIds.length > 0) {
        cars.forEach((car) => {
          if (car.id === info.carIds[info.carIds.length - 1])
            setSelectedCar(car);
        });
      } else setSelectedCar(null);
    }, [info.carIds]);

    return (
      <>
        <RaceInfo
          isLoading={isLoading}
          qualification={qualification}
          {...info}
        />

        <div
          className={`${styles.join_race__wrapper} ${scrollStyles.custom_scrollbar}`}
        >
          <ul className={styles.join_race__grid}>
            {pathname.includes("special") && info.timeToStart === "00m:00s" ? (
              <div className={styles.join_race__no_cars}>
                <div>{t("event_over")}</div>
              </div>
            ) : cars.length > 0 &&
              (info.status === "opened" || info.status === undefined) ? (
              cars.map((item, key) => {
                return (
                  <Item
                    disabled={info.sendDisabled}
                    qualification={qualification}
                    setList={info.setCarIds}
                    list={info.carIds}
                    key={key}
                    disableSelect={
                      pathname.includes("street")
                        ? info.carIds.length ===
                          info.participants?.required! -
                            info.participants?.current!
                        : false
                    }
                    {...item}
                  />
                );
              })
            ) : (
              <div className={styles.join_race__no_cars}>
                {renderMessages()}
              </div>
            )}
          </ul>

          <Boosts selectedCar={selectedCar} />
        </div>
      </>
    );
  }
);

export default CurrentRaceTab;
