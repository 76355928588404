import { SpinePlayer } from "@esotericsoftware/spine-player";
import {
  PRELOADER_ANIMATION_ATLAS,
  PRELOADER_ANIMATION_JSON,
  PRELOADER_ANIMATION_PNG,
} from "./constants";
import { PreloaderProps } from "./types";
import { useEffect, useRef } from "react";
import styles from "./preloader.module.scss";

const playerContainer = document.createElement("div");

const player = new SpinePlayer(playerContainer, {
  jsonUrl: "loading.json",
  atlasUrl: "loading.atlas",
  rawDataURIs: {
    "loading.json": PRELOADER_ANIMATION_JSON,
    "loading.atlas": PRELOADER_ANIMATION_ATLAS,
    "loading.png": PRELOADER_ANIMATION_PNG,
  },
  animation: "animation",
  premultipliedAlpha: false,
  backgroundColor: "#00000000",
  viewport: {
    debugRender: false,
  },
  showControls: false,
  preserveDrawingBuffer: true,
});

export default function Preloader({ loaded }: PreloaderProps) {
  const contRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const cont = contRef.current as HTMLDivElement;
    cont.appendChild(playerContainer);
  }, []);

  useEffect(() => {
    if (loaded) {
      player.pause();
    } else {
      player.play();
    }
  }, [loaded]);

  return (
    <div
      ref={contRef}
      className={` ${styles["spine-player-container"]} ${
        loaded ? styles.done : ""
      }`}
    ></div>
  );
}
