import React from "react";

const First: React.FC = () => {
  return (
    <svg
      width={"46rem"}
      height={"78.2rem"}
      viewBox="0 0 460 782"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M447.287 23.0035L424.361 0.0774689H126.921L102.974 24.0248H36.2379L10.1072 50.1203V192.29L0 202.397V546.323L14.5445 560.868V711.771L84.6961 781.923H125.653L140.338 767.272H398.406L398.653 767.695L412.986 781.923H460V402.463L449.224 391.687L447.287 23.0035Z"
        fill="#16081B"
      />

      <clipPath id="first-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M0.972,0.029 L0.923,0 H0.276 L0.224,0.031 H0.079 L0.022,0.064 V0.246 L0,0.259 V0.699 L0.032,0.717 V0.91 L0.184,1 H0.273 L0.305,0.981 H0.866 L0.867,0.982 L0.898,1 H1 V0.515 L0.977,0.501 L0.972,0.029"></path>
      </clipPath>
    </svg>
  );
};

export default First;
