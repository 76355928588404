import React from "react";
import styles from "styles/components/small-main-card.module.scss";
import { useNavigate } from "react-router";
import { ROUTES_PATH } from "constants/routesPath";
import useSound from "use-sound";
import sound from "sounds/main_menu.mp3";
import { useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import RatingsIcon from "icons/RatingsIcon";
import Fifth from "./Frames/Fifth";
import { useTranslation } from "react-i18next";

const Ratings: React.FC = () => {
  const { t } = useTranslation();

  const { muteSound } = useAppSelector(selectPopup);
  const navigate = useNavigate();
  const [play] = useSound(sound);

  const handleRedirect = () => {
    !muteSound && play();
    navigate(ROUTES_PATH.RATINGS);
  };

  return (
    <div
      onClick={handleRedirect}
      style={{ clipPath: "url(#fifth-clip-path)" }}
      className={`${styles.small_main_card} ${styles["small_main_card--history"]} `}
    >
      <div className={styles.small_main_card__frame}>
        <Fifth />
      </div>

      <div className={styles.small_main_card__content}>
        <div className={styles.small_main_card__img}>
          <img src="/img/main/cards/10.webp" alt="Ratings" />
        </div>

        <div className={styles.small_main_card__icon}>
          <RatingsIcon />
        </div>

        <div className={styles.small_main_card__wrapper}>
          <div className={styles.small_main_card__title}>{t("ratings")}</div>

          <div className={styles.small_main_card__text}>
            {t("check_leaderboard")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ratings;
