import React, { useEffect, useState } from "react";
import Copy from "components/Copy";
import styles from "styles/pages/profile.module.scss";
import { useAppSelector, useAppDispatch } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { setHintContent, setHintIsOpen } from "redux/reducers/PopupSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import ProfileIcon from "icons/ProfileIcon";
import Wrapper from "../Wrapper";
import BalanceInfo from "components/Sidebar/BalanceInfo";
import InGameBalance from "components/Sidebar/InGameBalance";
import MGT from "icons/MGT";
import Input from "components/FormElements/Input";
import Button from "components/Buttons/Button";
import { BackofficeApi } from "utils/api/Backoffice";
import { setBalance } from "redux/reducers/UserSlice";
import Alert from "utils/alert";
import { useTranslation } from "react-i18next";

const Wallet: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { user } = useAppSelector(selectUser);
  const address = user?.user.wallet.address;

  const [count, setCount] = useState("");
  const [dataSending, setDataSending] = useState(false);

  const renderHint = (key: number) => {
    const isDesktop = window.innerWidth > 1023.98;

    switch (key) {
      case 1: {
        dispatch(
          setHintContent({
            name: "judy",
            onNext() {
              renderHint(2);
            },
            text: t("wallet_hint_text_1"),
            hole: isDesktop
              ? {
                  left: "21%",
                  height: "6rem",
                  width: "49rem",
                  top: "14rem",
                }
              : undefined,
            img: isDesktop
              ? {
                  right: "-23rem",
                }
              : undefined,
            content: isDesktop
              ? {
                  left: "38rem",
                }
              : undefined,
          })
        );
        break;
      }
      case 2: {
        dispatch(
          setHintContent({
            name: "judy",
            onNext() {
              renderHint(3);
            },
            text: t("wallet_hint_text_2"),
            hole: isDesktop
              ? {
                  left: "21%",
                  height: "18rem",
                  width: "38rem",
                  top: "20rem",
                }
              : undefined,
            img: isDesktop
              ? {
                  right: "-23rem",
                }
              : undefined,
            content: isDesktop
              ? {
                  left: "38rem",
                }
              : undefined,
          })
        );
        break;
      }
      case 3: {
        dispatch(
          setHintContent({
            name: "judy",
            onNext() {
              renderHint(4);
            },
            text: t("wallet_hint_text_3"),
            hole: isDesktop
              ? {
                  left: "40%",
                  height: "18rem",
                  width: "13rem",
                  top: "20rem",
                }
              : undefined,
            img: isDesktop
              ? {
                  right: "-23rem",
                }
              : undefined,
            content: isDesktop
              ? {
                  left: "38rem",
                }
              : undefined,
          })
        );
        break;
      }
      case 4: {
        dispatch(
          setHintContent({
            name: "judy",
            onNext() {
              localStorage.setItem("wallet_hint", "completed");
              dispatch(setHintIsOpen(false));
            },
            text: t("wallet_hint_text_4"),
            hole: isDesktop
              ? {
                  left: "6.5%",
                  height: "5rem",
                  width: "6rem",
                  top: "62.5rem",
                }
              : {
                  width: "7rem",
                  height: "7rem",
                  top: "2.5rem",
                  left: "22%",
                },
            img: isDesktop
              ? {
                  right: "-23rem",
                }
              : undefined,
            content: isDesktop
              ? {
                  left: "38rem",
                }
              : undefined,
          })
        );
        break;
      }
    }
  };

  const TopupMarketing = () => {
    const { t } = useTranslation();

    setDataSending(true);

    BackofficeApi.topupMarketing(count, t("topup_success"))
      .then((res) => {
        if (res) {
          dispatch(setBalance(res.balance));
          setCount("");
        }
      })
      .catch(() => Alert("error", t("default_error")))
      .finally(() => setDataSending(false));
  };

  useEffect(() => {
    if (!localStorage.getItem("access_token")) navigate(ROUTES_PATH.LOGIN);
    else {
      if (!localStorage.getItem("wallet_hint")) {
        renderHint(1);
        dispatch(setHintIsOpen(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <div className={styles.profile__col}>
        <div className={styles.profile__wrapper}>
          <div className={styles.profile__header}>
            <div className={styles.profile__icon}>
              <ProfileIcon />
            </div>

            <div className={styles.profile__title}>{t("wallet")}</div>
          </div>

          <div className={styles.profile__wallet}>
            <div className={styles.profile__subtitle}>{t("main_wallet")}</div>{" "}
            <span>
              {address ? (
                <>
                  {address.substring(0, 8) + "..." + address.slice(-6)}{" "}
                  <Copy text={address} noMargin />
                </>
              ) : (
                "..."
              )}
            </span>
          </div>

          <BalanceInfo buttons={false} />

          <div className={styles.profile__subtitle}>{t("game_wallet")}</div>
          <InGameBalance buttons={false} />

          {user?.user.balance.marketing && (
            <>
              <div className={styles.profile__subtitle}>{t("marketing")}</div>

              <div className={styles.profile__marketing}>
                <div className={styles.profile__balance}>
                  <MGT />
                  <span>{user?.user.balance.marketing.mgt} MGT</span>
                </div>

                <Input
                  placeholder={t("enter_amount")}
                  value={count}
                  setValue={setCount}
                />

                <Button
                  label={t("topup")}
                  disabled={dataSending}
                  callback={TopupMarketing}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Wallet;
