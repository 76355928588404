import React from "react";

const Finish: React.FC = () => {
  return (
    <svg
      width={30}
      height={50}
      viewBox="0 0 30 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={10} height={10} fill="#3F404F" />
      <rect x={10} y={10} width={10} height={10} fill="#3F404F" />
      <rect x={20} width={10} height={10} fill="#3F404F" />
      <rect y={20} width={10} height={10} fill="#3F404F" />
      <rect x={10} y={30} width={10} height={10} fill="#3F404F" />
      <rect x={20} y={20} width={10} height={10} fill="#3F404F" />
      <rect y={40} width={10} height={10} fill="#3F404F" />
      <rect x={20} y={40} width={10} height={10} fill="#3F404F" />
    </svg>
  );
};

export default Finish;
