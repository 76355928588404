import React from "react";

const ArrowDown: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.4rem"
      height="1.6rem"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.80664 0H8.15945V14.4203H5.80664V0Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.13183L1.66368 7.49579L6.98258 12.7263L12.3014 7.49579L13.9652 9.13183L8.64626 14.3624L8.64786 14.364L6.98418 16L6.98258 15.9984L6.98098 16L5.3173 14.364L5.3189 14.3624L0 9.13183Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowDown;
