import React, { useRef } from "react";
import styles from "./style.module.scss";
import Separator from "./icons/Separator";
import Frame from "./icons/Frame";
import { gsap } from "gsap";
import { useIsomorphicLayoutEffect } from "../../../../utils/useIsomorphicLayoutEffect";

export interface Props {
  title: string;
  text: string;
  img: string;
  id: number;
  parentRef?: string;
}

const Item: React.FC<Props> = ({ img, text, title, id, parentRef }) => {
  const itemRef = useRef<HTMLLIElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (itemRef.current) {
      const ctx = gsap.context(() => {
        if (Number(id) % 2 === 0) {
          gsap.from(itemRef.current, {
            scrollTrigger: {
              trigger: parentRef,
              start: `${id === 1 ? "-300" : id === 2 ? "0" : "450"} 0`,
            },
            duration: 0.4,
            opacity: 0,
            x: 500,
            ease: "inOut",
          });
        } else {
          gsap.from(itemRef.current, {
            scrollTrigger: {
              trigger: parentRef,
              start: `${id === 1 ? "-300" : id === 2 ? "0" : "450"} 0`,
            },
            duration: 0.4,
            opacity: 0,
            x: -500,
            ease: "inOut",
          });
        }

        gsap.from(imageRef.current, {
          scrollTrigger: {
            trigger: parentRef,
            start: `${id === 1 ? "-300" : id === 2 ? "0" : "450"} 0`,
          },
          duration: 0.4,
          opacity: 0,
          x: 500,
          ease: "inOut",
        });
      });

      return () => ctx.revert();
    }
  }, [itemRef]);

  return (
    <li ref={itemRef} className={styles.item}>
      <div className={styles.item__content}>
        <h5 className={styles.item__title}>{title}</h5>

        <div className={styles.item__separator}>
          <Separator />
        </div>

        <p className={styles.item__text}>{text}</p>
      </div>

      <div className={styles.item__picture}>
        <div className={styles.item__frame}>
          <Frame />
        </div>

        <div ref={imageRef} className={styles.item__image}>
          <img src={img} alt="Cyberpunk" />
        </div>
      </div>
    </li>
  );
};

export default Item;
