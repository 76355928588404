import React, { useState } from "react";
import Input from "components/FormElements/Input";
import GameButton from "components/Buttons/GameButton";
import styles from "styles/pages/profile.module.scss";
import { UserApi } from "utils/api/User";
import Alert from "utils/alert";
import { useTranslation } from "react-i18next";

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const handleChange = () => {
    if (newPassword === repeatNewPassword) {
      UserApi.changePassword({ newPassword, oldPassword })
        .then((res) => {
          if (res?.changed) {
            Alert("success", t("pwd_change_success"));
            setOldPassword("");
            setNewPassword("");
            setRepeatNewPassword("");
          }
        })
        .catch(() => Alert("error", t("default_error")));
      return;
    }

    Alert("error", t("pwd_not_match"));
  };

  return (
    <div className={styles.profile__password}>
      <div className={styles.profile__wallet} style={{ marginBottom: "2rem" }}>
        {t("change_pwd")}
      </div>

      <Input
        value={oldPassword}
        setValue={setOldPassword}
        label={t("old_pwd")}
        type="password"
      />

      <Input
        value={newPassword}
        setValue={setNewPassword}
        label={t("new_pwd")}
        type="password"
      />

      <Input
        value={repeatNewPassword}
        setValue={setRepeatNewPassword}
        label={t("repeat_new_pwd")}
        type="password"
      />

      <div className={styles.profile__button} style={{ marginTop: "2.1rem" }}>
        <GameButton
          disabled={
            repeatNewPassword.length < 8 ||
            newPassword.length < 8 ||
            oldPassword.length < 8
          }
          small
          label={t("change")}
          onClick={handleChange}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
