import React from "react";
import { Link } from "react-router-dom";
import styles from "styles/components/button.module.scss";

interface IButtonProps {
  label: string;
  callback?: () => void;
  disabled?: boolean;
  lessPadding?: boolean;
  big?: boolean;
  href?: string;
  target?: string;
}

const Button: React.FC<IButtonProps> = ({
  callback,
  label,
  disabled,
  lessPadding,
  big,
  href,
  target,
}) => {
  const classNames = `${styles.primary_button} ${
    lessPadding ? styles["primary_button--less-padding"] : ""
  } ${big ? styles["primary_button--big"] : ""} ${
    disabled ? styles["primary_button--disabled"] : ""
  }`;

  const renderContent = () => {
    return (
      <>
        <span>{label}</span>
      </>
    );
  };

  return (
    <>
      {href ? (
        <Link
          target={target ? target : "_self"}
          to={href}
          className={classNames}
        >
          {renderContent()}
        </Link>
      ) : (
        <button
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            if (callback) callback();
          }}
          className={classNames}
        >
          {renderContent()}
        </button>
      )}
    </>
  );
};

export default Button;
