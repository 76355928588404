import { IHintContent, INotification, IPopup } from "redux/models/PopupState";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PopupState = IPopup;

const initialState: PopupState = {
  gameIsOpen: false,
  gameContent: null,
  screenDisabled: false,
  hintIsOpen: false,
  hintContent: {
    name: "judy",
    onNext: () => {},
    text: "",
  },
  muteSound: false,
  muteMusic: false,
  notification: {
    message: "",
    open: false,
  },
  sidebarHidden: false,
};

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setGameContent(state, action: PayloadAction<React.ReactNode>) {
      state.gameContent = action.payload;
    },

    setGameIsOpen(state, action: PayloadAction<boolean>) {
      state.gameIsOpen = action.payload;
    },

    setHintIsOpen(state, action: PayloadAction<boolean>) {
      state.hintIsOpen = action.payload;
    },

    setHintContent(state, action: PayloadAction<IHintContent>) {
      state.hintContent = action.payload;
    },

    setDisabled(state, action: PayloadAction<boolean>) {
      state.screenDisabled = action.payload;
    },

    setMuteMusic(state, action: PayloadAction<boolean>) {
      state.muteMusic = action.payload;
    },

    setMuteSound(state, action: PayloadAction<boolean>) {
      state.muteSound = action.payload;
    },

    setNotifications(state, action: PayloadAction<INotification>) {
      state.notification = action.payload;
    },

    setSidebarHidden(state, action: PayloadAction<boolean>) {
      state.sidebarHidden = action.payload;
    },
  },
});

export const {
  setDisabled,
  setGameContent,
  setGameIsOpen,
  setHintContent,
  setHintIsOpen,
  setMuteMusic,
  setMuteSound,
  setNotifications,
  setSidebarHidden,
} = popupSlice.actions;
export default popupSlice.reducer;
