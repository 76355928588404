import { memo } from "react";
import styles from "styles/components/sidebar.module.scss";
import Button from "components/Buttons/Button";
import MGT from "icons/MGT";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { setGameContent, setGameIsOpen } from "redux/reducers/PopupSlice";
import Metamask from "icons/Metamask";
import TopupGameBalance from "components/GamePopup/TopupGameBalance";
import Claim from "components/GamePopup/Claim";
import { useTranslation } from "react-i18next";

interface InGameBalanceProps {
  buttons?: boolean;
}

const InGameBalance: React.FC<InGameBalanceProps> = memo(
  ({ buttons = true }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { user } = useAppSelector(selectUser);

    const handleClaim = () => {
      dispatch(setGameContent(<Claim />));
      dispatch(setGameIsOpen(true));
    };

    const handleTopup = () => {
      dispatch(setGameContent(<TopupGameBalance />));
      dispatch(setGameIsOpen(true));
    };

    return (
      <div
        className={`${styles.sidebar__info_item} ${
          styles["sidebar__info_item--ingame"]
        } ${!buttons ? styles["sidebar__info_item--no-buttons"] : ""}`}
      >
        <div
          className={`${styles.sidebar__info_left} ${
            !buttons ? styles["sidebar__info_left--no-margin"] : ""
          }`}
        >
          <MGT />
          <div>
            {buttons && (
              <span className={styles.sidebar__info_title}>
                {t("game_balance")}
              </span>
            )}

            <span className={styles.sidebar__info_data}>
              {user
                ? `${Number(user.user.balance.ingame.mgt).toFixed(2)} MGT`
                : "..."}

              {!buttons && <Metamask />}
            </span>

            <span
              className={`${styles.sidebar__info_title} ${styles["sidebar__info_title--additional"]}`}
            >
              ~
              {user
                ? Number(user.user.balance.ingame.mgtInUSDT).toFixed(2)
                : "..."}{" "}
              USDT
            </span>
          </div>

          <div className={styles.sidebar__info_buttons}>
            <Button callback={handleTopup} label={t("topup")} />
            <Button callback={handleClaim} label={t("claim")} />
          </div>
        </div>
      </div>
    );
  }
);

export default InGameBalance;
