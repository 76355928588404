import React, { useRef, useEffect } from "react";
import {
  PRELOADER_ANIMATION_ATLAS,
  PRELOADER_ANIMATION_JSON,
  PRELOADER_ANIMATION_PNG,
} from "./variables";
import { SpinePlayer } from "@esotericsoftware/spine-player";

const playerContainer = document.createElement("div");
export const player = new SpinePlayer(playerContainer, {
  jsonUrl: "loot_box.json",
  atlasUrl: "loot_box.atlas",
  rawDataURIs: {
    "loot_box.json": PRELOADER_ANIMATION_JSON,
    "loot_box.atlas": PRELOADER_ANIMATION_ATLAS,
    "loot_box.png": PRELOADER_ANIMATION_PNG,
  },
  backgroundColor: "#00000000",
  alpha: true,
  animation: "idle",
  premultipliedAlpha: true,
  viewport: {
    debugRender: false,
  },
  showControls: false,
  preserveDrawingBuffer: true,
  showLoading: false,
  defaultMix: 0,
});

const FreeLootboxAnim: React.FC = () => {
  const contRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cont = contRef.current as HTMLDivElement;
    cont.appendChild(playerContainer);
  }, []);

  return <div ref={contRef} className="spine-player-container"></div>;
};

export default FreeLootboxAnim;
