import { IInventoryCarItem } from "interfaces/Inventory";
import React from "react";
import styles from "styles/components/car-card.module.scss";
import ImageLazy from "./ImageLazy";
import Button from "./Buttons/Button";
import { ROUTES_PATH } from "constants/routesPath";
import { useTranslation } from "react-i18next";
import Car from "icons/Car";

const CarCard: React.FC<IInventoryCarItem> = ({
  class: className,
  id,
  image,
  level,
  qualified,
  specs,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.card}>
      <div className={styles.card__top}>
        <div className={styles.card__image}>
          <ImageLazy src={image} alt="Car avatar" />
        </div>
      </div>
      <div className={styles.card__bottom}>
        <div className={styles.card__title}>{title}</div>

        <ul className={styles.card__list}>
          <li className={styles.card__item}>
            <div className={styles.card__info}>
              <Car />
            </div>
            <span className={styles.card__label}>{className}</span>
          </li>

          {qualified && (
            <li className={styles.card__item}>
              <div className={styles.card__info}>
                {specs &&
                  specs.acceleration + specs.durability + specs.handling}
              </div>
              <span className={styles.card__label}>{t("chance_pts")}</span>
            </li>
          )}

          <li className={styles.card__item}>
            <div className={styles.card__info}>{level}</div>
            <span className={styles.card__label}>{t("level")}</span>
          </li>
        </ul>

        {!qualified && (
          <Button
            href={ROUTES_PATH.QUAL_RACING}
            label={t("qualify_your_car")}
            big
          />
        )}
      </div>
    </div>
  );
};

export default CarCard;
