import React from "react";

const GameClose: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"4rem"}
      height={"4rem"}
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle
        cx={20}
        cy={20}
        r={19}
        stroke="url(#paint0_linear_313_30)"
        strokeWidth={"0.2rem"}
      />
      <line
        x1="12.2929"
        y1="27.8493"
        x2="27.8492"
        y2="12.2929"
        stroke="white"
        strokeWidth={"0.2rem"}
      />
      <line
        x1="11.7071"
        y1="12.2929"
        x2="27.2635"
        y2="27.8492"
        stroke="white"
        strokeWidth={"0.2rem"}
      />
      <defs>
        <linearGradient
          id="paint0_linear_313_30"
          x1={20}
          y1={0}
          x2={20}
          y2={40}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7854" />
          <stop offset={1} stopColor="#E73B4B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GameClose;
