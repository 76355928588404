import React from "react";

const Bnb: React.FC = () => {
  return (
    <svg
      width={"2.4rem"}
      height={"2.4rem"}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: "2.4rem" }}
    >
      <g clipPath="url(#clip0_23_993)">
        <path
          d="M15.3179 10.5L9.82659 7.75L25.1445 0L40.4624 7.75L34.9711 10.5L25.1445 5.75L15.3179 10.5ZM44.5087 15.25V21L50 18.25V12.5L44.5087 9.75L39.0173 12.5L44.5087 15.25ZM19.6532 12.5L25.1445 15.25L30.6358 12.5L25.1445 9.75L19.6532 12.5ZM40.4624 17.25L34.9711 14.5L25.1445 19.25L15.3179 14.5L9.82659 17.25V23L19.6532 27.75V37.5L25.1445 40.25L30.6358 37.5V27.75L40.4624 23V17.25ZM44.5087 34.75L34.9711 39.5V45.25L50.289 37.5V22.25L44.5087 25V34.75ZM34.9711 35.5L40.4624 32.75V27L34.9711 29.75V35.5ZM19.6532 41.5V47.25L25.1445 50L30.6358 47.25V41.5L25.1445 44.25L19.6532 41.5ZM0 18.25L5.49133 21V15.25L11.2717 12.5L5.78035 9.75L0 12.5V18.25ZM5.78035 25L0.289016 22.25V37.5L15.6069 45.25V39.5L5.78035 34.75V25ZM15.3179 29.75L9.82659 27V32.75L15.3179 35.5V29.75Z"
          fill="#EB4B41"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_993">
          <rect width={50} height={50} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Bnb;
