import React from "react";
import styles from "styles/components/main-card.module.scss";

const QualRace: React.FC = () => {
  return (
    <svg
      width={"10.5rem"}
      height={"11.5rem"}
      viewBox="0 0 105 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles["border-2"]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.9573 3.07924L78.75 12.0427L94.5427 21.2195C101.159 25.0609 105 31.6768 105 39.3597V57.5V75.6402C105 83.3231 101.159 89.939 94.5427 93.9939L78.75 102.957L62.9573 112.134C56.3415 115.976 48.6585 115.976 42.0427 112.134L26.25 102.957L10.4573 93.9939C3.84146 89.939 0 83.3231 0 75.6402V57.5V39.3597C0 31.6768 3.84146 25.0609 10.4573 21.2195L26.25 12.0427L42.0427 3.07924C48.6585 -0.975634 56.3415 -0.975634 62.9573 3.07924ZM42.0427 14.817L20.7012 26.9817C14.0854 30.8231 10.2439 37.439 10.2439 45.3353V69.878C10.2439 77.561 14.0854 84.1768 20.7012 88.0183L42.0427 100.396C48.6585 104.238 56.3415 104.238 62.9573 100.396L84.2988 88.0183C90.9146 84.1768 94.7561 77.561 94.7561 69.878V45.3353C94.7561 37.439 90.9146 30.8231 84.2988 26.9817L62.9573 14.817C56.3415 10.7622 48.6585 10.7622 42.0427 14.817Z"
        fill="white"
      />
      <path
        className={styles["image-2"]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4208 26.3415L60.8233 36.7988C61.0367 37.2256 61.2501 37.8658 61.0367 38.5061C60.6099 39.1463 60.183 39.5732 59.5428 39.7866L56.1282 41.0671L81.0977 45.9756C81.7379 46.189 82.3782 46.6158 82.5916 47.4695C82.805 48.1097 82.805 48.75 82.1647 49.3902L74.4818 58.7805C74.055 59.2073 73.4147 59.4207 72.7745 59.4207C72.1343 59.4207 71.494 59.2073 71.0672 58.5671L68.933 55.5793L71.9208 80.9756C71.9208 81.6158 71.7074 82.2561 71.0672 82.8963C70.4269 83.3232 69.7867 83.3232 68.933 83.1097L57.8355 78.4146C57.1952 78.2012 56.7684 77.7744 56.555 77.1341C56.3416 76.4939 56.555 75.8536 56.7684 75.2134L59.116 72.439L35.8538 82.8963C35.2135 83.3232 34.3599 83.1097 33.7196 82.6829C33.0794 82.2561 32.866 81.6158 32.866 80.9756L33.7196 68.811C33.933 68.1707 34.1465 67.5305 34.7867 67.1036C35.2135 66.8902 35.8538 66.6768 36.494 66.8902L39.9086 68.1707L22.8355 49.3902C22.1952 48.75 22.1952 48.1097 22.4086 47.2561C22.6221 46.6158 23.0489 45.9756 23.9025 45.7622L35.6404 42.9878C36.2806 42.7744 36.9208 42.9878 37.3477 43.4146C37.9879 43.8414 38.2013 44.2683 38.2013 45.1219V48.75L50.7928 26.5549C51.0062 25.9146 51.6465 25.4878 52.5001 25.4878C53.3538 25.4878 53.994 25.7012 54.4208 26.3415Z"
        fill="white"
      />
    </svg>
  );
};

export default QualRace;
