import { SetState } from "interfaces/General";
import React, { useState, useRef, useEffect, memo } from "react";
import styles from "styles/components/form-elements/pin-input.module.scss";

let currentPinIndex = 0;

interface PinInputProps {
  pin: string[];
  setPin: SetState<string[]>;
}

const PinInput: React.FC<PinInputProps> = memo(({ pin, setPin }) => {
  const [activePin, setActivePin] = useState<number>(0);

  const pasted = useRef(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    if (!pasted.current) {
      const { value } = target;
      const newPin: string[] = [...pin];
      newPin[currentPinIndex] = value.substring(value.length - 1);

      if (!value) setActivePin(currentPinIndex - 1);
      else setActivePin(currentPinIndex + 1);

      setPin(newPin);
      return;
    }

    pasted.current = false;
  };

  const handleKeyDown = (
    { key }: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentPinIndex = index;
    if (key === "Backspace") setActivePin(currentPinIndex - 1);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    pasted.current = true;
    const pastedCode = e.clipboardData.getData("Text").substring(0, 4);
    const newPin = [];

    for (let i = 0; i < pastedCode.length; i++) {
      if (!isNaN(Number(pastedCode[i]))) {
        newPin.push(pastedCode[i] ? pastedCode[i] : "");
      }
    }

    if (newPin.length < 4) {
      for (let i = newPin.length; i < 4; i++) {
        newPin.push("");
      }
    }

    setPin([...newPin]);
    setActivePin(pastedCode.length - 1);
  };

  const handleFocus = (key: number) => {
    currentPinIndex = key;
    setActivePin(key);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activePin]);

  return (
    <div className={styles.pin_input}>
      {pin.map((item, key) => (
        <input
          className={`${styles.pin_input__field} ${
            item !== "" ? styles["pin_input__field--filled"] : ""
          }`}
          ref={key === activePin ? inputRef : null}
          value={item}
          key={key}
          type="number"
          onChange={handleChange}
          onKeyDown={(e) => handleKeyDown(e, key)}
          onPaste={handlePaste}
          onFocus={() => handleFocus(key)}
        />
      ))}
    </div>
  );
});

export default PinInput;
