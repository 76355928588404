import React, { useState } from "react";
import styles from "./style.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const NoBrakes: React.FC = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    fetch(`https://api.metaraces.io/backoffice/landing-2-0/waitlist`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    }).then((res) => console.log(res));
  };

  return (
    <section id="brakes" className={styles.brakes}>
      <div className={styles.brakes__bg}>
        <img alt="garage" src={"/img/landing/no-brakes/bg.jpg"} />
      </div>

      <div className={styles.brakes__separator}>
        <img alt="separator" src={"/img/landing/no-brakes/seperator.png"} />
      </div>

      <div className="landing-container">
        <div className={styles.brakes__content}>
          <SectionTitle parentRef="#brakes" label="No brakes" main />

          <div className={styles.brakes__row}>
            <Input
              setValue={setEmail}
              value={email}
              placeholder="Enter your email address..."
            />
            <Button
              label="WaitList"
              className={styles.brakes__button}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoBrakes;
