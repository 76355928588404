import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Layout from "components/Layout";
import { Provider } from "react-redux";
import { setupStore } from "redux/store";

const store = setupStore();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
};

export default App;
