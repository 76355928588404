import React from "react";
import styles from "styles/components/small-main-card.module.scss";
import Sixth from "./Frames/Sixth";
import InventoryIcon from "icons/main-cards/InventoryIcon";
import { useNavigate } from "react-router";
import { ROUTES_PATH } from "constants/routesPath";
import useSound from "use-sound";
import sound from "sounds/main_menu.mp3";
import { useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import { useTranslation } from "react-i18next";

const RaceHistory: React.FC = () => {
  const { t } = useTranslation();

  const { muteSound } = useAppSelector(selectPopup);
  const navigate = useNavigate();
  const [play] = useSound(sound);

  const handleRedirect = () => {
    !muteSound && play();
    navigate(ROUTES_PATH.INVENTORY_PARTS);
  };

  return (
    <div
      onClick={handleRedirect}
      style={{ clipPath: "url(#sixth-clip-path)" }}
      className={`${styles.small_main_card} ${styles["small_main_card--history"]} `}
    >
      <div className={styles.small_main_card__frame}>
        <Sixth />
      </div>

      <div className={styles.small_main_card__content}>
        <div className={styles.small_main_card__img}>
          <img src="/img/main/cards/07.webp" alt="Get reward" />
        </div>

        <div className={styles.small_main_card__icon}>
          <InventoryIcon />
        </div>

        <div className={styles.small_main_card__wrapper}>
          <div className={styles.small_main_card__title}>{t("inventory")}</div>

          <div className={styles.small_main_card__text}>{t("check_stuff")}</div>
        </div>
      </div>
    </div>
  );
};

export default RaceHistory;
