import {
  ICheckRefferalResponse,
  IReferralClaimResponse,
  IRefferal,
} from "interfaces/Referral";
import { API_ROUTES, newApi, newAuthApi } from ".";
import { errorCallback } from "./Auth";

export const ReferralSystemApi = {
  async check(code: string) {
    const response = await newApi
      .post<ICheckRefferalResponse>(API_ROUTES.REFERRAL_CHECK, {
        code,
      })
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async claim() {
    const response = await newAuthApi
      .post<IReferralClaimResponse>(API_ROUTES.REFERRAL_CLAIM)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async get() {
    const response = await newAuthApi
      .get<IRefferal>(API_ROUTES.REFERRAL_GET)
      .then(({ data }) => {
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },
};
