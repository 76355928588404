import React from "react";

const Small: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="275px"
      height="310px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 1.61 1.81"
      imageRendering={"optimizeQuality"}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "\n   \n    .fil0 {fill:#1DD7FF}\n   \n  ",
          }}
        />
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M0.87 0.02l0.34 0.19 0.33 0.19c0.04,0.03 0.07,0.07 0.07,0.12l0 0.39 0 0.38c0,0.05 -0.03,0.1 -0.07,0.12l-0.33 0.19 -0.34 0.19c-0.04,0.03 -0.09,0.03 -0.14,0l-0.2 -0.11 0.23 -0.4 0.1 0.06c0.02,0.01 0.02,0.02 0.02,0.04l-0.01 0.04c0,0.02 0,0.03 0.02,0.04l0.07 0.04c0.02,0.01 0.04,0.01 0.05,-0.01l0.19 -0.34c0.01,-0.02 0.01,-0.04 -0.01,-0.05l-0.73 -0.42c-0.01,-0.01 -0.04,0 -0.05,0.01l-0.19 0.34c-0.01,0.02 -0.01,0.04 0.01,0.05l0.07 0.04c0.01,0.01 0.03,0.01 0.04,0l0.04 -0.03c0.01,-0.01 0.03,-0.01 0.04,-0.01l0.1 0.06 -0.23 0.4 -0.22 -0.13c-0.04,-0.02 -0.07,-0.07 -0.07,-0.12l0 -0.38 0 -0.39c0,-0.05 0.03,-0.09 0.07,-0.12l0.33 -0.19 0.33 -0.19c0.05,-0.03 0.1,-0.03 0.14,0zm0.41 0.99l0 0.01c-0.01,0.02 -0.04,0.02 -0.05,0.01l-0.73 -0.42c-0.02,0 -0.02,-0.03 -0.01,-0.04l0 -0.02c0.01,-0.01 0.04,-0.02 0.05,-0.01l0.73 0.42c0.02,0.01 0.02,0.03 0.01,0.05zm0.08 -0.12l0.05 -0.1c0.01,-0.02 0.01,-0.04 -0.01,-0.05l-0.73 -0.42c-0.02,-0.01 -0.04,0 -0.05,0.01l-0.05 0.1c-0.01,0.02 -0.01,0.04 0.01,0.05l0.73 0.42c0.01,0.01 0.04,0 0.05,-0.01zm-0.58 0.08c0.05,0.03 0.07,0.09 0.04,0.15 -0.03,0.05 -0.09,0.06 -0.14,0.03 -0.05,-0.02 -0.07,-0.09 -0.04,-0.14 0.03,-0.05 0.09,-0.07 0.14,-0.04z"
        />
      </g>
    </svg>
  );
};

export default Small;
