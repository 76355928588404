import React from "react";
import styles from "styles/pages/main.module.scss";
import StreetRace from "./StreetRace";
import MainContent from "./MainContent";
import LegalRace from "./LegalRace";
import FreeRace from "./FreeRace";
import SmoothImage from "components/SmoothImage";
import { mainBgHash } from "constants/blurhashes";
import SpecialEvents from "./SpecialEvents";

interface MainProps {
  streetRace?: boolean;
  legalRace?: boolean;
  freeRace?: boolean;
  specialRace?: boolean;
}

const Main: React.FC<MainProps> = ({
  legalRace,
  streetRace,
  freeRace,
  specialRace,
}) => {
  const renderContent = () => {
    if (streetRace) return <StreetRace />;

    if (legalRace) return <LegalRace />;

    if (freeRace) return <FreeRace />;

    if (specialRace) return <SpecialEvents />;

    return <MainContent />;
  };

  return (
    <div
      className={`${styles.main} ${streetRace ? styles["main--street"] : ""}`}
    >
      <div className={styles.main__bg}>
        <SmoothImage
          src="/img/main/bg.webp"
          alt="Main background"
          hash={mainBgHash}
        />
      </div>

      {renderContent()}
    </div>
  );
};

export default Main;
