import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  memo,
  useMemo,
} from "react";
import { useBridge } from "common/bridge";
import styles from "styles/components/sidebar.module.scss";
import Arrow from "icons/Arrow";
import { Link, useLocation } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import FreeIcon from "icons/FreeIcon";
import Gift from "icons/Gift";
import { useTranslation } from "react-i18next";

interface INavItem {
  label: string;
  id: string;
  active: boolean;
  href: string;
  free?: boolean;
  gift?: boolean;
}

const Navigation: React.FC = memo(() => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const { user } = useAppSelector(selectUser);

  const arrowRef = useRef() as any;

  const [navList, setNavList] = useState<INavItem[]>([]);
  const [activeElement, setActiveElement] = useState<number | null>(0);

  const defaultList = useMemo(
    () => [
      {
        label: t("main"),
        id: "1",
        active: true,
        href: ROUTES_PATH.MAIN,
      },

      {
        label: t("profile"),
        id: "2",
        active: false,
        href: ROUTES_PATH.PROFILE,
        // gift: true,
      },

      {
        label: t("referral_program"),
        id: "8",
        active: false,
        href: ROUTES_PATH.INVITE_FRIENDS,
      },

      {
        label: t("inventory"),
        id: "3",
        active: false,
        href: ROUTES_PATH.INVENTORY_CARS,
      },

      {
        label: t("shop"),
        id: "4",
        active: false,
        href: ROUTES_PATH.SHOP,
        free: Number(user?.user.balance.ingame.lootbox) > 0,
      },

      {
        label: t("garage"),
        id: "5",
        active: false,
        href: ROUTES_PATH.GARAGE,
      },

      {
        label: t("ratings"),
        id: "6",
        active: false,
        href: ROUTES_PATH.RATINGS,
      },

      {
        label: t("how_to_play"),
        id: "7",
        active: false,
        href: ROUTES_PATH.HOW_TO_PLAY,
      },
    ],
    [t]
  );

  const changePixiRoute = () => {
    if (pathname === ROUTES_PATH.GARAGE) {
      useBridge.actions.gotoGarage();
    }
  };

  const toggleNavState = useCallback(
    (e: React.MouseEvent<HTMLLIElement>, id: number) => {
      let newArr = [...navList];

      arrowRef.current.style.top = e.currentTarget.offsetTop + "px";

      newArr.forEach((item, key) => {
        if (id === key) {
          item.active = true;
          setActiveElement(id);
          return;
        }

        item.active = false;
      });

      setNavList(newArr);
    },
    [arrowRef, navList]
  );

  useEffect(() => {
    changePixiRoute();

    let newArr = [...navList];

    newArr.forEach((item, key) => {
      item.active = false;

      if (item.href === ROUTES_PATH.INVENTORY_PARTS) {
        if (
          pathname === ROUTES_PATH.INVENTORY_LOOTBOXES ||
          pathname === ROUTES_PATH.INVENTORY_CARBOXES
        ) {
          item.active = true;
          setActiveElement(key);
        }
      }

      if (item.href === pathname) {
        item.active = true;
        setActiveElement(key);
      }
    });

    setNavList(newArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (activeElement) {
      const activeEl = navList.filter((item) => item.active)[0];
      const element = document.querySelector(`#nav-item-${activeEl.id}`)!;

      // @ts-ignore
      arrowRef.current.style.top = element.offsetTop + "px";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeElement]);

  useEffect(() => {
    let activeFlag = false;

    navList.forEach((item) => {
      if (item.active) activeFlag = true;
    });

    if (!activeFlag) setActiveElement(null);
  }, [navList]);

  useEffect(() => {
    if (Number(user?.user.balance.ingame.lootbox) > 0) {
      const newNavList = [...navList];

      newNavList.forEach((item) => {
        if (item.href === ROUTES_PATH.SHOP) item.free = true;
      });

      setNavList(newNavList);
    } else {
      const newNavList = [...navList];

      newNavList.forEach((item) => {
        if (item.href === ROUTES_PATH.SHOP) item.free = false;
      });

      setNavList(newNavList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setNavList(defaultList);
  }, [t]);

  return (
    <nav className={styles.sidebar__nav}>
      <ul className={styles.sidebar__nav_list}>
        <div className={styles.sidebar__nav_arrow} ref={arrowRef}>
          {activeElement !== null && <Arrow />}
        </div>

        {navList.map((item, key) => {
          return (
            <li
              id={`nav-item-${item.id}`}
              onClick={(e) => toggleNavState(e, key)}
              className={`${styles.sidebar__nav_item} ${
                item.active ? styles["sidebar__nav_item--active"] : ""
              }`}
              key={key}
            >
              <Link to={item.href}>{item.label}</Link>
              {item.free && <FreeIcon />}
              {/* {item.gift && <Gift />} */}
            </li>
          );
        })}
      </ul>
    </nav>
  );
});

export default Navigation;
