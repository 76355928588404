import {
  IGetQualRaceResponse,
  IGetRaceResponse,
  IQualRaceEmulationResponse,
} from "interfaces/Race";
import { API_ROUTES, newAuthApi } from ".";
import { errorCallback } from "./Auth";
import Alert from "utils/alert";

export const QualRaceApi = {
  async getRace() {
    const response = await newAuthApi.get<IGetQualRaceResponse>(
      API_ROUTES.QUAL_RACE
    );

    return response.data;
  },

  async getRaceById(id: string) {
    const response = await newAuthApi.get<IGetRaceResponse>(
      `${API_ROUTES.QUAL_RACE}${id}`
    );

    return response.data;
  },

  async joinToRace(carIds: number[], message: string) {
    const response = await newAuthApi
      .post<IQualRaceEmulationResponse>(API_ROUTES.QUAL_RACE_JOIN, {
        carIds,
      })
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },

  async getRaceEmulation() {
    const { data } = await newAuthApi.get<IQualRaceEmulationResponse>(
      API_ROUTES.QUAL_RACE
    );

    return data;
  },
};
