import CopyIcon from "icons/CopyIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/components/copy.module.scss";
import Alert from "utils/alert";

interface CopyProps {
  withText?: boolean;
  noMargin?: boolean;
  input?: boolean;
  text: string;
}

const Copy: React.FC<CopyProps> = ({
  withText = false,
  noMargin = false,
  input = false,
  text,
}) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    Alert("success", t("copy_success"));
  };

  return (
    <button
      onClick={handleCopy}
      className={`${styles.copy} ${input ? styles["copy--input"] : ""} ${
        noMargin ? styles["copy--no-margin"] : ""
      }`}
    >
      <CopyIcon />
      {withText && <span>{t("copy_text")}</span>}
    </button>
  );
};

export default Copy;
