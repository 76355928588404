import React from "react";

const NewYearLights: React.FC = () => {
  return (
    <svg
      width={"42.5rem"}
      height={"10.7rem"}
      viewBox="0 0 425 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-5.60582 -15.8905C-6.38512 -17.1747 -5.97255 -18.8259 -4.68899 -19.6056C-3.40542 -20.3853 -1.75513 -19.9725 -0.975824 -18.6883C-0.700776 -18.2296 92.77 140.098 233.32 -4.49295C234.351 -5.57079 236.071 -5.59372 237.148 -4.56175C241.182 -1.14477 345.563 85.6785 419.551 13.8762C420.628 12.8442 422.347 12.8671 423.379 13.922C424.433 14.9999 424.41 16.7198 423.333 17.7747C350.422 88.5451 250.442 13.1194 235.452 1.05677C90.9364 147.574 -5.33077 -15.4089 -5.60582 -15.8905Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.448 85.3115V83.6374C160.448 80.1058 164.001 76.5512 167.531 76.5512H169.204C172.734 76.5512 176.287 80.1058 176.287 83.6374V85.3115C176.287 94.8286 160.448 94.8515 160.448 85.3115ZM170.9 48.2981H165.835V62.0348C158.89 62.1724 160.838 69.2127 160.448 72.8361C160.173 75.4275 155.039 76.5741 155.039 84.6465C155.039 91.5951 161.159 97.8098 167.531 97.8098H169.204C180.091 97.8098 185.89 82.9953 178.074 75.4504C177.295 74.6936 176.516 74.3038 176.31 73.1342C175.691 69.7173 178.097 62.1953 170.9 62.0348V48.2981ZM165.835 71.4831V67.424H170.9V71.4831C169.365 71.1161 167.416 70.8639 165.835 71.4831Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M265.683 56.2328V54.5358C265.683 51.0042 269.235 47.4496 272.765 47.4496H274.438C277.968 47.4496 281.544 51.0042 281.544 54.5358V56.2328C281.544 65.7499 265.683 65.7729 265.683 56.2328ZM276.135 23.7372H271.069V32.9332C264.147 33.0937 266.072 40.1341 265.683 43.7574C265.408 46.3488 260.273 47.4725 260.273 55.5449C260.273 62.4935 266.416 68.7082 272.765 68.7082H274.438C285.326 68.7082 291.148 53.9166 283.309 46.3488C282.529 45.615 281.773 45.2251 281.567 44.0556C280.948 40.6386 283.355 33.0937 276.135 32.9332V23.7372ZM271.069 42.3815V38.3453H276.135V42.3815C274.599 42.0375 272.651 41.7852 271.069 42.3815Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4874 69.7861V68.112C28.4874 64.5804 32.0401 61.0258 35.5699 61.0258H37.2431C40.7729 61.0258 44.3257 64.5804 44.3257 68.112V69.7861C44.3257 79.3032 28.4874 79.3261 28.4874 69.7861ZM38.9393 26.5121H33.8738V46.5094C26.9288 46.647 28.8771 53.6873 28.4874 57.3107C28.2124 59.9021 23.0781 61.0488 23.0781 69.1211C23.0781 76.0697 29.198 82.2844 35.5699 82.2844H37.2431C48.1305 82.2844 53.9295 67.4699 46.1135 59.925C45.3342 59.1683 44.5549 58.7784 44.3486 57.6088C43.7526 54.1919 46.1364 46.6699 38.9393 46.5094V26.5121ZM33.8738 55.9577V51.8986H38.9393V55.9577C37.4036 55.5908 35.4553 55.3385 33.8738 55.9577Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.862 72.7903V71.0933C379.862 67.5617 383.415 64.0071 386.945 64.0071H388.618C392.148 64.0071 395.701 67.5617 395.701 71.0933V72.7903C395.701 82.3074 379.862 82.3303 379.862 72.7903ZM390.314 40.2947H385.249V49.4907C378.304 49.6512 380.252 56.6916 379.862 60.3149C379.587 62.9063 374.453 64.03 374.453 72.1253C374.453 79.0739 380.573 85.2657 386.945 85.2657H388.618C399.506 85.2657 405.304 70.4741 397.488 62.9063C396.709 62.1725 395.93 61.7826 395.724 60.6131C395.128 57.1961 397.511 49.6512 390.314 49.4907V40.2947ZM385.249 58.939V54.9028H390.314V58.939C388.779 58.595 386.83 58.3427 385.249 58.939Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.539 59.1224H100.13V70.9557C94.652 70.9557 95.0417 74.9689 95.0875 79.395C95.1334 85.0364 92.0391 84.1879 92.0391 89.1643C92.0391 94.049 94.6291 101.387 97.8609 104.621C102.881 109.689 107.831 105.813 110.49 100.883C112.828 96.5257 115.304 89.3707 112.163 84.2338C110.628 81.757 110.559 83.3394 110.582 79.395C110.628 74.946 111.201 70.9557 105.539 70.9557V59.1224ZM97.4484 90.1733C97.4484 81.6194 108.244 83.2706 108.244 88.8432C108.244 93.6591 104.141 109.139 99.1674 96.8926C98.4798 95.1956 97.4484 92.4437 97.4484 90.1733ZM100.474 76.0009H105.539V79.051H100.474V76.0009Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.446 14.7476H213.036V29.0805C207.558 29.0805 207.948 33.1167 207.994 37.5427C208.04 43.1841 204.945 42.3356 204.945 47.312C204.945 52.1967 207.535 59.5352 210.767 62.7687C215.787 67.8368 220.738 63.9612 223.397 59.0306C225.734 54.6505 228.21 47.5184 225.07 42.3815C223.534 39.9048 223.465 41.4871 223.488 37.5427C223.557 33.0937 224.107 29.0805 218.446 29.0805V14.7476ZM210.355 48.3211C210.355 39.7442 221.15 41.3954 221.15 46.968C221.15 51.8068 217.047 67.2864 212.074 55.0403C211.386 53.3433 210.355 50.5914 210.355 48.3211ZM213.38 34.1486H218.446V37.1987H213.38V34.1486Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M331.368 44.0098H325.958V55.8201C320.457 55.8201 320.87 59.8333 320.916 64.2594C320.961 69.9008 317.867 69.0523 317.867 74.0516C317.867 78.9363 320.457 86.2518 323.666 89.5083C328.686 94.5764 333.66 90.7008 336.318 85.7473C338.656 81.3901 341.132 74.258 337.969 69.1211C336.456 66.6444 336.364 68.2267 336.41 64.2594C336.456 59.8104 337.029 55.8201 331.368 55.8201V44.0098ZM323.254 75.0607C323.254 66.4838 334.049 68.135 334.049 73.7076C334.049 78.5464 329.946 94.026 324.996 81.7799C324.308 80.06 323.254 77.3081 323.254 75.0607ZM326.302 60.8882H331.368V63.9154H326.302V60.8882Z"
        fill="white"
      />
    </svg>
  );
};

export default NewYearLights;
