import { SetState } from "interfaces/General";

type countDownType = string | number;

const MINUTE = 60;
const HOUR = 3600;
const DAY = 86400;

export function startTimer(time: number, setState: SetState<string>) {
  var timer: any;

  function updateCountdown() {
    if (time === 0) {
      clearInterval(timer);
      setState("00m:00s");
      return;
    }

    let minutes: countDownType = Math.floor(
      time > HOUR
        ? time / MINUTE - (Math.floor(time / HOUR) % 24) * MINUTE
        : time / MINUTE
    );
    minutes = minutes < 10 ? "0" + minutes : minutes;

    if (time < MINUTE) minutes = "00";

    time = time - 1;

    if (time < HOUR) {
      let seconds: countDownType = time % MINUTE;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      const data = `${minutes}m:${seconds}s`;

      setState(data);
      return;
    }

    if (time < DAY) {
      let hours: countDownType = Math.floor(time / HOUR);
      hours = hours < 10 ? "0" + hours : hours;

      const data = `${hours}h:${minutes}m`;

      setState(data);
      return;
    }

    let hours: countDownType = Math.floor(time / HOUR) % 24;
    hours = hours < 10 ? "0" + hours : hours;

    let days: countDownType = Math.floor(time / DAY);
    days = days < 10 ? "0" + days : days;

    const data = `${days}d:${hours}h`;

    setState(data);
  }

  const updateInterval = () => {
    if (time > DAY) return 1000 * 60 * 60;

    if (time > HOUR && time < DAY) return 1000 * 60;

    return 1000;
  };

  updateCountdown();
  timer = setInterval(updateCountdown, updateInterval());

  return timer;
}
