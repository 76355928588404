import { API_ROUTES, newAuthApi } from ".";
import { IUserBalance } from "interfaces/User";
import { errorCallback } from "./Auth";
import Alert from "utils/alert";

export const BackofficeApi = {
  async topupMarketing(amount: string, message: string) {
    const response = await newAuthApi
      .post<{ balance: IUserBalance }>(API_ROUTES.BACKOFFICE_TOPUP_MARKETING, {
        amount,
      })
      .then(({ data }) => {
        Alert("success", message);
        return data;
      })
      .catch((e) => errorCallback(e));

    return response;
  },
};
