import callbacks from "./callbacks";
import { TARGET_GAME } from "./constants";
import { EventType, ListenEventResult } from "./types";

const listen = <T>(
  eventType: EventType,
  callback: (result: ListenEventResult<T>) => void,
  once = false
) => {
  const result: ListenEventResult<T> = {
    type: eventType,
  };

  const onMessage = (e: MessageEvent) => {
    const { key, target, value } = e.data;
    if (target === TARGET_GAME && key === eventType) {
      result.data = value;

      callback(result);
      if (once) {
        (window as any).removeEventListener("message", onMessage);
      }
    }
  };
  (window as any).addEventListener("message", onMessage);
  return () => {
    (window as any).removeEventListener("message", onMessage);
  };
};

const message = {
  listen,
};

message.listen(EventType.EVENT_RACE_LOADED, () => {
  callbacks.call("onRaceLoaded");
});
message.listen(EventType.EVENT_RACE_MUTE_MUSIC, (result) => {
  callbacks.call("onRaceMuteMusic", result.data);
});
message.listen(EventType.EVENT_RACE_MUTE_SOUNDS, (result) => {
  callbacks.call("onRaceMuteSound", result.data);
});
message.listen<string>(EventType.EVENT_RACE_PLAY_SOUND, (result) => {
  callbacks.call("onRacePlaySound", result.data);
});
message.listen(EventType.EVENT_RACE_EXIT, () => {
  callbacks.call("onRaceExit");
});

export default message;
