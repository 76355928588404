import React from "react";

const Speed: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"3.1rem"}
      height={"2.5rem"}
      viewBox="0 0 31 25"
      fill="none"
    >
      <path
        d="M15.5022 0C18.292 0 20.9105 0.751288 23.1716 2.06646L20.0772 5.21049C18.679 4.57968 17.131 4.22997 15.5022 4.22997C12.3798 4.22997 9.55387 5.51503 7.5085 7.5932C5.46313 9.67137 4.19836 12.5427 4.19836 15.7151C4.19836 16.8128 4.34822 17.8703 4.62818 18.8675C4.81263 19.5285 5.05471 20.1643 5.3462 20.77H25.6566C25.9481 20.1643 26.1902 19.5285 26.3763 18.8675C26.6546 17.8703 26.8045 16.8128 26.8045 15.7151C26.8045 14.3581 26.5739 13.0564 26.149 11.8483L29.3126 8.63396C30.3715 10.7623 30.9677 13.1668 30.9677 15.7151C30.9677 17.2027 30.7618 18.65 30.3765 20.0238C29.9796 21.4377 29.3917 22.7729 28.6407 23.9994L28.0281 25H2.97476L2.36214 23.9994C1.61283 22.7729 1.02326 21.4377 0.62637 20.0238C0.241011 18.65 0.0351562 17.2027 0.0351562 15.7151C0.0351562 11.3747 1.76598 7.44595 4.5656 4.60311C7.36358 1.75858 11.2303 0 15.5022 0ZM28.4003 3.03193C29.115 3.75812 29.1166 4.94612 28.4003 5.67064L18.7251 15.4608C18.7317 15.5512 18.7366 15.6432 18.7366 15.7352C18.7366 17.5038 17.3253 18.9378 15.5846 18.9378C13.8439 18.9378 12.4325 17.5038 12.4325 15.7352C12.4325 13.9666 13.8439 12.5326 15.5846 12.5326C15.8398 12.5326 16.0869 12.5644 16.324 12.623L25.8032 3.03193C26.5196 2.30741 27.6872 2.30574 28.4003 3.03193Z"
        fill="white"
      />
    </svg>
  );
};

export default Speed;
