import React from "react";
import styles from "./style.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import Item, { Props } from "./Item";

const aboutList: Props[] = [
  {
    img: "/img/landing/about/01.jpg",
    text: "Meta Racing is an exciting new racing game where every race offers cash prizes. You can either take your winnings immediately or invest them in upgrading your car collection.",
    title: "A NEW RACING EXPERIENCE",
    id: 1,
  },

  {
    img: "/img/landing/about/02.jpg",
    text: "Your cars are NFTs, meaning their value increases as you participate in races and upgrade them. Win more often and earn tokens by selling cars on the marketplace (coming soon).",
    title: "NFT CARS",
    id: 2,
  },

  {
    img: "/img/landing/about/03.jpg",
    text: "There are plenty of races to choose from, ranging from Street Races to Grand Prix Races, giving everyone the chance to compete for insane prizes.",
    title: "RACES",
    id: 3,
  },
];

const About: React.FC = () => {
  return (
    <section id="about" className={styles.about}>
      <div className={styles.about__separator}>
        <img src={"/img/landing/about/seperator.png"} alt="Separator" />
      </div>

      <div className="landing-container">
        <SectionTitle parentRef="#about" label="WHAT’S META RACING?" />

        <ul className={styles.about__list}>
          {aboutList.map((item, key) => (
            <Item key={key} {...item} parentRef="#about" />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default About;
