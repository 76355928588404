import InventoryCard from "components/InventoryCard";
import { IInventoryCarItem, IInventoryCard } from "interfaces/Inventory";
import React, { useState, useEffect } from "react";
import styles from "styles/pages/inventory.module.scss";
import scrollbarStyles from "styles/components/custom-scrollbar.module.scss";
import { IRarityItem, carClasses, carDetails } from ".";
import { inventoryTabTypes } from "interfaces/Tabs";
import { IAdvandedInventoryCard } from ".";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import { SetState } from "interfaces/General";
import GameButton from "components/Buttons/GameButton";
import { ROUTES_PATH } from "constants/routesPath";
import { useTranslation } from "react-i18next";
import CarCard from "components/CarCard";

interface ItemsGridProps {
  activeTab: inventoryTabTypes;
  rarityFilters: IRarityItem[];
  activeClass: carClasses;
  activeDetail: carDetails;
  lootboxes: IAdvandedInventoryCard[];
  setLootboxes: SetState<IAdvandedInventoryCard[]>;
  setHideFilters: SetState<boolean>;
  hideFilters: boolean;
  isLoading: boolean;
}

const ItemsGrid: React.FC<ItemsGridProps> = ({
  activeTab,
  rarityFilters,
  activeClass,
  activeDetail,
  lootboxes,
  setLootboxes,
  setHideFilters,
  hideFilters,
  isLoading,
}) => {
  const { t } = useTranslation();

  const { inventory } = useAppSelector(selectUser);

  const [parts, setParts] = useState<IInventoryCard[]>([]);
  const [carboxes, setCarboxes] = useState<IInventoryCard[]>([]);
  const [cars, setCars] = useState<IInventoryCarItem[]>([]);
  const [filteredElements, setFilteredElements] = useState<
    IAdvandedInventoryCard[] | IInventoryCard[]
  >([]);

  const filterByRarity = (array: IInventoryCard[]) => {
    setFilteredElements(() => {
      const newArr: IInventoryCard[] = [];

      array.forEach((item) => {
        rarityFilters.forEach((rarity) => {
          if (rarity.active && rarity.key === item.rarity) newArr.push(item);
        });
      });

      return newArr;
    });
  };

  const filterByClass = () => {
    setFilteredElements((prev) => {
      let array: IInventoryCard[] = [];

      if (activeClass.key !== "all") {
        prev.forEach((item) => {
          if (
            item.className &&
            item.className.toLowerCase() === activeClass.label.toLowerCase()
          ) {
            array.push(item);
          }
        });
      } else {
        array = [...prev];
      }

      return array;
    });
  };

  const filterByDetail = () => {
    setFilteredElements((prev) => {
      let array: IInventoryCard[] = [];

      if (activeDetail.key !== "all") {
        prev.forEach((item) => {
          const part = item.name.toLocaleLowerCase();

          if (part === activeDetail.label.toLowerCase()) {
            array.push(item);
          }
        });
      } else {
        array = [...prev];
      }

      return array.sort((a, b) => {
        return a.equipped === b.equipped ? 0 : a ? 1 : -1;
      });
    });
  };

  useEffect(() => {
    if (parts) {
      switch (activeTab) {
        case "cb":
          setFilteredElements(carboxes);
          break;
        case "dt":
          setFilteredElements(parts);
          filterByRarity(parts);
          filterByClass();
          filterByDetail();
          break;
        case "lb":
          setFilteredElements(lootboxes);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, rarityFilters, activeClass, activeDetail, parts]);

  useEffect(() => {
    if (inventory) {
      const carboxesArr: IInventoryCard[] = [];
      const partsArray: IInventoryCard[] = [];

      if (inventory.parts) {
        inventory.parts.forEach((item, key) => {
          const formattedItem: IInventoryCard = {
            id: key,
            name: item.type,
            type: "detail",
            characteristics: item.specs,
            className: item.class,
            img: item.image,
            rarity: item.rarity,
            equipped: item.equipped,
          };

          partsArray.push(formattedItem);
        });
      }

      setParts(partsArray);

      if (inventory.carboxes) {
        inventory.carboxes.forEach((item) => {
          const formattedItem: IInventoryCard = {
            ...item,
            type: "carbox",
            name: t("carbox"),
          };

          carboxesArr.push(formattedItem);
        });
      }

      setCarboxes(carboxesArr);

      if (inventory.cars) {
        setCars(inventory.cars);
      }
    }
  }, [inventory]);

  useEffect(() => {
    switch (activeTab) {
      case "dt":
        if (parts.length <= 0) {
          setHideFilters(true);
        } else {
          setHideFilters(false);
        }
        break;

      case "lb":
        if (lootboxes.length <= 0) {
          setHideFilters(true);
        } else {
          setHideFilters(false);
        }
        break;

      case "cb":
        if (carboxes.length <= 0) {
          setHideFilters(true);
        } else {
          setHideFilters(false);
        }
        break;
      case "cars":
        if (cars.length <= 0) {
          setHideFilters(true);
        } else {
          setHideFilters(false);
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, parts, lootboxes, carboxes]);

  return (
    <>
      {!hideFilters || isLoading ? (
        <ul
          className={`${styles.inventory__grid} ${
            activeTab === "cars" ? styles["inventory__grid--cars"] : ""
          } ${scrollbarStyles.custom_scrollbar}`}
        >
          {activeTab === "cars"
            ? cars.map((item) => <CarCard key={item.id} {...item} />)
            : filteredElements.map((item) => (
                <InventoryCard
                  setList={activeTab === "lb" ? setLootboxes : undefined}
                  key={item.id}
                  {...item}
                />
              ))}

          {activeTab !== "cars" &&
            filteredElements.length < 18 &&
            Array.from({ length: 18 - filteredElements.length }, (_, i) => (
              <li
                key={i}
                className={`${styles.inventory__grid_item} ${styles["inventory__grid_item--empty"]}`}
              ></li>
            ))}
        </ul>
      ) : (
        <div className={styles.inventory__no_items}>
          <div>
            {t("dont_have")}{" "}
            {activeTab === "dt"
              ? t("details_2")
              : activeTab === "cb"
              ? t("carboxes")
              : activeTab === "cars"
              ? t("cars")
              : t("lootboxes")}
          </div>

          <GameButton
            label={`${t("buy")} ${
              activeTab === "cb" || activeTab === "cars"
                ? t("carbox")
                : t("lootbox")
            }`}
            href={ROUTES_PATH.SHOP}
          />
        </div>
      )}
    </>
  );
};

export default ItemsGrid;
