import React, { useState, useRef } from "react";
import styles from "./style.module.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Line from "./icons/Line";
import { gsap } from "gsap";
import { useIsomorphicLayoutEffect } from "../../../utils/useIsomorphicLayoutEffect";
import Update from "./Update";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const Hero: React.FC = () => {
  const [email, setEmail] = useState("");

  const titleRef = useRef<HTMLHeadingElement>(null);
  const heroCar = useRef<HTMLDivElement>(null);
  const heroRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (titleRef.current) {
      const ctx = gsap.context(() => {
        gsap.set(titleRef.current, { perspective: 400 });

        gsap.from(titleRef.current, {
          duration: 0.4,
          opacity: 0,
          scale: 1,
          delay: 0.8,
          y: -100,
          transformOrigin: "0% 50% -50",
          ease: "inOut",
          stagger: 0.025,
        });

        gsap.from(heroCar.current, {
          scrollTrigger: {
            trigger: "#hero",
            start: "top top",
            scrub: true,
          },
          css: {
            x: 100,
          },
        });
      });

      return () => ctx.revert();
    }
  }, []);

  const handleSubmit = () => {
    fetch(`https://api.metaraces.io/backoffice/landing-2-0/waitlist`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    }).then((res) => console.log(res));
  };

  return (
    <section id="hero" ref={heroRef} className={styles.hero}>
      <div className={styles.hero__bg}>
        <img alt="Cyberpunk city" src={"/img/landing/hero/bg.jpg"} />
      </div>

      <div className={styles.hero__separator}>
        <img alt="Separator" src={"/img/landing/hero/separator.png"} />
      </div>

      <div ref={heroCar} className={styles.hero__car}>
        <img src={"/img/landing/car.png"} alt="Car" />
      </div>

      <div className="landing-container">
        <div className={styles.hero__content}>
          <h1 ref={titleRef} className={styles.hero__title}>
            Hyper reboot 2.0
          </h1>

          <div className={styles.hero__features}>
            <ul className={styles.hero__updates}>
              <Update id={1} label="New design" />
              <li className={styles.hero__bar}></li>
              <Update id={2} label="New features" />
              <li className={styles.hero__bar}></li>
              <Update id={3} label="New tokenomics" />
            </ul>

            <div className={styles.hero__frame}>
              <div className={styles.hero__round}></div>
              <Line />
            </div>
          </div>

          <div className={styles.hero__row}>
            <Input
              setValue={setEmail}
              value={email}
              placeholder="Enter your email address..."
            />

            <Button
              className={styles.hero__button}
              onClick={handleSubmit}
              label="Waitlist"
            />
          </div>

          <p className={styles.hero__label}>
            {/* Мы уведомим вас о важных событиях. Не упустите возможность. */}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
