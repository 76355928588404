import React, { memo, useState } from "react";
import styles from "styles/components/form-elements/input.module.scss";
import formStyles from "styles/components/form-elements/form.module.scss";
import ShowPassword from "icons/ShowPassword";
import HidePassword from "icons/HidePassword";
import { SetState } from "interfaces/General";
import Copy from "components/Copy";
// import HidePassword from "icons/HidePassword";

type inputTypes = "text" | "number" | "password";

interface InputProps {
  placeholder?: string;
  value: string;
  setValue: SetState<string>;
  error?: { message: string } | null;
  type?: inputTypes;
  label?: string;
  id?: string;
  copy?: string;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = memo(
  ({
    placeholder,
    value,
    setValue,
    type = "text",
    error,
    label,
    id = "",
    copy,
    disabled = false,
  }) => {
    const classNames = `${styles.input} ${
      type === "password" ? styles["input--password"] : ""
    } ${error ? styles["input--error"] : ""} ${
      value.trim() !== "" ? styles["input--filled"] : ""
    }`;

    const [currentType, setCurrentType] = useState<inputTypes>(type);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    const togglePasswordState = (type: inputTypes) => setCurrentType(type);

    return (
      <div className={`${formStyles.form__control}`}>
        {label && (
          <label className={formStyles.form__label} htmlFor={id}>
            {label}
          </label>
        )}

        <input
          disabled={disabled}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          id={id}
          className={classNames}
          type={currentType}
          inputMode={currentType === "number" ? "numeric" : undefined}
          pattern={currentType === "number" ? "[0-9]*" : undefined}
        />

        {copy && <Copy input noMargin text={copy} />}

        {error && <div className={styles.input__error}>{error.message}</div>}

        {type === "password" && (
          <div
            className={`${styles.input__show_icon} ${
              label ? styles["input__show_icon--label"] : ""
            }`}
            onClick={() =>
              togglePasswordState(
                currentType === "password" ? "text" : "password"
              )
            }
          >
            {currentType === "password" ? <ShowPassword /> : <HidePassword />}
          </div>
        )}
      </div>
    );
  }
);

export default Input;
