import React, { memo } from "react";
import styles from "styles/components/inventory-card.module.scss";
import Detail from "icons/inventory/Detail";
import Control from "icons/inventory/Control";
import Speed from "icons/inventory/Speed";
import Checkbox from "./FormElements/Checkbox";
import { IInventoryCard } from "interfaces/Inventory";
import { IAdvandedInventoryCard } from "pages/Inventory";
import { SetState } from "interfaces/General";
import RoundedCheck from "./RoundedCheck";
import ImageLazy from "./ImageLazy";
import { useTranslation } from "react-i18next";

interface InventoryCardProps extends IInventoryCard {
  active?: boolean;
  setList?: SetState<IAdvandedInventoryCard[]>;
  open?: boolean;
}

const InventoryCard: React.FC<InventoryCardProps> = memo(
  ({
    rarity,
    name,
    type,
    className,
    characteristics,
    id,
    active,
    setList,
    img,
    equipped,
    open,
  }) => {
    const { t } = useTranslation();

    const classNames = `${styles.inventory_card} ${
      rarity ? styles[`inventory_card--${rarity}`] : ""
    }  ${type === "lootbox" ? styles[`inventory_card--box`] : ""} ${
      equipped ? styles[`inventory_card--equipped`] : ""
    } ${open ? styles[`inventory_card--open`] : ""}`;

    return (
      <li className={classNames}>
        <div className={styles.inventory_card__top}>
          {equipped && (
            <div className={styles.inventory_card__equip}>
              <RoundedCheck />
              <div>{t("equipped")}</div>
            </div>
          )}

          <div className={styles.inventory_card__img}>
            <ImageLazy
              src={
                type === "carbox"
                  ? "/img/inventory/carbox.png"
                  : type === "lootbox"
                  ? "/img/inventory/lootbox.png"
                  : img!
              }
              alt="Inventory item"
            />
          </div>
        </div>

        <div className={styles.inventory_card__bottom}>
          <div className={styles.inventory_card__content}>
            {className && (
              <div className={styles.inventory_card__car}>{className}</div>
            )}
            <div className={styles.inventory_card__name}>{name}</div>
            <div className={styles.inventory_card__rarity}>{rarity}</div>

            {characteristics && (
              <div className={styles.inventory_card__characteristics}>
                {characteristics.durability > 0 && (
                  <div className={styles.inventory_card__attribute}>
                    <Detail /> <span>+{characteristics.durability}</span>
                  </div>
                )}

                {characteristics.acceleration > 0 && (
                  <div className={styles.inventory_card__attribute}>
                    <Speed /> <span>+{characteristics.acceleration}</span>
                  </div>
                )}

                {characteristics.handling > 0 && (
                  <div className={styles.inventory_card__attribute}>
                    <Control /> <span>+{characteristics.handling}</span>
                  </div>
                )}
              </div>
            )}

            {type === "lootbox" && id !== undefined && setList && (
              <div className={styles.inventory_card__checkbox}>
                <Checkbox
                  id={String(id)}
                  //@ts-ignore
                  checked={active}
                  setChecked={setList}
                  listed
                />
              </div>
            )}
          </div>
        </div>
      </li>
    );
  }
);

export default InventoryCard;
