import { Dispatch } from "react";
import {
  setBlockchainBalance,
  setIngameBalance,
} from "redux/reducers/UserSlice";
import { setNotifications } from "redux/reducers/PopupSlice";
import { SetState } from "interfaces/General";

const MAX_ATTEMPTS = 10;

export const socketConnect = (
  dispatch: Dispatch<any>,
  socket: WebSocket,
  setOnline?: SetState<number>
) => {
  let reconnectAttemts = 0;

  socket.onopen = handleOpen;
  socket.onmessage = handleMessage;
  socket.onerror = handleError;
  socket.onclose = handleClose;

  function handleOpen() {
    reconnectAttemts = 0;
  }

  function handleMessage(e: MessageEvent<any>) {
    let msg = JSON.parse(e.data);

    if (msg.error) {
      socket.close();
      return;
    }

    const event = msg.event;

    if (
      event !== "stats" &&
      event !== "balance" &&
      event !== "balance_ingame" &&
      event !== "balance_blockchain"
    )
      dispatch(
        setNotifications({
          message: `<span>${event}</span> ${JSON.stringify(msg.data)}`,
          open: true,
        })
      );
    if (event === "stats" && setOnline) setOnline(msg.data.online);
    if (event === "balance_ingame") {
      dispatch(setIngameBalance(msg.data));
    }
    if (event === "balance_blockchain") {
      dispatch(setBlockchainBalance(msg.data));
    }
  }

  function handleClose() {
    if (reconnectAttemts < MAX_ATTEMPTS) {
      setTimeout(() => {
        socketConnect(dispatch, socket);
      }, 10000);

      reconnectAttemts += 1;
      return;
    }

    console.log("Too much connection tries, please try later");
  }

  function handleError(err: Event) {
    console.error("Socket encountered error: ", err, "Closing socket");
    socket.close();
  }
};
