import Error from "icons/Error";
import Success from "icons/Success";
import { toast } from "react-toastify";
import sound from "sounds/error_key.mp3";

type alertTypes = "success" | "error";

const Alert = (type: alertTypes, message: string) => {
  const audio = new Audio(sound);

  toast.dismiss();

  switch (type) {
    case "error":
      audio.play();
      return toast.error(message, {
        icon: <Error />,
      });
    case "success":
      return toast.success(message, {
        icon: <Success />,
      });
    default:
      return toast.success(message);
  }
};

export default Alert;
