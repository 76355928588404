import React, { useEffect } from "react";
import Hero from "./pages/Home/Hero";
import About from "./pages/Home/About";
import New from "./pages/Home/New";
import Roadmap from "./pages/Home/Roadmap";
import NoBrakes from "./pages/Home/NoBrakes";
import Header from "./layout/Header";
import Footer from "./layout/Footer";

import "./styles/globals.scss";
import TgApp from "./pages/Home/TgApp";

const Landing: React.FC = () => {
  useEffect(() => {
    const el = document.querySelector("html");
    if (el) el.style.fontSize = "62.5%";
  }, []);

  return (
    <div className="landing-app">
      <div className="landing-wrapper">
        <div className="landing-container relative">
          <Header />
        </div>

        <main>
          <Hero />
          <About />
          <New />
          <TgApp />
          <Roadmap />
          <NoBrakes />
        </main>

        <Footer />
      </div>
    </div>
  );
};

export default Landing;
