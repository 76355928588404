import { API_ROUTES, newAuthApi } from ".";
import { IGetRatingResponse } from "interfaces/Ratings";

export const RatingApi = {
  async getRating() {
    const response = await newAuthApi.get<IGetRatingResponse>(
      API_ROUTES.RATING
    );

    return response.data;
  },
};
