import React, { useEffect } from "react";
import styles from "styles/pages/main.module.scss";
import Card from "./Card";
import First from "./Frames/First";
import LegalRaceIcon from "icons/main-cards/LegalRace";
import { ROUTES_PATH } from "constants/routesPath";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectRaces } from "redux/selectors/racesSelector";
import { useNavigate } from "react-router-dom";
import { LegalRaceTypes } from "interfaces/Race";
import GoBack from "components/GoBack";
import { setHintContent, setHintIsOpen } from "redux/reducers/PopupSlice";
import { fetchLegalRacesData } from "redux/asyncActions/fetchLegalRacesData";
import { useTranslation } from "react-i18next";

interface LegalCardProps {
  index: LegalRaceTypes;
}

const selectImage = (index: LegalRaceTypes) => {
  if (index === "newbies") return "/img/street-race/01.webp";
  if (index === "beginners") return "/img/legal-race/02.webp";
  return "/img/legal-race/03.webp";
};

const LegalCard: React.FC<LegalCardProps> = ({ index }) => {
  const { t } = useTranslation();

  const { legal } = useAppSelector(selectRaces);

  return (
    <>
      {legal && legal?.races[index] ? (
        <Card
          key={index}
          race={{
            reward: legal.races[index].fund,
            racers: legal.races[index].participants.current,
            timeToStart: legal.races[index].startIn,
            status: "started",
            raceName: t("legal_racing"),
            avatars: legal.races[index].participants.avatars,
            conditions: legal.races[index].conditions,
          }}
          clipPath="first-clip-path"
          icon={<LegalRaceIcon />}
          img={selectImage(index)}
          title={`${index} race`}
          text={
            index === "newbies"
              ? t("legal_newbiews_text")
              : index === "beginners"
              ? t("legal_beginners_text")
              : t("legal_unlimited_text")
          }
          frame={<First />}
          href={`${ROUTES_PATH.LEGAL_RACING}/${legal.races[index].id}`}
          legal
        />
      ) : (
        <Card
          key={index}
          race={{
            status: "started",
            raceName: t("legal_racing"),
          }}
          clipPath="first-clip-path"
          icon={<LegalRaceIcon />}
          img={selectImage(index)}
          title={`${index} race`}
          text={
            index === "newbies"
              ? t("legal_newbiews_text")
              : index === "beginners"
              ? t("legal_beginners_text")
              : t("legal_unlimited_text")
          }
          frame={<First />}
          legal
        />
      )}
    </>
  );
};

const LegalRace: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const cardsToRender: LegalRaceTypes[] = ["newbies", "beginners", "unlimited"];

  useEffect(() => {
    dispatch(fetchLegalRacesData());

    if (!localStorage.getItem("access_token")) {
      navigate(ROUTES_PATH.LOGIN);
    }

    if (
      !localStorage.getItem("legal_select_hint") &&
      localStorage.getItem("access_token")
    ) {
      dispatch(
        setHintContent({
          name: "john",
          onNext() {
            dispatch(
              setHintContent({
                name: "john",
                onNext() {
                  localStorage.setItem("legal_select_hint", "completed");
                  dispatch(setHintIsOpen(false));
                },
                text: t("legal_select_hint_text_2"),
                blue: true,
              })
            );
          },
          text: t("legal_select_hint_text_1"),
          blue: true,
        })
      );
      dispatch(setHintIsOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GoBack />
      <div className={styles.main__grid}>
        {cardsToRender.map((item) => (
          <LegalCard key={item} index={item} />
        ))}
      </div>
    </>
  );
};

export default LegalRace;
