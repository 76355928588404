import { ROUTES_PATH } from "constants/routesPath";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";

const RefCode: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [cookies, setCookies] = useCookies(["referralcode"]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      setCookies("referralcode", params.code, {
        expires: new Date(new Date().getDate() + 30),
      });
      navigate(ROUTES_PATH.REGISTRATION);
    } else {
      navigate(ROUTES_PATH.MAIN);
    }
  }, []);

  return <></>;
};

export default RefCode;
