import React from "react";
import styles from "styles/components/car-avatar.module.scss";
import CarFrame from "icons/CarFrame";
import ImageLazy from "./ImageLazy";

interface CarAvatarProps {
  width: string;
  height: string;
  id: string;
  img: string;
  loading?: boolean;
  active?: boolean;
  blue?: boolean;
  extraClass?: string;
}

const CarAvatar: React.FC<CarAvatarProps> = ({
  height,
  id,
  img,
  width,
  loading,
  active,
  blue = false,
  extraClass,
}) => {
  return (
    <div
      className={`${styles.car_avatar} ${extraClass ? extraClass : ""}`}
      style={{
        height: height,
        width: width,
      }}
    >
      {!loading && (
        <div className={styles.car_avatar__img}>
          <ImageLazy clipPath={id} src={img} alt="Car preview" />
        </div>
      )}

      <CarFrame
        id={String(id)}
        active={active}
        orange={!active && !blue ? true : false}
        blue={blue}
      />
    </div>
  );
};

export default CarAvatar;
