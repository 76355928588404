import React from "react";
import styles from "styles/components/main-card.module.scss";

const GrandPrix: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"11.6rem"}
      height={"11.3rem"}
      viewBox="0 0 116 113"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5957 0L57.9159 0L76.1577 0C83.9868 0 90.6415 3.84062 94.5561 10.6597L103.716 26.4925L112.876 42.3252C116.791 49.1443 116.791 56.8256 112.876 63.5662L103.716 79.4774L94.5561 95.3102C90.6415 102.051 83.9868 105.97 76.1577 105.97H57.9159H39.5957C31.7666 105.97 25.1119 102.051 21.1973 95.3102L12.0373 79.4774L2.8772 63.5662C-0.959066 56.8256 -0.959066 49.1443 2.8772 42.3252L12.0373 26.4925L21.1973 10.6597C25.1119 3.84062 31.7666 0 39.5957 0ZM27.1474 20.8491L14.6992 42.3252C10.8629 49.1443 10.8629 56.8256 14.6992 63.5662L27.1474 85.1208C30.9837 91.8615 37.7167 95.7021 45.4676 95.7021H70.2859C78.0367 95.7021 84.7697 91.8615 88.6843 85.1208L101.054 63.5662C104.969 56.8256 104.969 49.1443 101.054 42.3252L88.6843 20.8491C84.7697 14.1084 78.0367 10.2678 70.2859 10.2678L45.4676 10.2678C37.7167 10.2678 30.9837 14.1084 27.1474 20.8491Z"
        fill="white"
        className={styles["border-3"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.8273 59.1773V45.3824C96.8273 40.2877 100.507 40.7579 103.639 40.7579V33.1551C99.176 33.0767 96.4358 32.6848 92.9127 36.5254C91.2686 38.3281 89.8594 40.5228 89.8594 44.0499V60.4313C89.8594 66.3098 94.9483 71.4045 99.6458 71.4045H103.639V63.7233C100.507 63.7233 96.8273 64.1936 96.8273 59.1773Z"
        fill="white"
        className={styles["border-3"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6577 52.983C47.6577 51.8857 48.2058 49.691 48.5972 48.9072C53.1381 38.7178 68.0917 42.3233 68.0917 52.983C68.0917 63.4859 53.373 67.2482 48.6755 57.2155C48.2841 56.3533 47.6577 54.1587 47.6577 52.983ZM54.4691 36.2097C53.5296 36.6799 51.6506 36.6016 48.5972 38.7178C47.1097 37.6989 45.857 35.1123 43.5866 35.1123C41.7076 35.1123 39.9852 36.6016 39.9852 38.4827C39.9852 41.0692 42.4905 42.0098 43.5866 43.6558L41.6293 47.8099C41.3944 48.5153 41.3944 48.9856 41.1596 49.6127C39.672 49.6127 37.6365 49.2991 36.3055 49.691C33.3305 50.71 33.3305 55.256 36.3055 56.1966C37.6365 56.6669 39.672 56.3533 41.1596 56.3533C41.3944 56.902 41.3944 57.3723 41.551 57.9993C41.9425 59.3318 42.882 61.1345 43.5866 62.2319C42.6471 63.7211 39.9852 65.0535 39.9852 67.2482C39.9852 69.2077 41.551 70.8537 43.43 70.8537C45.9353 70.8537 46.9531 68.3455 48.5972 67.2482L52.7466 69.2077C53.373 69.4428 53.921 69.4428 54.4691 69.7563C54.4691 71.2456 54.1559 73.2834 54.6256 74.6159C55.6434 77.516 60.106 77.516 61.1238 74.6159C61.5935 73.2834 61.2804 71.2456 61.2804 69.7563C62.8462 68.9725 64.0989 69.2861 67.1522 67.2482C68.6397 68.2671 69.8924 70.8537 72.1628 70.8537C74.0418 70.8537 75.7642 69.2861 75.7642 67.4833C75.7642 64.8968 73.2589 63.8778 72.1628 62.2319C74.1201 59.2534 73.8853 57.9993 74.6682 56.3533C76.0774 56.3533 78.1913 56.6669 79.4439 56.1966C82.419 55.256 82.419 50.71 79.4439 49.691C78.1913 49.2991 76.0774 49.6127 74.6682 49.6127C74.355 49.064 74.355 48.5937 74.1984 47.9667C73.807 46.6342 72.8675 44.8315 72.1628 43.6558C73.1023 42.2449 75.7642 40.9125 75.7642 38.7178C75.7642 36.7583 74.1984 35.1123 72.3977 35.1123C69.8141 35.1123 68.7963 37.6205 67.1522 38.7178C66.3693 38.1692 65.5081 37.6989 64.4903 37.307C63.0028 36.6016 61.8284 36.4448 61.2804 36.2097C61.2804 34.7204 61.5935 32.6826 61.1238 31.3501C60.106 28.45 55.6434 28.45 54.6256 31.3501C54.1559 32.6826 54.4691 34.7204 54.4691 36.2097Z"
        fill="white"
        className={styles["image-1"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.4688 52.512C54.4688 58.2337 61.2801 56.9012 61.2801 53.4525C61.2801 47.7308 54.4688 49.0632 54.4688 52.512Z"
        fill="white"
        className={styles["image-1"]}
      />
      <path
        d="M27.3778 28.0597C27.3778 25.6788 24.6087 23.7488 21.1928 23.7488C17.7769 23.7488 15.0078 25.6788 15.0078 28.0597C15.0078 30.4405 17.7769 32.3706 21.1928 32.3706C24.6087 32.3706 27.3778 30.4405 27.3778 28.0597Z"
        fill="white"
        className={styles["border-3"]}
      />
      <path
        d="M27.3778 77.8302C27.3778 75.4493 24.6087 73.5193 21.1928 73.5193C17.7769 73.5193 15.0078 75.4493 15.0078 77.8302C15.0078 80.211 17.7769 82.1411 21.1928 82.1411C24.6087 82.1411 27.3778 80.211 27.3778 77.8302Z"
        fill="white"
        className={styles["border-3"]}
      />
      <path
        d="M81.3011 112.64C84.0408 112.64 86.2617 109.133 86.2617 104.807C86.2617 100.481 84.0408 96.9734 81.3011 96.9734C78.5614 96.9734 76.3404 100.481 76.3404 104.807C76.3404 109.133 78.5614 112.64 81.3011 112.64Z"
        fill="white"
        className={styles["border-3"]}
      />
      <path
        d="M34.5003 112.641C37.24 112.641 39.4609 109.133 39.4609 104.807C39.4609 100.481 37.24 96.9736 34.5003 96.9736C31.7606 96.9736 29.5396 100.481 29.5396 104.807C29.5396 109.133 31.7606 112.641 34.5003 112.641Z"
        fill="white"
        className={styles["border-3"]}
      />
    </svg>
  );
};

export default GrandPrix;
