import React from "react";
import styles from "styles/components/small-main-card.module.scss";
import useSound from "use-sound";
import sound from "sounds/main_menu.mp3";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import Fifth from "./Frames/Fifth";
import AirdropIcon from "icons/main-cards/AirdropIcon";
import { setGameContent, setGameIsOpen } from "redux/reducers/PopupSlice";
import AirdropContent from "components/GamePopup/AirdropContent";
import { useTranslation } from "react-i18next";

export const airdropInfo = {
  expires: Date.UTC(2024, 4),
};

const Airdrop: React.FC = () => {
  const { t } = useTranslation();

  const { muteSound } = useAppSelector(selectPopup);
  const [play] = useSound(sound);
  const dispatch = useAppDispatch();

  const handleOpenInfo = () => {
    !muteSound && play();
    // dispatch(setGameContent(<AirdropContent />));
    // dispatch(setGameIsOpen(true));
  };

  return (
    <div
      onClick={handleOpenInfo}
      style={{ clipPath: "url(#fifth-clip-path)" }}
      className={`${styles.small_main_card} ${styles["small_main_card--history"]} `}
    >
      <div className={styles.small_main_card__frame}>
        <Fifth />
      </div>

      <div className={styles.small_main_card__content}>
        <div
          className={`${styles.small_main_card__img} ${styles["small_main_card__img--fullsize"]}`}
        >
          <img src="/img/main/cards/11.webp" alt="Ratings" />
        </div>

        <div className={styles.small_main_card__icon}>
          <AirdropIcon />
        </div>

        <div className={styles.small_main_card__wrapper}>
          <div className={styles.small_main_card__prize}>{t("airdrop")}</div>

          <div className={styles.small_main_card__prize_subtext}>
            {t("coming_soon")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Airdrop;
