import React from "react";

const Line: React.FC = () => {
  return (
    <svg
      width={592}
      height={44}
      viewBox="0 0 592 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_22_27050)">
        <path
          d="M5 39L21.5 22.5H570.5L587.5 4.5"
          stroke="#33E9E9"
          strokeWidth="1"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_22_27050"
          x="0.822266"
          y="0.328369"
          width="590.859"
          height="42.8484"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.913725 0 0 0 0 0.913725 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_22_27050"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_22_27050"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Line;
