import React from "react";

const CopyIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"2.4rem"}
      height={"2.5rem"}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.7797 0H20.5063C21.9568 0 23.1434 1.18576 23.1434 2.63524V14.8152C23.1434 16.2646 21.9568 17.4504 20.5063 17.4504H19.9189V8.27479C19.9189 5.49624 17.6433 3.22288 14.8627 3.22288H7.14258V2.63524C7.14258 1.18576 8.32918 0 9.7797 0Z"
        fill="#E73B4B"
      />
      <path
        d="M13.3644 6.65674H2.63712C1.18068 6.65674 0 7.83658 0 9.29198V22.3648C0 23.8202 1.18068 25 2.63712 25H13.3644C14.8208 25 16.0015 23.8202 16.0015 22.3648V9.29198C16.0015 7.83658 14.8208 6.65674 13.3644 6.65674Z"
        fill="#E73B4B"
      />
    </svg>
  );
};

export default CopyIcon;
