import Select from "components/FormElements/Select";
import React, { useState, useEffect, useMemo } from "react";
import styles from "styles/pages/inventory.module.scss";
import RaritySelect from "./RaritySelect";
import ItemsGrid from "./ItemsGrid";
import { rarityTypes } from "interfaces/Rarity";
import { ISelectItem } from "interfaces/Form";
import { carClassType, carPartFilterType } from "interfaces/Car";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import SmallButton from "components/Buttons/SmallButton";
import { ITabItem, inventoryTabTypes } from "interfaces/Tabs";
import Tabs from "components/Tabs";
import { IInventoryCard } from "interfaces/Inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import {
  setGameContent,
  setGameIsOpen,
  setHintContent,
  setHintIsOpen,
} from "redux/reducers/PopupSlice";
import Lootbox from "components/GamePopup/Lootbox";
import { action } from "common/bridge";
import { selectUser } from "redux/selectors/userSelector";
import { fetchInventoryData } from "redux/asyncActions/fetchInventoryData";
import SmoothImage from "components/SmoothImage";
import { inventoryBgHash } from "constants/blurhashes";
import { useTranslation } from "react-i18next";

interface InventoryProps {
  currentTab: inventoryTabTypes;
}

export type carClasses = ISelectItem<carClassType | "All classes">;
export type carDetails = ISelectItem<carPartFilterType>;

export interface IRarityItem {
  active: boolean;
  label: rarityTypes;
  id: string;
  key: rarityTypes;
}

export interface IAdvandedInventoryCard extends IInventoryCard {
  active: boolean;
  id: number;
}

const Inventory: React.FC<InventoryProps> = ({ currentTab }) => {
  const { t } = useTranslation();

  const tabs: ITabItem<inventoryTabTypes>[] = useMemo(
    () => [
      {
        label: t("cars"),
        href: ROUTES_PATH.INVENTORY_CARS,
        key: "cars",
      },

      {
        label: t("details"),
        href: ROUTES_PATH.INVENTORY_PARTS,
        key: "dt",
      },

      {
        label: t("lootboxes"),
        href: ROUTES_PATH.INVENTORY_LOOTBOXES,
        key: "lb",
      },

      {
        label: t("carboxes"),
        href: ROUTES_PATH.INVENTORY_CARBOXES,
        key: "cb",
      },
    ],
    [t]
  );

  const classFilters: carClasses[] = useMemo(
    () => [
      {
        key: "all",
        label: t("all_classes"),
      },
      { key: "m01", label: "Muscle01" },
      { key: "m02", label: "Muscle02" },
      { key: "r01", label: "Road01" },
      { key: "r02", label: "Road02" },
      { key: "s01", label: "Sport01" },
      { key: "s02", label: "Sport02" },
      { key: "v01", label: "Vintage01" },
      { key: "v02", label: "Vintage02" },
    ],
    []
  );

  const detailFilters: carDetails[] = useMemo(
    () => [
      {
        key: "all",
        label: t("all_types"),
      },

      {
        key: "rf",
        label: t("roof"),
      },

      {
        key: "rims",
        label: t("rims"),
      },

      {
        key: "hls",
        label: t("headlights"),
      },

      {
        key: "grl",
        label: t("grill"),
      },

      {
        key: "wg",
        label: t("wing"),
      },

      {
        key: "ss",
        label: t("sideskirts"),
      },

      {
        key: "mrrs",
        label: t("mirrors"),
      },

      {
        key: "fns",
        label: t("fenders"),
      },

      {
        key: "bm",
        label: t("bumper"),
      },

      {
        key: "hd",
        label: t("hood"),
      },

      {
        key: "str",
        label: t("sticker"),
      },
    ],
    []
  );

  const navigate = useNavigate();
  const { inventory } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [hideFilters, setHideFilters] = useState(false);
  const [selected, setSelected] = useState(0);
  const [activeTab, setActiveTab] = useState<inventoryTabTypes>(currentTab);
  const [rarityFilters, setRarityFilters] = useState<IRarityItem[]>([
    {
      label: t("common"),
      active: true,
      id: "common-filter",
      key: "common",
    },

    {
      label: t("uncommon"),
      active: true,
      id: "uncommon-filter",
      key: "uncommon",
    },

    {
      label: t("rare"),
      active: true,
      id: "rare-filter",
      key: "rare",
    },

    {
      label: t("epic"),
      active: true,
      id: "epic-filter",
      key: "epic",
    },

    {
      label: t("legendary"),
      active: true,
      id: "legendary-filter",
      key: "legendary",
    },
  ]);

  const [activeClass, setActiveClass] = useState<carClasses>(classFilters[0]);
  const [activeDetail, setActiveDetail] = useState<carDetails>(
    detailFilters[0]
  );

  const [lootboxes, setLootboxes] = useState<IAdvandedInventoryCard[]>([]);

  const handleOpenLootbox = (all?: boolean) => {
    const ids: number[] = [];

    if (all) {
      inventory?.lootboxes.forEach((item) => ids.push(item.id));
    } else {
      lootboxes.forEach((item) => {
        if (item.active) ids.push(item.id);
      });
    }

    dispatch(setGameContent(<Lootbox ids={ids} />));
    dispatch(setGameIsOpen(true));
  };

  const renderFilters = () => {
    switch (activeTab) {
      case "dt":
        return (
          <>
            <Select
              current={activeDetail}
              list={detailFilters}
              setCurrent={setActiveDetail}
            />
            <Select
              current={activeClass}
              list={classFilters}
              setCurrent={setActiveClass}
            />
          </>
        );
      case "cb":
        return (
          <SmallButton
            onClick={() => {
              navigate(ROUTES_PATH.CARBOXES);
              action.post.gotoCarbox();
            }}
            label={t("open_carboxes")}
          />
        );
      case "lb":
        return (
          <>
            <SmallButton
              onClick={handleOpenLootbox}
              label={`${t("open")} ${selected > 0 ? selected : ""} ${
                selected <= 1 ? t("lootbox") : t("lootboxes")
              }`}
              disabled={selected <= 0}
            />

            <SmallButton
              onClick={() => handleOpenLootbox(true)}
              label={t("open_all_lootboxes")}
            />
          </>
        );
    }
  };

  useEffect(() => {
    let activeCount = 0;

    lootboxes.forEach((item) => {
      if (item.active) activeCount++;
    });

    setSelected(activeCount);

    activeCount = 0;
  }, [lootboxes]);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (!localStorage.getItem("access_token") && navigate) {
      navigate(ROUTES_PATH.LOGIN);
    } else if (dispatch) {
      dispatch(fetchInventoryData());
    }
  }, [navigate, dispatch]);

  useEffect(() => {
    if (inventory) {
      const lootboxesArray: IAdvandedInventoryCard[] = [];

      if (inventory.lootboxes) {
        inventory.lootboxes.forEach((item) => {
          const formattedItem: IAdvandedInventoryCard = {
            ...item,
            type: "lootbox",
            name: t("lootbox"),
            active: false,
          };

          lootboxesArray.push(formattedItem);
        });
      }

      setLootboxes(lootboxesArray);

      setIsLoading(false);
    }
  }, [inventory]);

  useEffect(() => {
    if (
      inventory &&
      inventory.carboxes.length > 0 &&
      activeTab === "cb" &&
      !localStorage.getItem("inventory_hint") &&
      dispatch
    ) {
      dispatch(
        setHintContent({
          name: "judy",
          onNext() {
            localStorage.setItem("inventory_hint", "completed");
            dispatch(setHintIsOpen(false));
          },
          text: t("inventory_hint_text"),
          hole:
            window.innerWidth > 1023.98
              ? {
                  width: "27rem",
                  height: "10rem",
                  left: "19.5%",
                  top: "9rem",
                }
              : {
                  width: "27rem",
                  height: "10rem",
                  left: "1%",
                  top: "22%",
                },
        })
      );
      dispatch(setHintIsOpen(true));
    }
  }, [inventory, activeTab, dispatch]);

  return (
    <div className={styles.inventory}>
      <div className={styles.inventory__bg}>
        <SmoothImage
          src="/img/inventory-bg.webp"
          alt="Cyberpunk city"
          hash={inventoryBgHash}
        />
      </div>

      <div className={styles.inventory__content}>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

        {!hideFilters && (
          <div
            className={`${styles.inventory__filters} ${
              activeTab === "cars" ? styles["inventory__filters--hidden"] : ""
            }`}
          >
            <div className={styles.inventory__selects}>{renderFilters()}</div>

            {activeTab === "dt" && (
              <RaritySelect
                rarityFilters={rarityFilters}
                setRarityFilters={setRarityFilters}
              />
            )}
          </div>
        )}

        <ItemsGrid
          hideFilters={hideFilters}
          lootboxes={lootboxes}
          setLootboxes={setLootboxes}
          activeClass={activeClass}
          activeDetail={activeDetail}
          rarityFilters={rarityFilters}
          activeTab={activeTab}
          setHideFilters={setHideFilters}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default Inventory;
