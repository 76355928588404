import React, { useEffect } from "react";
import MuteMusic from "icons/MuteMusic";
import MuteSound from "icons/MuteSound";
import { setMuteMusic, setMuteSound } from "redux/reducers/PopupSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import styles from "styles/components/sidebar.module.scss";

const Sounds: React.FC = () => {
  const dispatch = useAppDispatch();
  const { muteMusic, muteSound } = useAppSelector(selectPopup);

  useEffect(() => {
    if (localStorage.getItem("muted_sound") && dispatch) {
      dispatch(setMuteSound(true));
    }

    if (localStorage.getItem("muted_music") && dispatch) {
      dispatch(setMuteMusic(true));
    }
  }, [dispatch]);

  const handleMuteMusic = () => {
    dispatch(setMuteMusic(!muteMusic));

    if (muteMusic) {
      localStorage.removeItem("muted_music");
      return;
    }

    localStorage.setItem("muted_music", "true");
  };

  const handleMuteSounds = () => {
    dispatch(setMuteSound(!muteSound));

    if (muteSound) {
      localStorage.removeItem("muted_sound");
      return;
    }

    localStorage.setItem("muted_sound", "true");
  };

  return (
    <>
      <div
        onClick={handleMuteMusic}
        className={`${styles.sidebar__mute_music} ${
          !muteMusic ? styles["sidebar__mute_music--active"] : ""
        }`}
      >
        <MuteMusic />
      </div>

      <div
        onClick={handleMuteSounds}
        className={`${styles.sidebar__mute_sound} ${
          !muteSound ? styles["sidebar__mute_sound--active"] : ""
        }`}
      >
        <MuteSound />
      </div>
    </>
  );
};

export default Sounds;
