import React from "react";

const Medium: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="275px"
      height="310px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 1.58 1.79"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      imageRendering="optimizeQuality"
      // xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "\n   \n    .fil0 {fill:#1DD7FF}\n   \n  ",
          }}
        />
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M0.42 1.37l0.14 -0.15c0,0.02 0,0.04 0.01,0.06 0,0.02 0.02,0.03 0.04,0.03 0.05,0 0.04,-0.07 0.04,-0.13l0.11 0.06c0,0.01 -0.02,0.03 -0.03,0.04 -0.03,0.03 -0.09,0.09 -0.11,0.11 -0.03,0.02 -0.06,0.04 -0.11,0.03 -0.03,0 -0.07,-0.03 -0.09,-0.05zm0.44 -1.35l0.33 0.19 0.32 0.19c0.05,0.02 0.07,0.06 0.07,0.11l0 0.38 0 0.38c0,0.05 -0.02,0.09 -0.07,0.12l-0.32 0.19 -0.33 0.19c-0.04,0.02 -0.09,0.02 -0.14,0l-0.32 -0.19 -0.13 -0.08c0,-0.01 0.01,-0.02 0.02,-0.03 0.01,-0.01 0.03,-0.03 0.04,-0.03l0.05 0.04c0.01,0.02 0.03,0.03 0.05,0.04 0.06,0.03 0.15,0.05 0.22,0l0.32 -0.33c0.02,-0.01 0.05,-0.04 0.04,-0.07 0,-0.03 -0.05,-0.23 -0.05,-0.25 -0.01,-0.01 -0.19,-0.17 -0.2,-0.18 -0.05,-0.05 -0.08,0.02 -0.12,0.05 -0.04,-0.02 -0.05,-0.06 -0.08,-0.06 -0.03,0.01 -0.06,0.05 -0.08,0.07l-0.1 0.1c-0.04,0.04 -0.04,0.04 -0.01,0.09 0.01,0.02 0.02,0.03 0.03,0.04 -0.02,0.03 -0.22,0.22 -0.23,0.24 -0.02,0.04 0.04,0.07 0.06,0.09 -0.02,0.03 -0.07,0.06 -0.09,0.12l-0.07 -0.04c-0.05,-0.03 -0.07,-0.07 -0.07,-0.12l0 -0.38 0 -0.38c0,-0.05 0.02,-0.09 0.07,-0.11l0.33 -0.19 0.32 -0.19c0.05,-0.03 0.1,-0.03 0.14,0zm0.26 0.96l0 0.02c0.01,0.07 0.07,0.13 0.15,0.13l0.01 0c0.07,0 0.14,-0.07 0.14,-0.14 0,-0.05 -0.03,-0.11 -0.06,-0.15 -0.01,-0.02 -0.02,-0.04 -0.04,-0.06 -0.02,-0.03 -0.02,-0.05 -0.06,-0.05 -0.02,0 -0.03,0.03 -0.04,0.05 -0.02,0.02 -0.03,0.04 -0.04,0.06 -0.02,0.03 -0.06,0.1 -0.06,0.14zm-0.3 -0.33l0.16 0.14 0.06 -0.07c0.06,-0.06 0.1,-0.09 0.2,-0.1 0.04,0 0.07,0 0.09,0 0.02,0 0.01,-0.04 0.01,-0.16 0,-0.02 -0.01,-0.04 -0.03,-0.04 -0.28,-0.01 -0.36,0.08 -0.49,0.23z"
        />
      </g>
    </svg>
  );
};

export default Medium;
