import { createAsyncThunk } from "@reduxjs/toolkit";
import { FreeRaceApi } from "utils/api/FreeRace";

export const fetchFreeRacesData = createAsyncThunk(
  "user/fetchFreeRacesData",
  async () => {
    const response = FreeRaceApi.getRaces();

    return response;
  }
);
