import React from "react";

const WhitePaper: React.FC = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      enableBackground="new 0 0 256 256"
      xmlSpace="preserve"
      height={"3rem"}
      width={"3rem"}
    >
      <linearGradient gradientTransform="rotate(-270)" id="linear-gradient-5">
        <stop offset="0%" stopColor="#504153" />
        <stop offset="100%" stopColor="#504153" />
      </linearGradient>
      <g>
        <g>
          <path
            fill="url(#linear-gradient-5)"
            d="M186.6,188.2c-0.1-0.1-0.3-0.3-0.4-0.5c-0.3-0.3-0.6-0.6-0.9-0.8c-0.7-0.5-1.4-0.8-2.3-1c-0.4-0.1-0.8-0.1-1.3-0.1l0,0l0,0c-0.1,0-0.2,0-0.4,0H97.7c-0.1,0-24,0-24.2,0c-3.5,0-6.3,2.8-6.3,6.3c0,0.2,0,0.4,0,0.7c0.1,0.8,0.3,1.6,0.7,2.4c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.3,0.5,0.7,0.8,0.9c0.1,0.1,0.3,0.3,0.5,0.4c0.9,0.8,2.1,1.3,3.4,1.4c0.2,0,0.4,0,0.6,0h23.8h84.5c1.3,0,2.5-0.4,3.5-1.1c0.3-0.2,0.7-0.5,0.9-0.8c0.1-0.1,0.3-0.3,0.4-0.5c0.5-0.6,0.9-1.3,1.2-2.1c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.4,0.1-0.8,0.1-1.3l0,0l0,0C188.1,190.7,187.5,189.3,186.6,188.2z"
          />
          <path
            fill="url(#linear-gradient-5)"
            d="M31.1,16.4C31.1,16.4,31.1,16.4,31.1,16.4c0,0,0,0.6,0,1.7C31.1,17.1,31.1,16.4,31.1,16.4z"
          />
          <path
            fill="url(#linear-gradient-5)"
            d="M186.2,151.8c-0.3-0.3-0.6-0.6-0.9-0.8c-0.7-0.5-1.4-0.8-2.3-1c-0.4-0.1-0.8-0.1-1.3-0.1l0,0l0,0c-0.1,0-0.2,0-0.4,0H97.7c-0.1,0-24,0-24.2,0c-3.5,0-6.3,2.8-6.3,6.3c0,0.2,0,0.4,0,0.7c0.1,0.8,0.3,1.6,0.7,2.4c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.3,0.5,0.6,0.8,0.9c0.1,0.1,0.3,0.3,0.5,0.4c0.9,0.8,2.1,1.3,3.4,1.4c0.2,0,0.4,0,0.6,0h23.8h84.5c3.5,0,6.3-2.8,6.3-6.3C188.1,154.5,187.4,152.9,186.2,151.8z"
          />
          <path
            fill="url(#linear-gradient-5)"
            d="M224.8,76.9c0-0.1,0-0.2,0-0.4c0.2-2.3-0.8-4.8-2.9-6.9c-0.1-0.1-0.2-0.2-0.4-0.3l-35.3-34.3c-0.1-0.1-22.3-21.6-22.4-21.8c-0.5-0.4-0.9-0.8-1.4-1.2c-0.4-0.3-0.8-0.6-1.2-0.9c-1.1-0.8-2.4-1.3-3.9-1.3c-0.1,0-0.2,0-0.4,0H79.6c-0.1,0-35.6,0-35.8,0c-6.2,0-11.3,4.4-12.5,10.2c-0.2,0.8-0.2,1.7-0.3,2.5c0-2,0-3.6,0-4.6c0,1.9,0,4.7,0,4.7l0,0l0,0v0c0,26.9,0,207.8,0,210.5l0,0l0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,6.1,4.4,11.2,10.2,12.4c0.8,0.2,1.7,0.3,2.6,0.3c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0h35.1h133.5c0.7,0,1.3-0.1,1.9-0.3c5.8-1.2,10.2-6.3,10.2-12.5c0-0.1,0-14.3,0-24.7l0,0c0-3.5-2.8-6.3-6.3-6.3c-3.5,0-6.3,2.8-6.3,6.4c0,0.1,0,0.2,0,0.3c0,9.7,0,23.5,0,24.5c0,0-159.8,0-168.4,0c0-1.2,0-210.7,0-210.7h108l0,47.9l0,0c0,6.2,4.4,11.3,10.2,12.5c0.8,0.2,2.4,0.3,2.4,0.3h47.8c0,0,0,77.5,0,88.6c0,0,0,0,0,0.1c0,3.5,2.8,6.4,6.3,6.4c3.5,0,6.3-2.8,6.3-6.4l0,0l0,0l0,0l0,0c0-10.9,0-19,0-19V77.5c0-0.1,0-0.2,0-0.2C224.8,77.1,224.8,77,224.8,76.9z M164.5,70.6c0-1.3,0-38.9,0-38.9l40.8,39C205.3,70.6,168,70.6,164.5,70.6z"
          />
          <path
            fill="url(#linear-gradient-5)"
            d="M97.3,126.6h84.5l0,0c0.4,0,0.9,0,1.3-0.1c0.2,0,0.4-0.1,0.6-0.1c0.8-0.2,1.5-0.7,2.1-1.2c0.1-0.1,0.3-0.3,0.5-0.4c0.3-0.3,0.6-0.6,0.8-0.9c0.7-1,1.1-2.2,1.1-3.5c0-0.9-0.2-1.7-0.5-2.5c-1-2.3-3.2-3.9-5.8-3.9c-0.1,0-0.2,0-0.4,0H97.7c0,0-1,0-2.6,0c-0.2,0-0.5,0-0.8,0c-0.7,0-1.4,0-2.2,0c-0.8,0-1.7,0-2.6,0c-0.2,0-0.4,0-0.5,0c-1.6,0-3.3,0-5,0c-0.4,0-0.7,0-1.1,0c-4.9,0-9.2,0-9.3,0c-2,0-3.7,0.9-4.9,2.3c-0.1,0.1-0.2,0.3-0.4,0.5c-0.2,0.3-0.4,0.7-0.6,1.1c-0.3,0.7-0.5,1.6-0.5,2.5c0,3.5,2.8,6.3,6.3,6.3L97.3,126.6z"
          />
        </g>
      </g>
    </svg>
  );
};

export default WhitePaper;
