import React from "react";
import styles from "styles/components/sidebar.module.scss";
import UserInfo from "./Sidebar/UserInfo";
import BalanceInfo from "./Sidebar/BalanceInfo";
import InGameBalance from "./Sidebar/InGameBalance";
import Connect from "./Sidebar/Connect";
import Sounds from "./Sidebar/Sounds";

interface MobileHeaderProps {
  online: number;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ online }) => {
  return (
    <div className={styles.sidebar__mobile_header}>
      <div className={styles.sidebar__info}>
        {localStorage.getItem("access_token") ? (
          <>
            <UserInfo />

            <BalanceInfo />

            <InGameBalance />
          </>
        ) : (
          <Connect />
        )}
      </div>

      <div className={styles.sidebar__mobile_sounds}>
        <div className={styles.sidebar__online}>{online ? online : "..."}</div>

        <Sounds />
      </div>
    </div>
  );
};

export default MobileHeader;
