import React from "react";

const Large: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="275px"
      height="310px"
      version="1.1"
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 1.56 1.76"
      imageRendering="optimizeQuality"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "\n   \n    .fil0 {fill:#8FD12F}\n   \n  ",
          }}
        />
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          className="fil0"
          d="M0.37 1.41c0,-0.01 0.02,-0.03 0.03,-0.03 0.04,-0.03 0.1,-0.03 0.14,0.01 0.03,0.04 0.03,0.11 -0.02,0.15 -0.04,0.03 -0.1,0.02 -0.14,-0.02 -0.02,-0.02 -0.03,-0.06 -0.02,-0.09 0,0 0.01,0 0.01,-0.01 0,-0.01 0,0 0,-0.01zm0.48 -1.39l0.32 0.18 0.32 0.19c0.04,0.03 0.07,0.07 0.07,0.12l0 0.37 0 0.37c0,0.05 -0.03,0.09 -0.07,0.12l-0.32 0.18 -0.32 0.19c-0.05,0.02 -0.1,0.02 -0.14,0l-0.13 -0.08c0.06,-0.03 0.11,-0.11 0.12,-0.18 0,-0.04 0,-0.03 0.02,-0.07 0.05,0.03 0.09,0.06 0.14,0.05 0.06,-0.02 0.08,-0.09 0.07,-0.14 -0.02,-0.04 -0.07,-0.06 -0.11,-0.09l0.07 -0.11c0.06,0.03 0.13,0.04 0.17,-0.03 0.03,-0.05 0,-0.11 -0.04,-0.14 -0.01,0 -0.03,-0.01 -0.04,-0.01 -0.01,-0.01 -0.02,-0.01 -0.03,-0.01 -0.09,-0.04 -0.21,-0.08 -0.31,-0.12 -0.02,-0.01 -0.05,-0.02 -0.08,-0.03 -0.04,-0.02 -0.1,0 -0.12,0.04 -0.03,0.06 -0.01,0.12 0.04,0.15 0.01,0 0.01,0 0.02,0.01l-0.03 0.05c-0.06,-0.03 -0.13,-0.08 -0.19,0 -0.03,0.04 -0.03,0.11 0.03,0.14l0.06 0.04c-0.02,0.04 -0.02,0.03 -0.05,0.05 -0.06,0.04 -0.1,0.12 -0.1,0.2l-0.15 -0.09c-0.05,-0.03 -0.07,-0.07 -0.07,-0.12l0 -0.37 0 -0.37c0,-0.05 0.02,-0.09 0.07,-0.12l0.32 -0.19 0.32 -0.18c0.04,-0.03 0.09,-0.03 0.14,0zm0.34 0.84c0.03,-0.05 0.01,-0.11 -0.03,-0.14 -0.03,-0.02 -0.37,-0.14 -0.43,-0.16 -0.04,-0.02 -0.06,-0.02 -0.09,-0.02 -0.1,0.02 -0.1,0.15 -0.03,0.19l0.43 0.16c0.03,0.01 0.05,0.03 0.08,0.02 0.02,-0.01 0.06,-0.02 0.07,-0.05zm-0.48 -0.51c-0.03,0.06 -0.01,0.12 0.02,0.14 0.04,0.02 0.41,0.15 0.47,0.18 0.02,0 0.02,0.01 0.04,0.01 0.01,0 0.02,0 0.03,-0.01 0.09,-0.03 0.09,-0.14 0.02,-0.18 -0.03,-0.02 -0.41,-0.16 -0.45,-0.18 -0.03,0 -0.05,-0.01 -0.07,0 -0.02,0.01 -0.05,0.02 -0.06,0.04zm-0.05 0.7c0.02,-0.03 0.08,-0.01 0.05,0.05l-0.03 0.05 -0.06 -0.03 0.04 -0.07z"
        />
      </g>
    </svg>
  );
};

export default Large;
