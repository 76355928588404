import React, { useCallback, useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import DownloadApp from "components/DownloadApp";
import Main from "pages/Main";
import Client from "components/Client";
import Sidebar from "components/Sidebar";
import Shop from "pages/Shop";
import Ratings from "pages/Ratings";
import HowToPlay from "pages/HowToPlay";
import { ROUTES_PATH } from "constants/routesPath";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import GameOverlay from "./GameOverlay";
import GamePopup from "./GamePopup";
import Login from "pages/Login";
import RecoverPwd from "pages/RecoverPwd";
import ChangePwd from "pages/ChangePwd";
import Registration from "pages/Registration";
import Profile from "pages/Profile";
import Inventory from "pages/Inventory";
import { setGameIsOpen, setSidebarHidden } from "redux/reducers/PopupSlice";
import JoinQualification from "pages/JoinQualification";
import Racing from "pages/Racing";
import Hint from "./Hint";
import useSound from "use-sound";
import music_menu from "sounds/music/music_main.mp3";
import music_race from "sounds/music/music_race.mp3";
import music_garage from "sounds/music/music_garage.mp3";
import { selectPopup } from "redux/selectors/popupSelector";
import Notification from "./Notification";
import Burger from "./Burger";
import { useMedia } from "use-media";
import MobileHeader from "./MobileHeader";
import { isPwa } from "utils/isPWA";
import FlipDevice from "./FlipDevice";
import { fetchData } from "utils/fetchData";
import { socketConnect } from "utils/socketConnect";
import { selectUser } from "redux/selectors/userSelector";
import Wallet from "pages/Profile/Wallet";
import JoinStreetRacePage from "pages/JoinStreetRacePage";
import JoinLegalRacePage from "pages/JoinLegalRacePage";
import JoinFreeRacePage from "pages/JoinFreeRacePage";
import JoinSpecialRacePage from "pages/JoinSpecialRacePage";
import InviteFriends from "pages/InviteFriends";
import RefCode from "pages/RefCode";
import Landing from "landing";

const authorizationPages = [
  ROUTES_PATH.CHANGE_PASSWORD,
  ROUTES_PATH.RESET_PASSWORD,
  ROUTES_PATH.REGISTRATION,
  ROUTES_PATH.LOGIN,
  ROUTES_PATH.REMAKE,
];

const AUTH_PATHS = [
  {
    path: ROUTES_PATH.CHANGE_PASSWORD,
    element: <ChangePwd />,
  },

  {
    path: ROUTES_PATH.RESET_PASSWORD,
    element: <RecoverPwd />,
  },

  {
    path: ROUTES_PATH.REGISTRATION,
    element: <Registration />,
  },

  {
    path: ROUTES_PATH.LOGIN,
    element: <Login />,
  },

  {
    path: ROUTES_PATH.REMAKE,
    element: <Landing />,
  },
];

const GAME_PATHS = [
  {
    path: ROUTES_PATH.RATINGS,
    element: <Ratings />,
  },

  {
    path: ROUTES_PATH.MAIN,
    element: <Main />,
  },

  {
    path: ROUTES_PATH.SHOP,
    element: <Shop />,
  },

  {
    path: ROUTES_PATH.PROFILE,
    element: <Profile />,
  },

  {
    path: ROUTES_PATH.HOW_TO_PLAY,
    element: <HowToPlay />,
  },

  {
    path: ROUTES_PATH.INVENTORY_CARS,
    element: <Inventory currentTab="cars" />,
  },

  {
    path: ROUTES_PATH.INVENTORY_PARTS,
    element: <Inventory currentTab="dt" />,
  },

  {
    path: ROUTES_PATH.INVENTORY_LOOTBOXES,
    element: <Inventory currentTab="lb" />,
  },

  {
    path: ROUTES_PATH.INVENTORY_CARBOXES,
    element: <Inventory currentTab="cb" />,
  },

  {
    path: ROUTES_PATH.STREET_RACING,
    element: <Main streetRace />,
  },

  {
    path: ROUTES_PATH.LEGAL_RACING,
    element: <Main legalRace />,
  },

  {
    path: ROUTES_PATH.FREE_RACING,
    element: <Main freeRace />,
  },

  {
    path: ROUTES_PATH.SPECIAL_RACING,
    element: <Main specialRace />,
  },

  {
    path: ROUTES_PATH.STREET_RACING_JOIN,
    element: <JoinStreetRacePage />,
  },

  {
    path: ROUTES_PATH.LEGAL_RACING_JOIN,
    element: <JoinLegalRacePage />,
  },

  {
    path: ROUTES_PATH.FREE_RACING_JOIN,
    element: <JoinFreeRacePage />,
  },

  {
    path: ROUTES_PATH.SPECIAL_RACING_JOIN,
    element: <JoinSpecialRacePage />,
  },

  {
    path: ROUTES_PATH.QUAL_RACING,
    element: <JoinQualification />,
  },

  {
    path: `${ROUTES_PATH.STREET_RACING}/:id/view`,
    element: <Racing type="street" />,
  },

  {
    path: `${ROUTES_PATH.LEGAL_RACING}/:id/view`,
    element: <Racing type="legal" />,
  },

  {
    path: `${ROUTES_PATH.FREE_RACING}/:id/view`,
    element: <Racing type="free" />,
  },

  {
    path: `${ROUTES_PATH.SPECIAL_RACING}/:id/view`,
    element: <Racing type="special" />,
  },

  {
    path: ROUTES_PATH.WALLET,
    element: <Wallet />,
  },

  {
    path: ROUTES_PATH.INVITE_FRIENDS,
    element: <InviteFriends />,
  },

  {
    path: ROUTES_PATH.REMAKE,
    element: <Landing />,
  },
];

type pauseTypes = "garage" | "main" | "race" | "none";

const Layout: React.FC = () => {
  const [playMain, { stop: stopMain, pause: pauseMain }] = useSound(
    music_menu,
    { volume: 0.5 }
  );
  const [playRace, { stop: stopRace, pause: pauseRace }] = useSound(
    music_race,
    { volume: 0.3 }
  );
  const [playGarage, { stop: stopGarage, pause: pauseGarage }] = useSound(
    music_garage,
    { volume: 0.5 }
  );

  const isMobile = useMedia("(max-width: 1023.98px)");
  const isPortrait = useMedia("(max-width: 430px)");
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);
  const { muteMusic, sidebarHidden } = useAppSelector(selectPopup);

  const firstConnect = useRef(false);
  const socketRef = useRef<WebSocket | null>(null);

  const [online, setOnline] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isPWA, setIsPWA] = useState(false);

  const stopMusic = useCallback(
    (pause: pauseTypes) => {
      pause === "main" ? pauseMain() : stopMain();
      pause === "garage" ? pauseGarage() : stopGarage();
      pause === "race" ? pauseRace() : stopRace();
    },
    [pauseMain, pauseGarage, pauseRace, stopMain, stopGarage, stopRace]
  );

  const renderRoutes = () => {
    return (
      <>
        <Route path={ROUTES_PATH.GARAGE} element={<Client />} />
        <Route path={ROUTES_PATH.CARBOXES} element={<Client />} />
        <Route path={ROUTES_PATH.REF_CODE} element={<RefCode />} />
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </>
    );
  };

  const playMusic = useCallback(() => {
    if (pathname.includes(ROUTES_PATH.GARAGE)) {
      stopMusic("garage");
      playGarage();
      return;
    }

    if (pathname.includes(ROUTES_PATH.RACING)) {
      stopMusic("race");
      playRace();
      return;
    }

    stopMusic("main");
    playMain();
    return;
  }, [pathname, stopMusic, playGarage, playRace, playMain]);

  const toggleMenuState = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  useEffect(() => {
    setMenuIsOpen(false);
    window.scrollTo(0, 0);

    dispatch(setGameIsOpen(false));

    if (pathname.includes("view")) {
      dispatch(setSidebarHidden(true));
    } else {
      dispatch(setSidebarHidden(false));
    }

    if (!authorizationPages.includes(pathname)) {
      document.body.classList.add("lock");
    } else {
      document.body.classList.remove("lock");
    }

    if (pathname === ROUTES_PATH.REMAKE) {
      document.body.classList.add("landing");
    } else {
      document.body.classList.remove("landing");
    }

    if (!muteMusic) {
      playMusic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (muteMusic && stopMusic) stopMusic("none");
    else if (playMusic) playMusic();
  }, [muteMusic, stopMusic, playMusic]);

  useEffect(() => {
    setIsPWA(isPwa());
    fetchData(dispatch, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!firstConnect.current) {
      if (user) {
        if (socketRef.current) {
          socketRef.current.close();
          socketRef.current = null;
        }

        socketRef.current = new WebSocket(
          `${process.env.REACT_APP_WDS_URL}?jwt=${localStorage.getItem(
            "access_token"
          )}`
        );
        socketConnect(dispatch, socketRef.current, setOnline);
        firstConnect.current = true;
      } else {
        if (socketRef.current) {
          socketRef.current.close();
          socketRef.current = null;
        }
      }
    }
  }, [user, dispatch]);

  return (
    <div className={pathname !== ROUTES_PATH.REMAKE ? "wrapper" : ""}>
      <ToastContainer />

      {authorizationPages.includes(pathname) ? (
        <Routes>
          {AUTH_PATHS.map((item) => (
            <Route key={item.path} {...item} />
          ))}

          {renderRoutes()}
        </Routes>
      ) : (
        <>
          {isMobile && !isPWA ? (
            <DownloadApp />
          ) : (
            <>
              {isPortrait ? (
                <FlipDevice />
              ) : (
                <>
                  <Burger isActive={menuIsOpen} callback={toggleMenuState} />

                  {isMobile && <MobileHeader online={online} />}
                  {!sidebarHidden && (
                    <Sidebar menuIsOpen={menuIsOpen} online={online} />
                  )}

                  <div
                    className={`client ${sidebarHidden ? "client--full" : ""}`}
                  >
                    <Routes>
                      {GAME_PATHS.map((item) => (
                        <Route key={item.path} {...item} />
                      ))}

                      {renderRoutes()}
                    </Routes>

                    <Notification />
                  </div>

                  <GameOverlay />

                  <GamePopup />

                  <Hint />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Layout;
