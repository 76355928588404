import React from "react";
import styles from "styles/components/hint.module.scss";
import Frame from "./Frame";
import { useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import { useTranslation } from "react-i18next";

const Hint: React.FC = () => {
  const { t } = useTranslation();
  const { hintContent, hintIsOpen } = useAppSelector(selectPopup);

  return (
    <div className={`${styles.hint} ${hintIsOpen ? styles["hint--open"] : ""}`}>
      <div
        style={hintContent.img}
        className={`${styles.hint__img} ${
          hintContent.name === "jason" ? styles["hint__img--jason"] : ""
        } ${hintContent.name === "john" ? styles["hint__img--john"] : ""} ${
          hintContent.name === "judy_christmas"
            ? styles["hint__img--christmas"]
            : ""
        }`}
      >
        <img
          src={`/img/characters/${hintContent.name}.png`}
          alt="Game character"
        />
      </div>
      <div style={hintContent.content} className={styles.hint__content}>
        <div className={styles.hint__frame}>
          <Frame blue={hintContent.blue} />
        </div>

        <div className={styles.hint__wrapper}>
          <div className={styles.hint__name}>
            {hintContent.name === "judy_christmas" ? "Judy" : hintContent.name}
          </div>

          <div className={styles.hint__text}>{hintContent.text}</div>

          <div className={styles.hint__button_row}>
            <button
              className={styles.hint__button}
              onClick={hintContent.onNext}
            >
              {t("next")}
            </button>
          </div>
        </div>
      </div>

      <div className={styles.hint__hole} style={hintContent.hole}></div>
    </div>
  );
};

export default Hint;
