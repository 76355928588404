import React from "react";
import styles from "styles/components/main-card.module.scss";

const RatingsIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="29.4rem"
      height="32.2rem"
      version="1.1"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 6.03 6.6"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "\n   \n    .fil0 {fill:white}\n    .fil1 {fill:white;fill-rule:nonzero}\n   \n  ",
          }}
        />
      </defs>
      <g id="Слой_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <rect
          className={`fil0 ${styles["image-3"]}`}
          x="2.69"
          y="4.76"
          width="0.66"
          height="1.47"
        />
        <path
          className={`fil0 ${styles["border-3"]}`}
          d="M3.62 0.17l0.9 0.52 0.9 0.52c0.39,0.22 0.61,0.6 0.61,1.05l0 1.04 0 1.04c0,0.44 -0.22,0.82 -0.61,1.05l-0.9 0.52 -0.9 0.52c-0.39,0.22 -0.83,0.22 -1.21,0l-0.9 -0.52 -0.91 -0.52c-0.38,-0.23 -0.6,-0.61 -0.6,-1.05l0 -1.04 0 -1.04c0,-0.45 0.22,-0.83 0.6,-1.05l0.91 -0.52 0.9 -0.52c0.38,-0.23 0.82,-0.23 1.21,0zm-1.21 0.67l-1.22 0.71c-0.39,0.22 -0.61,0.6 -0.61,1.04l0 1.41c0,0.45 0.22,0.83 0.61,1.05l1.22 0.71c0.38,0.22 0.82,0.22 1.21,0l1.22 -0.71c0.39,-0.22 0.61,-0.6 0.61,-1.05l0 -1.41c0,-0.44 -0.22,-0.82 -0.61,-1.04l-1.22 -0.71c-0.39,-0.22 -0.83,-0.22 -1.21,0z"
        />
        <g id="_667005136" className={styles["image-3"]}>
          <path
            className="fil0"
            d="M4.29 2.28l0 1.46c0,0.7 -0.58,1.27 -1.28,1.27l0 0c-0.7,0 -1.27,-0.57 -1.27,-1.27l0 -1.46 2.55 0z"
          />
          <g>
            <path
              className="fil1"
              d="M4.62 2.99l-0.71 0c-0.07,0 -0.13,0.06 -0.13,0.12l0 0.53c0,0.07 0.06,0.12 0.13,0.12l0.22 0c0.37,0 0.59,-0.24 0.59,-0.61l0 -0.07c0,-0.07 -0.04,-0.09 -0.1,-0.09zm-1.02 -0.31l1.3 0c0.07,0 0.12,0.05 0.12,0.12l0 0.4c0,0.25 -0.1,0.47 -0.26,0.64 -0.16,0.16 -0.39,0.26 -0.63,0.26l-0.59 0c-0.07,0 -0.12,-0.06 -0.12,-0.12l0 -1.18c0,-0.07 0.05,-0.12 0.12,-0.12l0.06 0z"
            />
            <path
              className="fil1"
              d="M1.41 2.99l0.71 0c0.07,0 0.12,0.06 0.12,0.12l0 0.53c0,0.07 -0.05,0.12 -0.12,0.12l-0.22 0c-0.38,0 -0.59,-0.24 -0.59,-0.61l0 -0.07c0,-0.07 0.03,-0.09 0.1,-0.09zm1.02 -0.31l-1.3 0c-0.07,0 -0.13,0.05 -0.13,0.12l0 0.4c0,0.25 0.1,0.47 0.27,0.64 0.16,0.16 0.39,0.26 0.63,0.26l0.59 0c0.07,0 0.12,-0.06 0.12,-0.12l0 -1.18c0,-0.07 -0.05,-0.12 -0.12,-0.12l-0.06 0z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RatingsIcon;
