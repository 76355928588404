import { createAsyncThunk } from "@reduxjs/toolkit";
import { LegalRaceApi } from "utils/api/LegalRace";

export const fetchLegalRacesData = createAsyncThunk(
  "user/fetchLegalRacesData",
  async () => {
    const response = LegalRaceApi.getRaces();

    return response;
  }
);
