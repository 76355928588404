import { TARGET_WRAPPER } from "./constants";
import message from "./message";
import { ActionRaceInit, ActionType, EventType, Message } from "./types";

let _iframe: HTMLIFrameElement | undefined;
let _isReady = false;

message.listen(EventType.EVENT_RACE_READY_GET_INIT, () => {
  _isReady = true;
});

message.listen(EventType.EVENT_RACE_EXIT, () => {
  _isReady = false;
});

const postMessage = (m: Message) => {
  if (!_isReady || !_iframe) {
    return false;
  } else {
    _iframe.contentWindow?.postMessage(m, "*");
    return true;
  }
};

let _observer: MutationObserver;

function setIframe(iframeId: string) {
  const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
  if (iframe && iframe !== _iframe) {
    _iframe = iframe;
    return true;
  } else {
    return false;
  }
}

function init(iframeId: string) {
  _observer?.disconnect();

  if (!setIframe(iframeId)) {
    _observer = new MutationObserver(() => {
      if (setIframe(iframeId)) {
        _observer?.disconnect();
      }
    });
    _observer.observe(document, {
      childList: true,
      subtree: true,
    });
  }
}

let _raceInitIntervalId: number;
const raceInit = (iframeId: string, value: ActionRaceInit) => {
  window.clearInterval(_raceInitIntervalId);
  _raceInitIntervalId = window.setInterval(() => {
    if (
      postMessage({
        key: ActionType.ACTION_RACE_INIT,
        target: TARGET_WRAPPER,
        value,
      })
    ) {
      window.clearInterval(_raceInitIntervalId);
    }
  }, 100);

  init(iframeId);
};

const action = {
  post: {
    raceInit,
  },
};

export default action;
