import React from "react";

const HidePassword: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"3.3rem"}
      height={"1.4rem"}
      viewBox="0 0 33 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00115 0L2.96875 2.08585V2.1085C10.216 9.91426 20.4718 13.46 30.0871 2.11212C30.0871 2.0315 28.5031 0.451142 28.3826 0.333827C20.7413 8.25826 12.6207 7.95931 5.00115 0Z"
        fill="#74566C"
      />
      <path
        d="M17.8834 8.19391H15.2051V13.7648H17.8834V8.19391Z"
        fill="#74566C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.059 1.09747L27.2363 2.86128L31.1757 6.80062L32.9984 5.03681L29.059 1.09747Z"
        fill="#74566C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.014 5.92999L21.7891 7.14844L24.5448 11.9901L26.7697 10.7716L24.014 5.92999Z"
        fill="#74566C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8339 5.92999L11.0588 7.14844L8.30303 11.9901L6.07812 10.7716L8.8339 5.92999Z"
        fill="#74566C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.93934 1.09747L5.76203 2.86128L1.82269 6.80062L0 5.03681L3.93934 1.09747Z"
        fill="#74566C"
      />
    </svg>
  );
};

export default HidePassword;
