import Checkbox from "components/FormElements/Checkbox";
import React from "react";
import styles from "styles/pages/inventory.module.scss";
import { IRarityItem } from ".";
import { SetState } from "interfaces/General";

interface RaritySelectProps {
  rarityFilters: IRarityItem[];
  setRarityFilters: SetState<IRarityItem[]>;
}

const RaritySelect: React.FC<RaritySelectProps> = ({
  rarityFilters,
  setRarityFilters,
}) => {
  return (
    <div className={styles.inventory__rarity}>
      {rarityFilters.map((item) => (
        <Checkbox
          key={item.id}
          id={item.id}
          checked={item.active}
          setChecked={setRarityFilters}
          rarity={item.key}
          listed
        />
      ))}
    </div>
  );
};

export default RaritySelect;
