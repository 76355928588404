import React from "react";
import styles from "styles/components/gamePopup/receive-bnb.module.scss";
import PopupLayout from "./PopupLayout";
import Copy from "components/Copy";
import GameButton from "components/Buttons/GameButton";
import { setGameIsOpen } from "redux/reducers/PopupSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import QRCode from "react-qr-code";
import { tokenTypes } from "interfaces/Tokens";
import { useTranslation } from "react-i18next";

interface ReceiveBnbProps {
  type: tokenTypes;
}

const ReceiveBnb: React.FC<ReceiveBnbProps> = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const address = user!.user.wallet.address;

  const handleClose = () => dispatch(setGameIsOpen(false));

  return (
    <PopupLayout>
      <div className={styles.receive_bnb}>
        <div className={styles.receive_bnb__title}>
          {t("deposit")} {type} (BEP-20)
        </div>

        <div className={styles.receive_bnb__qr}>
          <div className={styles.receive_bnb__qr_content}>
            <QRCode value={address} />
          </div>
        </div>

        <div className={styles.receive_bnb__address}>
          {address} <Copy text={address} noMargin />
        </div>

        <div className={styles.receive_bnb__text}>{t("deposit_text")}</div>

        <GameButton onClick={handleClose} label="Close" />
      </div>
    </PopupLayout>
  );
};

export default ReceiveBnb;
