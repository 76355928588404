import { TARGET_WRAPPER } from "./constants";
import { setToken } from "./token";
import {
  ActionType,
  CostValue,
  EventType,
  Message,
  RarityValue,
  UpdateRace,
} from "./types";

let _lastSceneIsCarboxes = false;
export const getLastSceneIsCarboxes = () => {
  const temp = _lastSceneIsCarboxes;
  _lastSceneIsCarboxes = false;
  return temp;
}

let _iframe: HTMLIFrameElement | undefined;
let _isReady = false;
let _balance = 0;
let _races: UpdateRace[] = [];

const postMessage = (m: Message) => {
  if (!_isReady) {
    console.warn(
      "игра еще не готова!!! еще не получил из игры EventType.EVENT_GET_STATE"
    );
  } else if (!_iframe) {
    console.warn("не найден iframe получатель");
  } else {
    _iframe.contentWindow?.postMessage(m, "*");
  }
};

const gotoMain = () =>
  postMessage({
    key: ActionType.ACTION_GOTO_MAIN,
    target: TARGET_WRAPPER,
  });

const gotoGarage = () => {
  postMessage({
    key: ActionType.ACTION_GOTO_GARAGE,
    target: TARGET_WRAPPER,
  });
}
  

const gotoCarbox = () =>{
  _lastSceneIsCarboxes = true;
  postMessage({
    key: ActionType.ACTION_GOTO_CARBOX,
    target: TARGET_WRAPPER,
  });
}


const gotoRace = () =>
  postMessage({
    key: ActionType.ACTION_GOTO_RACE,
    target: TARGET_WRAPPER,
  });

const gotoShop = () =>
  postMessage({
    key: ActionType.ACTION_GOTO_SHOP,
    target: TARGET_WRAPPER,
  });

const gotoRules = () =>
  postMessage({
    key: ActionType.ACTION_GOTO_RULES,
    target: TARGET_WRAPPER,
  });

const gotoRating = () =>
  postMessage({
    key: ActionType.ACTION_GOTO_RATING,
    target: TARGET_WRAPPER,
  });

const showLootboxPopup = () =>
  postMessage({
    key: ActionType.ACTION_SHOW_OPEN_LOOTBOX,
    target: TARGET_WRAPPER,
  });

const muteSounds = (value: boolean) =>
  postMessage({
    key: ActionType.ACTION_MUTE_SOUNDS,
    target: TARGET_WRAPPER,
    value,
  });

const muteMusic = (value: boolean) =>
  postMessage({
    key: ActionType.ACTION_MUTE_MUSIC,
    target: TARGET_WRAPPER,
    value,
  });

const updateOffsetTime = (offsetTime: number) => {
  postMessage({
    key: ActionType.ACTION_UPDATE_OFFSET_TIME,
    target: TARGET_WRAPPER,
    value: offsetTime,
  });
};
const updateBalance = (balance: number = -1) => {
  if (balance === -1 || isNaN(balance)) {
    balance = _balance;
  }
  _balance = balance;
  postMessage({
    key: ActionType.ACTION_UPDATE_BALANCE,
    target: TARGET_WRAPPER,
    value: balance,
  });
};

const updateCost = (cost: CostValue) =>
  postMessage({
    key: ActionType.ACTION_UPDATE_COST,
    target: TARGET_WRAPPER,
    value: cost,
  });

const updateRarity = (rarity: RarityValue) =>
  postMessage({
    key: ActionType.ACTION_UPDATE_RARITY,
    target: TARGET_WRAPPER,
    value: rarity,
  });

const updateRaces = (races: UpdateRace[] | null = null) => {
  if (races === null) {
    races = _races;
  }
  _races = races || [];

  postMessage({
    key: ActionType.ACTION_UPDATE_RACES,
    target: TARGET_WRAPPER,
    value: races,
  });
};

const showBuyCarboxPopup = () =>
  postMessage({
    key: ActionType.ACTION_SHOW_BUY_CARBOX,
    target: TARGET_WRAPPER,
  });

const showBuyLootboxPopup = () =>
  postMessage({
    key: ActionType.ACTION_SHOW_BUY_LOOTBOX,
    target: TARGET_WRAPPER,
  });

const showChangerUSDTToMGT = (usdt: number) =>
  postMessage({
    key: ActionType.ACTION_SHOW_CHANGER_USDT_TO_MGT,
    target: TARGET_WRAPPER,
    value: usdt,
  });
const showChangerMGTToUSDT = (mgt: number) =>
  postMessage({
    key: ActionType.ACTION_SHOW_CHANGER_MGT_TO_USDT,
    target: TARGET_WRAPPER,
    value: mgt,
  });

const updateUnclaimed = (amount: number) =>
  postMessage({
    key: ActionType.ACTION_UPDATE_UNCLAIMED,
    target: TARGET_WRAPPER,
    value: amount,
  });

const purchaseApproved = (success: boolean) =>
  postMessage({
    key: ActionType.ACTION_PURCHASE_APPROVED,
    target: TARGET_WRAPPER,
    value: success,
  });

const error = () =>
  postMessage({
    key: ActionType.ACTION_ERROR,
    target: TARGET_WRAPPER,
  });

const connect = () => {
  postMessage({
    key: ActionType.ACTION_CONNECT,
    target: TARGET_WRAPPER,
  });
};

const disconnect = () => {
  setToken("");
  postMessage({
    key: ActionType.ACTION_DISCONNECT,
    target: TARGET_WRAPPER,
  });
};

const responseEvent = (event: EventType, value?: any) =>
  postMessage({
    key: event,
    target: TARGET_WRAPPER,
    value,
  });

const action = {
  setIframeReceiver: (iframe: HTMLIFrameElement) => (_iframe = iframe),
  setIsReady: (value: boolean) => (_isReady = value),
  post: {
    gotoMain,
    gotoGarage,
    gotoCarbox,
    gotoRace,
    gotoShop,
    gotoRules,
    gotoRating,
    showLootboxPopup,
    muteSounds,
    muteMusic,
    updateOffsetTime,
    updateBalance,
    updateCost,
    updateRarity,
    updateRaces,
    showBuyCarboxPopup,
    showBuyLootboxPopup,
    showChangerUSDTToMGT,
    showChangerMGTToUSDT,
    updateUnclaimed,
    purchaseApproved,
    error,
    connect,
    disconnect,
    responseEvent,
  },
};

export default action;
