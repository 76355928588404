import { TARGET_GAME, TARGET_WRAPPER } from "./constants";

export enum EventType {
  EVENT_RACE_READY_GET_INIT = "event_race_ready_get_init", // игра готова получить init данные
  EVENT_RACE_MUTE_SOUNDS = "action_race_mute_sounds",
  EVENT_RACE_MUTE_MUSIC = "action_race_mute_music",
  EVENT_RACE_PLAY_SOUND = "event_race_play_sound", // проиграть звук
  EVENT_RACE_LOADED = "event_race_loaded", // игра загрузилась
  EVENT_RACE_EXIT = "event_race_exit", // пользователь вышел из гонки
}

const targets = [TARGET_GAME, TARGET_WRAPPER] as const;
export type Message = {
  key: EventType | ActionType;
  target: (typeof targets)[number];
  value?: any;
};

export enum ActionType {
  UNDEFINED = "undefined",
  ACTION_RACE_INIT = "action_race_init",
}

export type WaitUntilResult<T> = {
  type: ActionType;
  done: boolean;
  timeout: number;
  data?: T;
};
export type ListenResult<T> = {
  type: ActionType;
  data?: T;
};

export type ListenEventResult<T> = {
  type: EventType;
  data?: T;
};

export type Callback = (...args: any) => void;
export type CallbacksDict = {
  onRacePlaySound: Array<Callback>;
  onRaceMuteSound: Array<Callback>;
  onRaceMuteMusic: Array<Callback>;
  onRaceReadyGetInit: Array<Callback>;
  onRaceLoaded: Array<Callback>;
  onRaceExit: Array<Callback>;
};

export type CarColorType =
  | "C01"
  | "C02"
  | "C03"
  | "C04"
  | "C05"
  | "C06"
  | "C07"
  | "C08"
  | "C09"
  | "C10";

export type CarRewards = {
  xp: number;
  lootbox: number;
  tokens: number;
};

export type CarProps = {
  color: CarColorType;
  finishPosition: number;
  carId: number;
  name: string;
  isUser: boolean;
  image: string;
  rewards: CarRewards;
  equipment: {
    body: number;
    wing: number;
    roof: number;
    mirrors: number;
    headlights: number;
    grill: number;
    bumper: number;
    hood: number;
    rims: number;
    sideskirts: number;
    fenders: number;
    sticker: number;
  };
};

export type Prizes = {
  tokens?: number | null;
  lootboxes?: number | null;
};
export type Track = {
  id: number;
  name: string;
};
export type ActionRaceInit = {
  timeStamp: number;
  id: number | string;
  track?: Track;
  name: string;
  type: string;
  raceTime: number;
  prizes: Prizes;
  mute: {
    sound: boolean;
    music: boolean;
  };
  cars: CarProps[];
};
