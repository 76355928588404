import React from "react";

const Frame: React.FC = () => {
  return (
    <svg
      width={708}
      height={392}
      viewBox="0 0 708 392"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_bdi_22_27066)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M686.774 9L698.999 22.4609L699 22.4624L698.999 22.4628V65.8455L695.943 72.4189V189.426L698.999 195.999V369.538L686.774 383L686.772 382.996L686.771 383H603.577L594.82 379.634H445.948L437.19 383H21.2229L21.2218 382.995L9 369.538V195.999L12.0565 189.426V72.4187L9 65.8453V22.4607L9.00206 22.4601L21.2208 9.0056L21.2229 9H91.2806L100.038 12.3656H607.956L616.713 9H686.771L686.772 9.00308L686.774 9Z"
          fill="url(#paint0_radial_22_27066)"
          fillOpacity="0.6"
          shapeRendering="crispEdges"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M686.774 9L698.999 22.4609L699 22.4624L698.999 22.4628V65.8455L695.943 72.4189V189.426L698.999 195.999V369.538L686.774 383L686.772 382.996L686.771 383H603.577L594.82 379.634H445.948L437.19 383H21.2229L21.2218 382.995L9 369.538V195.999L12.0565 189.426V72.4187L9 65.8453V22.4607L9.00206 22.4601L21.2208 9.0056L21.2229 9H91.2806L100.038 12.3656H607.956L616.713 9H686.771L686.772 9.00308L686.774 9Z"
          stroke="#33E9E9"
          strokeWidth="0.5"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_bdi_22_27066"
          x="-1.25"
          y="-1.39461"
          width="710.633"
          height="394.841"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation={5} />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_22_27066"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.913725 0 0 0 0 0.913725 0 0 0 0.77 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_22_27066"
            result="effect2_dropShadow_22_27066"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_22_27066"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={3} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.2 0 0 0 0 0.913725 0 0 0 0 0.913725 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect3_innerShadow_22_27066"
          />
        </filter>
        <radialGradient
          id="paint0_radial_22_27066"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(310.745 196) scale(342.333 741.523)"
        >
          <stop stopColor="#267373" />
          <stop offset={1} />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Frame;
