import CarFrame from "icons/CarFrame";
import React from "react";
import styles from "styles/components/car-frames.module.scss";

interface CarFramesProps {
  avatars: string[];
}

const CarFrames: React.FC<CarFramesProps> = ({ avatars }) => {
  return (
    <div className={styles.car_frames}>
      {avatars.map((avatar, key) => (
        <div
          key={key * 10}
          style={{
            clipPath: `url(#frame-clip-${key})`,
            WebkitClipPath: `url(#frame-clip-${key})`,
          }}
          className={styles.car_frames__frame}
        >
          <CarFrame blue id={String(key)} />

          <div className={styles.car_frames__wrapper}>
            <img src={avatar} alt="Car avatar" />
          </div>
        </div>
      ))}

      {avatars.length < 4 &&
        Array.from({ length: 4 - avatars.length }, (_, i) => (
          <div key={i} className={styles.car_frames__frame}>
            <CarFrame />
          </div>
        ))}
    </div>
  );
};

export default CarFrames;
