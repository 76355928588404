import React from "react";
import styles from "styles/components/main-card.module.scss";

const Shop: React.FC = () => {
  return (
    <svg
      width={"10.5rem"}
      height={"11.5rem"}
      viewBox="0 0 105 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63 2.92188L78.75 12.1094L94.5 21.0781C101.062 25.0156 105 31.5781 105 39.4531V57.6094V75.5469C105 83.4219 101.062 89.9844 94.5 93.9219L78.75 102.891L63 112.078C56.2187 115.797 48.5625 115.797 42 112.078L26.25 102.891L10.5 93.9219C3.9375 89.9844 0 83.4219 0 75.5469V57.6094V39.4531C0 31.5781 3.9375 25.0156 10.5 21.0781L26.25 12.1094L42 2.92188C48.5625 -0.796875 56.2187 -0.796875 63 2.92188ZM42 14.7344L20.7812 26.9844C14 30.9219 10.0625 37.4844 10.0625 45.1406V69.8594C10.0625 77.5156 14 84.0781 20.7812 88.0156L42 100.266C48.5625 104.203 56.2187 104.203 63 100.266L84.2188 88.0156C91 84.0781 94.7188 77.5156 94.7188 69.8594V45.1406C94.7188 37.4844 91 30.9219 84.2188 26.9844L63 14.7344C56.2187 10.7969 48.5625 10.7969 42 14.7344Z"
        fill="white"
        className={styles["border-4"]}
      />
      <path
        d="M59.9375 72.4844H45.2812V109.672H59.9375V72.4844Z"
        fill="white"
        className={styles["image-4"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.125 48.6406V49.2969C76.125 50.3906 75.25 51.2656 74.1562 51.2656H30.625C29.75 51.2656 28.875 50.3906 28.875 49.2969V48.6406C28.875 47.5469 29.75 46.6719 30.625 46.6719H74.1562C75.25 46.6719 76.125 47.5469 76.125 48.6406Z"
        fill="white"
        className={styles["image-4"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8438 54.9844H74.375C75.25 54.9844 76.125 55.8594 76.125 56.9531V77.2969C76.125 78.1719 75.25 79.0469 74.375 79.0469H70C69.3437 79.0469 68.6875 78.6094 68.25 77.9531L67.375 75.5469C67.1562 74.6719 66.5 74.2344 65.625 74.2344H39.375C38.5 74.2344 37.8438 74.6719 37.625 75.5469L36.75 77.9531C36.3125 78.6094 35.6562 79.0469 35 79.0469H30.8438C29.75 79.0469 28.875 78.1719 28.875 77.2969V56.9531C28.875 55.8594 29.75 54.9844 30.8438 54.9844ZM52.7187 59.7969C55.7812 59.7969 58.1875 62.2031 58.1875 65.2656C58.1875 68.3281 55.7812 70.7344 52.7187 70.7344C49.6562 70.7344 47.0312 68.3281 47.0312 65.2656C47.0312 62.2031 49.6562 59.7969 52.7187 59.7969Z"
        fill="white"
        className={styles["image-4"]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.125 41.2031V35.2969C76.125 34.4219 75.25 33.5469 74.375 33.5469H30.8438C29.75 33.5469 28.875 34.4219 28.875 35.2969V41.2031C28.875 42.2969 29.75 42.9531 30.8438 42.9531H74.375C75.25 42.9531 76.125 42.2969 76.125 41.2031Z"
        fill="white"
        className={styles["image-4"]}
      />
    </svg>
  );
};

export default Shop;
