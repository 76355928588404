import React from "react";

const Telegram: React.FC = () => {
  return (
    <svg
      width={"3.5rem"}
      height={"2.9rem"}
      viewBox="0 0 35 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient gradientTransform="rotate(-270)" id="linear-gradient-3">
        <stop offset="0%" stopColor="#504153" />
        <stop offset="100%" stopColor="#504153" />
      </linearGradient>
      <path
        fill="url(#linear-gradient-3)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13.5115C0 14.4012 1.00305 14.5632 1.90565 14.8624C2.67106 15.1162 3.50303 15.3617 4.29797 15.601L7.90475 16.6909C9.55338 17.2198 8.90561 17.2261 10.3688 16.3766C10.8053 16.1231 11.1544 15.8945 11.5921 15.6312L13.9859 14.1556C15.5181 13.1671 17.2288 12.2028 18.7854 11.2161C19.1843 10.9633 19.58 10.741 19.9615 10.4915C20.3635 10.2287 20.7595 10.0046 21.178 9.73929C21.9825 9.22931 22.7598 8.76367 23.5707 8.26259L25.9733 6.7957C26.3519 6.57308 28.8476 4.79153 28.8476 5.7248C28.8476 5.90784 28.453 6.22788 28.3174 6.34525L24.9677 9.29881C24.8214 9.42554 24.7734 9.43213 24.6257 9.56699L23.7046 10.4078C23.5571 10.542 23.5083 10.5497 23.3624 10.6759L15.944 17.2195C15.8357 17.318 15.7807 17.3878 15.6728 17.4901C15.5211 17.6339 15.4833 17.6365 15.3327 17.7603L14.0715 18.8714C13.8035 19.0776 13.6734 19.1739 13.6684 19.6167L13.125 27.2055C14.0006 27.2055 14.3355 26.8535 14.9189 26.3491C15.5092 25.8386 15.6903 25.5307 16.4545 24.9034C16.5758 24.8038 16.653 24.7153 16.7652 24.6044C16.9863 24.3859 17.1589 24.2182 17.3803 24.0002L18.6621 22.8423L24.9512 27.3397C25.2534 27.5631 25.5432 27.7574 25.8597 27.9915L26.7306 28.6132C27.0126 28.7861 27.4769 28.949 27.9182 28.9935C28.8117 29.0836 29.342 28.2489 29.5577 27.5673L30.6537 22.535C31.7852 17.5427 32.8169 12.4245 33.9457 7.44177C34.0669 6.90647 35 2.77846 35 2.30131C35 1.31587 34.7925 0.406219 33.5476 0.302505C33.0298 0.259393 31.6827 0.825373 31.1712 1.02513C30.4142 1.32078 29.6798 1.57792 28.8957 1.87847C28.1199 2.17581 27.4134 2.45074 26.6314 2.74286C23.83 3.78936 20.3325 5.04314 17.5783 6.13721L6.25971 10.3947C5.47883 10.7046 4.76169 10.9409 3.98409 11.2481C2.92526 11.6661 0.000156239 12.4633 0.000156239 13.5115H0Z"
      />
    </svg>
  );
};

export default Telegram;
