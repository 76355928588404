import Steps from "components/Auth/Steps";
import Input from "components/FormElements/Input";
import GameButton from "components/Buttons/GameButton";
import React, { useState, useEffect } from "react";
import styles from "styles/components/auth.module.scss";
import Alert from "utils/alert";
import { authStatuses } from ".";
import { SetState } from "interfaces/General";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchData } from "utils/fetchData";
import { AuthApi } from "utils/api/Auth";
import { useAppDispatch } from "redux/hooks/redux";

interface CreateAccountProps {
  setStatus: SetState<authStatuses>;
  email: string;
  code: string;
  setPhrase: SetState<string>;
}

const CreateAccount: React.FC<CreateAccountProps> = ({
  setStatus,
  email,
  code,
  setPhrase,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [cookies] = useCookies(["referralcode"]);

  const [dataSending, setDataSending] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const handleSignup = () => {
    setDataSending(true);

    if (repeatPassword === password) {
      AuthApi.signup(
        {
          email,
          verifycode: code,
          password,
          referralcode: inviteCode,
        },
        t("account_create_success")
      )
        .then((res) => {
          if (res?.mnemonic) {
            setPhrase(res.mnemonic);
          }

          if (res?.user) {
            setStatus("phrase");
            fetchData(dispatch);
          }
        })
        .catch(() => {
          Alert("error", t("default_error"));
        })
        .finally(() => setDataSending(false));
    } else {
      Alert("error", t("password_dont_match"));
    }
  };

  useEffect(() => {
    if (cookies.referralcode) setInviteCode(cookies.referralcode);
  }, [cookies]);

  return (
    <>
      <Steps active={3} />

      <div className={styles.auth__title}>{t("create_your_account")}</div>
      <div className={styles.auth__text}>{t("choose_pwd")}</div>

      <form className={styles.auth__form}>
        <Input
          placeholder={t("password")}
          type="password"
          value={password}
          setValue={setPassword}
        />

        <Input
          placeholder={t("repeat_pwd")}
          type="password"
          value={repeatPassword}
          setValue={setRepeatPassword}
        />

        <div className={styles.auth__message}>{t("password_condition")}</div>

        {/* <div className={`${styles.auth__link} ${styles["auth__link--small"]}`}>
          By creating an account, I agree to the <Link to="/">Term of use</Link>{" "}
          and acknowledge that I have read the{" "}
          <Link to="/">Privacy policy</Link>
        </div> */}

        <GameButton
          disabled={password.length < 8 || dataSending}
          label={t("continue")}
          onClick={handleSignup}
        />
      </form>
    </>
  );
};

export default CreateAccount;
