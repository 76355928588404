import React from "react";

const Sixth: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"43.6rem"}
      height={"13.8rem"}
      viewBox="0 0 436 138"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M435.961 12.11L435.961 116.038L413.999 138L111.793 138L88.8972 115.104L24.9982 115.104L-0.000697717 90.1049L-0.000691123 14.68L14.1342 -3.68773e-05L353.293 -7.22703e-06L366.026 12.11L435.961 12.11Z"
        fill="#16081B"
      />

      <clipPath id="sixth-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M1,0.088 L1,0.841 L0.95,1 L0.256,1 L0.204,0.834 L0.057,0.834 L0,0.653 L0,0.106 L0.032,0 L0.81,0 L0.84,0.088 L1,0.088"></path>
      </clipPath>
    </svg>
  );
};

export default Sixth;
