import React, { useState } from "react";
import styles from "styles/components/auth.module.scss";
import Input from "components/FormElements/Input";
import GameButton from "components/Buttons/GameButton";
import { Link } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import Steps from "components/Auth/Steps";
import { AuthApi } from "utils/api/Auth";
import Alert from "utils/alert";
import { authStatuses } from ".";
import { SetState } from "interfaces/General";
import { useTranslation } from "react-i18next";

interface EnterEmailProps {
  email: string;
  setStatus: SetState<authStatuses>;
  setEmail: SetState<string>;
}

const EnterEmail: React.FC<EnterEmailProps> = ({
  setEmail,
  email,
  setStatus,
}) => {
  const { t } = useTranslation();

  const [dataSending, setDataSending] = useState(false);

  const handleRegister = () => {
    setDataSending(true);
    AuthApi.presignup({ email }, t("code_sent"))
      .then((response) => {
        if (response?.presignup) setStatus("code");
      })
      .catch(() => {
        Alert("error", t("default_error"));
      })
      .finally(() => setDataSending(false));
  };

  return (
    <>
      <Steps active={1} />

      <form className={styles.auth__form}>
        <Input
          placeholder={t("enter_email")}
          value={email}
          setValue={setEmail}
        />

        <GameButton
          label={t("continue")}
          onClick={handleRegister}
          disabled={dataSending}
        />
      </form>

      <div className={styles.auth__link}>
        {t("already_have_account")}{" "}
        <Link to={ROUTES_PATH.LOGIN}>{t("sign_in")}</Link>
      </div>
    </>
  );
};

export default EnterEmail;
