import React from "react";

const Lock: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"2.9rem"}
      height={"3.8rem"}
      viewBox="0 0 29 38"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.93447 15.701H20.0655V10.1041C20.0655 7.05803 17.5481 4.532 14.5123 4.532C11.4519 4.532 8.93447 7.05803 8.93447 10.1041V15.701ZM24.286 15.7753C26.9515 16.2459 29 18.5738 29 21.3722V31.5011C29 34.6215 26.4579 37.1723 23.3481 37.1723H5.65191C2.54213 37.1723 0 34.6215 0 31.5011V21.3722C0 18.5738 2.04851 16.2459 4.71404 15.7753V9.83172C4.71404 4.43294 9.10723 0 14.5123 0C19.8928 0 24.286 4.43294 24.286 9.83172V15.7753Z"
        fill="white"
      />
    </svg>
  );
};

export default Lock;
