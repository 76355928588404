import { createAsyncThunk } from "@reduxjs/toolkit";
import { StreetRaceApi } from "utils/api/StreetRace";

export const fetchStreetRacesData = createAsyncThunk(
  "user/fetchStreetRacesData",
  async () => {
    const response = StreetRaceApi.getRaces();

    return response;
  }
);
