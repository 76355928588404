import GameButton from "components/Buttons/GameButton";
import CarAvatar from "components/CarAvatar";
import Checkbox from "components/FormElements/Checkbox";
import TotalPoints from "components/TotalPoints";
import { ROUTES_PATH } from "constants/routesPath";
import Control from "icons/inventory/Control";
import Detail from "icons/inventory/Detail";
import Speed from "icons/inventory/Speed";
import { IAdvancedCarItem } from "interfaces/Car";
import { SetState } from "interfaces/General";
import React, { memo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styles from "styles/pages/joinRace/item.module.scss";

interface ItemProps extends IAdvancedCarItem {
  setList?: SetState<number[]>;
  list?: number[];
  qualification?: boolean;
  disabled?: boolean;
  disableSelect?: boolean;
  isOwner?: boolean;
  participantsTab?: boolean;
}

const Item: React.FC<ItemProps> = memo(
  ({
    specs,
    level,
    title,
    id,
    setList,
    inRace,
    qualified,
    qualification,
    list,
    disabled,
    image,
    disableSelect,
    isOwner = false,
    participantsTab = false,
  }) => {
    const { t } = useTranslation();

    const [checked, setChecked] = useState(!!list?.includes(id));
    const { pathname } = useLocation();

    const acceleration = specs?.acceleration || 0;
    const durability = specs?.durability || 0;
    const handling = specs?.handling || 0;
    const chance = specs?.chance || 0;

    const classNames = `${styles.item} ${
      list?.includes(id) || isOwner ? styles["item--active"] : ""
    } ${!qualified && !qualification ? styles["item--qual"] : ""} ${
      inRace ? styles["item--in-race"] : ""
    } ${qualification ? styles["item--qualification"] : ""} ${
      disabled ? styles["item--disabled"] : ""
    }`;

    const handleChange = () => {
      if (list && setList) {
        if (disableSelect) {
          setChecked(false);
        } else {
          setChecked(!checked);
        }

        if (pathname.includes("free") || pathname.includes("special")) {
          if (list?.includes(id)) {
            setList([]);
            return;
          }

          setList([id]);
          setChecked(true);
          return;
        }

        let newCarIds = [...list];

        if (newCarIds.includes(id)) {
          newCarIds = newCarIds.filter((item) => item !== id);
        } else {
          if (!disableSelect) newCarIds.push(id);
        }

        setList(newCarIds);
      }
    };

    useEffect(() => {
      if (list?.length === 0) setChecked(false);
      if (!list?.includes(id)) {
        setChecked(false);
      }
    }, [list, id]);

    return (
      <li
        onClick={() => {
          if (!disabled) {
            if (!qualification && !inRace && qualified) {
              handleChange();
            } else if (qualification && !inRace) {
              handleChange();
            }
          }
        }}
        className={classNames}
      >
        <CarAvatar
          blue={pathname.includes("legal")}
          active={checked || isOwner}
          img={image}
          id={String(id)}
          height="12rem"
          width="13.3rem"
        />

        <div className={styles.item__wrapper}>
          {!qualified ? (
            <>
              <div className={styles.item__qual_info}>
                <div className={styles.item__name}>{title}</div>
                <div className={styles.item__qual}>{t("need_qualify")}</div>
              </div>

              <div className={styles.item__qual_btn}>
                {!qualification ? (
                  <GameButton
                    label={t("qualify")}
                    href={ROUTES_PATH.QUAL_RACING}
                  />
                ) : inRace ? (
                  <div className={styles.item__in_race}>{t("in_race")}</div>
                ) : (
                  <div className={styles.item__checkbox}>
                    {!disabled && (
                      <Checkbox
                        id={String(id)}
                        checked={checked}
                        setChecked={setChecked}
                      />
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className={styles.item__info}>
                <div className={styles.item__name}>{title}</div>
                <div className={styles.item__level}>
                  {t("level")}: {level}
                </div>
              </div>

              <div className={styles.item__chars}>
                <div className={styles.item__durability}>
                  <Detail /> {t("durability")}: {durability}
                </div>

                <div className={styles.item__acceleration}>
                  <Speed /> {t("acceleration")}: {acceleration}
                </div>

                <div className={styles.item__handling}>
                  <Control /> {t("handling")}: {handling}
                </div>
              </div>

              {!qualification && (
                <div className={styles.item__total}>
                  <span>{t("total_points")}</span>

                  <TotalPoints points={chance} />
                </div>
              )}

              {participantsTab && isOwner && (
                <div
                  className={`${styles.item__in_race} ${styles["item__in_race--owner"]}`}
                >
                  {t("owner")}
                </div>
              )}

              {inRace ? (
                <div className={styles.item__in_race}>{t("in_race")}</div>
              ) : (
                <div className={styles.item__checkbox}>
                  {!disabled && (
                    <Checkbox
                      id={String(id)}
                      checked={checked}
                      setChecked={setChecked}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </li>
    );
  }
);

export default Item;
