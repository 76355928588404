import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "styles/pages/racing.module.scss";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  IQualRaceEmulation,
  IRaceEmulation,
  RaceNameTypes,
} from "interfaces/Race";
import { startTimer } from "utils/startTimer";
import { IRaceCar } from "interfaces/Car";
import { StreetRaceApi } from "utils/api/StreetRace";
import { LegalRaceApi } from "utils/api/LegalRace";
import { FreeRaceApi } from "utils/api/FreeRace";
import { SpecialRaceApi } from "utils/api/SpecialRace";
import { CarColorType, CarProps, action, callbacks } from "common/raceBridge";
import Preloader from "components/Preloader";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { setMuteMusic, setMuteSound } from "redux/reducers/PopupSlice";

import soundHover from "sounds/navedenie.mp3";
import soundClickButton from "sounds/click_button.mp3";
import soundCrash1 from "sounds/avaria1.mp3";
import soundCrash2 from "sounds/avaria2.mp3";
import soundCrash3 from "sounds/avaria3.mp3";
import { selectPopup } from "redux/selectors/popupSelector";
import { useTranslation } from "react-i18next";

interface RacingProps {
  type: RaceNameTypes;
  initialRace?: IQualRaceEmulation;
  selectedCars?: number;
}

export interface IRacer extends IRaceCar {
  progress: number;
  track: {
    duration: number;
    keyPoints: string;
  };
  ref?: SVGAnimateMotionElement;
}

export const CAR_KEY_TIMES = "0;0.1;0.2;0.3;0.4;0.5;0.6;0.7;0.8;0.9;1";

const defaultEquipment = {
  body: 0,
  wing: 0,
  roof: 0,
  mirrors: 0,
  headlights: 0,
  grill: 0,
  bumper: 0,
  hood: 0,
  rims: 0,
  sideskirts: 0,
  fenders: 0,
  sticker: 0,
};

type pixiSoundTypes =
  | "navedenie"
  | "click_button"
  | "avaria1"
  | "avaria2"
  | "avaria3";

const sounds = {
  navedenie: soundHover,
  click_button: soundClickButton,
  avaria1: soundCrash1,
  avaria2: soundCrash2,
  avaria3: soundCrash3,
};

const RACE_IFRAME_ID = "race_iframe";

const Racing: React.FC<RacingProps> = ({ type, initialRace }) => {
  const { t, i18n } = useTranslation();

  const [params] = useSearchParams();
  const { id } = useParams();
  const delayRef = useRef<number | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { muteSound, muteMusic } = useAppSelector(selectPopup);

  const [race, setRace] = useState<IRaceEmulation>();
  const [timeToStart, setTimeToStart] = useState("");

  const [raceVisible, setRaceVisible] = useState(false);
  const [raceLoaded, setRaceLoaded] = useState(false);

  useEffect(() => {
    if (race) {
      const cars: CarProps[] = [];

      race.results.map((item, key) => {
        const car: CarProps = {
          carId: key,
          color: item.car.color as CarColorType,
          equipment: defaultEquipment,
          finishPosition: item.position - 1,
          image: item.car.image,
          isUser: item.isOwner,
          name: item.car.title,
          rewards: item.rewards,
        };

        cars.push(car);
      });

      action.post.raceInit(RACE_IFRAME_ID, {
        id: race.id!,
        track: {
          ...race.track!,
          name: race.title!,
        },
        name: type === "legal" ? `${race.type} racing` : race.title!,
        raceTime: Boolean(params.get("history")) ? 1000000 : 0,
        timeStamp: Date.now(),
        prizes: race.prizes!,
        cars,
        mute: {
          sound: muteSound,
          music: muteMusic,
        },
        type: t(`${type}_racing`),
      });

      setRaceVisible(true);
    }
  }, [race]);

  useEffect(() => {
    if (raceVisible) {
      callbacks.clearAll();
      callbacks.add("onRaceLoaded", () => {
        setRaceLoaded(true);
      });
      callbacks.add("onRaceMuteMusic", (value: boolean) => {
        dispatch(setMuteMusic(value));
      });
      callbacks.add("onRacePlaySound", (value: pixiSoundTypes) => {
        const audio = new Audio(sounds[value]);

        if (!muteSound) audio.play();
        console.log("play sound", value);
      });
      callbacks.add("onRaceMuteSound", (value: boolean) => {
        dispatch(setMuteSound(value));
      });
      callbacks.add("onRaceExit", () => {
        callbacks.clearAll();
        navigate(-1);
        setRaceVisible(false);
        setRaceLoaded(false);
      });
    }
  }, [raceVisible, muteSound]);

  const fetchData = useCallback(() => {
    if (type !== "qual") {
      const promise =
        type === "legal"
          ? LegalRaceApi
          : type === "street"
          ? StreetRaceApi
          : type === "free"
          ? FreeRaceApi
          : SpecialRaceApi;

      promise.getRaceEmulation(id!).then((res) => {
        if (res) {
          setRace(res.race);
          if (
            timeToStart === "" ||
            (timeToStart === "00m:00s" && Number(res.race.startIn) > 0)
          ) {
            delayRef.current = res.race.startIn;
            if (res.race.startIn) {
              startTimer(res.race.startIn + 1, setTimeToStart);
            } else setTimeToStart("00:00");
          }
        }
      });
    }
  }, [type, timeToStart, id]);

  useEffect(() => {
    if (type !== "qual" && id) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  useEffect(() => {
    if (initialRace) {
      setRace(initialRace as IRaceEmulation);
      delayRef.current = initialRace.startIn;
      startTimer(initialRace.startIn, setTimeToStart);
    }
  }, [initialRace]);

  return (
    <div className={styles.racing}>
      {raceVisible && (
        <iframe
          className={styles.racing__frame}
          id={RACE_IFRAME_ID}
          src={`${process.env.REACT_APP_PIXI_RACE}?locale=${
            i18n.language.split("-")[0]
          }`}
          title={"canvas"}
          sandbox="allow-same-origin allow-scripts"
          name={`${Date.now()}`}
        />
      )}

      <Preloader loaded={raceLoaded} />
    </div>
  );
};

export default Racing;
