import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserApi } from "utils/api/User";

export const fetchUserData = createAsyncThunk(
  "user/fetchUserData",
  async () => {
    const response = UserApi.getUserData();

    return response;
  }
);
