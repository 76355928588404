import React from "react";

const SelectArrow: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.4rem"
      height="0.8rem"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        d="M0 0.949768H6.94978H13.8996L6.94978 7.89955L0 0.949768Z"
        fill="white"
      />
    </svg>
  );
};

export default SelectArrow;
