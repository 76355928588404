import React from "react";
import styles from "styles/components/main-card.module.scss";

const Garage: React.FC = () => {
  return (
    <svg
      width={"10.5rem"}
      height={"11.5rem"}
      viewBox="0 0 105 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_203_103)">
        <path
          className={styles["border-3"]}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.9943 2.8422L78.7358 12.0247L94.4772 20.9886C101.255 24.924 104.972 31.4829 104.972 39.3536V57.5V75.6464C104.972 83.2985 101.255 90.076 94.4772 93.7928L78.7358 102.975L62.9943 111.939C56.4354 115.875 48.7833 115.875 42.0058 111.939L26.2643 102.975L10.5229 93.7928C3.96393 90.076 0.0285645 83.2985 0.0285645 75.6464V57.5V39.3536C0.0285645 31.4829 3.96393 24.924 10.5229 20.9886L26.2643 12.0247L42.0058 2.8422C48.7833 -0.874526 56.4354 -0.874526 62.9943 2.8422ZM42.0058 14.6483L20.7985 26.8916C14.021 30.827 10.0856 37.3859 10.0856 45.2567V69.7433C10.0856 77.3954 14.021 84.173 20.7985 88.1084L42.0058 100.352C48.7833 104.068 56.4354 104.068 62.9943 100.352L84.4202 88.1084C90.9791 84.173 94.9145 77.3954 94.9145 69.7433V45.2567C94.9145 37.3859 90.9791 30.827 84.4202 26.8916L62.9943 14.6483C56.4354 10.7129 48.7833 10.7129 42.0058 14.6483Z"
          fill="white"
        />
        <path
          className={styles["image-3"]}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.9944 34.7624L64.5248 35.4183L75.2378 41.3213C75.8936 41.7586 76.3309 42.4145 76.3309 43.289V55.0951V66.9011C76.3309 67.7757 75.8936 68.4316 75.2378 68.8688L64.5248 74.7719L53.5933 80.6749C52.9374 81.1122 52.2815 81.1122 51.6256 80.6749L40.694 74.7719L29.9811 68.8688C29.1066 68.4316 28.8879 67.7757 28.8879 66.9011V55.0951V43.289C28.8879 42.4145 29.1066 41.7586 29.9811 41.3213L40.694 35.4183L42.0058 34.7624C42.6617 34.3251 43.5362 34.3251 44.1921 34.7624C44.848 35.1996 45.0666 35.8555 45.0666 36.5114V56.8441C45.0666 57.9373 46.1598 58.8118 47.253 58.8118H57.7473C59.059 58.8118 59.9336 57.9373 59.9336 56.8441V36.5114C59.9336 35.8555 60.3708 35.1996 61.0267 34.7624C61.6826 34.3251 62.3385 34.3251 62.9944 34.7624Z"
          fill="white"
        />
        <path
          className={styles["image-3"]}
          d="M61.9013 71.9297H43.3176V111.502H61.9013V71.9297Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_203_103">
          <rect width={105} height={115} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Garage;
