import React, { useRef, useEffect } from "react";
import {
  PRELOADER_ANIMATION_ATLAS,
  PRELOADER_ANIMATION_JSON,
  PRELOADER_ANIMATION_PNG,
} from "./variables";
import { SpinePlayer } from "@esotericsoftware/spine-player";

const playerContainer = document.createElement("div");
export const player = new SpinePlayer(playerContainer, {
  jsonUrl: "carbox.json",
  atlasUrl: "carbox.atlas",
  rawDataURIs: {
    "carbox.json": PRELOADER_ANIMATION_JSON,
    "carbox.atlas": PRELOADER_ANIMATION_ATLAS,
    "carbox.png": PRELOADER_ANIMATION_PNG,
  },
  backgroundColor: "#00000000",
  animation: "idle_shop",
  premultipliedAlpha: true,
  viewport: {
    debugRender: false,
  },
  showControls: false,
  preserveDrawingBuffer: true,
  showLoading: false,
});

const ShopCarbox: React.FC = () => {
  const contRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cont = contRef.current as HTMLDivElement;
    cont.appendChild(playerContainer);
  }, []);

  return <div ref={contRef} className="spine-player-container"></div>;
};

export default ShopCarbox;
