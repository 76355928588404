import OpenLootbox from "components/Animations/ShopLootbox/OpenLootbox";
import GameButton from "components/Buttons/GameButton";
import InventoryCard from "components/InventoryCard";
import { IFeeError } from "interfaces/General";
import { IInventoryCard } from "interfaces/Inventory";
import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux";
import { addParts, removeLootboxes } from "redux/reducers/UserSlice";
import styles from "styles/components/gamePopup/lootbox.module.scss";
import Alert from "utils/alert";
import { LootboxesApi } from "utils/api/Lootboxes";
import ReceiveBnb from "../ReceiveBnb";
import { setGameContent } from "redux/reducers/PopupSlice";
import { selectUser } from "redux/selectors/userSelector";
import { useTranslation } from "react-i18next";

interface LootboxProps {
  ids: number[];
}

const Lootbox: React.FC<LootboxProps> = ({ ids }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectUser);

  const [dataSending, setDataSending] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const [cards, setCards] = useState<IInventoryCard[]>([]);
  const [fee, setFee] = useState("");
  const [error, setError] = useState<IFeeError>();
  const [activeCard, setActiveCard] = useState<IInventoryCard>({
    id: 0,
    className: "Muscle01",
    name: "Fenders",
    type: "detail",
    rarity: "epic",
    characteristics: {
      handling: 10,
      durability: 30,
      acceleration: 0,
    },
  });

  const handleOpen = () => {
    if (error?.type === "bnb") {
      dispatch(setGameContent(<ReceiveBnb type="bnb" />));
      return;
    }

    setDataSending(true);

    LootboxesApi.open(ids)
      .then((res) => {
        if (res) {
          setIsOpening(true);

          const formattedParts: IInventoryCard[] = [];

          res.parts.forEach((item, key) => {
            const formattedItem: IInventoryCard = {
              id: key,
              name: item.type,
              type: "detail",
              characteristics: item.specs,
              className: item.class,
              img: item.image,
              rarity: item.rarity,
              equipped: item.equipped,
            };

            formattedParts.push(formattedItem);
          });

          setCards(formattedParts);

          dispatch(addParts(res.parts));
          dispatch(removeLootboxes(ids));
        } else {
          setDataSending(false);
        }
      })
      .catch((e) => {
        Alert("error", t("default_error"));
      });
  };

  useEffect(() => {
    let activeIndex = 0;

    const changeFunction = () => {
      if (activeIndex + 1 <= cards.length) {
        setIsOpening(false);
        activeIndex++;

        setTimeout(() => {
          setActiveCard(cards[activeIndex]);
          setIsOpening(true);
          startTimer();
        }, 1000);
      }
    };

    const startTimer = () => {
      const timer = setTimeout(() => {
        if (activeIndex + 1 === cards.length) {
          clearTimeout(timer);
          return;
        }
        changeFunction();
      }, 3000);
    };

    if (cards) {
      setActiveCard(cards[0]);

      if (cards.length > 1) {
        startTimer();
      }
    }
  }, [cards]);

  useEffect(() => {
    LootboxesApi.openFee(ids).then((res) => {
      if (res) {
        setFee(res?.fee);
      }
    });
  }, [ids]);

  useEffect(() => {
    if (Number(fee) > Number(user?.user.balance.blockchain?.bnb)) {
      setError({
        type: "bnb",
        message: t("not_enough_bnb_error"),
      });
    }
  }, [fee, user]);

  return (
    <div className={styles.lootbox} onClick={(e) => e.stopPropagation()}>
      <div className={styles.lootbox__title}>Lootbox</div>
      <div className={styles.lootbox__image}>
        <OpenLootbox isOpening={isOpening} />

        <div
          className={`${styles.lootbox__card} ${
            isOpening ? styles["lootbox__card--scaled"] : ""
          }`}
        >
          <InventoryCard open {...activeCard} />
        </div>
      </div>
      <div className={styles.lootbox__gradient}></div>

      <div className={styles.lootbox__fee}>
        {t("blockchain_fee")}: <span>{fee}</span> BNB
      </div>

      <div className={styles.lootbox__button}>
        {!dataSending && !isOpening ? (
          <GameButton
            label={error ? `${t("deposit")} BNB` : t("open")}
            onClick={handleOpen}
          />
        ) : (
          !isOpening && (
            <span className={styles.lootbox__opening}>{t("opening")}...</span>
          )
        )}
      </div>

      {error && <div className={styles.lootbox__error}>{error.message}</div>}
    </div>
  );
};

export default Lootbox;
