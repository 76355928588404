import React from "react";
import styles from "styles/components/loader.module.scss";

const Loader: React.FC = () => {
  return (
    <div className={styles["lds-spinner"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
