import React, { useRef } from "react";
import styles from "./style.module.scss";
import SectionTitle from "../../../components/SectionTitle";
import Button from "../../../components/Button";
import { useIsomorphicLayoutEffect } from "../../../utils/useIsomorphicLayoutEffect";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const TgApp: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(() => {
    if (contentRef.current && imageRef.current) {
      gsap.registerPlugin(ScrollTrigger);

      const ctx = gsap.context(() => {
        gsap.set(imageRef.current, { perspective: 400 });
        gsap.set(contentRef.current, { perspective: 400 });

        gsap.from(imageRef.current, {
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "-600 0",
          },
          duration: 0.5,
          opacity: 0,
          scale: 1,
          delay: 0.5,
          x: -600,
          transformOrigin: "0% 50% -50",
          ease: "inOut",
          stagger: 0.025,
        });

        gsap.from(contentRef.current, {
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "-600 0",
          },
          duration: 0.5,
          opacity: 0,
          scale: 1,
          delay: 1,
          x: 600,
          transformOrigin: "0% 50% -50",
          ease: "inOut",
          stagger: 0.025,
        });
      });

      return () => ctx.revert();
    }
  }, [contentRef, imageRef]);

  return (
    <section ref={sectionRef} id="tg-app" className={styles.app}>
      <div className={styles.app__separator}>
        <img src={"/img/landing/tg-app/seperator.png"} alt="Separator" />
      </div>

      <div className={styles.app__top_separator}>
        <img src={"/img/landing/new/separator.png"} alt="separator" />
      </div>

      <div className="landing-container">
        <SectionTitle parentRef="#tg-app" label="Telegram app" />

        <div className={styles.app__pluses}>
          <img src={"/img/landing/pluses.png"} alt="Pluses" />
        </div>

        <div className={styles.app__row}>
          <div ref={imageRef} className={styles.app__img}>
            <img src={"/img/landing/tg-app/tg-app.png"} alt="Telegram app" />
          </div>

          <div ref={contentRef} className={styles.app__content}>
            <h4 className={styles.app__title}>Telegram app</h4>

            <p className={styles.app__text}>
              The Meta Racing relaunch will take some time, so to keep you
              entertained, we`&apos;`ve created a mini-game inside Telegram.
              Achieve high scores and receive nice bonuses when the main game is
              released.
            </p>

            <Button
              target="blank"
              href="https://t.me/MetaRacingGame_bot/start"
              label="Join now"
              className={styles.app__button}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TgApp;
