import React from "react";

interface AuthFrameProps {
  dark?: boolean;
}

const AuthFrame: React.FC<AuthFrameProps> = ({ dark }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"77.1rem"}
      height={"78.2rem"}
      viewBox="0 0 771 782"
      fill="none"
      style={
        dark
          ? {
              filter: "drop-shadow(0px 0px 7px rgba(41, 242, 217, 0.80))",
            }
          : undefined
      }
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M439.108 14.6746L424.433 0H357.074H310.033H126.94L103.009 23.9308H36.2246L10.0977 50.0577V192.247L0 202.365V546.366L14.5309 560.896V711.829L84.702 782H125.688L140.363 767.325H331.009L345.683 782H413.063H460.083H643.177L667.128 758.069H733.892L760.019 731.942V589.753L770.117 579.655V235.655L755.606 221.124V70.1711L685.415 0H644.449L629.775 14.6746H439.108Z"
        fill={dark ? "#180F1A" : "#16081B"}
      />
    </svg>
  );
};

export default AuthFrame;
