import React, { memo, useState, useEffect, useRef } from "react";
import styles from "styles/components/main-card.module.scss";
import CarFrames from "components/CarFrames";
import GameButton from "components/Buttons/GameButton";
import { Link, useNavigate } from "react-router-dom";
import { IConditions, RaceStatus } from "interfaces/Race";
import { startTimer } from "utils/startTimer";
import useSound from "use-sound";
import sound from "sounds/main_menu.mp3";
import soundHover from "sounds/navedenie.mp3";
import { useAppSelector } from "redux/hooks/redux";
import { selectPopup } from "redux/selectors/popupSelector";
import { renderChancePts } from "utils/renderChancePts";
import NewYearLights from "icons/NewYearLights";
import Cup from "icons/Cup";
import ChancePoints from "icons/ChancePoints";
import Racers from "icons/Racers";
import { useTranslation } from "react-i18next";

interface CardRace {
  racers?: number;
  reward?: string;
  timeToStart?: number;
  raceName?: string;
  status?: RaceStatus;
  avatars?: string[];
  conditions?: IConditions;
}

interface CardProps {
  img: string;
  icon: React.ReactNode;
  frame: React.ReactNode;
  clipPath: string;
  title?: string;
  text?: string;
  small?: boolean;
  race?: CardRace;
  href?: string;
  comingSoon?: boolean;
  label?: string;
  legal?: boolean;
  free?: boolean;
  longStreet?: boolean;
  street?: {
    racers: {
      current: number;
      required: number;
    };
    conditions: IConditions;
  };
  special?: boolean;
}

const Card: React.FC<CardProps> = memo(
  ({
    icon,
    img,
    small,
    text,
    title,
    race,
    frame,
    clipPath,
    href,
    comingSoon,
    legal,
    label,
    street,
    special,
    free,
    longStreet,
  }) => {
    const { t } = useTranslation();

    const [playClick] = useSound(sound);
    const [playHover] = useSound(soundHover);
    const navigate = useNavigate();
    const { muteSound } = useAppSelector(selectPopup);
    const timerRef = useRef<any>(null);

    const [timer, setTimer] = useState("");

    const classNames = `${styles.main_card} ${
      small ? styles["main_card--small"] : ""
    } ${race || comingSoon ? styles["main_card--race"] : ""} ${
      comingSoon ? styles["main_card--coming-soon"] : ""
    } ${legal ? styles["main_card--legal"] : ""} ${
      street ? styles["main_card--street"] : ""
    } ${special ? styles["main_card--special"] : ""} ${
      longStreet ? styles["main_card--long-street"] : ""
    }`;

    useEffect(() => {
      if (race?.timeToStart) {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
        timerRef.current = startTimer(race?.timeToStart, setTimer);
      }
    }, [race?.timeToStart]);

    return (
      <div
        onMouseOver={() => {
          !muteSound && playHover();
        }}
        style={{ clipPath: "url(#" + clipPath + ")" }}
        className={classNames}
      >
        <div className={styles.main_card__frame}>{frame}</div>

        <div className={styles.main_card__content}>
          {special && (
            <div className={styles.main_card__lights}>
              <NewYearLights />
            </div>
          )}

          {href && (
            <Link
              onClick={() => {
                !muteSound && playClick();
              }}
              className={styles.main_card__link}
              to={href}
            ></Link>
          )}

          {longStreet && race?.racers !== undefined && race?.conditions && (
            <div className={styles.main_card__top_row}>
              <div className={styles.main_card__racers}>
                <div className={styles.main_card__info_icon}>
                  <Racers />
                </div>

                <div
                  className={`${styles.main_card__info_text} ${styles["main_card__info_text--legal"]}`}
                >
                  <span>{t("racers")}</span>
                  {race.racers}
                </div>
              </div>

              <div
                className={`${styles.main_card__row} ${styles["main_card__row--right"]}`}
              >
                <div>
                  <span className={styles.main_card__label}>
                    {t("chance_pts")}
                  </span>
                  <span className={styles.main_card__info}>
                    {renderChancePts(race.conditions)}
                  </span>
                </div>
                <ChancePoints />
              </div>
            </div>
          )}

          {race?.racers !== undefined && race?.timeToStart !== undefined && (
            <>
              <div className={styles.main_card__top_row}>
                <div className={styles.main_card__racers}>
                  <div className={styles.main_card__info_icon}>
                    <Racers />
                  </div>

                  <div
                    className={`${styles.main_card__info_text} ${styles["main_card__info_text--legal"]}`}
                  >
                    <span>{t("racers")}</span>
                    {race.racers}
                  </div>
                </div>

                {race.status === "opened" || !special ? (
                  <div>
                    <span className={styles.main_card__label}>
                      {t("before_start")}
                    </span>
                    <span className={styles.main_card__info}>
                      {timer !== "" ? timer : "..."}
                    </span>
                  </div>
                ) : race.status === "live" ? (
                  <div className={styles.main_card__live}>{t("live")}</div>
                ) : (
                  <></>
                )}
              </div>

              {legal && !free && !special && (
                <div className={styles.main_card__top_second_row}>
                  <div className={styles.main_card__row}>
                    <Cup />
                    <div>
                      <span className={styles.main_card__label}>
                        {t("distribution_text_2")}
                      </span>
                      <span className={styles.main_card__info}>
                        {title?.includes("unlimited") ? "30%" : "70%"}
                      </span>
                    </div>
                  </div>

                  {!title?.includes("newbies") && (
                    <div
                      className={`${styles.main_card__row} ${styles["main_card__row--right"]}`}
                    >
                      <div>
                        <span className={styles.main_card__label}>
                          {t("chance_pts")}
                        </span>
                        <span className={styles.main_card__info}>
                          {title?.includes("unlimited")
                            ? "unlimited"
                            : Number(race.conditions?.points.max) > 0
                            ? Number(race.conditions?.points.min) +
                              "-" +
                              race.conditions?.points.max
                            : "-"}
                        </span>
                      </div>
                      <ChancePoints />
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          <div className={styles.main_card__img}>
            <img src={img} alt="Racing" />
          </div>

          <div
            className={`${styles.main_card__icon} ${
              race?.raceName ? styles["main_card__icon--race"] : ""
            }`}
          >
            {icon}
          </div>

          {label && <div className={styles.main_card__soon_label}>{label}</div>}

          {comingSoon ? (
            <>
              <div className={styles.main_card__soon}>{t("coming_soon")}</div>
              <div className={styles.main_card__text}>{text}</div>
            </>
          ) : (
            <>
              {race?.raceName && (
                <div className={styles.main_card__race_name}>
                  {race.raceName}
                </div>
              )}

              <div className={styles.main_card__title}>{title}</div>

              {street && (
                <div className={styles.main_card__race_info}>
                  <div className={styles.main_card__racers}>
                    <div className={styles.main_card__info_icon}>
                      <Racers />
                    </div>

                    <div className={styles.main_card__info_text}>
                      <span>{t("racers")}</span>
                      {street.racers.current}/{street.racers.required}
                    </div>
                  </div>
                  <div className={styles.main_card__chance_points}>
                    <div
                      className={`${styles.main_card__info_icon} ${styles["main_card__info_icon--chance"]}`}
                    >
                      <ChancePoints />
                    </div>

                    <div className={styles.main_card__info_text}>
                      <span>{t("chance_pts")}</span>
                      {renderChancePts(street.conditions)}
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.main_card__text}>{text}</div>

              {legal && race?.reward && (
                <div className={styles.main_card__reward}>
                  {race.reward} <span>mgt</span>
                </div>
              )}

              {legal && <CarFrames avatars={race?.avatars || []} />}

              {!small && !legal && race && (
                <GameButton label={t("join")} onClick={() => navigate(href!)} />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default Card;
