import CounterBorder from "icons/CounterBorder";
import CounterMinus from "icons/CounterMinus";
import CounterPlus from "icons/CounterPlus";
import { SetState } from "interfaces/General";
import React, { useEffect, useRef } from "react";
import { useAppSelector } from "redux/hooks/redux";
import { selectUser } from "redux/selectors/userSelector";
import styles from "styles/components/counter.module.scss";
import useSound from "use-sound";
import sound from "sounds/click_button.mp3";
import { selectPopup } from "redux/selectors/popupSelector";

interface CounterProps {
  count: number;
  setCount: SetState<number>;
  disabled?: boolean;
  changable?: boolean;
}

const START_DELAY = 300;
const CONTINIOUS_DELAY = 100;

const Counter: React.FC<CounterProps> = ({
  count,
  setCount,
  disabled,
  changable,
}) => {
  const [play] = useSound(sound);

  const { muteSound } = useAppSelector(selectPopup);
  const { user } = useAppSelector(selectUser);

  const timer = useRef<NodeJS.Timer>();

  const incrementCallback = () => {
    timeoutClear();

    timer.current = setInterval(() => {
      setCount((prev) => prev + 1);
    }, CONTINIOUS_DELAY);
  };

  const decrementCallback = () => {
    timeoutClear();

    timer.current = setInterval(() => {
      if (count !== 1) setCount((prev) => prev - 1);
    }, CONTINIOUS_DELAY);
  };

  const increment = () => {
    !muteSound && play();

    setCount((prev) => prev + 1);

    timer.current = setInterval(() => incrementCallback(), START_DELAY);
  };

  const decrement = () => {
    !muteSound && play();

    if (count !== 0) setCount((prev) => prev - 1);

    timer.current = setInterval(() => decrementCallback(), START_DELAY);
  };

  const timeoutClear = () => {
    clearInterval(timer.current);
  };

  useEffect(() => {
    if (count <= 0) timeoutClear();
    if (disabled) timeoutClear();
  }, [count, disabled]);

  return (
    <div className={styles.counter}>
      {changable && (
        <div
          className={`${styles.counter__minus} ${
            count === 0 || !user ? styles["counter__minus--disabled"] : ""
          }`}
          onMouseDown={decrement}
          onMouseUp={timeoutClear}
        >
          <CounterMinus />
        </div>
      )}

      <div className={styles.counter__border}>
        <CounterBorder />
        <span className={styles.counter__value}>{count}</span>
      </div>

      {changable && (
        <div
          className={`${styles.counter__plus} ${
            !user || disabled ? styles["counter__plus--disabled"] : ""
          }`}
          onMouseDown={increment}
          onMouseUp={timeoutClear}
        >
          <CounterPlus />
        </div>
      )}
    </div>
  );
};

export default Counter;
