import React from "react";

const ChancePoints: React.FC = () => {
  return (
    <svg
      width={"3.8rem"}
      height={"3.8rem"}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7363 10.2303L26.0448 11.2927C27.2183 11.4031 28.0947 12.5224 27.9918 13.7806L27.0025 25.9067C26.8996 27.1648 25.8556 28.1045 24.6821 27.9941L13.3719 26.9317C12.1984 26.8214 11.322 25.702 11.4249 24.4439L12.4141 12.3178C12.517 11.0596 13.5611 10.12 14.7363 10.2303ZM24.2439 13.0207C25.3609 13.0207 26.2689 13.9942 26.2689 15.1936C26.2689 16.3913 25.3609 17.3647 24.2439 17.3647C23.1251 17.3647 22.2172 16.3913 22.2172 15.1936C22.2172 13.9942 23.1251 13.0207 24.2439 13.0207ZM15.3272 20.9701C16.4459 20.9701 17.3522 21.9418 17.3522 23.1412C17.3522 24.3406 16.4459 25.3123 15.3272 25.3123C14.2084 25.3123 13.3021 24.3406 13.3021 23.1412C13.3021 21.9418 14.2084 20.9701 15.3272 20.9701ZM19.7855 17.0088C20.9042 17.0088 21.8105 17.9822 21.8105 19.1799C21.8105 20.3793 20.9042 21.3509 19.7855 21.3509C18.6668 21.3509 17.7605 20.3793 17.7605 19.1799C17.7605 17.9822 18.6668 17.0088 19.7855 17.0088ZM11.8631 2.28807C12.9801 2.28807 13.8881 3.25972 13.8881 4.45916C13.8881 5.65859 12.9801 6.63024 11.8631 6.63024C10.7443 6.63024 9.83805 5.65859 9.83805 4.45916C9.83805 3.25972 10.7443 2.28807 11.8631 2.28807ZM6.92004 13.5671C8.03878 13.5671 8.94505 14.5405 8.94505 15.7399C8.94505 16.9376 8.03878 17.911 6.92004 17.911C5.8013 17.911 4.89503 16.9376 4.89503 15.7399C4.89503 14.5405 5.8013 13.5671 6.92004 13.5671ZM4.16968 5.31869C5.28841 5.31869 6.19469 6.29212 6.19469 7.49156C6.19469 8.68922 5.28841 9.66265 4.16968 9.66265C3.05094 9.66265 2.14466 8.68922 2.14466 7.49156C2.14466 6.29212 3.05094 5.31869 4.16968 5.31869ZM12.0788 0.14011L1.40935 4.30255C0.302234 4.73499 -0.273732 6.059 0.127951 7.24598L4.01033 18.6851C4.41367 19.8721 5.6486 20.4896 6.75572 20.0572L10.2314 18.7011L10.7659 12.163C10.9468 9.93849 12.8042 8.26746 14.8807 8.46143L17.2576 8.68566L14.8242 1.51395C14.4209 0.326966 13.186 -0.290549 12.0788 0.14011Z"
        fill="white"
      />
    </svg>
  );
};

export default ChancePoints;
