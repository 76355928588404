import React from "react";

const Fifth: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"43.7rem"}
      height={"12.3rem"}
      viewBox="0 0 437 123"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.519531 125.89V21.9616L22.4812 0H324.687L347.583 22.8962H411.482L436.481 47.895V123.32L422.346 138H83.1871L70.4541 125.89H0.519531Z"
        fill="#16081B"
      />

      <clipPath id="fifth-clip-path" clipPathUnits="objectBoundingBox">
        <path d="M0.001,0.912 V0.159 L0.052,0 H0.745 L0.797,0.166 H0.944 L1,0.347 V0.894 L0.969,1 H0.191 L0.162,0.912 H0.001"></path>
      </clipPath>
    </svg>
  );
};

export default Fifth;
