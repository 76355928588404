import Check from "icons/Check";
import React from "react";
import styles from "styles/components/auth.module.scss";

interface StepsProps {
  active: number;
}

const Steps: React.FC<StepsProps> = ({ active }) => {
  const stepsList = [1, 2, 3, 4];

  return (
    <ul className={styles.auth__steps}>
      {stepsList.map((item) => (
        <li
          key={item}
          className={`${styles.auth__step} ${
            active === item ? styles["auth__step--active"] : ""
          } ${active > item ? styles["auth__step--passed"] : ""}`}
        >
          {active > item ? <Check /> : item}
        </li>
      ))}
    </ul>
  );
};

export default Steps;
