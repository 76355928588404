import Tabs from "components/Tabs";
import { ITabItem, joinRaceTabTypes } from "interfaces/Tabs";
import React, { useState, useEffect, useMemo } from "react";
import styles from "styles/pages/joinRace/join-race.module.scss";
import CurrentRaceTab, { CurrentRaceTabProps } from "./CurrentRaceTab";
import HistoryTab from "./HistoryTab";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import GoBack from "components/GoBack";
import DistributionTab from "./DistributionTab";
import { IDistributionItem, IPool } from "./DistributionItem";
import SmoothImage from "components/SmoothImage";
import { inventoryBgHash } from "constants/blurhashes";
import { useTranslation } from "react-i18next";
import ParticipantsTab from "./ParticipantsTab";
import { IParticipantItem } from "interfaces/Car";

interface JoinRaceProps extends CurrentRaceTabProps {
  isLoading: boolean;
  prizes?: IDistributionItem[];
  pool?: IPool;
  participants?: IParticipantItem[];
}

const JoinRace: React.FC<JoinRaceProps> = ({
  cars,
  info,
  qualification,
  isLoading,
  prizes,
  pool,
  participants,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<joinRaceTabTypes>("cur");

  const tabs: ITabItem<joinRaceTabTypes>[] = useMemo(
    () => [
      {
        key: "cur",
        label: t("join_race_tab_1"),
      },

      {
        key: "par",
        label: t("join_race_tab_4"),
      },

      {
        key: "dis",
        label: t("join_race_tab_2"),
      },

      {
        key: "his",
        label: t("join_race_tab_3"),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (!localStorage.getItem("access_token") && navigate) {
      navigate(ROUTES_PATH.LOGIN);
    }
  }, [navigate]);

  const renderTabs = () => {
    if (qualification) {
      return (
        <CurrentRaceTab
          isLoading={isLoading}
          info={info}
          cars={cars}
          qualification={true}
        />
      );
    }

    if (activeTab === "cur") {
      return <CurrentRaceTab isLoading={isLoading} info={info} cars={cars} />;
    }

    if (activeTab === "his") {
      return <HistoryTab />;
    }

    if (activeTab === "dis" && pool && prizes) {
      return (
        <DistributionTab
          pool={pool}
          prizes={prizes}
          legal={pathname.includes("legal")}
          special={pathname.includes("special")}
        />
      );
    }

    if (participants)
      return (
        <ParticipantsTab
          isLoading={isLoading}
          info={info}
          cars={participants}
        />
      );
  };

  return (
    <div className={styles.join_race}>
      <GoBack />

      <div className={styles.join_race__bg}>
        <SmoothImage
          src="/img/inventory-bg.webp"
          alt="Cyberpunk city"
          hash={inventoryBgHash}
        />
      </div>

      <div className={styles.join_race__content}>
        {!qualification && (
          <Tabs
            morePadding
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {renderTabs()}
      </div>
    </div>
  );
};

export default JoinRace;
