import { IAdvancedRace, IGetRaceResponse } from "interfaces/Race";
import { startTimer } from "./startTimer";
import { formatCars } from "./formatCars";
import { SetState } from "interfaces/General";
import { IAdvancedCarItem } from "interfaces/Car";

interface updateRaceCallbackParams {
  data: IGetRaceResponse;
  timeToStart: string;
  startsIn: React.MutableRefObject<number | null>;
  timerRef: React.MutableRefObject<NodeJS.Timer | null>;
  setTimeToStart: SetState<string>;
  setCars: SetState<IAdvancedCarItem[]>;
  carIds: number[];
  setRace: SetState<IAdvancedRace | undefined>;
}

export const updateRaceCallback = ({
  data,
  timeToStart,
  startsIn,
  timerRef,
  setTimeToStart,
  setCars,
  carIds,
  setRace,
}: updateRaceCallbackParams) => {
  if (data.race) {
    setRace(data.race);

    if (
      (data.race.startIn &&
        Number(data.race.startIn) > Number(startsIn.current)) ||
      timeToStart === ""
    ) {
      startsIn.current = data.race.startIn;
      if (timerRef.current) clearTimeout(timerRef.current);

      if (!!data.race.startIn)
        timerRef.current = startTimer(data.race.startIn, setTimeToStart);
    }
  }

  let formattedCars = null;

  if (data.user) formattedCars = formatCars(data.user.inventory.cars, carIds);
  if (formattedCars) setCars(formattedCars);
};
